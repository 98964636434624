/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '../core/CustomIcon';
export const DropArrowOpenedIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M8 10L4 6H12L8 10Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="DropArrowOpenedIcon" data-sentry-source-file="DropArrowOpenedIcon.tsx" />;