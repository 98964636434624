import { FC, MouseEvent, ReactNode } from 'react';
import * as I from '../imported/components';
const SIDEBAR_BORDER_COLOR = '#303030'; // naturals650
const SIDEBAR_FOCUS_BACKGROUND_COLOR = '#474747'; // naturals600

export const Sidebar: FC<{
  isTokensSelected: boolean;
  onTokenClick: (e: MouseEvent<HTMLButtonElement>) => void;
  sideBarItems: ReactNode;
}> = ({
  isTokensSelected,
  onTokenClick,
  sideBarItems
}) => {
  return <I.LeftNav style={{
    borderRight: `1px solid ${SIDEBAR_BORDER_COLOR}`
  }} LeftNavHeader_Props={{
    style: {
      borderBottom: `1px solid ${SIDEBAR_BORDER_COLOR}`,
      boxSizing: 'content-box'
    }
  }} LeftNavContent_Props={{
    children: sideBarItems
  }} Token_management_item_Props={{
    style: {
      borderBottom: `1px solid ${SIDEBAR_BORDER_COLOR}`
    },
    Token_button_Props: {
      disabled: false,
      hierarchy: 'primary',
      onClick: onTokenClick,
      ...(isTokensSelected && {
        style: {
          backgroundColor: SIDEBAR_FOCUS_BACKGROUND_COLOR
        }
      })
    }
  }} data-sentry-element="unknown" data-sentry-component="Sidebar" data-sentry-source-file="Sidebar.tsx" />;
};