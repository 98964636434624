import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { FlexDirectionField } from './flexDirection';
import { FlexWrapField } from './flexWrap';
export const FlexDirectionAndWrap: FC = () => {
  const {
    shouldRender
  } = useSubModule(DDPSubModulesKeys.flexDirectionAndWrapping);
  if (!shouldRender) return null;
  return <SubModule title="Direction / Wrapping" data-sentry-element="SubModule" data-sentry-component="FlexDirectionAndWrap" data-sentry-source-file="FlexDirectionAndWrap.tsx">
      <MuiGrid container sx={{
      flexDirection: 'row',
      alignItems: 'center',
      gap: '16px'
    }} data-sentry-element="MuiGrid" data-sentry-source-file="FlexDirectionAndWrap.tsx">
        <FlexDirectionField data-sentry-element="FlexDirectionField" data-sentry-source-file="FlexDirectionAndWrap.tsx" />
        <FlexWrapField data-sentry-element="FlexWrapField" data-sentry-source-file="FlexDirectionAndWrap.tsx" />
      </MuiGrid>
    </SubModule>;
};