/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const JustifyContentSpaceAroundRowDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M2.5 2C2.22386 2 2 2.22386 2 2.5V13.5C2 13.7761 2.22386 14 2.5 14C2.77614 14 3 13.7761 3 13.5V2.5C3 2.22386 2.77614 2 2.5 2Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M13.5 2C13.2239 2 13 2.22386 13 2.5V13.5C13 13.7761 13.2239 14 13.5 14C13.7761 14 14 13.7761 14 13.5V2.5C14 2.22386 13.7761 2 13.5 2Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M11 10C11 10.5523 10.5523 11 10 11C9.44772 11 9 10.5523 9 10L9 6C9 5.44772 9.44771 5 10 5C10.5523 5 11 5.44772 11 6L11 10Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M6 11C6.55229 11 7 10.5523 7 10L7 6C7 5.44772 6.55228 5 6 5C5.44772 5 5 5.44772 5 6L5 10C5 10.5523 5.44772 11 6 11Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="JustifyContentSpaceAroundRowDDPIcon" data-sentry-source-file="JustifyContentSpaceAroundRowDDPIcon.tsx" />;