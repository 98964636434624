import { FC, PropsWithChildren } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { IconButton } from '@jux/ui/components';
import * as S from './Modal.style';
export const ModalWrapper: FC<PropsWithChildren<{
  onClose: () => void;
}>> = ({
  onClose,
  children
}) => <S.Paper container data-sentry-element="unknown" data-sentry-component="ModalWrapper" data-sentry-source-file="ModalWrapper.tsx">
    <MuiGrid sx={{
    position: 'absolute',
    top: '0',
    right: '0',
    padding: '10px'
  }} data-sentry-element="MuiGrid" data-sentry-source-file="ModalWrapper.tsx">
      <IconButton icon="CLOSE" variant="icon" onClick={onClose} data-sentry-element="IconButton" data-sentry-source-file="ModalWrapper.tsx" />
    </MuiGrid>
    {children}
  </S.Paper>;