/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from './core/CustomIcon';
export const CloseSmallIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M10.7838 5.2162C10.4955 4.92793 10.0281 4.92793 9.73987 5.2162L8 6.95608L6.26013 5.2162C5.97186 4.92793 5.50448 4.92793 5.2162 5.2162C4.92793 5.50448 4.92793 5.97186 5.2162 6.26013L6.95608 8L5.2162 9.73987C4.92793 10.0281 4.92793 10.4955 5.2162 10.7838C5.50448 11.0721 5.97186 11.0721 6.26013 10.7838L8 9.04392L9.73987 10.7838C10.0281 11.0721 10.4955 11.0721 10.7838 10.7838C11.0721 10.4955 11.0721 10.0281 10.7838 9.73987L9.04392 8L10.7838 6.26013C11.0721 5.97186 11.0721 5.50448 10.7838 5.2162Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="CloseSmallIcon" data-sentry-source-file="CloseSmallIcon.tsx" />;