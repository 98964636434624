import merge from 'lodash/merge';
import { CSSProperties, useCallback } from 'react';
import { getComponentStyles, getResolvedContextStyles } from '@jux/canjux/core';
import { StyleStatesObject, StylesObject, VariantsValues } from '@jux/types';
import { useNodeParentContextState } from '@jux/ui/components/editor/hooks/useNodeParentContextState';
import { PLACEHOLDER_PSEUDO_ELEMENT } from '@jux/constants';
import { NodeInteractiveState } from '@jux/data-entities';

const getMergedStyles = ({
  rootStyles,
  variantsStyles,
  contextStyles,
  isPlaceholderStyles,
}: {
  rootStyles: StylesObject | undefined;
  variantsStyles: (StylesObject | StyleStatesObject)[] | undefined;
  contextStyles: StylesObject[] | undefined;
  isPlaceholderStyles?: boolean;
}) => {
  const finalStyles = merge(
    {},
    rootStyles,
    ...(variantsStyles ?? []),
    ...(contextStyles ?? [])
  );

  if (isPlaceholderStyles) {
    return finalStyles[PLACEHOLDER_PSEUDO_ELEMENT];
  }

  return finalStyles;
};

export const useNodeStyles = () => {
  const { getIsNodeParentContextOnById } = useNodeParentContextState();

  const getNodeStyles = useCallback(
    ({
      id,
      variantsValues,
      nodeState = 'default',
      parentContextId,
      isPlaceholderStyles,
    }: {
      // the node id
      id: string;
      // the properties of the current node
      variantsValues: VariantsValues;
      // the interactive state of the node
      nodeState?: NodeInteractiveState;
      // the context id in which the node is currently at
      parentContextId?: string;

      isPlaceholderStyles?: boolean;
    }): Partial<CSSProperties> => {
      // node styles
      const { rootStyles, variantsStyles } =
        getComponentStyles()({
          id,
          interactiveState: nodeState,
          variantsValues,
        }) || {};

      let contextStyles = undefined;
      if (parentContextId && getIsNodeParentContextOnById(id)) {
        contextStyles = getResolvedContextStyles()({
          id,
          parentContextId,
        });
      }

      // resolve styles
      return getMergedStyles({
        contextStyles,
        variantsStyles,
        isPlaceholderStyles,
        rootStyles,
      });
    },
    [getIsNodeParentContextOnById]
  );

  return {
    getNodeStyles,
  };
};
