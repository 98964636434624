import { FC, FocusEvent, KeyboardEvent, useCallback } from 'react';
import { Input } from '../input';
import { NumericFieldContentProps } from './NumericFieldContent.interface';
export const NumericFieldContent: FC<NumericFieldContentProps> = ({
  inputProps: {
    onBlur,
    onEnter,
    onEscape,
    onFocus,
    inputRegister,
    ...inputProps
  },
  isFieldDisabled,
  isComputed,
  htmlForTarget
}) => {
  const handleOnEnter = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    onEnter(e);
    e.currentTarget.blur();
  }, [onEnter]);
  const handleOnEscape = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    onEscape(e);
    e.currentTarget.blur();
  }, [onEscape]);
  const handleOnBlur = useCallback((e: FocusEvent<HTMLInputElement>) => {
    onBlur(e);
  }, [onBlur]);
  const handleOnFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
    onFocus?.(e);
  }, [onFocus]);
  return <Input id={htmlForTarget} isComputed={isComputed} onEscape={handleOnEscape} onEnter={handleOnEnter} {...inputProps} {...inputRegister()}
  // make sure to render onBlur after inputRegister() to override it, otherwise it will be not call the correct onBlur
  // due to the fact we have specific logic we have with Escape that it both discardChanges and blur the element and on this case we don't want to save (when save is the default behavior of onBlur from react-hook-form)
  onBlur={handleOnBlur} onFocus={handleOnFocus} isFieldDisabled={isFieldDisabled} withAutoWidth data-sentry-element="Input" data-sentry-component="NumericFieldContent" data-sentry-source-file="NumericFieldContent.tsx" />;
};