import { FC, PropsWithChildren, ReactNode } from 'react';
import { PopperDirection } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import * as S from './NavigationPopper.style';
export const NavigationPopper: FC<PropsWithChildren<{
  header?: ReactNode;
  popperDirection?: PopperDirection;
  testId?: string;
}>> = ({
  children,
  header,
  popperDirection = 'ltr',
  testId
}) => <S.Wrapper data-testid={testId} popperDirection={popperDirection} data-sentry-element="unknown" data-sentry-component="NavigationPopper" data-sentry-source-file="NavigationPopper.tsx">
    <S.WrapperContent data-sentry-element="unknown" data-sentry-source-file="NavigationPopper.tsx">
      <S.ContentHeader data-sentry-element="unknown" data-sentry-source-file="NavigationPopper.tsx">{header}</S.ContentHeader>

      <MuiGrid data-sentry-element="MuiGrid" data-sentry-source-file="NavigationPopper.tsx">{children}</MuiGrid>
    </S.WrapperContent>
  </S.Wrapper>;