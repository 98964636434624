import { COMPONENT_TAG_NAME, ComponentElementType, ComponentProps } from '@jux/data-entities';
import parse from 'html-react-parser';
import { forwardRef, JSX, ReactElement, useMemo } from 'react';
export const Svg = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxSvg>, ComponentProps<typeof COMPONENT_TAG_NAME.JuxSvg>>(({
  content,
  children,
  rootElement: RootElement = 'svg',
  ...props
}, ref) => useMemo(() => {
  if (!content) return null;
  return parse(content, {
    trim: true,
    transform: (reactNode, domNode) => {
      // pass jux props only to root element
      if ('name' in domNode && domNode.name === 'svg') {
        return <RootElement {...props} {...(reactNode as ReactElement).props} ref={ref} />;
      }
      return reactNode;
    }
  }) as JSX.Element;
}, [RootElement, content, props, ref]));