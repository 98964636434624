import { FC, useState } from 'react';
import capitalize from 'lodash/capitalize';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { IconButton, TextField, Typography } from '@jux/ui/components';
import { SegmentEventSourcePage } from '@jux/data-access/analytics/track-events-properties';
import { UseFieldFormRegisterReturn } from './useFieldForm';
import { useSaveInviteMembersForm } from './useSaveInviteMembersForm';
export type InviteMembersContentProps = {
  sourcePage: SegmentEventSourcePage;
  registerEmailField: () => UseFieldFormRegisterReturn;
  getEmailValue: () => string;
  setEmailValue: (value: string) => void;
  closeSubmitModal: () => void;
};
export const InviteMembersContent: FC<InviteMembersContentProps> = ({
  sourcePage,
  getEmailValue,
  registerEmailField,
  setEmailValue,
  closeSubmitModal
}) => {
  const [errors, setErrors] = useState<string>();
  const {
    onSubmit
  } = useSaveInviteMembersForm({
    sourcePage,
    setEmailValue,
    getEmailValue,
    setErrors,
    closeSubmitModal
  });
  return <>
      <MuiGrid container item gap="6px" data-sentry-element="MuiGrid" data-sentry-source-file="InviteMembersContent.tsx">
        <MuiGrid item data-sentry-element="MuiGrid" data-sentry-source-file="InviteMembersContent.tsx">
          <Typography variant="label-s-medium" color={theme => theme.drimz.palette.text.secondary} data-sentry-element="Typography" data-sentry-source-file="InviteMembersContent.tsx">
            Add your colleague email here, the rest is on us
          </Typography>
        </MuiGrid>
        <MuiGrid item container data-sentry-element="MuiGrid" data-sentry-source-file="InviteMembersContent.tsx">
          <TextField type="email" height="32px" label="" fullWidth autoFocus placeholder="name@example.com" onEnter={onSubmit} {...registerEmailField()} error={Boolean(errors)} helperText={errors && <Typography variant="label-s-regular" color={theme => theme.drimz.palette.error.main}>
                  {capitalize(errors)}
                </Typography>} data-sentry-element="TextField" data-sentry-source-file="InviteMembersContent.tsx" />
        </MuiGrid>
      </MuiGrid>
      <MuiGrid item container marginTop="24px" data-sentry-element="MuiGrid" data-sentry-source-file="InviteMembersContent.tsx">
        <MuiGrid item data-sentry-element="MuiGrid" data-sentry-source-file="InviteMembersContent.tsx">
          <IconButton onClick={onSubmit} variant="primary" size="big" data-sentry-element="IconButton" data-sentry-source-file="InviteMembersContent.tsx">
            Send invite
          </IconButton>
        </MuiGrid>
      </MuiGrid>
    </>;
};