import * as CSS from 'csstype';
import { useCallback } from 'react';
import { dedupeContextStyles } from '@jux/calculate-styles';
import {
  getContextParentId,
  getNodeComponent,
  selectResolvedComponentProps,
  storeApi,
  useStore,
  useStoreActions,
  VariantsEditingContext,
} from '@jux/canjux/core';
import { useNodeParentContextState } from '@jux/ui/components/editor/hooks';
import { NodeInteractiveState, NodeType } from '@jux/data-entities';
import { DEFAULT_STATE } from '@jux/types';
import {
  stylesBySelectedNodeState,
  withInteractiveState,
} from './useSaveSelectedNodeDDPChanges.utils';

export const useSaveSelectedNodeDDPChanges = () => {
  const getContextParentIdByNodeId = useStore(getContextParentId);
  const getNodeComponentByNodeId = useStore(getNodeComponent);
  const {
    ddpActions: { updateComponentStyles },
  } = useStoreActions();

  const { getIsNodeParentContextOnById } = useNodeParentContextState();

  const saveStyleChanges = useCallback(
    ({
      nodeId,
      nodeInteractiveState,
      variantsEditingContext,
      newStyle,
    }: {
      nodeId: string;
      nodeInteractiveState: NodeInteractiveState;
      variantsEditingContext: VariantsEditingContext;
      newStyle: CSS.Properties | { [key: string]: CSS.Properties };
    }) => {
      const isNodeParentContextOn = getIsNodeParentContextOnById(nodeId);
      const component = getNodeComponentByNodeId(nodeId);
      const contextParentId = getContextParentIdByNodeId(nodeId);

      if (
        !component ||
        component.type === NodeType.INSTANCE ||
        component.type === NodeType.VARIANTS_GROUP
      )
        return;

      // Todo: refactor this to work for variant instances
      if (component.type === NodeType.VARIANT_INSTANCE) {
        //TODO: implement styled changes for specific variant - saveStylesForVariantNode({})
        return;
      }
      if (!component?.tagName) return;

      if (contextParentId && isNodeParentContextOn) {
        // Update the context parent instead of the selected node
        const { styles: contextParentComponentStyles } =
          getNodeComponentByNodeId(contextParentId) ?? {};
        if (!contextParentComponentStyles) return;

        const contextParentProps = selectResolvedComponentProps({
          id: contextParentId,
          onlyVariantsProps: true,
        })(storeApi.getState());

        if (!contextParentProps) return;

        const parentContextComponent =
          getNodeComponentByNodeId(contextParentId);
        const contextParentInteractiveState =
          parentContextComponent?.config.interactiveState ?? DEFAULT_STATE;

        const effectiveContextId = component.config.contextId ?? nodeId;

        // context parent styles
        const contextStyles = (
          contextParentComponentStyles.contextStyles || []
        ).concat([
          {
            styles: withInteractiveState({
              nodeInteractiveState,
              styles: newStyle,
            }),
            contextChildUuid: effectiveContextId,
            condition: {
              state: contextParentInteractiveState,
              propsValues: contextParentProps,
            },
          },
        ]);

        const newContextStyles = dedupeContextStyles({
          ...contextParentComponentStyles,
          contextStyles,
        });

        updateComponentStyles({
          nodeId: contextParentId,
          styles: newContextStyles,
        });
      } else {
        const newStyles = stylesBySelectedNodeState({
          currentStyles: component.styles,
          nodeInteractiveState,
          variantsEditingContext: variantsEditingContext,
          newCss: newStyle,
        });

        updateComponentStyles({ nodeId, styles: newStyles });
      }
    },
    [
      getContextParentIdByNodeId,
      getIsNodeParentContextOnById,
      getNodeComponentByNodeId,
      updateComponentStyles,
    ]
  );

  return { saveStyleChanges };
};
