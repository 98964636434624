/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const PaddingBottomDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M5.25 9C5.25 8.58579 5.58579 8.25 6 8.25H10C10.4142 8.25 10.75 8.58579 10.75 9C10.75 9.41421 10.4142 9.75 10 9.75H8.75L8.75 12.5H12.5L12.5 3.5L3.5 3.5L3.5 12.5H7.25L7.25 9.75H6C5.58579 9.75 5.25 9.41421 5.25 9ZM13 14C13.5523 14 14 13.5523 14 13L14 3C14 2.44772 13.5523 2 13 2L3 2C2.44772 2 2 2.44772 2 3L2 13C2 13.5523 2.44772 14 3 14L13 14Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="PaddingBottomDDPIcon" data-sentry-source-file="PaddingBottomDDPIcon.tsx" />;