import { VariantsValues } from '@jux/types';
import type { Draft as WritableDraft } from 'mutative';
import { getDefaultNodePropertiesByTagName, JuxStore } from '../wip';
import {
  ComponentInstanceData,
  ComponentSourceData,
  JuxComponentData,
  NodeData,
  NodeInteractiveState,
  NodeType,
  VariantsConfig,
} from '@jux/data-entities';
import { v4 as uuidv4 } from 'uuid';

const variantNodeDataBySource = ({
  sourceComponent,
  state,
}: {
  sourceComponent: JuxComponentData;
  state: WritableDraft<JuxStore>;
}): NodeData => {
  if (sourceComponent?.tagName) {
    return {
      properties: getDefaultNodePropertiesByTagName({
        tagName: sourceComponent?.tagName,
        type: sourceComponent?.type,
      }),
    };
  }

  const isInstanceInsideInstance = sourceComponent?.parentId
    ? state.components[sourceComponent.parentId]?.type === NodeType.INSTANCE
    : false;

  return {
    properties: {
      isContainer: false, // source is an instance and instances are not containers
      isDeletable: !isInstanceInsideInstance,
      isDraggable: !isInstanceInsideInstance,
      isDragged: false,
      isHidden: false,
      isImmutable: true,
      isSelectable: true,
    },
  } as NodeData;
};

const recursiveAddVariantChildren = ({
  parentNodeId,
  sourceComponentChildren,
  state,
}: {
  parentNodeId: string;
  sourceComponentChildren: string[];
  state: WritableDraft<JuxStore>;
}) => {
  const currentCanvas = state.canvases[state.currentCanvasName];

  return sourceComponentChildren.map((childId) => {
    const childSource = state.components[childId];
    if (!childSource) {
      throw new Error(
        'Cannot create new instance, one of the layers does not exist'
      );
    }

    const newNodeId = uuidv4();
    const newInstance: ComponentInstanceData = {
      id: newNodeId,
      type: NodeType.VARIANT_INSTANCE,
      sourceComponentId: childId,
      parentId: parentNodeId,
      config: {
        props: [], // don't set props for children, only for the root instance
      },
      children: recursiveAddVariantChildren({
        parentNodeId: newNodeId,
        sourceComponentChildren: childSource.children,
        state,
      }),
    };

    state.components[newInstance.id] = newInstance;
    currentCanvas.nodes[newInstance.id] = variantNodeDataBySource({
      sourceComponent: childSource,
      state,
    });

    return newNodeId;
  });
};

export const getVariantDisplayName = ({
  interactiveState,
  variantsConfig,
  variantValues,
}: {
  interactiveState: NodeInteractiveState;
  variantsConfig: VariantsConfig;
  variantValues: VariantsValues;
}) => {
  return `${interactiveState},${variantsConfig
    .map((variant) => variantValues[variant.variant])
    .join(',')}`;
};

export const createVariantInMatrixNodes = ({
  sourceComponentId,
  variantValues,
  interactiveState,
  variantsGroupId,
  state,
}: {
  sourceComponentId: string;
  variantValues: VariantsValues;
  interactiveState: NodeInteractiveState;
  variantsGroupId: string;
  state: WritableDraft<JuxStore>;
}) => {
  const sourceComponent = state.components[sourceComponentId];
  const variantsGroupComponent = state.components[
    variantsGroupId
  ] as ComponentSourceData;
  if (!variantsGroupComponent || !sourceComponent) return;

  const newVariantId = uuidv4();
  const rootInstanceNode: NodeData = {
    properties: getDefaultNodePropertiesByTagName({
      tagName: sourceComponent.tagName,
      type: sourceComponent.type,
    }),
  };
  rootInstanceNode.properties.isDraggable = false;

  const newVariantInstance: ComponentInstanceData = {
    type: NodeType.VARIANT_INSTANCE,
    id: newVariantId,
    parentId: variantsGroupId,
    sourceComponentId,
    config: { props: { ...variantValues }, interactiveState },
    displayName: getVariantDisplayName({
      interactiveState,
      variantsConfig: sourceComponent.config.variants ?? [],
      variantValues,
    }),
    children: recursiveAddVariantChildren({
      sourceComponentChildren: sourceComponent.children,
      parentNodeId: newVariantId,
      state,
    }),
  };

  state.components[newVariantId] = newVariantInstance;
  state.canvases[state.currentCanvasName].nodes[newVariantId] =
    rootInstanceNode;

  variantsGroupComponent.children.push(newVariantId);
};
