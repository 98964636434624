import { FC, useMemo, useRef } from 'react';
import { BaseFieldLayout } from '../common';
import { DDP_NUMERIC_FIELD_CLASS_NAME, DDP_NUMERIC_FIELD_NAME_PREFIX } from './Numeric.constant';
import { NumericFieldProps } from './NumericField.interface';
import { NumericFieldContent } from './NumericFieldContent';
export const NumericField: FC<NumericFieldProps> = ({
  value,
  isFieldDisabled,
  isComputed,
  icon,
  tooltipTitle,
  fieldKey,
  rawValue,
  tokensProps,
  inputProps
}) => {
  const fieldRef = useRef<HTMLDivElement>(null);
  const nameWithPrefix = useMemo(() => `${DDP_NUMERIC_FIELD_NAME_PREFIX}${inputProps.inputRegister().name}`, [inputProps]);
  return <BaseFieldLayout icon={icon} isFieldDisabled={isFieldDisabled} isComputed={isComputed} tooltipTitle={tooltipTitle} className={DDP_NUMERIC_FIELD_CLASS_NAME} htmlFor={nameWithPrefix} fieldRef={fieldRef} data-sentry-element="BaseFieldLayout" data-sentry-component="NumericField" data-sentry-source-file="NumericField.tsx">
      <NumericFieldContent htmlForTarget={nameWithPrefix} isComputed={isComputed} value={value} isFieldDisabled={isFieldDisabled} rawValue={rawValue} fieldKey={fieldKey} inputProps={inputProps} tokensProps={tokensProps} data-sentry-element="NumericFieldContent" data-sentry-source-file="NumericField.tsx" />
    </BaseFieldLayout>;
};