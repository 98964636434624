import Head from 'next/head';
import { FC } from 'react';
import { withImportant } from '@jux/ui/utils/css';
export const BackgroundHead: FC<{
  backgroundColor: CSSStyleDeclaration['backgroundColor'];
}> = ({
  backgroundColor
}) => <Head data-sentry-element="Head" data-sentry-component="BackgroundHead" data-sentry-source-file="BackgroundHead.tsx">
    <style>
      {`
        body {
          background-color: ${withImportant(backgroundColor)};
        }
      `}
    </style>
  </Head>;