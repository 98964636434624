import { ChangeEvent, FC, RefObject } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { FilesImportInput, FilesImportButton, FilesImportInputRef } from '@jux/ui/components';
export const UploadAssets: FC<{
  handleImportButtonClick: () => void;
  importFiles: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  inputRef: RefObject<FilesImportInputRef>;
  isDoneImporting: () => void;
}> = ({
  handleImportButtonClick,
  importFiles,
  inputRef,
  isDoneImporting
}) => <>
    {/* hidden file input, should be rendered regardless of menu visibility */}
    <FilesImportInput ref={inputRef} accept=".svg" onChange={importFiles} onCancel={isDoneImporting} data-sentry-element="FilesImportInput" data-sentry-source-file="UploadAssets.tsx" />
    <MuiGrid container item data-sentry-element="MuiGrid" data-sentry-source-file="UploadAssets.tsx">
      <FilesImportButton onClick={handleImportButtonClick} data-sentry-element="FilesImportButton" data-sentry-source-file="UploadAssets.tsx" />
    </MuiGrid>
  </>;