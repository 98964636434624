import { FC } from 'react';
import { ModulesKeys } from '../../DDP.interface';
import { Module } from '../../base';
import { useShouldRenderModuleByKey } from '../../hooks/useShouldRenderModuleByKey';
import { AlignAndDirection, TextColor, Typography } from './sub-modules';
const MODULE_KEY = ModulesKeys.text;
export const Text: FC = () => {
  const {
    shouldRender
  } = useShouldRenderModuleByKey(MODULE_KEY);
  if (!shouldRender) return null;
  return <Module moduleKey={MODULE_KEY} title="Text" data-sentry-element="Module" data-sentry-component="Text" data-sentry-source-file="Text.tsx">
      <Typography data-sentry-element="Typography" data-sentry-source-file="Text.tsx" />
      <TextColor data-sentry-element="TextColor" data-sentry-source-file="Text.tsx" />
      <AlignAndDirection data-sentry-element="AlignAndDirection" data-sentry-source-file="Text.tsx" />
    </Module>;
};