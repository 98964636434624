import { useCallback } from 'react';
import { FeedbackModal } from '@jux/ui-core';
import { useModal } from './base-hooks';
export const useOpenFeedbackModal = () => {
  const {
    open,
    close
  } = useModal<{
    onConfirm: (data: string) => void;
    onCancel: () => void;
  }>();
  const openFeedbackModal = useCallback(({
    onConfirm,
    onCancel
  }: {
    onConfirm: (data: string) => void;
    onCancel?: () => void;
  }) => {
    const handleCancel = () => {
      onCancel?.();
      close();
    };
    const handleConfirm = (data: string) => {
      onConfirm?.(data);
      close();
    };
    open({
      handlers: {
        onConfirm,
        onClose: close,
        onCancel: onCancel || close
      },
      content: <FeedbackModal onConfirm={handleConfirm} onCancel={handleCancel} />
    });
  }, [close, open]);
  return {
    openFeedbackModal
  };
};