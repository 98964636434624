import { FC } from 'react';
import { ButtonGroupField, GroupButton } from '../../../../../base';
import { useFlexWrapField } from './useFlexWrapField';
export const FlexWrapField: FC = () => {
  const {
    isFlexWrapSelected,
    handleFlexWrapClick
  } = useFlexWrapField();
  return <ButtonGroupField data-sentry-element="ButtonGroupField" data-sentry-component="FlexWrapField" data-sentry-source-file="FlexWrapField.tsx">
      <GroupButton icon="DDP_FIELD_FLEX_WRAP" isSelected={isFlexWrapSelected} onClick={handleFlexWrapClick} tooltipTitle="Wrap children" allowToggle data-sentry-element="GroupButton" data-sentry-source-file="FlexWrapField.tsx" />
    </ButtonGroupField>;
};