import { FC } from 'react';
import { selectedNodeHasContextParent, useStore } from '@jux/canjux/core';
import { useShouldRenderModuleByKey } from '@jux/ui/components/editor/components/panels/DDP/hooks';
import { Module } from '../../base';
import { ModulesKeys } from '../../DDP.interface';
import { ParentContext } from './sub-modules/parentContext';
const MODULE_KEY = ModulesKeys.context;
export const Context: FC = () => {
  const {
    shouldRender
  } = useShouldRenderModuleByKey(MODULE_KEY);
  const hasContextParent = useStore(selectedNodeHasContextParent);
  if (!shouldRender || !hasContextParent) {
    return null;
  }
  return <Module moduleKey={MODULE_KEY} hideHeader data-sentry-element="Module" data-sentry-component="Context" data-sentry-source-file="Context.tsx">
      <ParentContext data-sentry-element="ParentContext" data-sentry-source-file="Context.tsx" />
    </Module>;
};