import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { MaxHeightField } from './MaxHeightField';
import { MinHeightField } from './MinHeightField';
import { HeightField } from './HeightField';
export const Height: FC = () => {
  const {
    shouldRender
  } = useSubModule(DDPSubModulesKeys.height);
  if (!shouldRender) return null;
  return <SubModule title="Height" data-sentry-element="SubModule" data-sentry-component="Height" data-sentry-source-file="Height.tsx">
      <HeightField data-sentry-element="HeightField" data-sentry-source-file="Height.tsx" />
      <MaxHeightField data-sentry-element="MaxHeightField" data-sentry-source-file="Height.tsx" />
      <MinHeightField data-sentry-element="MinHeightField" data-sentry-source-file="Height.tsx" />
    </SubModule>;
};