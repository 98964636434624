import { FC } from 'react';
import { IconButton, IconButtonProps, LoadingButtonWithIcon as Button } from '@jux/ui/components/common';
import { EDITOR_DDP_TEXT_FIELD_BASE_BACKGROUND_HOVER_COLOR, EDITOR_DDP_TEXT_FIELD_BASE_COLOR } from '@jux/ui/theme/palette';
export const TokenCreationAction: FC<Pick<IconButtonProps, 'onClick'> & {
  hasNoTokens?: boolean;
  shouldRenderImportTokensButton?: boolean;
}> = ({
  shouldRenderImportTokensButton,
  hasNoTokens,
  ...props
}) => {
  if (shouldRenderImportTokensButton || hasNoTokens) {
    return <IconButton variant="secondary" color={EDITOR_DDP_TEXT_FIELD_BASE_COLOR} fullWidth sx={{
      padding: '8px 4px',
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: EDITOR_DDP_TEXT_FIELD_BASE_BACKGROUND_HOVER_COLOR
      }
    }} typographyVariant="label-s-medium" {...props}>
        {shouldRenderImportTokensButton ? 'Create new' : 'Create new token'}
      </IconButton>;
  }
  return <Button icon="ADD" iconProps={{
    sx: {
      ml: '5px'
    }
  }} color={EDITOR_DDP_TEXT_FIELD_BASE_COLOR} fullWidth sx={{
    justifyContent: 'flex-start',
    padding: '8px 4px',
    '&:hover': {
      backgroundColor: EDITOR_DDP_TEXT_FIELD_BASE_BACKGROUND_HOVER_COLOR
    }
  }} typographyVariant="label-s-medium" typographyProps={{
    sx: {
      ml: '11px'
    }
  }} {...props} data-sentry-element="Button" data-sentry-component="TokenCreationAction" data-sentry-source-file="TokenCreationAction.tsx">
      Create new token
    </Button>;
};