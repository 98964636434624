import { useCallback } from 'react';
import { isNumber } from 'lodash';
import { isAlias } from '@juxio/design-tokens';
import { ComponentTagName } from '@jux/data-entities';
import { CommonKeysValueType } from '@jux/canjux/core';
import { AnalyticsEvents } from '@jux/data-access/analytics/analytics-events';
import { useAnalytics } from './useAnalytics';

export const useTrackEvents = () => {
  const { trackEvent } = useAnalytics();

  const trackFieldUnitChangeEvent = useCallback(
    (fieldName: string, unitValue: string) => {
      trackEvent({
        eventName: AnalyticsEvents.DDP_FIELD_UNIT_SELECTED,
        properties: {
          fieldName,
          unit: unitValue,
        },
      });
    },
    [trackEvent]
  );

  const trackTokenDetachedEvent = useCallback(
    (fieldName: string) => {
      trackEvent({
        eventName: AnalyticsEvents.DDP_FIELD_TOKEN_DETACHED,
        properties: {
          fieldName,
        },
      });
    },
    [trackEvent]
  );

  const trackModuleToggleOpenEvent = useCallback(
    (moduleKey: string, isOpened: boolean) => {
      trackEvent({
        eventName: AnalyticsEvents.DDP_MODULE_TOGGLE_OPEN,
        properties: {
          moduleKey,
          isOpened,
        },
      });
    },
    [trackEvent]
  );

  const trackFieldValueChangeEvent = useCallback(
    (fieldName: string, value: any) => {
      const fieldValueType = isAlias(value)
        ? 'token'
        : isNumber(value)
        ? 'number'
        : 'string';

      trackEvent({
        eventName: AnalyticsEvents.DDP_VALUE_CHANGE,
        properties: {
          fieldName,
          fieldValueType,
        },
      });
    },
    [trackEvent]
  );

  const trackPropCreatedEvent = useCallback(
    ({
      componentUUID,
      tagName,
      propName,
    }: {
      componentUUID: string;
      tagName: ComponentTagName;
      propName: string;
    }) => {
      trackEvent({
        eventName: AnalyticsEvents.NEW_PROP_CREATED,
        properties: {
          componentUUID,
          tagName,
          createdPropName: propName,
        },
      });
    },
    [trackEvent]
  );
  const trackPropValueCreatedEvent = useCallback(
    ({
      componentUUID,
      tagName,
      propName,
      newPropValue,
    }: {
      componentUUID: string;
      tagName: ComponentTagName;
      propName: string;
      newPropValue: string;
    }) => {
      trackEvent({
        eventName: AnalyticsEvents.NEW_PROP_VALUE_CREATED,
        properties: {
          componentUUID,
          tagName,
          propName,
          newPropValueName: newPropValue,
        },
      });
    },
    [trackEvent]
  );
  const trackPropRenamedEvent = useCallback(
    ({
      componentUUID,
      tagName,
      oldPropName,
      newPropName,
    }: {
      componentUUID: string;
      tagName: ComponentTagName;
      oldPropName: string;
      newPropName: string;
    }) => {
      trackEvent({
        eventName: AnalyticsEvents.PROP_RENAMED,
        properties: {
          componentUUID,
          tagName,
          oldPropName,
          newPropName,
        },
      });
    },
    [trackEvent]
  );
  const trackPropValueRenamedEvent = useCallback(
    ({
      componentUUID,
      tagName,
      propName,
      oldPropValueName,
      newPropValueName,
    }: {
      componentUUID: string;
      tagName: ComponentTagName;
      propName: string;
      oldPropValueName: string;
      newPropValueName: string;
    }) => {
      trackEvent({
        eventName: AnalyticsEvents.PROP_VALUE_RENAMED,
        properties: {
          componentUUID,
          tagName,
          propName,
          oldPropValueName,
          newPropValueName,
        },
      });
    },
    [trackEvent]
  );
  const trackPropDeletedEvent = useCallback(
    ({
      componentUUID,
      tagName,
      deletedPropName,
    }: {
      componentUUID: string;
      tagName: ComponentTagName;
      deletedPropName: string;
    }) => {
      trackEvent({
        eventName: AnalyticsEvents.PROP_DELETED,
        properties: {
          componentUUID,
          tagName,
          deletedPropName,
        },
      });
    },
    [trackEvent]
  );
  const trackPropValueDeletedEvent = useCallback(
    ({
      componentUUID,
      tagName,
      propName,
      deletedPropValueName,
    }: {
      componentUUID: string;
      tagName: ComponentTagName;
      propName: string;
      deletedPropValueName: string;
    }) => {
      trackEvent({
        eventName: AnalyticsEvents.PROP_VALUE_DELETED,
        properties: {
          componentUUID,
          tagName,
          propName,
          deletedPropValueName,
        },
      });
    },
    [trackEvent]
  );

  const trackLayersRenameEvent = useCallback(
    (layerName: string, indentLevel: number) => {
      trackEvent({
        eventName: AnalyticsEvents.LAYERS_RENAME,
        properties: {
          text: layerName,
          level: indentLevel,
        },
      });
    },
    [trackEvent]
  );

  const trackObjectAddedToCanvasEvent = useCallback(
    ({
      tagName,
      componentUUID,
    }: {
      tagName: ComponentTagName;
      componentUUID?: string;
    }) => {
      trackEvent({
        eventName: AnalyticsEvents.OBJECT_ADDED_TO_CANVAS,
        properties: {
          tagName,
          componentUUID,
        },
      });
    },
    [trackEvent]
  );

  const trackTokenCreatedEvent = useCallback(
    (tokenType: string) => {
      trackEvent({
        eventName: AnalyticsEvents.TOKEN_CREATED,
        properties: {
          tokenType,
        },
      });
    },
    [trackEvent]
  );

  const trackTokenUpdatedEvent = useCallback(
    (tokenType: string) => {
      trackEvent({
        eventName: AnalyticsEvents.TOKEN_UPDATED,
        properties: {
          tokenType,
        },
      });
    },
    [trackEvent]
  );

  const trackTextNodeDoubleClickEvent = useCallback(
    ({ isEditMode, clickedOn }: { isEditMode: boolean; clickedOn: string }) => {
      trackEvent({
        eventName: AnalyticsEvents.TEXT_NODE_DOUBLE_CLICK,
        properties: {
          isEditMode,
          clickedOn,
        },
      });
    },
    [trackEvent]
  );

  const trackAddComponentToLibraryEvent = useCallback(
    ({ componentUUID }: { componentUUID: string }) => {
      trackEvent({
        eventName: AnalyticsEvents.ADD_COMPONENT_TO_LIBRARY,
        properties: {
          componentUUID,
        },
      });
    },
    [trackEvent]
  );

  const trackCreateAssetEvent = useCallback(
    ({ assetName }: { assetName: string }) => {
      trackEvent({
        eventName: AnalyticsEvents.CREATE_ASSET,
        properties: {
          assetName,
        },
      });
    },
    [trackEvent]
  );

  const trackOpenSidebar = useCallback(() => {
    trackEvent({
      eventName: AnalyticsEvents.OPEN_SIDEBAR,
    });
  }, [trackEvent]);

  const trackTokenSidebarClick = useCallback(() => {
    trackEvent({
      eventName: AnalyticsEvents.TOKEN_SIDEBAR_CLICK,
    });
  }, [trackEvent]);

  const trackEditorSidebarClick = useCallback(
    (canvasId: string) => {
      trackEvent({
        eventName: AnalyticsEvents.EDITOR_SIDEBAR_CLICK,
        properties: {
          canvasId,
        },
      });
    },
    [trackEvent]
  );

  const trackNavigationClick = useCallback(
    ({ name }: { name: 'components' | 'elements' | 'assets' }) => {
      const eventMap = {
        components: AnalyticsEvents.NAVIGATION_COMPONENTS_CLICKED,
        elements: AnalyticsEvents.NAVIGATION_ELEMENTS_CLICKED,
        assets: AnalyticsEvents.NAVIGATION_ASSETS_CLICKED,
      };
      trackEvent({
        eventName: eventMap[name],
      });
    },
    [trackEvent]
  );

  const trackAddToLibraryClick = useCallback(() => {
    trackEvent({
      eventName: AnalyticsEvents.ADD_TO_LIBRARY_CLICKED,
    });
  }, [trackEvent]);

  const trackModeClick = useCallback(
    ({ mode }: { mode: 'live' | 'edit' }) => {
      trackEvent({
        eventName:
          mode === 'live'
            ? AnalyticsEvents.LIVE_MODE_CLICKED
            : AnalyticsEvents.EDIT_MODE_CLICKED,
      });
    },
    [trackEvent]
  );

  const trackContextMenuClick = useCallback(
    ({
      name,
    }: {
      name: 'change_theme' | 'howto' | 'feedback' | 'shortcuts' | 'logout';
    }) => {
      const eventMap = {
        change_theme: AnalyticsEvents.CONTEXT_MENU_CHANGE_THEME_CLICKED,
        feedback: AnalyticsEvents.CONTEXT_MENU_FEEDBACK_CLICKED,
        howto: AnalyticsEvents.CONTEXT_MENU_HOW_TO_CLICKED,
        logout: AnalyticsEvents.CONTEXT_MENU_LOGOUT_CLICKED,
        shortcuts: AnalyticsEvents.CONTEXT_MENU_SHORTCUTS_CLICKED,
      };
      trackEvent({
        eventName: eventMap[name],
      });
    },
    [trackEvent]
  );

  const trackKeyboardShortcut = useCallback(
    ({
      shortcut,
      hotKey,
    }: {
      shortcut:
        | AnalyticsEvents.KBS_COPY
        | AnalyticsEvents.KBS_WRAP_WITH_DIV
        | AnalyticsEvents.KBS_DESELECT
        | AnalyticsEvents.KBS_DRILL_IN
        | AnalyticsEvents.KBS_DRILL_UP
        | AnalyticsEvents.KBS_UNDO
        | AnalyticsEvents.KBS_REDO
        | AnalyticsEvents.KBS_PASTE
        | AnalyticsEvents.KBS_DELETE;
      hotKey: CommonKeysValueType;
    }) => {
      trackEvent({
        eventName: AnalyticsEvents.KBS_SHORTCUT,
        properties: {
          shortcut,
          hotKey,
        },
      });
    },
    [trackEvent]
  );

  const trackRestoreSourceComponent = useCallback(() => {
    trackEvent({
      eventName: AnalyticsEvents.RESTORE_SOURCE_COMPONENT,
    });
  }, [trackEvent]);

  const trackGoToSourceComponent = useCallback(() => {
    trackEvent({
      eventName: AnalyticsEvents.GO_TO_SOURCE_COMPONENT,
    });
  }, [trackEvent]);

  const trackComponentResize = useCallback(() => {
    trackEvent({
      eventName: AnalyticsEvents.COMPONENT_RESIZED,
    });
  }, [trackEvent]);

  const trackUpdateComponentDIsplayName = useCallback(
    (position: 'canvas' | 'layers') => {
      trackEvent({
        eventName: AnalyticsEvents.UPDATE_COMPONENT_DISPLAY_NAME,
        properties: {
          position,
        },
      });
    },
    [trackEvent]
  );

  const trackFeedbackSent = useCallback(
    (feedback: string) => {
      trackEvent({
        eventName: AnalyticsEvents.FEEDBACK_SENT,
        properties: {
          feedback,
        },
      });
    },
    [trackEvent]
  );

  const trackFeedbackCancel = useCallback(() => {
    trackEvent({
      eventName: AnalyticsEvents.FEEDBACK_CANCEL,
    });
  }, [trackEvent]);

  const trackThemeChanged = useCallback(
    ({ themeId, themeName }: { themeId: string; themeName: string }) => {
      trackEvent({
        eventName: AnalyticsEvents.THEME_CHANGED,
        properties: {
          themeId,
          themeName,
        },
      });
    },
    [trackEvent]
  );

  return {
    trackAddComponentToLibraryEvent,
    trackAddToLibraryClick,
    trackComponentResize,
    trackContextMenuClick,
    trackCreateAssetEvent,
    trackEditorSidebarClick,
    trackFeedbackCancel,
    trackFeedbackSent,
    trackFieldUnitChangeEvent,
    trackFieldValueChangeEvent,
    trackGoToSourceComponent,
    trackKeyboardShortcut,
    trackLayersRenameEvent,
    trackModeClick,
    trackModuleToggleOpenEvent,
    trackNavigationClick,
    trackObjectAddedToCanvasEvent,
    trackOpenSidebar,
    trackPropCreatedEvent,
    trackPropDeletedEvent,
    trackPropRenamedEvent,
    trackPropValueCreatedEvent,
    trackPropValueDeletedEvent,
    trackPropValueRenamedEvent,
    trackRestoreSourceComponent,
    trackTextNodeDoubleClickEvent,
    trackThemeChanged,
    trackTokenCreatedEvent,
    trackTokenDetachedEvent,
    trackTokenSidebarClick,
    trackTokenUpdatedEvent,
    trackUpdateComponentDIsplayName,
  };
};
