import { forwardRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Typography as MuiTypography } from '@mui/material';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';
import { TypographyElement, TypographyProps } from './Typography.interface';
import { typographyVariants } from './Typography.variants';
export const Typography = forwardRef<TypographyElement, TypographyProps>(({
  variant,
  sx,
  ...props
}, ref) => <MuiTypography sx={mergeTyped(typographyVariants[variant], sx)} {...props} ref={ref} />);