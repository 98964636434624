/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const JustifyContentSpaceBetweenColumnDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M14 2.5C14 2.22386 13.7761 2 13.5 2H2.5C2.22386 2 2 2.22386 2 2.5C2 2.77614 2.22386 3 2.5 3L13.5 3C13.7761 3 14 2.77614 14 2.5Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M14 13.5C14 13.2239 13.7761 13 13.5 13L2.5 13C2.22386 13 2 13.2239 2 13.5C2 13.7761 2.22386 14 2.5 14H13.5C13.7761 14 14 13.7761 14 13.5Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M6 12C5.44772 12 5 11.5523 5 11C5 10.4477 5.44772 10 6 10H10C10.5523 10 11 10.4477 11 11C11 11.5523 10.5523 12 10 12L6 12Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M5 5C5 5.55228 5.44772 6 6 6L10 6C10.5523 6 11 5.55228 11 5C11 4.44771 10.5523 4 10 4L6 4C5.44772 4 5 4.44772 5 5Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="JustifyContentSpaceBetweenColumnDDPIcon" data-sentry-source-file="JustifyContentSpaceBetweenColumnDDPIcon.tsx" />;