import { FC } from 'react';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
import { IconBaseProps } from '../../Icons.interface';
export const CheckboxFieldElementPanelIcon: FC<IconBaseProps> = ({
  textColor,
  ...props
}) => <CustomIcon tag="svg" width="84" height="33" attr={{
  viewBox: '0 0 84 33'
}} child={[{
  tag: 'rect',
  attr: {
    y: '0.5',
    width: '32',
    height: '32',
    rx: '8',
    fill: 'white'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    d: 'M10 16.5L15 21.5L23 11.5',
    stroke: '#1A1A1A',
    strokeWidth: '3',
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
  },
  child: []
}, {
  tag: 'rect',
  attr: {
    x: '40',
    y: '6.5',
    width: '44',
    height: '7',
    rx: '2',
    fill: 'white'
  },
  child: []
}, {
  tag: 'rect',
  attr: {
    x: '40',
    y: '19.5',
    width: '31',
    height: '7',
    rx: '2',
    fill: 'white'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="CheckboxFieldElementPanelIcon" data-sentry-source-file="CheckboxFieldElementPanelIcon.tsx" />;