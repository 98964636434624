import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { BorderRadiusField } from './BorderRadiusField';
export const BorderRadius: FC = () => {
  const {
    shouldRender
  } = useSubModule(DDPSubModulesKeys.borderRadius);
  if (!shouldRender) return null;
  return <SubModule title="Radius" hideHeader data-sentry-element="SubModule" data-sentry-component="BorderRadius" data-sentry-source-file="BorderRadius.tsx">
      <BorderRadiusField data-sentry-element="BorderRadiusField" data-sentry-source-file="BorderRadius.tsx" />
    </SubModule>;
};