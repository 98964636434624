/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const NoneColorIndicatorDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="10" height="10" attr={{
  viewBox: '0 0 10 10'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M7.7838 2.2162C7.49552 1.92793 7.02814 1.92793 6.73987 2.2162L5 3.95608L3.26013 2.2162C2.97186 1.92793 2.50448 1.92793 2.2162 2.2162C1.92793 2.50448 1.92793 2.97186 2.2162 3.26013L3.95608 5L2.2162 6.73987C1.92793 7.02814 1.92793 7.49553 2.2162 7.7838C2.50448 8.07207 2.97186 8.07207 3.26013 7.7838L5 6.04392L6.73987 7.7838C7.02814 8.07207 7.49552 8.07207 7.7838 7.7838C8.07207 7.49553 8.07207 7.02814 7.7838 6.73987L6.04392 5L7.7838 3.26013C8.07207 2.97186 8.07207 2.50448 7.7838 2.2162Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="NoneColorIndicatorDDPIcon" data-sentry-source-file="NoneColorIndicatorDDPIcon.tsx" />;