import { RefObject, useCallback } from 'react';
import { PoppersKey, usePopper } from '@jux/ui/components';
import { AssetsPopper } from '../components';
export const useAssetsPopper = () => {
  const {
    open,
    isOpen
  } = usePopper(PoppersKey.assets);
  const openAssetsPopper = useCallback((sourceElementRef: RefObject<HTMLElement>) => {
    open({
      content: <AssetsPopper />,
      sourceElement: sourceElementRef.current
    });
  }, [open]);
  return {
    openAssetsPopper,
    isAssetsPopperOpen: isOpen()
  };
};