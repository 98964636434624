/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const EditIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M1.25 9.5C1.25 8.10189 2.13282 6.90998 3.37143 6.4513C3.58886 6.37078 3.75 6.17318 3.75 5.94131V2.5C3.75 2.22386 3.97386 2 4.25 2H4.75C5.02614 2 5.25 2.22386 5.25 2.5V5.94131C5.25 6.17318 5.41114 6.37078 5.62857 6.4513C6.86718 6.90998 7.75 8.10189 7.75 9.5C7.75 10.8981 6.86718 12.09 5.62857 12.5487C5.41114 12.6292 5.25 12.8268 5.25 13.0587V14.5C5.25 14.7761 5.02614 15 4.75 15H4.25C3.97386 15 3.75 14.7761 3.75 14.5V13.0587C3.75 12.8268 3.58886 12.6292 3.37143 12.5487C2.13282 12.09 1.25 10.8981 1.25 9.5ZM4.5 7.75C3.5335 7.75 2.75 8.5335 2.75 9.5C2.75 10.4665 3.5335 11.25 4.5 11.25C5.4665 11.25 6.25 10.4665 6.25 9.5C6.25 8.5335 5.4665 7.75 4.5 7.75Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M14.75 6.5C14.75 7.89811 13.8672 9.09002 12.6286 9.5487C12.4111 9.62922 12.25 9.82682 12.25 10.0587L12.25 13.5C12.25 13.7761 12.0261 14 11.75 14H11.25C10.9739 14 10.75 13.7761 10.75 13.5L10.75 10.0587C10.75 9.82682 10.5889 9.62922 10.3714 9.5487C9.13282 9.09002 8.25 7.89811 8.25 6.5C8.25 5.10189 9.13282 3.90998 10.3714 3.4513C10.5889 3.37078 10.75 3.17318 10.75 2.94131V1.5C10.75 1.22386 10.9739 1 11.25 1H11.75C12.0261 1 12.25 1.22386 12.25 1.5V2.94131C12.25 3.17318 12.4111 3.37078 12.6286 3.4513C13.8672 3.90998 14.75 5.10189 14.75 6.5ZM11.5 8.25C12.4665 8.25 13.25 7.4665 13.25 6.5C13.25 5.5335 12.4665 4.75 11.5 4.75C10.5335 4.75 9.75 5.5335 9.75 6.5C9.75 7.4665 10.5335 8.25 11.5 8.25Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="EditIcon" data-sentry-source-file="EditIcon.tsx" />;