import { FC, KeyboardEvent, useCallback } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { Input } from '../input';
import { isNoneColorValue } from '../../../hooks/useColorField/useColorField.utils';
import { ColorPickerPopup } from './ColorPickerPopup';
import { ColorFieldContentProps, ColorFieldValue } from './ColorField.interface';
import { ColorTokenFieldContent } from './ColorTokenFieldContent';
export const ColorFieldContent: FC<ColorFieldContentProps> = ({
  tokensProps,
  parsedValue,
  popupState,
  hexValueInputRegister,
  alphaValueInputRegister,
  setValueByRGBA,
  isComputed,
  hexInputProps,
  alphaInputProps
}) => {
  const {
    tokensPopperProps,
    isTokenized,
    tokenDisplayName,
    tokenParsedValue
  } = tokensProps ?? {};
  const {
    openTokens
  } = tokensPopperProps ?? {};
  const isNoneValue = isNoneColorValue(parsedValue?.hexValue);
  const handleHexOnEnter = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    hexInputProps.onEnter(e);
    e.currentTarget.blur();
  }, [hexInputProps]);
  const handleHexOnEscape = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    hexInputProps.onEscape(e);
    e.currentTarget.blur();
  }, [hexInputProps]);
  const handleAlphaOnEnter = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    alphaInputProps.onEnter(e);
    e.currentTarget.blur();
  }, [alphaInputProps]);
  const handleAlphaOnEscape = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    alphaInputProps.onEscape(e);
    e.currentTarget.blur();
  }, [alphaInputProps]);
  if (isTokenized && tokenDisplayName) {
    // TODO: remove this type usage and make the tokenParsedValue to be also ColorFieldValue
    const {
      rgbaColor,
      hexValue
    } = tokenParsedValue as ColorFieldValue;
    return <ColorTokenFieldContent rgbaColor={rgbaColor ?? ''} tokenDisplayName={tokenDisplayName} tokenHexValue={hexValue.slice(1) ?? ''} openTokens={openTokens} />;
  }
  return <MuiGrid display="flex" alignItems="center" gap="24px" data-sentry-element="MuiGrid" data-sentry-component="ColorFieldContent" data-sentry-source-file="ColorFieldContent.tsx">
      <MuiGrid display="flex" alignItems="center" gap="8px" data-sentry-element="MuiGrid" data-sentry-source-file="ColorFieldContent.tsx">
        <ColorPickerPopup color={parsedValue?.rgbaColor ?? ''} tokenHexValue={parsedValue?.hexValue ?? ''} popupState={popupState} handleColorPickerChange={setValueByRGBA} isNoneValue={isNoneValue} data-sentry-element="ColorPickerPopup" data-sentry-source-file="ColorFieldContent.tsx" />
        <Input id={hexInputProps.id} isComputed={isComputed} onEscape={handleHexOnEscape} onEnter={handleHexOnEnter} {...hexValueInputRegister()} sx={{
        width: '54px',
        height: '15px'
      }}
      // make sure to render onBlur after inputRegister() to override it, otherwise it will be not call the correct onBlur
      // due to the fact we have specific logic we have with Escape that it both discardChanges and blur the element and on this case we don't want to save (when save is the default behavior of onBlur from react-hook-form)
      onBlur={hexInputProps.onBlur} data-sentry-element="Input" data-sentry-source-file="ColorFieldContent.tsx" />
      </MuiGrid>
      {!isNoneValue && <Input id={alphaInputProps.id} isComputed={isComputed} onEscape={handleAlphaOnEscape} onEnter={handleAlphaOnEnter} {...alphaValueInputRegister()}
    // make sure to render onBlur after inputRegister() to override it, otherwise it will be not call the correct onBlur
    // due to the fact we have specific logic we have with Escape that it both discardChanges and blur the element and on this case we don't want to save (when save is the default behavior of onBlur from react-hook-form)
    onBlur={alphaInputProps.onBlur} onKeyDown={alphaInputProps.onKeyDown} />}
    </MuiGrid>;
};