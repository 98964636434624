/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const PaddingTopDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M10.75 7C10.75 7.41421 10.4142 7.75 10 7.75H6C5.58579 7.75 5.25 7.41421 5.25 7C5.25 6.58579 5.58579 6.25 6 6.25H7.25V3.5H3.5V12.5H12.5V3.5H8.75V6.25H10C10.4142 6.25 10.75 6.58579 10.75 7ZM3 2C2.44772 2 2 2.44772 2 3V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V3C14 2.44772 13.5523 2 13 2H3Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="PaddingTopDDPIcon" data-sentry-source-file="PaddingTopDDPIcon.tsx" />;