import { useCallback } from 'react';
import { useModal } from '../base-hooks';
import { SubmitModalContent } from './SubmitModalContent';
import { OpenSubmitModalProps } from './SubmitModal.interface';
export const useOpenSubmitModal = () => {
  const {
    open,
    close
  } = useModal();
  const openSubmitModal = useCallback(({
    onClose,
    ...props
  }: OpenSubmitModalProps) => {
    open({
      handlers: {
        onClose: onClose || close
      },
      content: <SubmitModalContent onClose={close} {...props} />
    });
  }, [close, open]);
  return {
    openSubmitModal,
    closeSubmitModal: close
  };
};