import { FC } from 'react';
import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';
import { DimensionField } from '../../../../base';
import { useFieldValues, useDimensionField, useSetFieldValue } from '../../../../hooks';
const FIELD_KEY = DDPPrimitiveFieldsKeys.borderWidth;
export const BorderWidthField: FC = () => {
  const {
    value,
    initialValue
  } = useFieldValues(FIELD_KEY);
  const {
    saveValue
  } = useSetFieldValue(FIELD_KEY);
  const props = useDimensionField({
    value,
    initialValue,
    saveChanges: saveValue,
    fieldName: FIELD_KEY,
    options: {
      disablePercentValue: true
    }
  });
  return <DimensionField icon="DDP_FIELD_BORDER_WIDTH" tooltipTitle="Border width" {...props} data-sentry-element="DimensionField" data-sentry-component="BorderWidthField" data-sentry-source-file="BorderWidthField.tsx" />;
};