import { MouseEventHandler } from 'react';
import { MuiGrid, styled } from '@jux/ui/components/common/mui';
import { LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_EXPANDER_COLOR } from '@jux/ui/theme/palette';
export type ExpanderIconWrapperProps = {
  showExpanderIcon: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
};
export const ExpanderIconWrapper = styled(MuiGrid, {
  shouldForwardProp: prop => prop !== 'showExpanderIcon'
})<ExpanderIconWrapperProps>({
  display: 'inline-flex',
  height: '16px',
  width: '16px',
  marginInlineEnd: '1px',
  justifyContent: 'center',
  alignItems: 'center',
  color: LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_EXPANDER_COLOR
}, ({
  showExpanderIcon
}) => ({
  visibility: showExpanderIcon ? 'visible' : 'hidden',
  pointerEvents: showExpanderIcon ? 'auto' : 'none'
}));