import { FC, useRef } from 'react';
import { Floors, useOnClickOutside, useOnKeyEvent, useOverlaysZIndex } from '@jux/ui/hooks';
import { recursivelyFindIfParentIsSourceElement } from '@jux/ui/utils/recursivelyFindIfParentIsSourceElement';
import { FocusTrap } from '@jux/ui/components';
import { usePopper } from '../base-hooks';
import { FocusElementForTrap } from '../../focusTrap';
import * as S from './Popper.style';
import { usePopperPositionStyles } from './usePopperPositionStyles';
export const Popper: FC = () => {
  const {
    isClosed,
    content,
    close,
    sourceElement,
    styles
  } = usePopper();
  const zIndex = useOverlaysZIndex({
    id: 'popper',
    floor: styles.floor ?? Floors.poppers
  });
  const positionStyles = usePopperPositionStyles({
    sourceElement,
    styles
  });
  const popperRef = useRef<HTMLDivElement>(null);
  useOnKeyEvent(popperRef, () => close(), 'Escape');
  useOnClickOutside({
    refOrSelector: [popperRef],
    handler: e => {
      const hasClickOnSourceElement = Boolean(sourceElement && recursivelyFindIfParentIsSourceElement({
        htmlElement: e.target as HTMLElement,
        sourceHtmlElement: sourceElement
      }));
      close({
        options: {
          shouldResetPreviouslyOpenedKey: !hasClickOnSourceElement
        }
      });
    },
    eventType: 'click',
    options: {
      eventListenerOptions: {
        capture: true
      }
    }
  });
  if (isClosed() || !sourceElement) return null;
  return <FocusTrap data-sentry-element="FocusTrap" data-sentry-component="Popper" data-sentry-source-file="Popper.tsx">
      <S.Popper container ref={popperRef} zIndex={zIndex} sx={positionStyles} data-sentry-element="unknown" data-sentry-source-file="Popper.tsx">
        <FocusElementForTrap tabIndex={1} data-sentry-element="FocusElementForTrap" data-sentry-source-file="Popper.tsx" />
        {content}
      </S.Popper>
    </FocusTrap>;
};