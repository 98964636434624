/**
 *  THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 */

/* eslint-disable */
// @ts-nocheck

import { createDrimz, type PropsOf } from '@jux/dui';
import React, { FC, forwardRef } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType } from '@jux/data-entities';
import { JuxReactElements } from '@jux/react';
const {
  styled
} = createDrimz();
type KeyboardListItem_text_root_Props = PropsOf<typeof JuxReactElements.JuxText>;
const KeyboardListItem_text_root: FC<KeyboardListItem_text_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxText {...props} ref={ref} />);
KeyboardListItem_text_root.displayName = 'KeyboardListItem_text_root';
KeyboardListItem_text_root.defaultProps = {
  rootElement: styled('p', {
    styles: {
      root: {
        color: '{color.core.grays_800}',
        width: 'auto',
        height: 'auto',
        display: 'inline-block',
        fontSize: '{typography.core.12_regular.fontSize}',
        fontFamily: '{typography.core.12_regular.fontFamily}',
        fontWeight: '{typography.core.12_regular.fontWeight}',
        lineHeight: '{typography.core.12_regular.lineHeight}',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        letterSpacing: '{typography.core.12_regular.letterSpacing}'
      }
    }
  }) as KeyboardListItem_text_root_Props['rootElement']
};
export type KeyboardListItem_text_Props = KeyboardListItem_text_root_Props;
export const KeyboardListItem_text: FC<KeyboardListItem_text_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxText>, KeyboardListItem_text_Props>((props, ref) => <KeyboardListItem_text_root {...props} ref={ref} />);
KeyboardListItem_text.displayName = 'KeyboardListItem_text';
KeyboardListItem_text.defaultProps = {
  text: 'Text Element'
};
import { ShortcutKey, type ShortcutKey_Props } from '../';
type KeyboardListItem_div_text_root_Props = PropsOf<typeof JuxReactElements.JuxText>;
const KeyboardListItem_div_text_root: FC<KeyboardListItem_div_text_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxText {...props} ref={ref} />);
KeyboardListItem_div_text_root.displayName = 'KeyboardListItem_div_text_root';
KeyboardListItem_div_text_root.defaultProps = {
  rootElement: styled('p', {
    styles: {
      root: {
        color: '{color.core.grays_800}',
        width: 'auto',
        height: 'auto',
        display: 'inline-block',
        fontSize: '{typography.core.12_regular.fontSize}',
        fontFamily: '{typography.core.12_regular.fontFamily}',
        fontWeight: '{typography.core.12_regular.fontWeight}',
        lineHeight: '{typography.core.12_regular.lineHeight}',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        letterSpacing: '{typography.core.12_regular.letterSpacing}'
      }
    }
  }) as KeyboardListItem_div_text_root_Props['rootElement']
};
export type KeyboardListItem_div_text_Props = KeyboardListItem_div_text_root_Props;
export const KeyboardListItem_div_text: FC<KeyboardListItem_div_text_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxText>, KeyboardListItem_div_text_Props>((props, ref) => <KeyboardListItem_div_text_root {...props} ref={ref} />);
KeyboardListItem_div_text.displayName = 'KeyboardListItem_div_text';
KeyboardListItem_div_text.defaultProps = {
  text: '+'
};
type KeyboardListItem_div_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const KeyboardListItem_div_root: FC<KeyboardListItem_div_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
KeyboardListItem_div_root.displayName = 'KeyboardListItem_div_root';
KeyboardListItem_div_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        padding: '0px 0px 0px 0px',
        alignItems: 'center',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF'
      }
    }
  }) as KeyboardListItem_div_root_Props['rootElement']
};
export type KeyboardListItem_div_Props = KeyboardListItem_div_root_Props & {
  ShortcutKey_1_Props?: ShortcutKey_Props;
  KeyboardListItem_div_text_Props?: KeyboardListItem_div_text_Props;
  ShortcutKey_2_Props?: ShortcutKey_Props;
};
export const KeyboardListItem_div: FC<KeyboardListItem_div_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, KeyboardListItem_div_Props>(({
  ShortcutKey_1_Props,
  KeyboardListItem_div_text_Props,
  ShortcutKey_2_Props,
  ...props
}, ref) => <KeyboardListItem_div_root {...props} ref={ref} data-jux-context="9ca7e925-2624-4c07-8f1a-34618d45e79b">
      {props.children || <>
          <ShortcutKey {...ShortcutKey_1_Props} />
          <KeyboardListItem_div_text {...KeyboardListItem_div_text_Props} data-jux-context="06800c17-8163-41da-be2c-56ae45dcd5c1" />
          <ShortcutKey {...ShortcutKey_2_Props} />
        </>}
    </KeyboardListItem_div_root>);
KeyboardListItem_div.displayName = 'KeyboardListItem_div';
KeyboardListItem_div.defaultProps = {};
type KeyboardListItem_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const KeyboardListItem_root: FC<KeyboardListItem_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
KeyboardListItem_root.displayName = 'KeyboardListItem_root';
KeyboardListItem_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        gap: 'normal {dimension.core.size_sm}',
        width: 'auto',
        height: '20px',
        display: 'flex',
        padding: '0px 0px 0px 0px',
        alignItems: 'center',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF'
      }
    }
  }) as KeyboardListItem_root_Props['rootElement']
};
export type KeyboardListItem_Props = KeyboardListItem_root_Props & {
  KeyboardListItem_text_Props?: KeyboardListItem_text_Props;
  KeyboardListItem_div_Props?: KeyboardListItem_div_Props;
};
export const KeyboardListItem: FC<KeyboardListItem_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, KeyboardListItem_Props>(({
  KeyboardListItem_text_Props,
  KeyboardListItem_div_Props,
  ...props
}, ref) => <KeyboardListItem_root {...props} ref={ref} data-jux-context="f34f404e-bbb2-4097-ad66-439b80eec462">
      {props.children || <>
          <KeyboardListItem_text {...KeyboardListItem_text_Props} data-jux-context="17cce251-04e7-4b28-a097-f63b291331c4" />
          <KeyboardListItem_div {...KeyboardListItem_div_Props} data-jux-context="9ca7e925-2624-4c07-8f1a-34618d45e79b" />
        </>}
    </KeyboardListItem_root>);
KeyboardListItem.displayName = 'KeyboardListItem';
KeyboardListItem.defaultProps = {};