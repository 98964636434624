import Tippy from '@tippyjs/react';
import { styled } from '@jux/ui/components/common/mui';
import { TOOLTIP_COLOR } from '@jux/ui/theme/palette';

// transition delay in ms into the tooltip appearance and disappearance
export const TOOLTIP_DELAY_IN = 800;
export const TOOLTIP_DELAY_OUT = 0;
export const StyledTippy = styled(Tippy, {
  shouldForwardProp: prop => prop !== 'multiline',
  label: 'StyledTippy'
})<{
  multiline?: boolean;
}>(({
  theme,
  multiline,
  visible
}) => ({
  backgroundColor: theme.drimz.palette.tooltip.background,
  padding: multiline ? '12px' : '6px 10px',
  ...(visible !== undefined && visible ? {
    transitionDelay: `${TOOLTIP_DELAY_IN}ms`
  } : {
    transitionDelay: `${TOOLTIP_DELAY_OUT}ms`
  }),
  '.tippy-content': {
    borderRadius: theme.drimz.size.borderRadius.main,
    padding: 0,
    backgroundColor: theme.drimz.palette.tooltip.background
  },
  '.tippy-arrow': {
    color: theme.drimz.palette.tooltip.background
  }
}));
export const TooltipContentLayout = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  color: TOOLTIP_COLOR
}));