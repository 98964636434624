import { FC, useCallback } from 'react';
import { PanelsKeys } from '@jux/ui/components/editor/state';
import { IconButton } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { usePanelStateByKey } from '@jux/ui/components/editor/hooks';
import { SubModule } from '../../../base';
export const EditProperties: FC = () => {
  const {
    togglePanel: togglePropertiesPanel
  } = usePanelStateByKey(PanelsKeys.properties);
  const handleClick = useCallback(() => {
    togglePropertiesPanel();
  }, [togglePropertiesPanel]);
  return <SubModule data-sentry-element="SubModule" data-sentry-component="EditProperties" data-sentry-source-file="EditProperties.tsx">
      <MuiGrid marginTop="8px" data-sentry-element="MuiGrid" data-sentry-source-file="EditProperties.tsx">
        <IconButton onClick={handleClick} variant="secondary" fullWidth icon="EDIT" data-sentry-element="IconButton" data-sentry-source-file="EditProperties.tsx">
          Edit properties
        </IconButton>
      </MuiGrid>
    </SubModule>;
};