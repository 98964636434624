import 'tippy.js/dist/tippy.css';
import { forwardRef, useMemo } from 'react';
import { TOOLTIP_COLOR } from '@jux/ui/theme/palette';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';
import { Floors, useOverlaysZIndex } from '@jux/ui/hooks';
import { Typography, typographyVariants } from '../typography';
import { MuiGrid } from '../mui';
import { TooltipProps } from './Tooltip.interface';
import * as S from './Tooltip.style';
const MULTI_LINE_PLACEMENT_MAP = {
  bottom: 'bottom',
  top: 'top',
  left: 'left-start',
  right: 'right-start'
} as const;
const UNCONTROLLED_STATE_VALUE = undefined;
export const Tooltip = forwardRef<Element, TooltipProps>(({
  header,
  content,
  arrow = true,
  multiline,
  disabled,
  placement = 'bottom',
  popperOptions,
  children,
  ...props
}, ref) => {
  const shouldDisplayMultilineLayout = useMemo(() => Boolean(header ?? multiline), [header, multiline]);
  const zIndex = useOverlaysZIndex({
    id: 'tooltip',
    floor: Floors.tooltip
  });

  /**
   * Resolving the right placement for the tooltip according to the multiline layout
   */
  const tippyPlacement = useMemo(() => shouldDisplayMultilineLayout ? MULTI_LINE_PLACEMENT_MAP[placement] : placement, [placement, shouldDisplayMultilineLayout]);

  /**
   * Resolving the right distance in pixels of the tooltip from the target, taking into account the arrow size.
   * In order to avoid the arrow from overlapping the target.
   */
  const offset = useMemo(() => arrow ? [0, 9] : [0, 1], [arrow]);
  const tooltipDisabled = useMemo(
  // visible only if it has passed visible controlled state or not visible if it׳s content is not defined
  () => disabled ?? (content ? UNCONTROLLED_STATE_VALUE : true), [content, disabled]);
  return <S.StyledTippy zIndex={zIndex} placement={tippyPlacement} arrow={arrow} appendTo={window.document.body} disabled={tooltipDisabled} multiline={shouldDisplayMultilineLayout} maxWidth="auto" popperOptions={mergeTyped({
    modifiers: [{
      name: 'offset',
      options: {
        offset
      }
    }, {
      name: 'flip'
    }]
  }, popperOptions)} content={<S.TooltipContentLayout>
            {header && <Typography variant="label-s-bold" sx={{
      color: TOOLTIP_COLOR
    }}>
                {header}
              </Typography>}
            {/* Render the content of the tooltip */}
            {content ? typeof content === 'string' ? <Typography variant="label-s-medium" sx={{
      color: TOOLTIP_COLOR,
      overflowWrap: 'break-word',
      ...(!shouldDisplayMultilineLayout && {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        wordBreak: 'break-all'
      })
    }}>
                  {content}
                </Typography> : <MuiGrid sx={typographyVariants['label-s-medium']}>
                  {content}
                </MuiGrid> : null}
          </S.TooltipContentLayout>} delay={[S.TOOLTIP_DELAY_IN, S.TOOLTIP_DELAY_OUT]} {...props} ref={ref}>
        {children}
      </S.StyledTippy>;
});