import { FC } from 'react';
import { Floors, useOverlaysZIndex } from '@jux/ui/hooks';
import { LayoutProps } from './Layout.interface';
import * as S from './Layout.style';
export const Layout: FC<LayoutProps> = ({
  navbar,
  content,
  sidebar,
  contentFullscreen
}) => {
  const navbarZIndex = useOverlaysZIndex({
    id: 'layout-navbar',
    floor: Floors.navbar
  });
  return <S.LayoutRoot data-sentry-element="unknown" data-sentry-component="Layout" data-sentry-source-file="Layout.tsx">
      <S.LayoutNavbar container zIndex={navbarZIndex} position={contentFullscreen ? 'fixed' : 'sticky'} data-sentry-element="unknown" data-sentry-source-file="Layout.tsx">
        {navbar}
      </S.LayoutNavbar>
      {sidebar}
      <S.LayoutContent container data-sentry-element="unknown" data-sentry-source-file="Layout.tsx">{content}</S.LayoutContent>
    </S.LayoutRoot>;
};