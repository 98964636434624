import { ChangeEvent, ComponentType, FormEvent, forwardRef, useCallback, useState } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType, ComponentProps } from '@jux/data-entities';
type ToggleComponentType = {
  Component: ComponentType<ComponentProps<typeof COMPONENT_TAG_NAME.JuxToggle>>;
};
type ToggleLiveModeProps = ToggleComponentType & ComponentProps<typeof COMPONENT_TAG_NAME.JuxToggle>;
export const ToggleLiveMode = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxToggle>, ToggleLiveModeProps>(({
  children,
  Component,
  onChange,
  checked,
  ...props
}, ref) => {
  const [isChecked, setIsChecked] = useState(checked);
  const handleChange = useCallback((e: FormEvent<HTMLButtonElement> & ChangeEvent<HTMLInputElement>) => {
    onChange?.(e);
    setIsChecked(!isChecked);
  }, [isChecked, onChange]);
  return <Component {...props} ref={ref} onChange={handleChange} checked={isChecked}>
      {children}
    </Component>;
});