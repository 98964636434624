import { styled } from '@jux/ui/components/common/mui';
import { colorIndicatorTransparencyPattern } from '@jux/ui/utils/colorIndicatorTransparencyPattern';
import { withHash } from '@jux/ui/utils/css';
import { ColorPickerPopupProps } from './ColorPickerPopup';
export const ColorIndicatorButtonWrapper = styled('button')(() => ({
  display: 'flex',
  backgroundColor: 'inherit',
  border: 'none',
  outline: 'none'
}));
export const ColorIndicator = styled('div', {
  shouldForwardProp: prop => prop !== 'isTokenized' && prop !== 'color' && prop !== 'tokenHexValue'
})<Pick<ColorPickerPopupProps, 'isTokenized' | 'color' | 'tokenHexValue'>>(({
  color,
  isTokenized,
  theme,
  tokenHexValue
}) => ({
  backgroundColor: withHash(tokenHexValue),
  borderRadius: isTokenized ? '50%' : theme.drimz.size.borderRadius.small,
  width: '12px',
  height: '12px',
  cursor: isTokenized ? 'default' : 'pointer',
  padding: '0',
  border: 'none',
  position: 'relative',
  overflow: 'hidden',
  ...colorIndicatorTransparencyPattern({
    borderRadius: isTokenized ? '50%' : theme.drimz.size.borderRadius.small,
    color,
    theme
  })
}));
export const ColorIndicatorDiv = styled('div', {
  shouldForwardProp: prop => prop !== 'isTokenized' && prop !== 'color' && prop !== 'tokenHexValue'
})<Pick<ColorPickerPopupProps, 'isTokenized' | 'color' | 'tokenHexValue'>>(({
  color,
  isTokenized,
  theme,
  tokenHexValue
}) => ({
  backgroundColor: withHash(tokenHexValue),
  borderRadius: isTokenized ? '50%' : theme.drimz.size.borderRadius.small,
  width: '12px',
  height: '12px',
  cursor: 'pointer',
  border: 'none',
  position: 'relative',
  overflow: 'hidden',
  ...colorIndicatorTransparencyPattern({
    borderRadius: isTokenized ? '50%' : theme.drimz.size.borderRadius.small,
    color,
    theme
  })
}));