import { DragEventHandler, FC, memo, MouseEventHandler } from 'react';
import { DATA_JUX_NODE_ID_ATTRIBUTE } from 'libs/canjux/core/src/constants';
import { NodeType } from '@jux/data-entities';
import { ItemLogicalSlotSeparator } from '@jux/ui/components/editor/components/panels/layers/ObjectNavigator';
import { useSettingsState } from '@jux/ui/state';
import { DropPosition, DropPositionType } from '../../types';
import { Expander, ExpanderProps } from './Expander';
import { ObjectNavigatorItemName, ObjectNavigatorItemNameProps } from './ObjectNavigatorItemName';
import { ObjectNavigatorItemTypeIcon } from './ObjectNavigatorItemTypeIcon';
import { ObjectNavigatorItemWrapper, ObjectNavigatorItemWrapperProps } from './ObjectNavigatorItemWrapper';
import { DroppableLine } from './DroppableLine';
import { useReorderLayers } from './useReorderLayers';
import { DropZoneIndication } from './DropZoneIndication';
import * as S from './ObjectNavigatorItem.style';
import { ObjectNavigatorItemHiddenIcon } from './ObjectNavigatorItemHiddenIcon';
export type ObjectNavigatorItemProps = Pick<ObjectNavigatorItemWrapperProps, 'indentLevel' | 'isHovered' | 'isHidden' | 'isLibraryComponent' | 'hasAncestorLibraryComponent' | 'hasAncestorSelected' | 'isSelected' | 'onMouseEnter' | 'onMouseLeave' | 'onDragStart' | 'onDragEnd' | 'onDrop' | 'onDragOver' | 'onClick'> & Pick<ExpanderProps, 'isExpanded'> & {
  objectType: NodeType;
  hasExpander: boolean;
  id: string;
  label: string;
  onExpanderClick: ExpanderProps['onClick'];
  tagName: string;
  onLabelBlur: ObjectNavigatorItemNameProps['onBlur'];
  onLabelDoubleClick: MouseEventHandler<HTMLElement>;
  isEditing: boolean;
  isParentHidden: boolean;
  droppable: boolean;
  draggable: boolean;
  dropPosition?: DropPositionType;
  onDragAbove: DragEventHandler<HTMLElement>;
  onDragBelow: DragEventHandler<HTMLElement>;
  onDragInside: DragEventHandler<HTMLElement>;
  onAbortDragInside: DragEventHandler<HTMLElement>;
  showDroppableLine: boolean;
  isDraggedInside: boolean;
  allowDropAbove: boolean;
  allowDropInside: boolean;
  allowDropBelow: boolean;
  hasDragStarted?: boolean;
  sourceComponentType?: NodeType;
};
export const ObjectNavigatorItem: FC<ObjectNavigatorItemProps> = memo(({
  allowDropAbove,
  allowDropBelow,
  allowDropInside,
  draggable,
  droppable,
  dropPosition,
  hasAncestorLibraryComponent,
  hasAncestorSelected,
  hasDragStarted,
  hasExpander,
  id,
  indentLevel,
  isDraggedInside,
  isEditing,
  isExpanded,
  isHidden,
  isHovered,
  sourceComponentType,
  isLibraryComponent,
  isParentHidden,
  isSelected,
  label,
  objectType,
  onAbortDragInside,
  onClick,
  onDragAbove,
  onDragBelow,
  onDragEnd,
  onDragInside,
  onDragOver,
  onDragStart,
  onDrop,
  onExpanderClick,
  onLabelBlur,
  onLabelDoubleClick,
  onMouseEnter,
  onMouseLeave,
  showDroppableLine,
  tagName
}) => {
  const {
    hoveredOnRef,
    dragPreviewRef
  } = useReorderLayers();
  const {
    settings: {
      tools: {
        layersPanelIds
      }
    }
  } = useSettingsState();
  const isLogicalSlot = objectType === NodeType.LOGICAL_SLOT;
  return <ObjectNavigatorItemWrapper {...{
    [DATA_JUX_NODE_ID_ATTRIBUTE]: id
  }} container hasAncestorLibraryComponent={hasAncestorLibraryComponent} hasAncestorSelected={hasAncestorSelected} indentLevel={indentLevel} isDraggedInside={isDraggedInside} isHidden={isHidden || isParentHidden} isHovered={isHovered} isLibraryComponent={isLibraryComponent} isSelected={isSelected} item objectType={objectType} onClick={onClick} onDragEnd={onDragEnd} onDragOver={onDragOver} onDragStart={draggable ? onDragStart : undefined} onDrop={droppable ? onDrop : undefined} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} ref={draggable ? hoveredOnRef : undefined} sourceComponentType={sourceComponentType}>
        {/* @ts-expect-error - react-dnd has type issues with React 18.3.1 */}
        <S.DragPreview ref={dragPreviewRef} />
        {droppable && hasDragStarted && <DropZoneIndication allowDropAbove={allowDropAbove} allowDropInside={allowDropInside} allowDropBelow={allowDropBelow} onDragAbove={onDragAbove} onDragBelow={onDragBelow} onDragInside={onDragInside} onAbortDragInside={onAbortDragInside} />}
        <S.NameIconContainer item>
          <Expander isExpanded={isExpanded} onClick={onExpanderClick} showExpanderIcon={hasExpander} />
          <ObjectNavigatorItemTypeIcon objectType={objectType} tagName={tagName} sourceComponentType={sourceComponentType} />
          <ObjectNavigatorItemName text={layersPanelIds ? id : label} isEditing={layersPanelIds ? false : isEditing} onBlur={onLabelBlur} onDoubleClick={onLabelDoubleClick} objectType={objectType} />
        </S.NameIconContainer>
        {isLogicalSlot && <ItemLogicalSlotSeparator />}
        {isHidden && !isLogicalSlot && <ObjectNavigatorItemHiddenIcon />}
        {droppable && showDroppableLine && dropPosition && (dropPosition === DropPosition.above || dropPosition === DropPosition.below) && <DroppableLine dropPosition={dropPosition} indentLevel={indentLevel} />}
      </ObjectNavigatorItemWrapper>;
});