import { useCallback } from 'react';
import {
  getNodeComponent,
  keyboardActionKeys,
  useCurrentCanvasName,
  useStore,
} from '@jux/canjux/core';
import { COMPONENT_TAG_NAME } from '@jux/data-entities';
import { useSaveNodeTextContent } from '@jux/ui/components/editor/hooks/useSaveNodeTextContent';
import { useTrackEvents } from '@jux/ui/hooks';
import { AnalyticsEvents } from '@jux/data-access/analytics';
import { useClipboardStore } from './useClipboardStore';
import { useClipboardCopyEvent, useClipboardPasteEvent } from './useHotkeys';

// TODO: move it to a util function
function checkIfElementIsEditable(element: HTMLElement) {
  return element.contentEditable === 'true' || element.tagName === 'INPUT';
}

export const useCopyPasteCanvasItems = () => {
  // TODO: support multi selection
  const currentCanvasName = useCurrentCanvasName();
  const selectedNodeIds = useStore((state) => state.selectedNodesStack);
  const getNodeComponentById = useStore(getNodeComponent);
  // TODO: we need to somehow separate the keyboard event from the clipboard event that could potentially be from context menu in the future
  const { trackKeyboardShortcut } = useTrackEvents();

  // utils
  const { saveTextContent } = useSaveNodeTextContent();
  const { writeToClipboard, readClipboardData } = useClipboardStore();
  const { pasteCopiedNodes, createTextNode } = useStore(
    (state) => state.commonActions
  );

  /**
   * Copying into the clipboard any nodes that are selected,
   * in a case the user is not on an input element and there are no currently selected text.
   */
  useClipboardCopyEvent(
    useCallback(
      async (e: ClipboardEvent) => {
        const selectedText = window.getSelection()?.toString().trim();
        const elementIsEditable = checkIfElementIsEditable(
          e.target as HTMLElement
        );

        // copy selected nodes to the clipboard only if there is no selection and we are not in input element
        if (
          !currentCanvasName ||
          !selectedNodeIds.length ||
          (selectedText && elementIsEditable)
        ) {
          return;
        }

        await writeToClipboard({
          canvasName: currentCanvasName,
          nodeIds: selectedNodeIds,
        });

        trackKeyboardShortcut({
          shortcut: AnalyticsEvents.KBS_COPY,
          hotKey: keyboardActionKeys.COPY,
        });
      },
      [
        currentCanvasName,
        selectedNodeIds,
        trackKeyboardShortcut,
        writeToClipboard,
      ]
    )
  );

  /**
   * Handling pasting anything into the canvas.
   */
  useClipboardPasteEvent(
    useCallback(
      async (event: ClipboardEvent) => {
        // Prevent pasting into the canvas while focusing on an input element or an editable dom element
        // TODO: we should check if the copied element is a text or node data, if its node data than we should not put the data on the target element text (maybe use stopPropagation)
        if (checkIfElementIsEditable(event.target as HTMLElement)) return;

        // read clipboard data
        const { text, nodes: nodesClipboardData } = await readClipboardData();

        // the node into which the copied node will be pasted
        const targetComponent = selectedNodeIds.length
          ? getNodeComponentById(selectedNodeIds[0])
          : undefined;

        // If a node was being copied, we need to create an identical node
        if (text) {
          // if the target node is a text node, replacing its text content
          if (
            selectedNodeIds.length &&
            targetComponent &&
            targetComponent.tagName === COMPONENT_TAG_NAME.JuxText &&
            targetComponent.config.props.text !== text
          ) {
            saveTextContent({ nodeId: selectedNodeIds[0], newText: text });
          }
          // otherwise creating a new text node on the parent of the target node
          else {
            createTextNode({
              text,
              targetNodeIds: selectedNodeIds,
              targetCanvasName: currentCanvasName,
            });
          }
        } else if (nodesClipboardData) {
          // creating an identical node but with the properties of the copied node
          pasteCopiedNodes({
            copiedItems: nodesClipboardData.nodes,
            copiedItemsRect: nodesClipboardData.nodesRect,
          });
        }
        trackKeyboardShortcut({
          shortcut: AnalyticsEvents.KBS_PASTE,
          hotKey: keyboardActionKeys.PASTE,
        });
      },
      [
        readClipboardData,
        selectedNodeIds,
        getNodeComponentById,
        trackKeyboardShortcut,
        saveTextContent,
        createTextNode,
        currentCanvasName,
        pasteCopiedNodes,
      ]
    )
  );
};
