import { ComponentProps, FC, useMemo } from 'react';
import { Tooltip } from '@jux/ui/components';
import { DDPFieldsStateKeys } from '@jux/ui/components/editor/state';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { parseFromStrToMulti } from '../../../hooks/useMultiDirectionFields';
import { formatDimensionDisplayValue } from '../../../hooks';
const DimensionFieldMultiDirectionalTooltipTitle: FC<{
  value: string;
  fieldKey?: DDPFieldsStateKeys;
}> = ({
  value
}) => {
  const parsedValue = useMemo(() => parseFromStrToMulti(value), [value]);
  return <MuiGrid container flexDirection="column" data-sentry-element="MuiGrid" data-sentry-component="DimensionFieldMultiDirectionalTooltipTitle" data-sentry-source-file="DimensionFieldMultiDirectionalTooltip.tsx">
      <MuiGrid item data-sentry-element="MuiGrid" data-sentry-source-file="DimensionFieldMultiDirectionalTooltip.tsx">
        Top: {formatDimensionDisplayValue(parsedValue.top)}
      </MuiGrid>
      <MuiGrid item data-sentry-element="MuiGrid" data-sentry-source-file="DimensionFieldMultiDirectionalTooltip.tsx">
        Right: {formatDimensionDisplayValue(parsedValue.right)}
      </MuiGrid>
      <MuiGrid item data-sentry-element="MuiGrid" data-sentry-source-file="DimensionFieldMultiDirectionalTooltip.tsx">
        Bottom: {formatDimensionDisplayValue(parsedValue.bottom)}
      </MuiGrid>
      <MuiGrid item data-sentry-element="MuiGrid" data-sentry-source-file="DimensionFieldMultiDirectionalTooltip.tsx">
        Left: {formatDimensionDisplayValue(parsedValue.left)}
      </MuiGrid>
    </MuiGrid>;
};

// TODO: this was a hack to render the proper tooltip data on multi-directional-field
// make sure to pass this data properly from the hook it self
export const DimensionFieldMultiDirectionalTooltip: FC<{
  isMultiDirectional?: boolean;
  value?: string;
  children: ComponentProps<typeof Tooltip>['children'];
}> = ({
  children,
  value,
  isMultiDirectional
}) => <Tooltip interactive={false} multiline content={isMultiDirectional && value ? <DimensionFieldMultiDirectionalTooltipTitle value={value} /> : undefined} data-sentry-element="Tooltip" data-sentry-component="DimensionFieldMultiDirectionalTooltip" data-sentry-source-file="DimensionFieldMultiDirectionalTooltip.tsx">
    {children}
  </Tooltip>;