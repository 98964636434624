/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const FlexDirectionReverseDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M10.5303 2.96967C10.2374 2.67678 9.76256 2.67678 9.46967 2.96967C9.17678 3.26256 9.17678 3.73744 9.46967 4.03033L9.68934 4.25H4.5C4.08579 4.25 3.75 4.58579 3.75 5C3.75 5.41421 4.08579 5.75 4.5 5.75H9.68934L9.46967 5.96967C9.17678 6.26256 9.17678 6.73744 9.46967 7.03033C9.76256 7.32322 10.2374 7.32322 10.5303 7.03033L12.0303 5.53033C12.3232 5.23744 12.3232 4.76256 12.0303 4.46967L10.5303 2.96967Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M5.46967 13.0303C5.76256 13.3232 6.23744 13.3232 6.53033 13.0303C6.82322 12.7374 6.82322 12.2626 6.53033 11.9697L6.31066 11.75H11.5C11.9142 11.75 12.25 11.4142 12.25 11C12.25 10.5858 11.9142 10.25 11.5 10.25H6.31066L6.53033 10.0303C6.82322 9.73744 6.82322 9.26256 6.53033 8.96967C6.23744 8.67678 5.76256 8.67678 5.46967 8.96967L3.96967 10.4697C3.67678 10.7626 3.67678 11.2374 3.96967 11.5303L5.46967 13.0303Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="FlexDirectionReverseDDPIcon" data-sentry-source-file="FlexDirectionReverseDDPIcon.tsx" />;