import {
  CanjuxState,
  ComponentActionsParams,
  createVariantInMatrixNodes,
  getDefaultNodePropertiesByTagName,
  getMatrixVariantsConfig,
  JuxStoreActionFn,
  setLayersData,
} from '@jux/canjux/core';
import {
  ComponentSourceData,
  ComponentTagNames,
  NodeData,
  NodeType,
  SOURCE_COMPONENT_TYPES,
} from '@jux/data-entities';
import { getVariantsGroupWrapperData } from '../../matrix-utils/getVariantsGroupWrapperData';
import { deleteNode } from './deleteNode';

/**
 * Turn Local / Library component node to Variants Group node
 */
export const convertNodeToVariantsGroup: JuxStoreActionFn<
  ComponentActionsParams['convertNodeToVariantsGroup'],
  CanjuxState
> = ({ nodeId, state }) => {
  const currentCanvas = state.canvases[state.currentCanvasName];
  const sourceRootNodeIndex = currentCanvas.rootNodesOrder.indexOf(nodeId);

  const component = state.components[nodeId];
  const nodeData = currentCanvas.nodes[nodeId];
  if (
    !SOURCE_COMPONENT_TYPES.includes(component.type) ||
    sourceRootNodeIndex === -1
  ) {
    return state;
  }

  const newVariantsGroupComponent = getVariantsGroupWrapperData(
    component as ComponentSourceData
  );
  const newVaraintGroupNode: NodeData = {
    position: nodeData.position,
    properties: {
      ...getDefaultNodePropertiesByTagName({
        tagName: ComponentTagNames.JuxDiv,
        type: NodeType.VARIANTS_GROUP,
      }),
    },
  };

  state.components[newVariantsGroupComponent.id] = newVariantsGroupComponent;
  currentCanvas.nodes[newVariantsGroupComponent.id] = newVaraintGroupNode;

  // This will delete the node from the canvas but not it's source component data
  deleteNode({ nodeId, state });
  currentCanvas.rootNodesOrder.splice(
    sourceRootNodeIndex, // original index before it was removed
    0,
    newVariantsGroupComponent.id
  );

  const variants = getMatrixVariantsConfig(component as ComponentSourceData);
  for (const variantCombination of variants) {
    createVariantInMatrixNodes({
      sourceComponentId: component.id,
      variantValues: variantCombination.variantValues,
      interactiveState: variantCombination.interactiveState,
      variantsGroupId: newVariantsGroupComponent.id,
      state,
    });
  }

  setLayersData(state);

  return state;
};
