import { LiveObject } from '@liveblocks/client';
import { isEmpty } from 'lodash';
import { useRouter } from 'next/router';
import { FC, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CORE_TOKEN_SET_NAME } from '@jux/types';
import { DEFAULT_CANVAS_NAME, getDefaultCanvas, liveblocksClient, Storage } from '@jux/canjux/core';
import { useLiveblocksRoom } from '@jux/ui/components/editor/hooks';
import { createCanvasData } from '../../../../canjux/core/src/store/wip/helpers/createCanvasData';
import { getInitialCoreTokenSetData } from '../../../../canjux/core/src/store/wip/helpers/token-sets/getInitialCoreTokenSetData';
const getInitialLiveblocksStorage = (): Storage => ({
  assets: new LiveObject(),
  canvases: new LiveObject({
    [DEFAULT_CANVAS_NAME]: new LiveObject(createCanvasData(DEFAULT_CANVAS_NAME))
  }),
  components: new LiveObject(),
  tokenSets: new LiveObject({
    [CORE_TOKEN_SET_NAME]: new LiveObject(getInitialCoreTokenSetData())
  }),
  editorTokenSetId: ''
});
function handleLiveblocksConnectionAndRedirect({
  roomId,
  handleRedirectToCanvas,
  handleEmptyRoomRedirect
}: {
  roomId: string | undefined;
  handleRedirectToCanvas: (canvasId: string) => void;
  handleEmptyRoomRedirect: () => void;
}) {
  if (roomId) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const {
      room
    } = liveblocksClient.enterRoom<any, Storage>(roomId, {
      initialPresence: {},
      initialStorage: getInitialLiveblocksStorage()
    });
    if (room) {
      room.getStorage().then(storage => {
        const root = storage.root.toImmutable();

        // In case we're missing storage data we redirect to editor where the initial data is created.
        if (isEmpty(root)) {
          handleEmptyRoomRedirect();
        }
        const defaultCanvas = getDefaultCanvas(root.canvases);
        let defaultCanvasId = defaultCanvas.id;
        const defaultCanvasName = defaultCanvas.name || DEFAULT_CANVAS_NAME;

        // If we're missing the canvas id we don't have any default canvas data.
        if (!defaultCanvasId) {
          const canvases = storage.root.get('canvases');
          const newCanvas = new LiveObject(defaultCanvas);

          // Must be updated for the redirect.
          defaultCanvasId = uuidv4();
          const creationTime = new Date().toISOString();
          newCanvas.update({
            id: defaultCanvasId,
            name: defaultCanvasName,
            createdAt: creationTime
          });
          canvases.update({
            [defaultCanvasName]: newCanvas
          });
        }

        // we finally redirect to the editor with the ID
        handleRedirectToCanvas(defaultCanvasId);
      });
    }
    return;
  }
}
export const RedirectToDefaultCanvas: FC = () => {
  const {
    room: {
      roomId
    }
  } = useLiveblocksRoom();
  const router = useRouter();
  const handleEmptyRoomRedirect = useCallback(() => {
    return router.replace('/editor');
  }, [router]);
  const handleRedirectToCanvas = useCallback((canvasId: string) => {
    return router.replace(`/editor/${canvasId}`);
  }, [router]);
  useEffect(() => {
    handleLiveblocksConnectionAndRedirect({
      roomId,
      handleEmptyRoomRedirect,
      handleRedirectToCanvas
    });
  }, [handleEmptyRoomRedirect, handleRedirectToCanvas, roomId, router]);
  return null;
};