import { useCallback } from 'react';
import {
  DDPSubModulesStateKeys,
  useDDPSubModulesState,
} from '@jux/ui/components/editor/state';
import { isTagNameSupportsSubModule } from '@jux/ui/components/editor/hooks/useSetupShouldRenderSubModule.utils';
import { useSelectedNodeComponent } from '@jux/ui/components/editor/hooks';
import {
  COMPONENT_TAG_NAME,
  ComponentTagName,
  NodeType,
} from '@jux/data-entities';

export const useShouldRenderSubModules = () => {
  const { getSubModulesStateByKey } = useDDPSubModulesState();
  const { component: selectedNodeComponent, sourceComponent } =
    useSelectedNodeComponent();

  const shouldRenderSubModule = useCallback(
    (key: DDPSubModulesStateKeys) => {
      if (!selectedNodeComponent) return false;

      if (key === 'textContent') {
        return sourceComponent?.tagName === COMPONENT_TAG_NAME.JuxText;
      }

      // If the selected node is not an instance and the tag name does not support the sub-module, return false
      if (
        !isTagNameSupportsSubModule({
          type: selectedNodeComponent.type,
          tagName:
            selectedNodeComponent.type === NodeType.INSTANCE
              ? (sourceComponent?.tagName as ComponentTagName)
              : (sourceComponent?.tagName as ComponentTagName),
          subModuleKey: key,
        })
      ) {
        return false;
      }

      const states = getSubModulesStateByKey(key);
      if (!states) return false;

      const { shouldRender } = states;

      return typeof shouldRender === 'function' ? shouldRender() : shouldRender;
    },
    [getSubModulesStateByKey, selectedNodeComponent, sourceComponent?.tagName]
  );

  return {
    shouldRenderSubModule,
  };
};
