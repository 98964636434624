/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const ZoomOutViewIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="18" height="2" attr={{
  viewBox: '0 0 18 2'
}} child={[{
  tag: 'rect',
  attr: {
    x: '0.5',
    width: '17',
    height: '2',
    rx: '1',
    fill: 'currentColor'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="ZoomOutViewIcon" data-sentry-source-file="ZoomOutViewIcon.tsx" />;