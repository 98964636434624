/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const BorderStyleDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M4 4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6H12C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4H4Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M4.70711 10.7071C5.10205 10.3122 5.52133 10.0931 5.90303 10.0584C6.24315 10.0275 6.71294 10.1272 7.29289 10.7071C8.31892 11.7331 9.54372 11.9023 10.5394 11.7364C11.4786 11.5799 12.2984 11.1158 12.7071 10.7071C13.0976 10.3166 13.0976 9.68342 12.7071 9.2929C12.3166 8.90237 11.6834 8.90237 11.2929 9.2929C11.2016 9.38422 10.7714 9.67015 10.2106 9.76361C9.70628 9.84766 9.18108 9.76687 8.70711 9.2929C7.78706 8.37285 6.75685 7.97253 5.72196 8.06661C4.72866 8.15691 3.89795 8.68784 3.29289 9.2929C2.90237 9.68342 2.90237 10.3166 3.29289 10.7071C3.68342 11.0976 4.31658 11.0976 4.70711 10.7071Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="BorderStyleDDPIcon" data-sentry-source-file="BorderStyleDDPIcon.tsx" />;