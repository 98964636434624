/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const JustifyContentFlexEndColumnDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M5 5C5 5.55228 5.44772 6 6 6L10 6C10.5523 6 11 5.55228 11 5C11 4.44771 10.5523 4 10 4H6C5.44772 4 5 4.44772 5 5Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M6 9C5.44772 9 5 8.55228 5 8C5 7.44772 5.44772 7 6 7L10 7C10.5523 7 11 7.44771 11 8C11 8.55228 10.5523 9 10 9L6 9Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M13.5 11C13.7761 11 14 11.2239 14 11.5C14 11.7761 13.7761 12 13.5 12H2.5C2.22386 12 2 11.7761 2 11.5C2 11.2239 2.22386 11 2.5 11L13.5 11Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="JustifyContentFlexEndColumnDDPIcon" data-sentry-source-file="JustifyContentFlexEndColumnDDPIcon.tsx" />;