/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from './core/CustomIcon';
export const ColorIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M8 14C10.2091 14 12 12.2091 12 10C12 6 8 2 8 2C8 2 4 6 4 10C4 12.2091 5.79086 14 8 14ZM9.39401 8.59892C9.39401 9.86518 8.6095 10.9482 7.5 11.3885C7.76904 11.5231 8.07268 11.5989 8.39401 11.5989C9.49858 11.5989 10.394 10.7035 10.394 9.59892C10.394 8.14109 9.33138 6.68326 8.75525 6C9.1284 6.81362 9.39401 7.70627 9.39401 8.59892Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="ColorIcon" data-sentry-source-file="ColorIcon.tsx" />;