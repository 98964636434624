import { RefObject, useCallback } from 'react';
import { AssetsTabs, PoppersKey, usePopper } from '@jux/ui/components';
import { DDPAssetsPopper } from './DDPAssetsPopper';
export const useDDPAssetsPopper = () => {
  const {
    open,
    isOpen,
    close
  } = usePopper(PoppersKey.ddpAssets);
  const openDDPAssetsPopper = useCallback(({
    sourceElementRef,
    handleAssetClick,
    selectedAssetId,
    selectedAssetType
  }: {
    sourceElementRef: RefObject<HTMLElement>;
    handleAssetClick: (assetId: string) => void;
    selectedAssetId?: string;
    selectedAssetType?: AssetsTabs;
  }) => {
    open({
      content: <DDPAssetsPopper handleAssetClick={handleAssetClick} selectedAssetId={selectedAssetId} selectedAssetType={selectedAssetType} />,
      sourceElement: sourceElementRef.current,
      styles: {
        positioning: 'bottom-right',
        direction: 'rtl'
      }
    });
  }, [open]);
  return {
    openDDPAssetsPopper,
    closeDDPAssetsPopper: () => close({
      options: {
        shouldResetPreviouslyOpenedKey: true
      }
    }),
    isDDPAssetsPopperOpen: isOpen()
  };
};