import { useCallback } from 'react';
import { selectedNodeComponentBooleanType, selectNodeLocalComponentDependencies, useStore, useStoreActions } from '@jux/canjux/core';
import { useSelectedNodeComponent, useSelectedNodeId } from '@jux/ui/components/editor/hooks';
import { useAddToLibraryModal } from '@jux/ui/components/common/modal/useAddToLibraryModal/useAddToLibraryModal';
import { useTrackEvents } from '@jux/ui/hooks';
import { useSettingsState } from '@jux/ui/state';
import { toast } from '@jux/ui/toast';
import { InviteMemberToast } from '../components';
export const useAddToLibrary = () => {
  const selectedNodeId = useSelectedNodeId();
  const {
    component: selectedNodeComponent
  } = useSelectedNodeComponent();
  const {
    isLocalComponent
  } = useStore(selectedNodeComponentBooleanType);
  const localComponentDependencies = useStore(selectNodeLocalComponentDependencies);
  const {
    settings: {
      devFeatures: {
        addToLibraryToast
      }
    },
    disableAddToLibraryToast
  } = useSettingsState();
  const {
    openAddToLibraryModal
  } = useAddToLibraryModal();
  const {
    trackAddComponentToLibraryEvent,
    trackAddToLibraryClick
  } = useTrackEvents();
  const {
    componentActions: {
      addComponentToLibrary
    }
  } = useStoreActions();
  const addComponentAndDependenciesToLibrary = useCallback(() => {
    if (selectedNodeId) {
      addComponentToLibrary({
        componentId: selectedNodeId
      });
      trackAddComponentToLibraryEvent({
        componentUUID: selectedNodeId
      });
      if (addToLibraryToast) {
        toast.action(({
          closeToast
        }) => <InviteMemberToast closeToast={closeToast} />);
        disableAddToLibraryToast();
      }
    }
  }, [selectedNodeId, addComponentToLibrary, trackAddComponentToLibraryEvent, addToLibraryToast, disableAddToLibraryToast]);
  const handleAddToLibrary = useCallback(async () => {
    if (!selectedNodeId || !isLocalComponent || !selectedNodeComponent) {
      return;
    }
    const deps = localComponentDependencies(selectedNodeId) ?? [];
    const dependenciesCount = deps.length;
    const hasLocalComponentDependencies = dependenciesCount > 0;
    if (hasLocalComponentDependencies) {
      const dependenciesNames = deps.map(dep => `[${dep}]`).join(', ');
      openAddToLibraryModal({
        componentName: selectedNodeComponent?.displayName || selectedNodeComponent.id,
        onConfirm: addComponentAndDependenciesToLibrary,
        dependenciesNames,
        dependenciesCount,
        componentsToAddCount: dependenciesCount + 1
      });
    } else {
      addComponentAndDependenciesToLibrary();
    }
    trackAddToLibraryClick();
  }, [addComponentAndDependenciesToLibrary, isLocalComponent, localComponentDependencies, openAddToLibraryModal, selectedNodeComponent, selectedNodeId, trackAddToLibraryClick]);
  return {
    handleAddToLibrary
  };
};