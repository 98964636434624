import { JSX, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
export type FilesImportInputRef = {
  click: () => void;
};
export const FilesImportInput = forwardRef<FilesImportInputRef, Pick<JSX.IntrinsicElements['input'], 'onChange' | 'accept'> & {
  onCancel?: () => void;
}>(({
  onCancel,
  ...props
}, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => ({
    click: () => inputRef.current?.click()
  }), []);
  useEffect(() => {
    const inputElement = inputRef.current;
    if (onCancel) {
      inputElement?.addEventListener('cancel', onCancel);
    }
    return () => {
      if (onCancel) {
        inputElement?.removeEventListener('cancel', onCancel);
      }
    };
  }, [onCancel]);
  return <input ref={inputRef} style={{
    display: 'none'
  }} type="file" multiple {...props} />;
});