import { forwardRef } from 'react';
import { LoadingButtonWithIcon as Button, LoadingButtonWithIconProps as ButtonProps } from '@jux/ui/components/common';
import { EDITOR_DDP_BUTTON_GROUP_FIELD_DEFAULT_BG_COLOR, EDITOR_DDP_BUTTON_GROUP_FIELD_DISABLED_BORDER_COLOR, EDITOR_DDP_BUTTON_GROUP_FIELD_DISABLED_COLOR, EDITOR_DDP_BUTTON_GROUP_FIELD_HOVER_COLOR, EDITOR_DDP_BUTTON_GROUP_FIELD_IDLE_BORDER_COLOR, EDITOR_DDP_BUTTON_GROUP_FIELD_IDLE_COLOR } from '@jux/ui/theme/palette';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';
import { JUX_BUTTON_GROUP_CLASS_NAME } from './ButtonGroupField.constant';

// TODO: this should be used in a `<ButtonGroup>`
//  component that would handle states, callbacks, etc.
//  (maybe refactor the ButtonGroupField component)
export const GroupButton = forwardRef<HTMLButtonElement, {
  isSelected: boolean;
  allowToggle?: boolean;
} & ButtonProps>(({
  isSelected = false,
  sx,
  allowToggle,
  ...props
}, ref) => <Button ref={ref} sx={mergeTyped({
  // common styles regardless the variant
  p: 0,
  borderRadius: 0,
  border: 0,
  // using outline so that it wont "consume space" and it will be easier to overlap a group button on top of another
  outline: '1px solid',
  color: EDITOR_DDP_BUTTON_GROUP_FIELD_IDLE_COLOR,
  borderColor: EDITOR_DDP_BUTTON_GROUP_FIELD_IDLE_BORDER_COLOR,
  cursor: 'pointer',
  zIndex: 1,
  width: theme => theme.drimz.size.editor.ddp.groupButton.width,
  height: theme => theme.drimz.size.editor.ddp.groupButton.height,
  backgroundColor: EDITOR_DDP_BUTTON_GROUP_FIELD_DEFAULT_BG_COLOR,
  ...(isSelected && {
    cursor: allowToggle ? 'pointer' : 'default',
    color: theme => theme.drimz.palette.text.primary,
    borderColor: theme => theme.drimz.palette.text.primary,
    zIndex: 2
  }),
  '&:hover, &:focus-visible': {
    backgroundColor: isSelected && !allowToggle ? EDITOR_DDP_BUTTON_GROUP_FIELD_DEFAULT_BG_COLOR : theme => theme.drimz.palette.background.hover,
    ...(!isSelected && {
      color: EDITOR_DDP_BUTTON_GROUP_FIELD_HOVER_COLOR,
      outlineColor: theme => theme.drimz.palette.background.hover,
      backgroundColor: theme => theme.drimz.palette.background.hover
    })
  },
  '&:disabled': {
    color: EDITOR_DDP_BUTTON_GROUP_FIELD_DISABLED_COLOR,
    outlineColor: EDITOR_DDP_BUTTON_GROUP_FIELD_DISABLED_BORDER_COLOR,
    zIndex: 0
  },
  // this css tweak is necessary to allow style changes on the first group button under button group component
  [`.${JUX_BUTTON_GROUP_CLASS_NAME} > *:first-of-type > &`]: {
    borderTopLeftRadius: theme => theme.drimz.size.borderRadius.main,
    borderBottomLeftRadius: theme => theme.drimz.size.borderRadius.main
  },
  // this css tweak is necessary to allow style changes on the last group button under button group component
  [`.${JUX_BUTTON_GROUP_CLASS_NAME} > *:last-child > &`]: {
    borderTopRightRadius: theme => theme.drimz.size.borderRadius.main,
    borderBottomRightRadius: theme => theme.drimz.size.borderRadius.main
  }
}, sx)} {...props} />);