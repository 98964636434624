import { FC } from 'react';
import { capitalize } from 'lodash';
import { SelectField } from '@jux/ui/components';
import { MuiMenuItem } from '@jux/ui/components/common/mui';
import { SubModule } from '../../../../base';
import { InteractiveStateProps } from './InteractiveState.interface';
export const InteractiveState: FC<InteractiveStateProps> = ({
  nodeInteractiveState,
  handleNodeInteractiveStateChange,
  options
}) => {
  return <SubModule title="Interactive state" renderInline data-sentry-element="SubModule" data-sentry-component="InteractiveState" data-sentry-source-file="InteractiveState.tsx">
      <SelectField name="nodeState" type="text" value={nodeInteractiveState} onChange={handleNodeInteractiveStateChange} blurOnSelect data-sentry-element="SelectField" data-sentry-source-file="InteractiveState.tsx">
        {options.map(option => option && <MuiMenuItem key={option} value={option}>
                {capitalize(option)}
              </MuiMenuItem>)}
      </SelectField>
    </SubModule>;
};