import { FC } from 'react';
import { DesignTokenTypeEnum } from '@juxio/design-tokens';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { TokenSelectionProps } from './TokenSelection.interface';
import { TokenSelectionButton } from './TokenSelectionButton';
import { TokenSelectionPopper } from './TokenSelectionPopper';
import { useTokenSelection } from './useTokenSelection';
import { TokenSelectionActions } from './TokenSelectionActions';
export const tokenSelectionWrapperClassName = 'token-selection-wrapper';
export const TokenSelection: FC<TokenSelectionProps> = ({
  anchorEl,
  renderOption,
  tokensProps
}) => {
  const {
    isTokenized,
    tokensPopperProps,
    tokenType,
    tokens
  } = tokensProps ?? {};
  const {
    isTokensOpen
  } = tokensPopperProps ?? {};
  const hasNoTokens = Boolean(tokens?.length === 0);
  const isSupportedToken = Boolean(tokenType);
  const canImportTokenType = Boolean(tokenType === DesignTokenTypeEnum.color);
  const {
    handleTokenButtonClick,
    fieldWidth,
    handleTokenSelection,
    selectedTokenValueByDisplayName,
    handleCloseTokens,
    handleTokenCreationAction,
    /*
     * TODO: the list of display names is used for the list of options in the autocomplete
     * we need to calculate the options value outside of this component where we also pass the renderOption component,
     * so that for different type of tokens we will have particular renderer and particular options value
     */
    tokensByDisplayName
  } = useTokenSelection({
    anchorEl,
    tokensProps
  });
  return <>
      <MuiGrid display="flex" overflow="hidden" className={tokenSelectionWrapperClassName} data-sentry-element="MuiGrid" data-sentry-source-file="TokenSelection.tsx">
        <TokenSelectionButton isTokenized={isTokenized} onClick={handleTokenButtonClick} data-sentry-element="TokenSelectionButton" data-sentry-source-file="TokenSelection.tsx" />
      </MuiGrid>
      {isTokensOpen && <TokenSelectionPopper parsedTokens={tokensByDisplayName} hasNoTokens={hasNoTokens} parsedSelectedTokenValue={selectedTokenValueByDisplayName} anchorEl={anchorEl} fieldWidth={fieldWidth} onTokenSelection={handleTokenSelection} renderOption={renderOption} closeTokens={handleCloseTokens} actions={isSupportedToken && <TokenSelectionActions handleTokenCreationAction={handleTokenCreationAction} hasNoTokens={hasNoTokens} isValidTokenType={canImportTokenType} />} />}
    </>;
};