import { RefObject, useState, MouseEvent } from 'react';
import { IconButton } from '../imported/components';
import { TEST_ID_DIV_ICON_BUTTON, TEST_ID_SLOT_ICON_BUTTON, TEST_ID_TEXT_ICON_BUTTON } from '@jux/constants';
const BUTTON_FOCUS_BORDER_COLOR = '#9e9e9e';
const ICON_COLOR = '#1A1A1A';
type ElmProps = {
  ref: RefObject<HTMLButtonElement>;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  iconContent: string;
};
interface Props {
  divProps: ElmProps;
  textProps: ElmProps;
  slotProps: ElmProps;
}
export const ElementButtons = ({
  slotProps,
  divProps,
  textProps
}: Props) => {
  const [isDivIconFocused, setIsDivIconFocused] = useState(false);
  const [isTextIconFocused, setIsTextIconFocused] = useState(false);
  const [isSlotIconFocused, setIsSlotIconFocused] = useState(false);
  return <>
      <IconButton disabled={false} hierarchy={'primary'} onClick={textProps.onClick} ref={textProps.ref} data-testid={TEST_ID_TEXT_ICON_BUTTON} style={{
      // focus state
      outline: 'none',
      ...(isTextIconFocused && {
        borderColor: BUTTON_FOCUS_BORDER_COLOR,
        borderRadius: '2px'
      })
    }} onFocus={() => setIsTextIconFocused(true)} onBlur={() => setIsTextIconFocused(false)} tabIndex={0} IconButton_Icon_Props={{
      content: textProps.iconContent,
      color: ICON_COLOR
    }} data-sentry-element="IconButton" data-sentry-source-file="ElementButtons.tsx" />
      <IconButton disabled={false} hierarchy={'primary'} onClick={divProps.onClick} ref={divProps.ref} data-testid={TEST_ID_DIV_ICON_BUTTON} style={{
      // focus state
      outline: 'none',
      ...(isDivIconFocused && {
        borderColor: BUTTON_FOCUS_BORDER_COLOR,
        borderRadius: '2px'
      })
    }} onFocus={() => setIsDivIconFocused(true)} onBlur={() => setIsDivIconFocused(false)} tabIndex={0} IconButton_Icon_Props={{
      content: divProps.iconContent,
      color: ICON_COLOR
    }} data-sentry-element="IconButton" data-sentry-source-file="ElementButtons.tsx" />
      <IconButton disabled={false} hierarchy={'primary'} onClick={slotProps.onClick} ref={slotProps.ref} data-testid={TEST_ID_SLOT_ICON_BUTTON} style={{
      // focus state
      outline: 'none',
      ...(isSlotIconFocused && {
        borderColor: BUTTON_FOCUS_BORDER_COLOR,
        borderRadius: '2px'
      })
    }} onFocus={() => setIsSlotIconFocused(true)} onBlur={() => setIsSlotIconFocused(false)} tabIndex={0} IconButton_Icon_Props={{
      content: slotProps.iconContent,
      color: ICON_COLOR
    }} data-sentry-element="IconButton" data-sentry-source-file="ElementButtons.tsx" />
    </>;
};