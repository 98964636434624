/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { Component, ErrorInfo, PropsWithChildren } from 'react';
import * as Sentry from '@sentry/nextjs';
import { Error } from './Error';
interface State {
  hasError: boolean;
}
class ErrorBoundary extends Component<PropsWithChildren, State> {
  public state: State = {
    hasError: false
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true
    };
  }
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo);
    Sentry.captureException(error);
    throw error;
  }
  public render() {
    if (this.state.hasError) {
      return <Error data-sentry-element="Error" data-sentry-component="ErrorBoundary" data-sentry-source-file="ErrorBoundary.tsx" />;
    }
    return this.props.children;
  }
}
export { ErrorBoundary };