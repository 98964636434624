import { FC, useEffect, useMemo, useRef } from 'react';
import { AssetsHeader, AssetsTabs, SystemAssets, Typography, UploadAssets, UserAssets, getAssetPreviewItemElementId, useAssetsTabs } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { NAVIGATOR_MENU_TAB_CONTENT_DESCRIPTION_COLOR } from '@jux/ui/theme/palette';
import { useUploadAsset } from '@jux/ui/hooks';
import { selectSystemAssets, selectUserAssets, useStore } from '@jux/canjux/core';
import * as S from './DDPAssetsPopper.style';
const DDP_EMPTY_USER_ASSETS_DESCRIPTION = <>
    Your personal assets are empty.
    <br />
    Add you own assets.
  </>;
export const DDPAssetsPopper: FC<{
  handleAssetClick: (assetId: string) => void;
  selectedAssetId?: string;
  selectedAssetType?: AssetsTabs;
}> = ({
  handleAssetClick,
  selectedAssetId,
  selectedAssetType
}) => {
  const userAssets = useStore(selectUserAssets);
  const systemAssets = useStore(selectSystemAssets);
  const {
    activeTab,
    isSystemAssetsTab,
    isUserAssetsTab,
    setActiveSystemAssetsTab,
    setActiveUserAssetsTab
  } = useAssetsTabs(selectedAssetType);
  const {
    handleImportButtonClick,
    importFiles,
    inputRef,
    isDoneImporting
  } = useUploadAsset();
  const noUserAssets = useMemo(() => !userAssets.length, [userAssets]);
  const ddpAssetsPopperItemsRef = useRef<HTMLDivElement>(null);

  // useEffect to scroll to selected asset
  useEffect(() => {
    if (!ddpAssetsPopperItemsRef.current || !selectedAssetId) return;
    if (selectedAssetType !== activeTab) {
      ddpAssetsPopperItemsRef.current.scrollTo(0, 0);
      return;
    }
    const selectedAsset = document.getElementById(getAssetPreviewItemElementId(selectedAssetId));
    selectedAsset?.scrollIntoView({
      behavior: 'instant',
      block: 'center'
    });
  }, [selectedAssetId, activeTab, selectedAssetType]);
  return <S.DDPAssetsPopperWrapper data-sentry-element="unknown" data-sentry-component="DDPAssetsPopper" data-sentry-source-file="DDPAssetsPopper.tsx">
      <S.ContentHeader data-sentry-element="unknown" data-sentry-source-file="DDPAssetsPopper.tsx">
        <AssetsHeader isSystemAssets={isSystemAssetsTab} setSystemAssets={setActiveSystemAssetsTab} isUserAssets={isUserAssetsTab} setUserAssets={setActiveUserAssetsTab} data-sentry-element="AssetsHeader" data-sentry-source-file="DDPAssetsPopper.tsx" />
      </S.ContentHeader>

      <S.DDPAssetsPopperItems ref={ddpAssetsPopperItemsRef} data-sentry-element="unknown" data-sentry-source-file="DDPAssetsPopper.tsx">
        {isSystemAssetsTab && <SystemAssets assets={systemAssets} handleAssetClick={({
        assetId
      }) => handleAssetClick(assetId)} selectedAssetId={selectedAssetId} />}
        {isUserAssetsTab && <MuiGrid container>
            {noUserAssets ? <MuiGrid container item gap="16px">
                <MuiGrid item>
                  <Typography variant="label-xs-regular" color={NAVIGATOR_MENU_TAB_CONTENT_DESCRIPTION_COLOR}>
                    {DDP_EMPTY_USER_ASSETS_DESCRIPTION}
                  </Typography>
                </MuiGrid>
                <MuiGrid item>
                  <UploadAssets inputRef={inputRef} handleImportButtonClick={handleImportButtonClick} importFiles={importFiles} isDoneImporting={isDoneImporting} />
                </MuiGrid>
              </MuiGrid> : <MuiGrid item>
                <UserAssets assets={userAssets} handleAssetClick={({
            assetId
          }) => handleAssetClick(assetId)} selectedAssetId={selectedAssetId} />
              </MuiGrid>}
          </MuiGrid>}
      </S.DDPAssetsPopperItems>
    </S.DDPAssetsPopperWrapper>;
};