/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const TextMixedColorDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="12" height="12" attr={{
  viewBox: '0 0 12 12'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M6 1.59949C4.8303 1.59949 3.77255 2.0727 3.00533 2.83992C2.9217 2.92355 2.84156 3.01064 2.76515 3.10095L6 4.96857L9.23485 3.10095C9.15844 3.01064 9.0783 2.92355 8.99467 2.83992C8.22745 2.0727 7.1697 1.59949 6 1.59949ZM9.98595 4.39935L6.75 6.26761L6.75 10.0036C7.61876 9.8485 8.39535 9.42859 8.99467 8.82927C9.7619 8.06204 10.2351 7.00429 10.2351 5.83459C10.2351 5.33007 10.1472 4.84705 9.98595 4.39935ZM5.25 10.0036L5.25 6.26761L2.01405 4.39936C1.85282 4.84706 1.76489 5.33007 1.76489 5.83459C1.76489 7.00429 2.2381 8.06204 3.00533 8.82927C3.60465 9.42859 4.38124 9.8485 5.25 10.0036ZM1.94467 1.77926C2.9817 0.742232 4.4165 0.0994873 6 0.0994873C7.5835 0.0994873 9.0183 0.742232 10.0553 1.77926C10.4082 2.13209 10.7153 2.53086 10.9671 2.96604C11.4557 3.81047 11.7351 4.79104 11.7351 5.83459C11.7351 7.41809 11.0924 8.8529 10.0553 9.88993C9.0183 10.927 7.5835 11.5697 6 11.5697C4.4165 11.5697 2.9817 10.927 1.94467 9.88993C0.907637 8.8529 0.264893 7.41809 0.264893 5.83459C0.264893 4.79104 0.544293 3.81047 1.03287 2.96605C1.28466 2.53086 1.59184 2.13209 1.94467 1.77926Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="TextMixedColorDDPIcon" data-sentry-source-file="TextMixedColorDDPIcon.tsx" />;