import { useCallback } from 'react';
import { PoppersKey, usePopper } from '@jux/ui/components';
import { Floors } from '@jux/ui/hooks';
import { TokenSetActionsPopper } from '../tokenSetsTabs/TokenSetActionsPopper';
export const useOpenTokenSetActionsPopper = () => {
  const {
    open,
    close,
    isOpen,
    currentlyOpenedInnerKey
  } = usePopper(PoppersKey.tokenSetActions);
  const withClose = useCallback((callback: () => void) => {
    callback();
    close();
  }, [close]);
  const openTokenSetActionsPopper = useCallback(({
    sourceElement,
    setKey,
    onDeleteSet,
    showDeleteSet,
    onCreateGroup
  }: {
    sourceElement: HTMLElement;
    setKey: string;
    onDeleteSet: () => void;
    showDeleteSet: boolean;
    onCreateGroup: () => void;
  }) => {
    open({
      innerKey: setKey,
      content: <TokenSetActionsPopper onDeleteSet={() => withClose(onDeleteSet)} showDeleteSet={showDeleteSet} onCreateGroup={() => withClose(onCreateGroup)} />,
      sourceElement,
      styles: {
        direction: 'rtl',
        positioning: 'bottom-right',
        offset: {
          top: 10
        },
        floor: Floors.navbar
      }
    });
  }, [open, withClose]);
  const isTokenSetActionsPopperOpen = useCallback((path: string) => isOpen(path) && currentlyOpenedInnerKey === path, [currentlyOpenedInnerKey, isOpen]);
  return {
    openTokenSetActionsPopper,
    isTokenSetActionsPopperOpen
  };
};