/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const ZIndexDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M2.51943 10.8683C1.84761 10.4844 1.84761 9.51566 2.51942 9.13177L4.5 8.00001L2.51943 6.86826C1.84761 6.48436 1.84761 5.51566 2.51942 5.13177L7.50386 2.28352C7.81129 2.10784 8.18871 2.10785 8.49614 2.28352L13.4806 5.13177C14.1524 5.51566 14.1524 6.48436 13.4806 6.86826L11.5 8.00001L13.4806 9.13177C14.1524 9.51566 14.1524 10.4844 13.4806 10.8683L8.49614 13.7165C8.18871 13.8922 7.81129 13.8922 7.50386 13.7165L2.51943 10.8683ZM4.02335 10L8 12.2724L11.9767 10L9.99807 8.85826L8.49614 9.71651C8.18871 9.89218 7.81129 9.89218 7.50386 9.71651L6.01167 8.86383L4.02335 10ZM11.9767 6.00001L8 3.72764L4.02335 6.00001L6.01167 7.1362L7.52335 8.00001L8 8.27239L8.47665 8.00001L11.9767 6.00001Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="ZIndexDDPIcon" data-sentry-source-file="ZIndexDDPIcon.tsx" />;