/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const UnifiedSidesSubModuleDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M4.5 4.5V11.5H11.5V4.5H4.5ZM4 3C3.44772 3 3 3.44772 3 4V12C3 12.5523 3.44772 13 4 13H12C12.5523 13 13 12.5523 13 12V4C13 3.44772 12.5523 3 12 3H4Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="UnifiedSidesSubModuleDDPIcon" data-sentry-source-file="UnifiedSidesSubModuleDDPIcon.tsx" />;