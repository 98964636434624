/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const AbstractImageIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="24" height="24" attr={{
  viewBox: '0 0 24 24'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M2 0C0.895431 0 0 0.89543 0 2V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V2C24 0.895431 23.1046 0 22 0H2ZM20 7C20 8.65685 18.6569 10 17 10C15.3431 10 14 8.65685 14 7C14 5.34315 15.3431 4 17 4C18.6569 4 20 5.34315 20 7ZM8 10L4 15.2V20H20L8 10Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="AbstractImageIcon" data-sentry-source-file="AbstractImageIcon.tsx" />;