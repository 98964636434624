import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useCallback } from 'react';
// importing from '../hooks' breaks the ui-playground
import { usePersistLocalStorageDataEffect } from '../hooks/usePersistLocalStorageDataEffect';

type Settings = {
  themeConfig: {
    direction?: 'ltr' | 'rtl';
    theme: 'light' | 'dark';
    responsiveFontSizes?: boolean;
  };

  // in progress features
  featureFlags: {
    borderToken?: boolean;
    variantsGroup?: boolean;
    presence?: boolean;
  };

  tools: {
    fpsCounter?: boolean;
    layersPanelIds?: boolean;
    customMaxThreshold?: number;
  };
  devFeatures: {
    addToLibraryToast?: boolean;
  };
};

const defaultSettings: Settings = {
  themeConfig: {
    direction: 'ltr',
    theme: 'light',
    responsiveFontSizes: true,
  },

  // in progress features
  featureFlags: {
    borderToken: false,
    variantsGroup: false,
    presence: false,
  },

  tools: {
    fpsCounter: false,
    layersPanelIds: false,
    customMaxThreshold: 0,
  },
  devFeatures: {
    addToLibraryToast: true,
  },
};

const LOCAL_STORAGE_KEY = 'settings';

const settingsAtom = atomWithStorage<Settings>(
  LOCAL_STORAGE_KEY,
  defaultSettings
);

export const useSettingsState = () => {
  const [settings, setSettings] = useAtom(settingsAtom);

  const toggleTheme = useCallback(() => {
    setSettings((prev) => ({
      ...prev,
      themeConfig: {
        ...prev.themeConfig,
        theme: prev.themeConfig.theme === 'light' ? 'dark' : 'light',
      },
    }));
  }, [setSettings]);

  const disableAddToLibraryToast = useCallback(() => {
    setSettings((prev) => ({
      ...prev,
      addToLibraryToast: false,
    }));
  }, [setSettings]);

  usePersistLocalStorageDataEffect({
    key: LOCAL_STORAGE_KEY,
    defaultData: defaultSettings,
    setData: setSettings as any, // some issue with jotai types
  });

  return {
    settings,
    toggleTheme,
    disableAddToLibraryToast,
  };
};
