import { ComponentProps } from 'react';
import { styled } from '@jux/ui/components/common/mui';
import { SxProps, Theme } from '@jux/ui/components/common/mui';
type NativePopperDivWrapperProps = ComponentProps<'div'> & {
  id: string;
  openStyles?: SxProps<Theme>;
};
export const PopoverDiv = styled(
// @ts-expect-error `popover` prop does not exist on @types/react 18.2.33 - will be fixed when upgrading to 18.3.3
// After 18.3.3 there is a MUI type problem in our project
(props: NativePopperDivWrapperProps) => <div popover="auto" {...props} />, {
  shouldForwardProp: prop => prop !== 'openStyles'
})(({
  openStyles
}) => ({
  [`&:popover-open`]: {
    ...openStyles
  }
}));