import { FC } from 'react';
import { DesignTokenTypeEnum } from '@juxio/design-tokens';
import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';
import { DimensionField } from '../../../../base';
import { useDimensionField, useFieldValues, useSetFieldValue } from '../../../../hooks';
const FIELD_KEY = DDPPrimitiveFieldsKeys.fontSize;
export const FontSizeField: FC<{
  isFieldDisabled?: boolean;
}> = ({
  isFieldDisabled
}) => {
  const {
    value,
    initialValue
  } = useFieldValues(FIELD_KEY);
  const {
    saveValue: setFieldValue
  } = useSetFieldValue(FIELD_KEY);
  const props = useDimensionField({
    value,
    initialValue,
    fieldName: FIELD_KEY,
    saveChanges: setFieldValue,
    parentFieldTokenType: DesignTokenTypeEnum.typography,
    options: {
      disablePercentValue: true
    }
  });
  return <DimensionField icon="DDP_FIELD_FONT_SIZE" tooltipTitle="Font size" isFieldDisabled={isFieldDisabled} {...props} data-sentry-element="DimensionField" data-sentry-component="FontSizeField" data-sentry-source-file="FontSizeField.tsx" />;
};