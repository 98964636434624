import { FC } from 'react';
import { Tab, Tabs } from '@jux/ui/components';
export const AssetsHeader: FC<{
  isSystemAssets: boolean;
  setSystemAssets: () => void;
  isUserAssets: boolean;
  setUserAssets: () => void;
}> = ({
  setSystemAssets,
  setUserAssets,
  isSystemAssets,
  isUserAssets
}) => <Tabs data-sentry-element="Tabs" data-sentry-component="AssetsHeader" data-sentry-source-file="AssetsHeader.tsx">
    <Tab title="Element assets" onClick={setSystemAssets} selected={isSystemAssets} data-sentry-element="Tab" data-sentry-source-file="AssetsHeader.tsx" />
    <Tab title="Your assets" onClick={setUserAssets} selected={isUserAssets} data-sentry-element="Tab" data-sentry-source-file="AssetsHeader.tsx" />
  </Tabs>;