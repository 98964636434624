/**
 *  THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 */

/* eslint-disable */
// @ts-nocheck

import { createDrimz, type PropsOf } from '@jux/dui';
import React, { FC, forwardRef } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType } from '@jux/data-entities';
import { JuxReactElements } from '@jux/react';
const {
  styled
} = createDrimz();
import { LeftNavHeader, type LeftNavHeader_Props } from '../';
import { Token_management_item, type Token_management_item_Props } from '../';
import { LeftNavContent, type LeftNavContent_Props } from '../';
type LeftNav_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const LeftNav_root: FC<LeftNav_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
LeftNav_root.displayName = 'LeftNav_root';
LeftNav_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        width: '358px',
        height: '100vh',
        display: 'flex',
        padding: '0px 0px 0px 0px',
        alignItems: 'stretch',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        backgroundColor: '{color.core.grays_800}'
      },
      variants: [{
        propsValues: {},
        styles: {
          '[data-jux-context="89f85952-e5cc-401e-9ed1-99a0d6deed71"]': {
            width: 'auto',
            height: '1px',
            backgroundColor: '{color.core.grays_500}'
          }
        }
      }]
    }
  }) as LeftNav_root_Props['rootElement']
};
export type LeftNav_Props = LeftNav_root_Props & {
  LeftNavHeader_Props?: LeftNavHeader_Props;
  Token_management_item_Props?: Token_management_item_Props;
  LeftNavContent_Props?: LeftNavContent_Props;
};
export const LeftNav: FC<LeftNav_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, LeftNav_Props>(({
  LeftNavHeader_Props,
  Token_management_item_Props,
  LeftNavContent_Props,
  ...props
}, ref) => <LeftNav_root {...props} ref={ref} data-jux-context="70d4ae80-640d-4532-913d-c07e90889cc3">
      {props.children || <>
          <LeftNavHeader {...LeftNavHeader_Props} />
          <Token_management_item {...Token_management_item_Props} />
          <LeftNavContent {...LeftNavContent_Props} />
        </>}
    </LeftNav_root>);
LeftNav.displayName = 'LeftNav';
LeftNav.defaultProps = {};