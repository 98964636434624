import { FC, useCallback } from 'react';
import { useDimensionField } from '../../../../hooks';
import { DimensionField } from '../../../../base';
import { useShadowsFields } from './useShadowsFields';
export const ShadowBlurField: FC = () => {
  const {
    value,
    initialValue,
    saveChanges
  } = useShadowsFields();
  const {
    blurRadius
  } = value || {};
  const {
    blurRadius: initialBlurRadius
  } = initialValue || {};
  const setFieldValue = useCallback((newValue: string | undefined) => {
    saveChanges({
      blurRadius: newValue
    });
  }, [saveChanges]);
  const props = useDimensionField({
    value: blurRadius ?? '',
    initialValue: initialBlurRadius ?? '',
    saveChanges: setFieldValue,
    fieldName: 'boxShadowBlur'
  });
  return <DimensionField icon="DDP_FIELD_BOX_SHADOW_BLUR" tooltipTitle="Blur" {...props} data-sentry-element="DimensionField" data-sentry-component="ShadowBlurField" data-sentry-source-file="ShadowBlurField.tsx" />;
};