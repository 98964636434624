import { FC } from 'react';
import { Icons, Typography } from '@jux/ui/components';
import { AcceptUserInviteForm } from './AcceptUserInviteForm';
import * as S from './Invite.style';
export const AcceptInvite: FC<{
  orgName: string;
  inviteToken: string;
}> = ({
  orgName,
  inviteToken
}) => <S.InviteCard data-sentry-element="unknown" data-sentry-component="AcceptInvite" data-sentry-source-file="AcceptInvite.tsx">
    <Icons variant="JUX_LONG_LOGO" size={80} sx={{
    color: theme => theme.drimz.palette.text.primary,
    height: 20
  }} data-sentry-element="Icons" data-sentry-source-file="AcceptInvite.tsx" />
    <Typography variant="h1" sx={{
    py: '40px',
    color: theme => theme.drimz.palette.text.primary
  }} data-sentry-element="Typography" data-sentry-source-file="AcceptInvite.tsx">
      Welcome to JUX! You’ve been invited to join {orgName} team
    </Typography>

    <AcceptUserInviteForm inviteToken={inviteToken} data-sentry-element="AcceptUserInviteForm" data-sentry-source-file="AcceptInvite.tsx" />
  </S.InviteCard>;