import {
  CommonActionsParams,
  CREATE_NEW_CANVAS_NODE_DISTANCE,
  JuxStoreActionFn,
  setLayersData,
} from '@jux/canjux/core';
import { ComponentTagNames, NodeType } from '@jux/data-entities';
import { ELEMENTS_DATA } from '@jux/elements';
import type { Draft as WritableDraft } from 'mutative';
import { CanjuxState } from '../../store';
import {
  createComponentInstanceCanvasNode,
  getDefaultNodeData,
} from '../helpers';
import { createNodeInternal } from './createNode';
import { moveNodesInternal } from './moveNodes';
import { setSelectedNodes } from './setSelectedNodes';

const wrapSourceComponentWithDiv = ({
  sourceNodeId,
  state,
}: {
  sourceNodeId: string;
  state: WritableDraft<CanjuxState>;
}) => {
  const currentCanvas = state.canvases[state.currentCanvasName];
  const sourceNodeData = currentCanvas.nodes[sourceNodeId];
  const sourceNodeComponent = state.components[sourceNodeId];

  const sourceNodePosition = sourceNodeData.position ?? { x: 0, y: 0 };

  const createDivElement = ELEMENTS_DATA[ComponentTagNames.JuxDiv];
  const divElementData = createDivElement({
    styles: {
      root: {
        padding: 0,
        width: 'auto',
        height: 'auto',
        display: 'flex',
      },
      states: {},
    },
  });

  const divComponent = divElementData.root;

  const newDivNodeData = getDefaultNodeData({
    position: {
      x: sourceNodePosition.x,
      y: sourceNodePosition.y,
    },
    isContainer: true,
  });

  // Create a new div as a root node
  createNodeInternal({
    canvasName: currentCanvas.name,
    data: {
      node: newDivNodeData,
      component: divComponent,
    },
    nodeId: divComponent.id,
    state,
  });

  createComponentInstanceCanvasNode({
    canvasName: currentCanvas.name,
    componentId: sourceNodeId,
    parentId: divComponent.id,
    propsOverrides: sourceNodeComponent.config.props,
    state,
  });

  // move the source component 80px to the right relative to the target root node
  sourceNodeData.position = {
    x:
      sourceNodePosition.x +
      state.canvasNodesDimensions[sourceNodeId]?.width +
      CREATE_NEW_CANVAS_NODE_DISTANCE,
    y: sourceNodePosition.y,
  };

  // Select the new div
  state.selectedNodesStack = [divComponent.id];
};

export const wrapWithDiv: JuxStoreActionFn<
  CommonActionsParams['wrapWithDiv'],
  CanjuxState
> = ({ nodeIds, state }) => {
  const { canvases, currentCanvasName, components } = state;

  const currentCanvas = canvases[currentCanvasName];

  const nodesToSelect: string[] = [];

  for (const nodeId of nodeIds) {
    const sourceNodeComponent = components[nodeId];
    const parentId = sourceNodeComponent.parentId;

    const isSourceNodeAComponent =
      sourceNodeComponent.type === NodeType.LIBRARY_COMPONENT ||
      sourceNodeComponent.type === NodeType.LOCAL_COMPONENT;

    // Do not allow wrapping a component with a div
    if (isSourceNodeAComponent) {
      wrapSourceComponentWithDiv({ state, sourceNodeId: nodeId });
      return state;
    }

    // Save the old position of the child in the parent's children array - in order to restore the order later
    const oldChildPosition = parentId
      ? components[parentId].children.indexOf(nodeId)
      : currentCanvas.rootNodesOrder.indexOf(nodeId);

    const nodeData = currentCanvas.nodes[nodeId];
    const position = nodeData.position ?? { x: 0, y: 0 };
    const properties = nodeData.properties;

    // If the node is not draggable, we cannot wrap it with a div.
    // If it is draggable then the parent is not immutable and this operation is allowed.
    if (!properties.isDraggable) return state;

    const createDivElement = ELEMENTS_DATA[ComponentTagNames.JuxDiv];
    const divElementData = createDivElement({
      styles: {
        root: {
          padding: 0,
          width: 'auto',
          height: 'auto',
          display: 'flex',
        },
        states: {},
      },
      parentId,
    });

    const divComponent = divElementData.root;

    const newDivNodeData = getDefaultNodeData({
      parentId,
      position: {
        x: position.x,
        y: position.y,
      },
      isContainer: true,
    });

    // Create the new div as a child of the parent, right before the selected node
    createNodeInternal({
      canvasName: currentCanvas.name,
      data: {
        node: newDivNodeData,
        component: divComponent,
        targetIndex: oldChildPosition,
      },
      nodeId: divComponent.id,
      state,
    });

    // Move the selected node to be a child of the new div
    moveNodesInternal({
      sourceNodeIds: [nodeId],
      targetNodeId: divComponent.id,
      targetPosition: {
        xSnapped: position.x,
        ySnapped: position.y,
      },
      state,
    });

    // Select the new div
    nodesToSelect.push(divComponent.id);
  }

  setSelectedNodes({ nodeIds: nodesToSelect, state });

  setLayersData(state);

  return state;
};
