import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { ShadowXField } from './ShadowXField';
import { ShadowYField } from './ShadowYField';
import { ShadowSpreadField } from './ShadowSpreadField';
import { ShadowColorField } from './ShadowColorField';
import { ShadowBlurField } from './ShadowBlurField';
import { ShadowInsetField } from './ShadowInsetField';
export const Shadow: FC = () => {
  const {
    shouldRender
  } = useSubModule(DDPSubModulesKeys.boxShadow);
  if (!shouldRender) return null;
  return <SubModule title="Shadow" data-sentry-element="SubModule" data-sentry-component="Shadow" data-sentry-source-file="Shadow.tsx">
      <ShadowXField data-sentry-element="ShadowXField" data-sentry-source-file="Shadow.tsx" />
      <ShadowYField data-sentry-element="ShadowYField" data-sentry-source-file="Shadow.tsx" />
      <ShadowSpreadField data-sentry-element="ShadowSpreadField" data-sentry-source-file="Shadow.tsx" />
      <ShadowBlurField data-sentry-element="ShadowBlurField" data-sentry-source-file="Shadow.tsx" />
      <ShadowColorField data-sentry-element="ShadowColorField" data-sentry-source-file="Shadow.tsx" />
      <ShadowInsetField data-sentry-element="ShadowInsetField" data-sentry-source-file="Shadow.tsx" />
    </SubModule>;
};