import { UserProvider } from '@auth0/nextjs-auth0/client';
import { CacheProvider } from '@emotion/react';
import { Hydrate, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Head from 'next/head';
import Script from 'next/script';
import { FC, useState } from 'react';
import logger from '@jux/ui-logger';
import { AppLayoutProvider, ErrorBoundary } from '@jux/ui/components';
import { HydrateAtoms, JotaiProvider, jotaiStore } from '@jux/ui/jotai';
import { api } from '@jux/ui/trpc/client/api';
import createEmotionCache, { EnhancedAppProps } from '@jux/ui/utils/CreateEmotionCache';

// css imports
import 'react-toastify/dist/ReactToastify.css';
import '@fontsource/nunito-sans';
import '../canjux.style.css';
const clientSideEmotionCache = createEmotionCache();
const FrontendService: FC<EnhancedAppProps> = ({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps
}) => {
  const [queryClient] = useState(() => new QueryClient({
    queryCache: new QueryCache({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        logger.error(`Error: ${error.message}`);
        // https://react-query.tanstack.com/reference/setLogger
      }
    })
  }));

  // const GTM_KEY = process.env.NEXT_PUBLIC_GTM_KEY;
  const isStaging = typeof window !== 'undefined' ? window.location.hostname.includes('staging') : false;
  const theme = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('settings') as string)?.theme : null;
  const isDarkTheme = theme === 'dark';
  return <>
      <ErrorBoundary data-sentry-element="ErrorBoundary" data-sentry-source-file="_app.tsx">
        <CacheProvider value={emotionCache} data-sentry-element="CacheProvider" data-sentry-source-file="_app.tsx">
          <Script src="https://kit.fontawesome.com/c0c753c6b3.js" crossOrigin="anonymous" data-sentry-element="Script" data-sentry-source-file="_app.tsx" />
          <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
            <title>JUX</title>
            <meta name="viewport" content="initial-scale=1, width=device-width" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
            <link rel="icon" href={isStaging ? '/favicon-staging.ico' : isDarkTheme ? '/favicon-dark-theme.ico' : '/favicon.ico'} />
          </Head>
          <UserProvider data-sentry-element="UserProvider" data-sentry-source-file="_app.tsx">
            <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="_app.tsx">
              <JotaiProvider store={jotaiStore} data-sentry-element="JotaiProvider" data-sentry-source-file="_app.tsx">
                <Hydrate state={pageProps.dehydratedState} data-sentry-element="Hydrate" data-sentry-source-file="_app.tsx">
                  <HydrateAtoms queryClient={queryClient} data-sentry-element="HydrateAtoms" data-sentry-source-file="_app.tsx">
                    <AppLayoutProvider data-sentry-element="AppLayoutProvider" data-sentry-source-file="_app.tsx">
                      <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
                    </AppLayoutProvider>
                  </HydrateAtoms>
                </Hydrate>
              </JotaiProvider>
            </QueryClientProvider>
          </UserProvider>
        </CacheProvider>
      </ErrorBoundary>
    </>;
};
export default api.withTRPC(FrontendService);