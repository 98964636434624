import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { AlignItemsField } from './AlignItemsField';
export const AlignItems: FC = () => {
  const {
    shouldRender
  } = useSubModule(DDPSubModulesKeys.alignItems);
  if (!shouldRender) return null;
  return <SubModule title="Align items" data-sentry-element="SubModule" data-sentry-component="AlignItems" data-sentry-source-file="AlignItems.tsx">
      <AlignItemsField data-sentry-element="AlignItemsField" data-sentry-source-file="AlignItems.tsx" />
    </SubModule>;
};