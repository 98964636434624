import {
  JuxStore,
  replacePathInStyles,
  updateComponentsWithNewStyles,
} from '@jux/canjux/core';
import {
  isValidTokenPath,
  prepareTokenPathForStringifyActions,
} from '@jux/ui/utils/tokensPatterns';
import { Draft as WritableDraft } from 'mutative';

export const replaceTokenPathInComponentsStyles = ({
  components,
  newPath,
  oldPath,
}: {
  components: WritableDraft<JuxStore['components']>;
  newPath: string;
  oldPath: string;
}) => {
  const stylesToChangeByComponentId = Object.entries(components)
    .map(([componentId, { styles }]) => {
      if (!styles) return undefined;

      const formattedOldPath = prepareTokenPathForStringifyActions(oldPath);
      const formattedNewPath = prepareTokenPathForStringifyActions(newPath);

      if (
        isValidTokenPath(formattedOldPath) &&
        isValidTokenPath(formattedNewPath)
      ) {
        const updatedStyles = replacePathInStyles({
          oldPath: formattedOldPath,
          newPath: formattedNewPath,
          styles,
        });

        if (updatedStyles) {
          return { [componentId]: updatedStyles };
        }
      }

      return undefined;
    })
    .filter(Boolean);

  updateComponentsWithNewStyles({
    components,
    stylesToChangeByComponentId,
  });
};
