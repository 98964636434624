import { createSelector } from 'reselect';
import {
  CanjuxState,
  findPathInStyles,
  getRootNodeOfNode,
} from '@jux/canjux/core';
import {
  isValidTokenPath,
  prepareTokenPathForStringifyActions,
} from '@jux/ui/utils/tokensPatterns';
import { getResolvedSourceComponentData } from './utils/getResolvedSourceComponentData';
import { NodeType } from '@jux/data-entities';

export type TokenUsages = {
  libraryComponents: Array<string>;
  localComponents: Array<string>;
  otherObjects: Array<string>;
};

export const getComponentsThatUseTokenPathSelector = createSelector(
  [(state: CanjuxState) => state.components],
  (components) => (tokenPath: string) => {
    const usageCount: TokenUsages = {
      libraryComponents: [],
      localComponents: [],
      otherObjects: [],
    };

    for (const component of Object.values(components)) {
      const sourceComponent = getResolvedSourceComponentData({
        component,
        components,
      });
      if (!sourceComponent?.styles) continue;

      const formattedTokenPath = prepareTokenPathForStringifyActions(tokenPath);

      if (isValidTokenPath(formattedTokenPath)) {
        if (
          findPathInStyles({
            path: formattedTokenPath,
            styles: sourceComponent.styles,
          })
        ) {
          const rootNode = getRootNodeOfNode({
            nodeId: component.id,
            components,
          });

          switch (rootNode.type) {
            case NodeType.LIBRARY_COMPONENT:
              // add the found root library component to the list
              if (!usageCount.libraryComponents.includes(rootNode.id)) {
                usageCount.libraryComponents.push(rootNode.id);
              }
              break;
            case NodeType.LOCAL_COMPONENT:
              // add the found root local component to the list
              if (!usageCount.localComponents.includes(rootNode.id)) {
                usageCount.localComponents.push(rootNode.id);
              }
              break;
            case NodeType.INSTANCE:
              // add the found root of instance component to the list
              if (!usageCount.otherObjects.includes(rootNode.id)) {
                usageCount.otherObjects.push(rootNode.id);
              }
              break;
            default:
              // Add the current component node to other objects
              // (no need to check for duplicates because we run this for each component)
              if (!usageCount.otherObjects.includes(component.id)) {
                usageCount.otherObjects.push(component.id);
              }
              break;
          }
        }
      }
    }

    return usageCount;
  }
);
