import { ComponentConfigWithStates } from '@jux/types';

export const replacePathInStyles = ({
  newPath,
  oldPath,
  styles,
}: {
  oldPath: string;
  newPath: string;
  styles: ComponentConfigWithStates;
}): ComponentConfigWithStates | undefined => {
  const stringifiedStyles = JSON.stringify(styles);

  if (!stringifiedStyles.includes(oldPath)) {
    return undefined;
  }

  const replacedStringifiedStyles = stringifiedStyles.replaceAll(
    oldPath,
    newPath
  );

  return JSON.parse(replacedStringifiedStyles) as ComponentConfigWithStates;
};
