/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const InputElementIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M5 7C5 6.72386 4.77614 6.5 4.5 6.5C4.22386 6.5 4 6.72386 4 7V9C4 9.27614 4.22386 9.5 4.5 9.5C4.77614 9.5 5 9.27614 5 9V7Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    fill: 'currentColor',
    d: 'M1 5C1 4.44772 1.44772 4 2 4H14C14.5523 4 15 4.44772 15 5V11C15 11.5523 14.5523 12 14 12H2C1.44772 12 1 11.5523 1 11V5ZM2.5 10.5V5.5H13.5V10.5H2.5Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="InputElementIcon" data-sentry-source-file="InputElementIcon.tsx" />;