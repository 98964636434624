import { FC, PropsWithChildren } from 'react';
import { MuiCard, MuiCardActions, MuiCardContent, MuiCardHeader } from '@jux/ui/components/common/mui';
import { Typography } from '@jux/ui/components';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';
import { DialogPopoverCardProps } from './DialogPopoverCard.interface';
export const DialogPopoverCard: FC<PropsWithChildren<DialogPopoverCardProps>> = ({
  children,
  title,
  actions,
  formProps,
  cardActionsProps,
  cardContentProps,
  cardHeaderProps,
  cardProps
}) => <MuiCard {...cardProps} data-sentry-element="MuiCard" data-sentry-component="DialogPopoverCard" data-sentry-source-file="DialogPopoverCard.tsx">
    <MuiCardHeader title={<Typography variant="dialogPopoverCardTypography">{title}</Typography>}
  // need to add this to avoid the default card padding
  sx={mergeTyped(cardHeaderProps?.sx || {}, {
    pb: 0
  })} {...cardHeaderProps} data-sentry-element="MuiCardHeader" data-sentry-source-file="DialogPopoverCard.tsx" />
    {formProps ? <form {...formProps}>
        <MuiCardContent {...cardContentProps}>{children}</MuiCardContent>
        {actions && <MuiCardActions {...cardActionsProps}>{actions}</MuiCardActions>}
      </form> : <>
        <MuiCardContent {...cardContentProps}>{children}</MuiCardContent>
        {actions && <MuiCardActions {...cardActionsProps}>{actions}</MuiCardActions>}
      </>}
  </MuiCard>;