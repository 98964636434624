import { FC } from 'react';
import { CardContentValue, CardContentValueWrapper, TypographyTokenProps } from './base';
import { formatTypographyTokenValueToString } from './helpers';
export const TypographyToken: FC<TypographyTokenProps> = ({
  aliasProps: {
    aliasName,
    isAlias
  },
  value
}) => <CardContentValueWrapper data-sentry-element="CardContentValueWrapper" data-sentry-component="TypographyToken" data-sentry-source-file="TypographyToken.tsx">
    <CardContentValue value={formatTypographyTokenValueToString(value)} aliasName={aliasName} isAlias={isAlias} data-sentry-element="CardContentValue" data-sentry-source-file="TypographyToken.tsx" />
  </CardContentValueWrapper>;