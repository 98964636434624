/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '../core/CustomIcon';

// AKA CollapseIcon
export const ContractIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M11.6997 3.35007C11.3683 2.90824 10.7415 2.8187 10.2997 3.15007L7.99973 5.00001L5.69967 3.15007C5.25784 2.8187 4.63104 2.90825 4.29967 3.35007C3.9683 3.7919 4.05784 4.4187 4.49967 4.75007L7.99973 7.50001L11.4997 4.75007C11.9415 4.4187 12.031 3.7919 11.6997 3.35007Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M11.7002 12.65C11.3688 13.0918 10.742 13.1814 10.3002 12.85L8.00022 11.0001L5.70016 12.85C5.25833 13.1814 4.63153 13.0918 4.30016 12.65C3.96879 12.2082 4.05833 11.5814 4.50016 11.25L8.00022 8.50006L11.5002 11.25C11.942 11.5814 12.0315 12.2082 11.7002 12.65Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="ContractIcon" data-sentry-source-file="ContractIcon.tsx" />;