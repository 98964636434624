import { FC, useCallback, useMemo } from 'react';
import { DesignTokenTypeEnum } from '@juxio/design-tokens';
import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';
import { FontFamilyFallbackMap, useFontFamilyOptions } from '@jux/ui/components/tokens/token-drawer/forms/helpers';
import { SelectField } from '../../../../base';
import { useFieldValues, useSelectField, useSetFieldValue } from '../../../../hooks';
const FIELD_KEY = DDPPrimitiveFieldsKeys.fontFamily;
const parseLabel = (label: string) => label?.replaceAll('"', '');
const parseFontFamilyToSingleFont = (value: string | string[], withBracketsOnSpaces?: boolean): string => {
  if (typeof value === 'string') {
    const firstValue = value?.split(', ')[0];
    if (withBracketsOnSpaces && firstValue.trim().includes(' ')) {
      return `"${firstValue}"`;
    }
    return firstValue;
  } else if (Array.isArray(value)) {
    return value[0]?.split(', ')[0];
  }
  return value;
};
const parseFontFamilyToFontWithFallbackArray = ({
  value,
  fallbacksMap,
  withBracketsOnSpaces
}: {
  value: string;
  fallbacksMap: FontFamilyFallbackMap;
  withBracketsOnSpaces?: boolean;
}): Array<string> => {
  const parsedFontFamily = parseFontFamilyToSingleFont(value, withBracketsOnSpaces);
  const parsedFontFamilyNoBrackets = parsedFontFamily?.replaceAll('"', '');
  return [parsedFontFamily, ...(fallbacksMap[parsedFontFamilyNoBrackets] || [])];
};
const parseFontFamilyWithFallbackArrayToString = (value: Array<string>): string => value.join(', ');
export const FontFamilyField: FC<{
  isFieldDisabled?: boolean;
}> = ({
  isFieldDisabled
}) => {
  const {
    value,
    initialValue
  } = useFieldValues(FIELD_KEY);
  const {
    saveValue: setFieldValue
  } = useSetFieldValue(FIELD_KEY);
  const parsedFontFamily = useMemo(() => parseFontFamilyToSingleFont(value)?.replaceAll('"', ''), [value]);
  const {
    options: fontFamilies,
    fallbacks
  } = useFontFamilyOptions();
  const fontFamilyOptions = useMemo(() => fontFamilies.map(fontFamily => {
    const fontWithFallback = parseFontFamilyToFontWithFallbackArray({
      value: fontFamily,
      fallbacksMap: fallbacks
    });
    return {
      value: fontWithFallback,
      label: parseLabel(fontFamily)
    };
  }), [fallbacks, fontFamilies]);
  const fontFamilySaveChanges = useCallback((newValue: string | undefined) => {
    if (!newValue) return;

    // check if value is token first
    if (newValue.includes('{')) {
      return setFieldValue(newValue);
    }
    const fontWithFallbacksArray = parseFontFamilyToFontWithFallbackArray({
      value: newValue,
      fallbacksMap: fallbacks,
      withBracketsOnSpaces: true
    });
    const fontWithFallbackString = parseFontFamilyWithFallbackArrayToString(fontWithFallbacksArray);
    return setFieldValue(fontWithFallbackString);
  }, [fallbacks, setFieldValue]);
  const props = useSelectField({
    fieldName: FIELD_KEY,
    value: parsedFontFamily,
    initialValue,
    tokenType: DesignTokenTypeEnum.fontFamily,
    saveChanges: fontFamilySaveChanges,
    initSelectionOptions: fontFamilyOptions,
    parentFieldTokenType: DesignTokenTypeEnum.typography
  });
  return <SelectField icon="DDP_FIELD_FONT_FAMILY" tooltipTitle="Font family" isFieldDisabled={isFieldDisabled} {...props} data-sentry-element="SelectField" data-sentry-component="FontFamilyField" data-sentry-source-file="FontFamilyField.tsx" />;
};