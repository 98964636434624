import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { MarginTopField } from './MarginTopField';
import { MarginRightField } from './MarginRightField';
import { MarginBottomField } from './MarginBottomField';
import { MarginLeftField } from './MarginLeftField';
import { MarginField } from './MarginField';
export const Margin: FC = () => {
  const {
    shouldRender,
    isOpen,
    setIsOpen
  } = useSubModule(DDPPrimitiveFieldsKeys.margin);
  if (!shouldRender) return null;
  return <SubModule title="Margin" isOpen={isOpen} setIsOpen={setIsOpen} data-sentry-element="SubModule" data-sentry-component="Margin" data-sentry-source-file="Margin.tsx">
      {isOpen ? <MuiGrid container item flex={1} flexDirection="column" gap="6px">
          <MarginTopField />
          <MarginRightField />
          <MarginBottomField />
          <MarginLeftField />
        </MuiGrid> : <MarginField />}
    </SubModule>;
};