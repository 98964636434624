import { ComponentProps, FC, useMemo } from 'react';
import colorString from 'color-string';
import { MuiBox } from '@jux/ui/components/common/mui';
import { parseNumber } from '@jux/ui/components/editor/components/panels/DDP/hooks/useNumericField/useNumericField.utils';
import { colorIndicatorTransparencyPattern } from '@jux/ui/utils/colorIndicatorTransparencyPattern';
import { CardContentValue, CardContentValueWrapper, ColorTokenProps } from './base';
const parseRgbaToHexAndAlphaString = (rgba: string) => {
  if (typeof rgba !== 'string' || rgba === '') return {
    hex: '',
    parsedColorValueString: rgba
  };
  const parsedRgba = colorString.get.rgb(rgba);
  if (!parsedRgba) return {
    hex: '',
    parsedColorValueString: rgba
  };
  const [r, g, b, alpha] = parsedRgba;
  const hex = colorString.to.hex([r, g, b]);
  return {
    hex,
    parsedColorValueString: <>
        {hex}
        {alpha && <span style={{
        marginLeft: '12px'
      }}>
            {parseNumber(alpha * 100)}%
          </span>}
      </>
  };
};
const COLOR_PREVIEW_SIZE = 16;
export const ColorToken: FC<ColorTokenProps> = ({
  value: color,
  aliasProps: {
    isAlias,
    aliasName
  }
}) => {
  const {
    hex,
    parsedColorValueString
  } = useMemo(() => parseRgbaToHexAndAlphaString(color), [color]);
  return <CardContentValueWrapper data-sentry-element="CardContentValueWrapper" data-sentry-component="ColorToken" data-sentry-source-file="ColorToken.tsx">
      <MuiBox sx={{
      backgroundColor: hex,
      width: COLOR_PREVIEW_SIZE,
      minWidth: COLOR_PREVIEW_SIZE,
      height: COLOR_PREVIEW_SIZE,
      borderRadius: '50%',
      mr: 1.5,
      '&': theme => colorIndicatorTransparencyPattern({
        borderRadius: '50%',
        color,
        theme
      })
    }} data-sentry-element="MuiBox" data-sentry-source-file="ColorToken.tsx" />
      <CardContentValue value={parsedColorValueString as ComponentProps<typeof CardContentValue>['value']} aliasName={aliasName} isAlias={isAlias} data-sentry-element="CardContentValue" data-sentry-source-file="ColorToken.tsx" />
    </CardContentValueWrapper>;
};