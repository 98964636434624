/**
 *  THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 */

/* eslint-disable */
// @ts-nocheck

import { createDrimz, type PropsOf } from '@jux/dui';
import React, { FC, forwardRef } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType } from '@jux/data-entities';
import { JuxReactElements } from '@jux/react';
const {
  styled
} = createDrimz();
type Input_root_Props = PropsOf<typeof JuxReactElements.JuxInput> & {
  disabled: true | false;
};
const Input_root: FC<Input_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxInput {...props} ref={ref} />);
Input_root.displayName = 'Input_root';
Input_root.defaultProps = {
  rootElement: styled('input', {
    styles: {
      root: {
        color: '#3B3B3B',
        width: '320px',
        height: '36px',
        display: 'inline-block',
        padding: '9px 12px',
        fontSize: '14px',
        fontFamily: 'Inter',
        fontWeight: 500,
        borderColor: '#9C9C9C',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '4px',
        '&::placeholder': {
          color: '#9C9C9C'
        },
        '&:hover': {
          borderColor: '#3B3B3B',
          borderStyle: 'solid',
          borderWidth: '1px'
        },
        '&:focus': {
          borderColor: '#3851DD',
          borderStyle: 'solid',
          borderWidth: '1px'
        }
      },
      variants: [{
        propsValues: {
          disabled: true
        },
        styles: {
          color: '#9C9C9C',
          borderColor: '#E6E6E6',
          borderStyle: 'solid',
          borderWidth: '1px'
        }
      }, {
        propsValues: {
          disabled: false
        },
        styles: {
          color: '{color.core.grays_800}',
          width: 'auto',
          borderColor: '{color.core.grays_300}',
          borderRadius: '2px',
          '&:hover': {
            borderColor: '{color.core.grays_400}'
          },
          '&:active': {
            borderColor: '{color.core.grays_500}'
          }
        }
      }]
    }
  }) as Input_root_Props['rootElement']
};
export type Input_Props = Input_root_Props;
export const Input: FC<Input_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxInput>, Input_Props>((props, ref) => <Input_root {...props} ref={ref} />);
Input.displayName = 'Input';
Input.defaultProps = {
  disabled: false
};