import { FC } from 'react';
import { ModalWrapper, Typography } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { SubmitModalContentProps } from './SubmitModal.interface';
export const SubmitModalContent: FC<SubmitModalContentProps> = ({
  title,
  onClose,
  children
}) => <ModalWrapper onClose={onClose} data-sentry-element="ModalWrapper" data-sentry-component="SubmitModalContent" data-sentry-source-file="SubmitModalContent.tsx">
    <MuiGrid item container data-sentry-element="MuiGrid" data-sentry-source-file="SubmitModalContent.tsx">
      <Typography variant="h2" color={theme => theme.drimz.palette.text.primary} data-sentry-element="Typography" data-sentry-source-file="SubmitModalContent.tsx">
        {title}
      </Typography>
    </MuiGrid>
    {children && <MuiGrid item container marginTop="24px">
        {children}
      </MuiGrid>}
  </ModalWrapper>;