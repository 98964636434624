import { useCallback } from 'react';
import { useSetupDDPModuleState } from '@jux/ui/components/editor/hooks';
import { NodeInteractiveState } from '@jux/data-entities';

export const useSetupDDPValues = () => {
  const { setupDDPValuesStateBySelectedNodeId } = useSetupDDPModuleState();

  const setupDDPValues = useCallback(
    (nodeState?: NodeInteractiveState) => {
      setupDDPValuesStateBySelectedNodeId({
        nodeState,
      });
    },
    [setupDDPValuesStateBySelectedNodeId]
  );

  return { setupDDPValues };
};
