import { useCallback } from 'react';
import {
  selectedNodeComponentVariantsPropsValues,
  selectedNodeInteractiveState,
  useStore,
} from '@jux/canjux/core';
import {
  useSaveSelectedNodeDDPChanges,
  useSelectedNodeId,
} from '@jux/ui/components/editor/hooks';
import {
  DDPFieldsStateKeys,
  useDDPFieldsState,
} from '@jux/ui/components/editor/state';
import { DEFAULT_STATE } from '@jux/types';
import { useTrackEvents } from '@jux/ui/hooks';
import { NodeInteractiveState } from '@jux/data-entities';

export const useSetMultiFieldsValues = () => {
  const selectedNodeId = useSelectedNodeId();
  const selectedNodeState = useStore(selectedNodeInteractiveState);

  // TODO: reimplement
  const getNodeInteractiveStateById = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (nodeId: string): NodeInteractiveState => DEFAULT_STATE,
    []
  );

  const { setFieldsStateByKey } = useDDPFieldsState();
  const { saveStyleChanges } = useSaveSelectedNodeDDPChanges();
  const selectedNodeVariantsValues = useStore(
    selectedNodeComponentVariantsPropsValues
  );
  const { trackFieldValueChangeEvent } = useTrackEvents();

  const setMultiFieldsValues = useCallback(
    (newValues: { [key in DDPFieldsStateKeys]?: any }) => {
      if (!selectedNodeId || !selectedNodeState) return;

      Object.keys(newValues).forEach((key) => {
        setFieldsStateByKey(key as DDPFieldsStateKeys, {
          value: newValues[key as DDPFieldsStateKeys],
        });
        trackFieldValueChangeEvent(key, newValues[key as DDPFieldsStateKeys]);
      });

      saveStyleChanges({
        nodeId: selectedNodeId,
        nodeInteractiveState: selectedNodeState,
        variantsEditingContext: selectedNodeVariantsValues || {},
        newStyle: newValues,
      });
    },
    [
      saveStyleChanges,
      selectedNodeId,
      selectedNodeState,
      selectedNodeVariantsValues,
      setFieldsStateByKey,
      trackFieldValueChangeEvent,
    ]
  );

  const setMultiFieldsValuesForNodeId = useCallback(
    (id: string, newValues: { [key in DDPFieldsStateKeys]?: any }) => {
      Object.keys(newValues).forEach((key) => {
        setFieldsStateByKey(key as DDPFieldsStateKeys, {
          value: newValues[key as DDPFieldsStateKeys],
        });
      });

      // TODO: use the editing context of the node id that was specified !
      saveStyleChanges({
        nodeId: id,
        nodeInteractiveState: getNodeInteractiveStateById(id),
        variantsEditingContext: selectedNodeVariantsValues || {}, // TODO: get the variants values of the node id
        newStyle: newValues,
      });
    },
    [
      saveStyleChanges,
      getNodeInteractiveStateById,
      selectedNodeVariantsValues,
      setFieldsStateByKey,
    ]
  );

  return {
    setMultiFieldsValues,
    setMultiFieldsValuesForNodeId,
  };
};
