import {
  CanjuxState,
  ComponentActionsParams,
  JuxStoreActionFn,
  setLayersData,
} from '@jux/canjux/core';
import { NodeType } from '@jux/data-entities';

/**
 * Convert Variants Group node into Local / Library component node
 */
export const convertVariantsGroupToSourceComponent: JuxStoreActionFn<
  ComponentActionsParams['convertVariantsGroupToSourceComponent'],
  CanjuxState
> = ({ nodeId, state }) => {
  const component = state.components[nodeId];
  if (component.type !== NodeType.VARIANTS_GROUP) {
    return state;
  }

  // Todo: implement the conversion logic here

  setLayersData(state);

  return state;
};
