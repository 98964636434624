/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const MinWidthDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M5.5 5.25C5.91421 5.25 6.25 5.58579 6.25 6V10C6.25 10.4142 5.91421 10.75 5.5 10.75C5.08579 10.75 4.75 10.4142 4.75 10V8.75H2C1.58579 8.75 1.25 8.41421 1.25 8C1.25 7.58579 1.58579 7.25 2 7.25H4.75V6C4.75 5.58579 5.08579 5.25 5.5 5.25ZM8 5.25C8.41421 5.25 8.75 5.58579 8.75 6V10C8.75 10.4142 8.41421 10.75 8 10.75C7.58579 10.75 7.25 10.4142 7.25 10V6C7.25 5.58579 7.58579 5.25 8 5.25ZM10.5 5.25C10.9142 5.25 11.25 5.58579 11.25 6V7.25H14C14.4142 7.25 14.75 7.58579 14.75 8C14.75 8.41421 14.4142 8.75 14 8.75H11.25V10C11.25 10.4142 10.9142 10.75 10.5 10.75C10.0858 10.75 9.75 10.4142 9.75 10V6C9.75 5.58579 10.0858 5.25 10.5 5.25Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="MinWidthDDPIcon" data-sentry-source-file="MinWidthDDPIcon.tsx" />;