/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const LineHeightDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M13 2.5C13 2.77614 12.7761 3 12.5 3H3.5C3.22386 3 3 2.77614 3 2.5C3 2.22386 3.22386 2 3.5 2H12.5C12.7761 2 13 2.22386 13 2.5Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M5.88331 12H4L6.86961 4H9.13445L12 12H10.1167L9.50126 10.1758H6.49994L5.88331 12ZM7.96956 5.82812H8.0345L9.05583 8.85547H6.94624L7.96956 5.82812Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M12.5 14C12.7761 14 13 13.7761 13 13.5C13 13.2239 12.7761 13 12.5 13H3.5C3.22386 13 3 13.2239 3 13.5C3 13.7761 3.22386 14 3.5 14H12.5Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="LineHeightDDPIcon" data-sentry-source-file="LineHeightDDPIcon.tsx" />;