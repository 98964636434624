/**
 *  THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 */

/* eslint-disable */
// @ts-nocheck

import { createDrimz, type PropsOf } from '@jux/dui';
import React, { FC, forwardRef } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType } from '@jux/data-entities';
import { JuxReactElements } from '@jux/react';
const {
  styled
} = createDrimz();
import { Token_button, type Token_button_Props } from '../';
type Token_management_item_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const Token_management_item_root: FC<Token_management_item_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
Token_management_item_root.displayName = 'Token_management_item_root';
Token_management_item_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        width: 'auto',
        height: 'auto',
        backgroundColor: '{color.core.grays_800}',
        display: 'flex',
        padding: '8px 8px 8px 8px',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderStyle: 'none',
        borderColor: ''
      }
    }
  }) as Token_management_item_root_Props['rootElement']
};
export type Token_management_item_Props = Token_management_item_root_Props & {
  Token_button_Props?: Token_button_Props;
};
export const Token_management_item: FC<Token_management_item_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, Token_management_item_Props>(({
  Token_button_Props,
  ...props
}, ref) => <Token_management_item_root {...props} ref={ref} data-jux-context="868cac9e-ab18-45cd-ba41-cd0772891cf9">
      {props.children || <>
          <Token_button disabled={false} hierarchy={'primary'} {...Token_button_Props} />
        </>}
    </Token_management_item_root>);
Token_management_item.displayName = 'Token_management_item';
Token_management_item.defaultProps = {};