/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { FC } from 'react';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
import { IconBaseProps } from '../../Icons.interface';
export const ToggleElementPanelIcon: FC<IconBaseProps> = ({
  textColor,
  ...props
}) => <CustomIcon tag="svg" width="42" height="25" style={{
  width: '42px',
  height: '25px'
}} attr={{
  viewBox: '0 0 42 25',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg'
}} child={[{
  tag: 'g',
  attr: {
    clipPath: 'url(#clip0_1533_31927)'
  },
  child: [{
    tag: 'rect',
    attr: {
      y: '0.5',
      width: '42',
      height: '24',
      rx: '12',
      fill: 'currentColor'
    },
    child: []
  }, {
    tag: 'g',
    attr: {
      filter: 'url(#filter0_d_1533_31927)'
    },
    child: [{
      tag: 'rect',
      attr: {
        x: '20',
        y: '2.5',
        width: '20',
        height: '20',
        rx: '10',
        fill: textColor || 'currentColor'
      },
      child: []
    }, {
      tag: 'rect',
      attr: {
        x: '20.5',
        y: '3',
        width: '19',
        height: '19',
        rx: '9.5',
        stroke: 'currentColor'
      },
      child: []
    }]
  }]
}, {
  tag: 'defs',
  attr: {},
  child: [{
    tag: 'filter',
    attr: {
      id: 'filter0_d_1533_31927',
      x: '18',
      y: '1.5',
      width: '24',
      height: '24',
      filterUnits: 'userSpaceOnUse',
      colorInterpolationFilters: 'sRGB'
    },
    child: [{
      tag: 'feFlood',
      attr: {
        floodOpacity: '0',
        result: 'BackgroundImageFix'
      },
      child: []
    }, {
      tag: 'feColorMatrix',
      attr: {
        in: 'SourceAlpha',
        type: 'matrix',
        values: '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0',
        result: 'hardAlpha'
      },
      child: []
    }, {
      tag: 'feOffset',
      attr: {
        dy: '1'
      },
      child: []
    }, {
      tag: 'feGaussianBlur',
      attr: {
        stdDeviation: '1'
      },
      child: []
    }, {
      tag: 'feComposite',
      attr: {
        in2: 'hardAlpha',
        operator: 'out'
      },
      child: []
    }, {
      tag: 'feColorMatrix',
      attr: {
        type: 'matrix',
        values: '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'
      },
      child: []
    }, {
      tag: 'feBlend',
      attr: {
        mode: 'normal',
        in2: 'BackgroundImageFix',
        result: 'effect1_dropShadow_1533_31927'
      },
      child: []
    }, {
      tag: 'feBlend',
      attr: {
        mode: 'normal',
        in: 'SourceGraphic',
        in2: 'effect1_dropShadow_1533_31927',
        result: 'shape'
      },
      child: []
    }]
  }, {
    tag: 'clipPath',
    attr: {
      id: 'clip0_1533_31927'
    },
    child: [{
      tag: 'rect',
      attr: {
        y: '0.5',
        width: '42',
        height: '24',
        rx: '12',
        fill: textColor || 'currentColor'
      },
      child: []
    }]
  }]
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="ToggleElementPanelIcon" data-sentry-source-file="ToggleElementPanelIcon.tsx" />;