/**
 *  THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 */

/* eslint-disable */
// @ts-nocheck

import { createDrimz, type PropsOf } from '@jux/dui';
import React, { FC, forwardRef } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType } from '@jux/data-entities';
import { JuxReactElements } from '@jux/react';
const {
  styled
} = createDrimz();
type Navigation_div_Logoicon_root_Props = PropsOf<typeof JuxReactElements.JuxSvg>;
const Navigation_div_Logoicon_root: FC<Navigation_div_Logoicon_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxSvg {...props} ref={ref} />);
Navigation_div_Logoicon_root.displayName = 'Navigation_div_Logoicon_root';
Navigation_div_Logoicon_root.defaultProps = {
  rootElement: styled('svg', {
    styles: {
      root: {}
    }
  }) as Navigation_div_Logoicon_root_Props['rootElement']
};
export type Navigation_div_Logoicon_Props = Navigation_div_Logoicon_root_Props;
export const Navigation_div_Logoicon: FC<Navigation_div_Logoicon_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxSvg>, Navigation_div_Logoicon_Props>((props, ref) => <Navigation_div_Logoicon_root {...props} ref={ref} />);
Navigation_div_Logoicon.displayName = 'Navigation_div_Logoicon';
Navigation_div_Logoicon.defaultProps = {
  content: '<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">\n<g clip-path="url(#clip0_1702_77511)">\n<rect width="28" height="28" rx="2" fill="#1A1A1A"></rect>\n<path d="M14.1265 14L10 20H14.7522L18.8 14L14.7522 8H10L14.1265 14Z" fill="white"></path>\n</g>\n<defs>\n<clipPath id="clip0_1702_77511">\n<rect width="28" height="28" rx="2" fill="white"></rect>\n</clipPath>\n</defs>\n</svg>\n'
};
import { Nav_left_actions, type Nav_left_actions_Props } from '../';
import { IconButton, type IconButton_Props } from '../';
type Navigation_div_div_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const Navigation_div_div_root: FC<Navigation_div_div_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
Navigation_div_div_root.displayName = 'Navigation_div_div_root';
Navigation_div_div_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        padding: '80px',
        alignItems: 'center',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF'
      }
    }
  }) as Navigation_div_div_root_Props['rootElement']
};
export type Navigation_div_div_Props = Navigation_div_div_root_Props & {
  IconButton_1_Props?: IconButton_Props;
  IconButton_2_Props?: IconButton_Props;
};
export const Navigation_div_div: FC<Navigation_div_div_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, Navigation_div_div_Props>(({
  IconButton_1_Props,
  IconButton_2_Props,
  ...props
}, ref) => <Navigation_div_div_root {...props} ref={ref} data-jux-context="306366b6-3b8e-4299-acf1-b7ac68c07a0f">
    {props.children || <>
        <IconButton disabled={false} hierarchy={'primary'} {...IconButton_1_Props} />
        <IconButton disabled={false} hierarchy={'primary'} {...IconButton_2_Props} />
      </>}
  </Navigation_div_div_root>);
Navigation_div_div.displayName = 'Navigation_div_div';
Navigation_div_div.defaultProps = {};
type Navigation_div_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const Navigation_div_root: FC<Navigation_div_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
Navigation_div_root.displayName = 'Navigation_div_root';
Navigation_div_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        padding: '80px',
        alignItems: 'center',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF'
      }
    }
  }) as Navigation_div_root_Props['rootElement']
};
export type Navigation_div_Props = Navigation_div_root_Props & {
  Navigation_div_Logoicon_Props?: Navigation_div_Logoicon_Props;
  Nav_left_actions_Props?: Nav_left_actions_Props;
  Navigation_div_div_Props?: Navigation_div_div_Props;
};
export const Navigation_div: FC<Navigation_div_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, Navigation_div_Props>(({
  Navigation_div_Logoicon_Props,
  Nav_left_actions_Props,
  Navigation_div_div_Props,
  ...props
}, ref) => <Navigation_div_root {...props} ref={ref} data-jux-context="97c4e3b6-f25c-45e0-9044-5d389ddc7592">
      {props.children || <>
          <Navigation_div_Logoicon {...Navigation_div_Logoicon_Props} data-jux-context="ea0efef6-9226-448d-a233-ccab13512f4b" />
          <Nav_left_actions {...Nav_left_actions_Props} />
          <Navigation_div_div {...Navigation_div_div_Props} data-jux-context="306366b6-3b8e-4299-acf1-b7ac68c07a0f" />
        </>}
    </Navigation_div_root>);
Navigation_div.displayName = 'Navigation_div';
Navigation_div.defaultProps = {};
import { JuxButton, type JuxButton_Props } from '../';
type Navigation_slot_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const Navigation_slot_root: FC<Navigation_slot_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
Navigation_slot_root.displayName = 'Navigation_slot_root';
Navigation_slot_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        padding: '80px',
        alignItems: 'center',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF'
      }
    }
  }) as Navigation_slot_root_Props['rootElement']
};
export type Navigation_slot_Props = Navigation_slot_root_Props & {
  JuxButton_Props?: JuxButton_Props;
};
export const Navigation_slot: FC<Navigation_slot_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, Navigation_slot_Props>(({
  JuxButton_Props,
  ...props
}, ref) => <Navigation_slot_root {...props} ref={ref} data-jux-context="df198fd7-eccc-41c2-9eb9-3b56ed559b1d">
    {props.children || <>
        <JuxButton hierarchy={'Primary'} disabled={false} size={'medium'} {...JuxButton_Props} />
      </>}
  </Navigation_slot_root>);
Navigation_slot.displayName = 'Navigation_slot';
Navigation_slot.defaultProps = {};
type Navigation_right_actions_live_edit_switch_slot_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const Navigation_right_actions_live_edit_switch_slot_root: FC<Navigation_right_actions_live_edit_switch_slot_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
Navigation_right_actions_live_edit_switch_slot_root.displayName = 'Navigation_right_actions_live_edit_switch_slot_root';
Navigation_right_actions_live_edit_switch_slot_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        padding: '80px',
        alignItems: 'center',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF'
      }
    }
  }) as Navigation_right_actions_live_edit_switch_slot_root_Props['rootElement']
};
export type Navigation_right_actions_live_edit_switch_slot_Props = Navigation_right_actions_live_edit_switch_slot_root_Props;
export const Navigation_right_actions_live_edit_switch_slot: FC<Navigation_right_actions_live_edit_switch_slot_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, Navigation_right_actions_live_edit_switch_slot_Props>((props, ref) => <Navigation_right_actions_live_edit_switch_slot_root {...props} ref={ref} />);
Navigation_right_actions_live_edit_switch_slot.displayName = 'Navigation_right_actions_live_edit_switch_slot';
Navigation_right_actions_live_edit_switch_slot.defaultProps = {};
type Navigation_right_actions_actions_slot_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const Navigation_right_actions_actions_slot_root: FC<Navigation_right_actions_actions_slot_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
Navigation_right_actions_actions_slot_root.displayName = 'Navigation_right_actions_actions_slot_root';
Navigation_right_actions_actions_slot_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        padding: '80px',
        alignItems: 'center',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF'
      }
    }
  }) as Navigation_right_actions_actions_slot_root_Props['rootElement']
};
export type Navigation_right_actions_actions_slot_Props = Navigation_right_actions_actions_slot_root_Props & {
  IconButton_1_Props?: IconButton_Props;
  IconButton_2_Props?: IconButton_Props;
};
export const Navigation_right_actions_actions_slot: FC<Navigation_right_actions_actions_slot_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, Navigation_right_actions_actions_slot_Props>(({
  IconButton_1_Props,
  IconButton_2_Props,
  ...props
}, ref) => <Navigation_right_actions_actions_slot_root {...props} ref={ref} data-jux-context="a44c2022-07e6-4912-94f4-f1ee2ec0c1f0">
      {props.children || <>
          <IconButton disabled={false} hierarchy={'primary'} {...IconButton_1_Props} />
          <IconButton disabled={false} hierarchy={'primary'} {...IconButton_2_Props} />
        </>}
    </Navigation_right_actions_actions_slot_root>);
Navigation_right_actions_actions_slot.displayName = 'Navigation_right_actions_actions_slot';
Navigation_right_actions_actions_slot.defaultProps = {};
type Navigation_right_actions_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const Navigation_right_actions_root: FC<Navigation_right_actions_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
Navigation_right_actions_root.displayName = 'Navigation_right_actions_root';
Navigation_right_actions_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        gap: 'normal {dimension.core.size_sm}',
        width: 'auto',
        height: 'auto',
        display: 'flex',
        padding: '0px 0px 0px 0px',
        alignItems: 'center',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF'
      }
    }
  }) as Navigation_right_actions_root_Props['rootElement']
};
export type Navigation_right_actions_Props = Navigation_right_actions_root_Props & {
  Navigation_right_actions_live_edit_switch_slot_Props?: Navigation_right_actions_live_edit_switch_slot_Props;
  Navigation_right_actions_actions_slot_Props?: Navigation_right_actions_actions_slot_Props;
};
export const Navigation_right_actions: FC<Navigation_right_actions_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, Navigation_right_actions_Props>(({
  Navigation_right_actions_live_edit_switch_slot_Props,
  Navigation_right_actions_actions_slot_Props,
  ...props
}, ref) => <Navigation_right_actions_root {...props} ref={ref} data-jux-context="976c1870-6d2b-4889-b519-6e66eac1cb93">
        {props.children || <>
            <Navigation_right_actions_live_edit_switch_slot {...Navigation_right_actions_live_edit_switch_slot_Props} data-jux-context="b4ff786f-ddac-40d1-9f43-3129b13c0c3c" />
            <Navigation_right_actions_actions_slot {...Navigation_right_actions_actions_slot_Props} data-jux-context="a44c2022-07e6-4912-94f4-f1ee2ec0c1f0" />
          </>}
      </Navigation_right_actions_root>);
Navigation_right_actions.displayName = 'Navigation_right_actions';
Navigation_right_actions.defaultProps = {};
type Navigation_root_Props = PropsOf<typeof JuxReactElements.JuxDiv> & {
  type: 'Editor' | 'Tokens';
};
const Navigation_root: FC<Navigation_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
Navigation_root.displayName = 'Navigation_root';
Navigation_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        gap: 'normal {dimension.core.size_lg}',
        width: '1440px',
        height: '48px',
        display: 'flex',
        padding: '0px 10px 0px 10px',
        alignItems: 'center',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF'
      },
      variants: [{
        propsValues: {
          type: 'Editor'
        },
        styles: {
          '[data-jux-context="97c4e3b6-f25c-45e0-9044-5d389ddc7592"]': {
            gap: 'normal {dimension.core.size_sm}',
            padding: '0px 0px 0px 0px'
          },
          '[data-jux-context="3eb9b635-57e0-4292-992a-0b183faae044"]': {
            padding: '0px 0px 0px 0px'
          },
          '[data-jux-context="306366b6-3b8e-4299-acf1-b7ac68c07a0f"]': {
            gap: 'normal {dimension.core.size_sm}',
            height: '48px',
            padding: '0px 0px 0px 0px'
          },
          '[data-jux-context="df198fd7-eccc-41c2-9eb9-3b56ed559b1d"]': {
            padding: '0px 0px 0px 0px'
          },
          '[data-jux-context="b4ff786f-ddac-40d1-9f43-3129b13c0c3c"]': {
            padding: '0px 0px 0px 0px'
          },
          '[data-jux-context="a44c2022-07e6-4912-94f4-f1ee2ec0c1f0"]': {
            gap: 'normal {dimension.core.size_sm}',
            padding: '0px 0px 0px 0px'
          }
        }
      }, {
        propsValues: {
          type: 'Tokens'
        },
        styles: {
          '[data-jux-context="97c4e3b6-f25c-45e0-9044-5d389ddc7592"]': {
            gap: 'normal {dimension.core.size_sm}',
            padding: '0px 0px 0px 0px'
          },
          '[data-jux-context="3eb9b635-57e0-4292-992a-0b183faae044"]': {
            padding: '0px 0px 0px 0px'
          },
          '[data-jux-context="306366b6-3b8e-4299-acf1-b7ac68c07a0f"]': {
            gap: 'normal 8px',
            height: '48px',
            padding: '0px 0px 0px 0px'
          },
          '[data-jux-context="df198fd7-eccc-41c2-9eb9-3b56ed559b1d"]': {
            display: 'none',
            padding: '0px 0px 0px 0px'
          },
          '[data-jux-context="b4ff786f-ddac-40d1-9f43-3129b13c0c3c"]': {
            padding: '0px 0px 0px 0px'
          },
          '[data-jux-context="a44c2022-07e6-4912-94f4-f1ee2ec0c1f0"]': {
            gap: 'normal {dimension.core.size_sm}',
            padding: '0px 0px 0px 0px'
          }
        }
      }]
    }
  }) as Navigation_root_Props['rootElement']
};
export type Navigation_Props = Navigation_root_Props & {
  Navigation_div_Props?: Navigation_div_Props;
  Navigation_slot_Props?: Navigation_slot_Props;
  Navigation_right_actions_Props?: Navigation_right_actions_Props;
};
export const Navigation: FC<Navigation_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, Navigation_Props>(({
  Navigation_div_Props,
  Navigation_slot_Props,
  Navigation_right_actions_Props,
  ...props
}, ref) => <Navigation_root {...props} ref={ref} data-jux-context="32144eaf-32b3-4a60-8e7d-29f57d8ba719">
      {props.children || <>
          <Navigation_div {...Navigation_div_Props} data-jux-context="97c4e3b6-f25c-45e0-9044-5d389ddc7592" />
          <Navigation_slot {...Navigation_slot_Props} data-jux-context="df198fd7-eccc-41c2-9eb9-3b56ed559b1d" />
          <Navigation_right_actions {...Navigation_right_actions_Props} data-jux-context="976c1870-6d2b-4889-b519-6e66eac1cb93" />
        </>}
    </Navigation_root>);
Navigation.displayName = 'Navigation';
Navigation.defaultProps = {
  type: 'Editor'
};