import { forwardRef, type CSSProperties, type ComponentProps, type HTMLAttributes, type ReactNode } from 'react';
import { useStore } from '../../hooks';
import type { PanelPosition } from '../../types';
import { MuiBox } from '@jux/ui/components/common/mui';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';
import { withPx } from '@jux/ui/utils/css';
import { LAYOUT_NAVBAR_HEIGHT } from '@jux/ui/theme/base/base-theme-size';
export type PanelProps = HTMLAttributes<HTMLDivElement> & {
  position: PanelPosition;
  children: ReactNode;
  fullHeight?: boolean;
};
const panelStyleByPosition = (position: PanelPosition): CSSProperties => {
  switch (position) {
    case 'top-left':
      return {
        top: 0,
        left: 0
      };
    case 'top-center':
      return {
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)'
      };
    case 'top-right':
      return {
        top: 0,
        right: 0
      };
    case 'bottom-left':
      return {
        bottom: 0,
        left: 0
      };
    case 'bottom-center':
      return {
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)'
      };
    case 'bottom-right':
      return {
        bottom: 0,
        right: 0
      };
    case 'center-left':
      return {
        top: '50%',
        left: 0,
        transform: 'translateY(-50%)'
      };
    case 'center-right':
      return {
        top: '50%',
        right: 0,
        transform: 'translateY(-50%)'
      };
  }
};

// TODO: add focus trap
// focus trap should be added to the children of the panel
// due to the way focus trap works, it must have at least one focusable element
// so we need to add a hidden focusable element to the children or some other solution
export const Panel = forwardRef<'div', PanelProps & Omit<ComponentProps<typeof MuiBox>, 'position'>>(({
  position,
  fullHeight,
  sx,
  ...props
}, ref) => {
  // In case user is selecting nodes, we want to disable pointer events on the panel
  const pointerEvents = useStore(s => s.userSelectionActive ? 'none' : 'all');
  return <MuiBox position="absolute" zIndex={5} width="auto" height={fullHeight ? `calc(100% - ${withPx(LAYOUT_NAVBAR_HEIGHT)})` : 'auto'} marginTop={fullHeight ? withPx(LAYOUT_NAVBAR_HEIGHT) : 0} sx={mergeTyped({
    pointerEvents,
    ...panelStyleByPosition(position)
  }, sx)} {...props} ref={ref} />;
});