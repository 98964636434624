import { FC, useCallback } from 'react';
import { IconButton } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { ComponentTagName } from '@jux/data-entities';
import { useCreateNewProp, useParsedProperties } from './hooks';
export const PropertiesActions: FC<{
  componentId: string | undefined;
  tagName: ComponentTagName | undefined;
}> = ({
  componentId,
  tagName
}) => {
  const {
    createNewProperty
  } = useCreateNewProp({
    componentId,
    tagName
  });
  const {
    hasProperties
  } = useParsedProperties();
  const handleOnClick = useCallback(() => {
    createNewProperty();
  }, [createNewProperty]);
  return <MuiGrid container item sx={{
    padding: hasProperties ? '16px 20px 24px 20px' : '24px 20px',
    position: 'sticky',
    zIndex: 1,
    bottom: 0,
    backgroundColor: theme => theme.drimz.palette.background.default
  }} data-sentry-element="MuiGrid" data-sentry-component="PropertiesActions" data-sentry-source-file="PropertiesActions.tsx">
      <IconButton fullWidth variant="secondary" icon="ADD" onClick={handleOnClick} data-sentry-element="IconButton" data-sentry-source-file="PropertiesActions.tsx">
        Add new property
      </IconButton>
    </MuiGrid>;
};