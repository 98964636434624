/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const TypographyDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M5.35414 13H3L6.58701 3H9.41806L13 13H10.6459L9.87657 10.7197H6.12493L5.35414 13ZM7.96195 5.28516H8.04313L9.31979 9.06934H6.6828L7.96195 5.28516Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="TypographyDDPIcon" data-sentry-source-file="TypographyDDPIcon.tsx" />;