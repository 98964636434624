import { FC } from 'react';
import { CardContentValue, CardContentValueWrapper, DimensionTokenProps } from './base';
export const DimensionToken: FC<DimensionTokenProps> = ({
  value,
  aliasProps: {
    isAlias,
    aliasName
  }
}) => <CardContentValueWrapper data-sentry-element="CardContentValueWrapper" data-sentry-component="DimensionToken" data-sentry-source-file="DimensionToken.tsx">
    <CardContentValue value={value} aliasName={aliasName} isAlias={isAlias} data-sentry-element="CardContentValue" data-sentry-source-file="DimensionToken.tsx" />
  </CardContentValueWrapper>;