/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const MarginRightDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M4.25 5.5C4.25 4.80964 4.80964 4.25 5.5 4.25H10.5C11.1904 4.25 11.75 4.80964 11.75 5.5V7.25H13.5C13.9142 7.25 14.25 7.58579 14.25 8C14.25 8.41421 13.9142 8.75 13.5 8.75H11.75V10.5C11.75 11.1904 11.1904 11.75 10.5 11.75H5.5C4.80964 11.75 4.25 11.1904 4.25 10.5V5.5ZM5.75 5.75V10.25H10.25V5.75H5.75Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="MarginRightDDPIcon" data-sentry-source-file="MarginRightDDPIcon.tsx" />;