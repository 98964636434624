/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const PaddingRightDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M9 10.75C8.58579 10.75 8.25 10.4142 8.25 10L8.25 6C8.25 5.58579 8.58579 5.25 9 5.25C9.41421 5.25 9.75 5.58579 9.75 6V7.25H12.5V3.5L3.5 3.5L3.5 12.5H12.5V8.75H9.75V10C9.75 10.4142 9.41421 10.75 9 10.75ZM14 3C14 2.44772 13.5523 2 13 2L3 2C2.44772 2 2 2.44772 2 3L2 13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V3Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="PaddingRightDDPIcon" data-sentry-source-file="PaddingRightDDPIcon.tsx" />;