import { useCallback } from 'react';
import { useStoreActions } from '@jux/canjux/core';
export const useUpdateNodeDisplayName = () => {
  const {
    componentActions: {
      updateComponentDisplayName
    }
  } = useStoreActions();
  return useCallback(({
    nodeId,
    value: displayName
  }: {
    nodeId: string;
    value: string;
  }) => {
    updateComponentDisplayName({
      displayName,
      nodeId
    });
  }, [updateComponentDisplayName]);
};