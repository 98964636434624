import { FC } from 'react';
import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';
import { SelectField } from '../../../../base';
import { useFieldValues, useSetFieldValue, useSelectField } from '../../../../hooks';
const FIELD_KEY = DDPPrimitiveFieldsKeys.borderStyle;
const styles = [{
  value: 'Solid',
  label: 'Solid'
}, {
  value: 'None',
  label: 'None'
}];
export const BorderStyleField: FC = () => {
  const {
    value,
    initialValue
  } = useFieldValues(FIELD_KEY);
  const {
    saveValue: setFieldValue
  } = useSetFieldValue(FIELD_KEY);
  const props = useSelectField({
    fieldName: FIELD_KEY,
    value,
    initialValue,
    saveChanges: setFieldValue,
    tokenType: null,
    initSelectionOptions: styles
  });
  return <SelectField icon="DDP_FIELD_BORDER_STYLE" tooltipTitle="Border style" {...props} data-sentry-element="SelectField" data-sentry-component="BorderStyleField" data-sentry-source-file="BorderStyleField.tsx" />;
};