import { CSSProperties, FC } from 'react';
import { KeyboardPopover } from '@jux/ui-core';
import { useTheme } from '@jux/ui/components/common/mui';
import { isMac } from '@jux/canjux/core';
import { POPPER_BOX_SHADOW_COLOR } from '@jux/ui/theme/palette';
import { PopoverDiv, popperControls } from '../popper';
export const KEYBOARD_SHORTCUT_POPOVER_ID = 'keyboard-shortcut-popover';
export const KeyboardShortcutPopover: FC<{
  offset?: CSSProperties['right'];
}> = ({
  offset = 0
}) => {
  const modifierText = isMac() ? '⌘' : 'ctrl';
  const theme = useTheme();
  const closePopover = () => popperControls(KEYBOARD_SHORTCUT_POPOVER_ID).close();
  return <PopoverDiv id={KEYBOARD_SHORTCUT_POPOVER_ID} openStyles={{
    position: 'absolute',
    inset: 'unset',
    border: 0,
    boxShadow: `0px 2px 12px 0px ${POPPER_BOX_SHADOW_COLOR}`,
    top: theme.drimz.size.layout.navbar.height,
    right: offset,
    margin: '10px'
  }} data-sentry-element="PopoverDiv" data-sentry-component="KeyboardShortcutPopover" data-sentry-source-file="KeyboardShortcutPopover.tsx">
      <KeyboardPopover onClick={closePopover} modifierText={modifierText} data-sentry-element="KeyboardPopover" data-sentry-source-file="KeyboardShortcutPopover.tsx" />
    </PopoverDiv>;
};