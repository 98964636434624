import { ComponentProps } from 'react';
import { MuiCard, styled, useTheme } from '@jux/ui/components/common/mui';
import { TextField } from '@jux/ui/components';
export const InviteCard = styled(MuiCard)(({
  theme
}) => ({
  padding: '64px 40px',
  width: '100%',
  maxWidth: 400,
  backgroundColor: theme.drimz.palette.background.default,
  borderRadius: theme.drimz.size.borderRadius.main
}));
export const InviteForm = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px'
}));
export const InviteTextField = styled((props: ComponentProps<typeof TextField>) => {
  const theme = useTheme();
  return <TextField autoFocus fullWidth margin="none" size="small" sx={{
    height: 'auto',
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: theme.drimz.size.borderRadius.small
    }
  }} {...props} />;
})(() => ({}));