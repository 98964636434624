import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';
import { PATHS } from '@jux/ui/utils/paths';
export const GoToPage: FC<{
  path: keyof typeof PATHS;
}> = ({
  path
}) => {
  const router = useRouter();
  useEffect(() => {
    router.push(PATHS[path]);
  }, [path, router]);
  return null;
};