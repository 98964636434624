import { FC } from 'react';
import { MenuList } from '@jux/ui/components';
import { DisableDeleteReturnType } from '../../interface';
import { DeleteTokenActionItem } from './DeleteTokenActionItem';
export const TokenCardActionsMenu: FC<{
  onDeleteToken: () => void;
  shouldDisableDeleteToken: () => DisableDeleteReturnType;
  isTokenCardHovered?: boolean;
}> = ({
  shouldDisableDeleteToken,
  isTokenCardHovered,
  onDeleteToken
}) => <MenuList isVisible={isTokenCardHovered} optionsButtonProps={{
  variant: 'icon',
  disableTooltip: true
}} paperProps={{
  sx: {
    marginTop: '-1px'
  }
}} popperPlacement="bottom-end" stopClickPropagation data-sentry-element="MenuList" data-sentry-component="TokenCardActionsMenu" data-sentry-source-file="TokenCardActionsMenu.tsx">
    <DeleteTokenActionItem onDeleteToken={onDeleteToken} shouldDisableDeleteToken={shouldDisableDeleteToken} data-sentry-element="DeleteTokenActionItem" data-sentry-source-file="TokenCardActionsMenu.tsx" />
  </MenuList>;