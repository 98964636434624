import { FC } from 'react';
import { TooltipTypography } from '@jux/ui/components';
import { CardAliasType } from './CardContentByType.interface';
import { formatAliasDisplay } from './CardContentByType.utils';
export const CardContentValue: FC<CardAliasType & {
  value: string;
}> = ({
  isAlias,
  aliasName,
  value
}) => <TooltipTypography variant="body-regular" sx={{
  color: theme => theme.drimz.palette.text.secondary
}} tooltipContent={() => value} shouldWordBreak data-sentry-element="TooltipTypography" data-sentry-component="CardContentValue" data-sentry-source-file="CardContentValue.tsx">
    {isAlias && aliasName ? formatAliasDisplay(aliasName) : value}
  </TooltipTypography>;