/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const TypographyIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M5.01704 14H2.38068L6.39772 2.36363H9.56818L13.5795 14H10.9432L10.0817 11.3466H5.88024L5.01704 14ZM7.93749 5.02272H8.0284L9.45812 9.42613H6.50499L7.93749 5.02272Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="TypographyIcon" data-sentry-source-file="TypographyIcon.tsx" />;