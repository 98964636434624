import { FC, useCallback, useMemo } from 'react';
import { DesignTokenTypeEnum, SupportedTokenTypes } from '@juxio/design-tokens';
import { useSettingsState, useTokenSetState } from '@jux/ui/state';
import { Typography } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { Floors, useOverlaysZIndex } from '@jux/ui/hooks';
import { useSelectedTokenState } from '../state';
import { useTokenDrawer } from '../../token-drawer/useTokenDrawer';
import { TokenTypeNavItem } from './TokenTypeNavItem';
import { tokenTypeNavItems } from './tokenTypeNavItems';
export const TokenTypeNav: FC = () => {
  const {
    currentTokenType,
    setCurrentTokenType
  } = useTokenSetState();
  const {
    close: closeTokenDrawer
  } = useTokenDrawer();
  const {
    deselectToken
  } = useSelectedTokenState();
  const handleTokenTypesClick = useCallback((type: SupportedTokenTypes) => {
    setCurrentTokenType(type);
    deselectToken();
    closeTokenDrawer();
  }, [closeTokenDrawer, deselectToken, setCurrentTokenType]);

  // TODO: remove this when border token is ready
  const {
    settings: {
      featureFlags: {
        borderToken
      }
    }
  } = useSettingsState();
  const tokenTypes = useMemo(() => tokenTypeNavItems.filter(({
    type
  }) => {
    if (type === DesignTokenTypeEnum.border) {
      return borderToken;
    }
    return true;
  }), [borderToken]);
  const navbarZIndex = useOverlaysZIndex({
    id: 'layout-navbar',
    floor: Floors.innerNavbar
  });
  return <MuiGrid display="flex" flexDirection="column" data-sentry-element="MuiGrid" data-sentry-component="TokenTypeNav" data-sentry-source-file="TokenTypeNav.tsx">
      <Typography variant="label-s-bold" sx={{
      color: theme => theme.drimz.palette.text.primary,
      height: theme => theme.drimz.size.layout.tokens.set.height,
      padding: theme => theme.drimz.size.layout.tokens.spacing.mainPadding,
      paddingTop: '16px',
      borderBottom: 1,
      borderColor: theme => theme.drimz.palette.divider.secondary,
      backgroundColor: theme => theme.drimz.palette.background.navigation,
      zIndex: navbarZIndex,
      position: 'relative'
    }} data-sentry-element="Typography" data-sentry-source-file="TokenTypeNav.tsx">
        Token types
      </Typography>
      <MuiGrid display="flex" flexDirection="column" padding={theme => theme.drimz.size.layout.tokens.spacing.mainPadding} gap="22px" data-sentry-element="MuiGrid" data-sentry-source-file="TokenTypeNav.tsx">
        {tokenTypes.map(({
        icon,
        title,
        type
      }) => <TokenTypeNavItem key={type} icon={icon} title={title} isSelected={currentTokenType === type} onClick={() => handleTokenTypesClick(type)} />)}
      </MuiGrid>
    </MuiGrid>;
};