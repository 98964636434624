import { useCallback } from 'react';
import { Typography, useOpenConfirmationModal } from '@jux/ui/components';
export const useDeletePropValueConfirmationModal = () => {
  const {
    openConfirmationModal
  } = useOpenConfirmationModal();
  const openDeletePropValueConfirmationModal = useCallback(({
    valueToDelete,
    fallbackOptionLabel,
    onConfirm,
    propName
  }: {
    valueToDelete: string;
    fallbackOptionLabel: string;
    onConfirm: () => void;
    propName: string;
  }) => {
    openConfirmationModal({
      title: `Delete ‘${valueToDelete}’ value from the prop ‘${propName}’`,
      confirmText: 'Delete value',
      cancelText: 'I’ve changed my mind',
      children: <Typography variant="body-regular" color={theme => theme.drimz.palette.text.secondary}>
            The value ‘{valueToDelete}’ will be deleted and swapped to ‘
            {fallbackOptionLabel}’ in all the instances which currently use it.
          </Typography>,
      onConfirm
    });
  }, [openConfirmationModal]);
  return {
    openDeletePropValueConfirmationModal
  };
};