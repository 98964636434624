import { FC, KeyboardEvent, PropsWithChildren, useCallback } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { TokenFieldContentWrapperProps } from './TokenFieldContentWrapper.interface';
export const TokenFieldContentWrapper: FC<PropsWithChildren<TokenFieldContentWrapperProps>> = ({
  onClick,
  isFieldDisabled,
  children
}) => {
  const handleClick = useCallback(() => {
    if (isFieldDisabled) return;
    onClick?.();
  }, [isFieldDisabled, onClick]);
  const handleKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (isFieldDisabled) return;
    if (typeof onClick === 'function' && event.key === ' ') {
      // Prevent the page from scrolling down when the user presses the space bar
      event.preventDefault();
    }
  }, [isFieldDisabled, onClick]);
  const handleKeyUp = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (isFieldDisabled) return;
    if (typeof onClick === 'function' && (event.key === 'Enter' || event.key === ' ')) {
      onClick();
    }
  }, [isFieldDisabled, onClick]);
  return <MuiGrid container display="flex" alignItems="center" justifyContent="space-between" flexWrap="nowrap" gap="8px" p="8px" sx={{
    cursor: isFieldDisabled ? 'default' : 'pointer',
    position: 'relative'
  }} tabIndex={isFieldDisabled ? undefined : 0} role="button" onClick={handleClick} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} data-sentry-element="MuiGrid" data-sentry-component="TokenFieldContentWrapper" data-sentry-source-file="TokenFieldContentWrapper.tsx">
      {children}
    </MuiGrid>;
};