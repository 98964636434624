/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const RelativeToDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M4 13C3.44772 13 3 12.5523 3 12V4C3 3.44772 3.44772 3 4 3H12C12.5523 3 13 3.44772 13 4V12C13 12.5523 12.5523 13 12 13H4ZM4.5 11.5V4.5H11.5V7H8C7.44772 7 7 7.44772 7 8V11.5H4.5ZM8.5 11.5H11.5V8.5H8.5V11.5Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="RelativeToDDPIcon" data-sentry-source-file="RelativeToDDPIcon.tsx" />;