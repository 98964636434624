import { FC, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHover } from 'usehooks-ts';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { generateName } from '@jux/ui/utils/generateName';
import { useFocusWithin } from '@jux/ui/hooks';
import { TokenGroupContentWrapper } from '../TokenGroupContentWrapper.style';
import { TokenCard } from '../tokenCard/TokenCard';
import { CreateNewTokenCard } from '../CreateNewTokenCard';
import { useOpenGroupHeaderActionsPopper } from '../../hooks/useOpenGroupHeaderActionsPopper';
import { useDeleteGroupConfirmationModal } from '../../hooks/useDeleteGroupConfirmationModal';
import { GROUP_HEADER_ACTION_BUTTON_CLASS_NAME, TokenGroupHeader } from './TokenGroupHeader';
import { TokenGroupProps } from './TokenGroup.interface';
const INNER_GROUPS_WRAPPER_CLASS_NAME = 'inner-groups-wrapper';
export const TokenGroup: FC<TokenGroupProps> = ({
  name: groupName,
  path: groupPath,
  totalTokens,
  parentGroupPath,
  hasAliasedTokens,
  isLocked,
  description,
  tokens,
  groups,
  shouldDisableDeleteGroup,
  shouldDisableDeleteToken,
  isTokenSelected,
  isDrawerOpen,
  handleRenameGroup,
  handleCreateToken,
  handleDeleteGroup,
  handleCreateGroup,
  handleEditToken,
  handleRenameToken,
  handleDeleteToken
}) => {
  const {
    openGroupHeaderActionsPopper,
    isGroupHeaderActionsPopperOpen
  } = useOpenGroupHeaderActionsPopper();
  const {
    openDeleteGroupConfirmationModal
  } = useDeleteGroupConfirmationModal();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const isWrapperHovered = useHover(wrapperRef);
  const isWrapperFocused = useFocusWithin(wrapperRef);
  const [hasCurrentCreateNewTokenCardClicked, setHasCurrentCreateNewTokenCardClicked] = useState(false);
  const groupsWrapperRef = useRef<HTMLDivElement>(null);
  const isGroupsWrapperHovered = useHover(groupsWrapperRef);
  const isGroupsWrapperFocused = useFocusWithin(groupsWrapperRef);
  const hoveredButNotOnGroupsArea = isWrapperHovered && !isGroupsWrapperHovered && !isGroupsWrapperFocused;
  const focusedButNotOnGroupsArea = isWrapperFocused && !isGroupsWrapperHovered && !isGroupsWrapperFocused;
  const hasTokens = Boolean(tokens.length);
  const isActionsOpen = isGroupHeaderActionsPopperOpen(groupPath);
  const showCreateNewTokenCard = hoveredButNotOnGroupsArea || focusedButNotOnGroupsArea || !hasTokens || isActionsOpen || isDrawerOpen && hasCurrentCreateNewTokenCardClicked;
  const [isGroupOpen, setIsGroupOpen] = useState(true);
  const hasGroups = Boolean(groups?.length);
  const groupNames = useMemo(() => groups.map(group => group.name), [groups]);
  const existingTokenNamesInGroup = useMemo(() => tokens.map(token => token.name), [tokens]);
  const createGroup = useCallback(() => {
    const generatedName = generateName({
      baseName: 'new_group',
      namesArray: groupNames,
      separator: '_'
    });
    handleCreateGroup({
      newGroupName: generatedName,
      groupPath: groupPath
    });
  }, [handleCreateGroup, groupNames, groupPath]);
  const deleteGroup = useCallback(() => {
    if (totalTokens === 0) {
      handleDeleteGroup(groupPath);
      return;
    }
    openDeleteGroupConfirmationModal({
      groupName: groupName,
      totalTokens,
      onConfirm: () => handleDeleteGroup(groupPath)
    });
  }, [handleDeleteGroup, openDeleteGroupConfirmationModal, groupName, groupPath, totalTokens]);
  const toggleGroupView = useCallback(() => {
    setIsGroupOpen(prev => !prev);
  }, []);
  const handleCreateNewToken = useCallback(() => {
    handleCreateToken({
      groupPath
    });
    setHasCurrentCreateNewTokenCardClicked(true);
  }, [handleCreateToken, groupPath]);
  const handleClickActionsButton = useCallback((e: MouseEvent<HTMLElement>) => {
    openGroupHeaderActionsPopper({
      path: groupPath,
      // we're using currentTarget because we want to use the button as the anchor element (and when using target it's the svg icon inside the button that's being used as the anchor element)
      sourceElement: e.currentTarget as HTMLElement,
      onCreateSubGroup: createGroup,
      onDeleteGroup: deleteGroup,
      onCreateToken: handleCreateNewToken,
      disableDeleteGroup: shouldDisableDeleteGroup({
        hasAliasedTokens,
        isLocked,
        path: groupPath
      })
    });
  }, [openGroupHeaderActionsPopper, groupPath, createGroup, deleteGroup, handleCreateNewToken, shouldDisableDeleteGroup, hasAliasedTokens, isLocked]);
  useEffect(() => {
    if (!isDrawerOpen) {
      setHasCurrentCreateNewTokenCardClicked(false);
    }
  }, [isDrawerOpen]);
  return <MuiGrid item container gap="16px" ref={wrapperRef} sx={{
    [`& > div:first-of-type .${GROUP_HEADER_ACTION_BUTTON_CLASS_NAME}`]: {
      display: isActionsOpen ? 'block' : 'none'
    },
    '&:hover, &:focus-within': {
      [`> div:first-of-type .${GROUP_HEADER_ACTION_BUTTON_CLASS_NAME}`]: {
        display: 'block'
      }
    }
  }} data-sentry-element="MuiGrid" data-sentry-component="TokenGroup" data-sentry-source-file="TokenGroup.tsx">
      <TokenGroupHeader totalTokens={totalTokens} isGroupOpen={isGroupOpen} toggleGroupView={toggleGroupView} hasAliasedTokens={hasAliasedTokens} groupNameProps={{
      label: groupName,
      onSaveChanges: newName => handleRenameGroup({
        newName,
        oldName: groupName,
        parentGroupPath
      })
    }} groupDescription={description} isLocked={isLocked} onClickActionsButton={handleClickActionsButton} existingGroupNames={groupNames} data-sentry-element="TokenGroupHeader" data-sentry-source-file="TokenGroup.tsx" />

      {isGroupOpen && <MuiGrid container gap="16px">
          <TokenGroupContentWrapper sx={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '24px'
      }}>
            {tokens.map(token => <TokenCard key={token.path} {...token} isSelected={isTokenSelected(token.path)} onEditToken={() => handleEditToken(token)} onRenameToken={newName => handleRenameToken({
          newName,
          path: token.path
        })} onDeleteToken={() => handleDeleteToken({
          tokenName: token.name,
          groupPath: groupPath
        })} shouldDisableDeleteToken={() => shouldDisableDeleteToken({
          groupPath: token.groupPath,
          tokenName: token.name,
          hasAlias: token.hasAlias
        })} existingTokenNamesInGroup={existingTokenNamesInGroup} />)}

            {showCreateNewTokenCard && <CreateNewTokenCard onClick={handleCreateNewToken} />}
          </TokenGroupContentWrapper>

          {hasGroups && <TokenGroupContentWrapper className={INNER_GROUPS_WRAPPER_CLASS_NAME} marginLeft="24px" ref={groupsWrapperRef}>
              {groups.map(group => <TokenGroup key={group.path} {...group} parentGroupPath={groupPath} isDrawerOpen={isDrawerOpen} shouldDisableDeleteGroup={shouldDisableDeleteGroup} shouldDisableDeleteToken={shouldDisableDeleteToken} isTokenSelected={isTokenSelected} handleCreateGroup={handleCreateGroup} handleCreateToken={handleCreateToken} handleDeleteGroup={handleDeleteGroup} handleRenameGroup={handleRenameGroup} handleEditToken={handleEditToken} handleRenameToken={handleRenameToken} handleDeleteToken={handleDeleteToken} />)}
            </TokenGroupContentWrapper>}
        </MuiGrid>}
    </MuiGrid>;
};