import { FC } from 'react';
import { Tooltip, Typography } from '@jux/ui/components';
import { MuiBox, MuiMenuItem } from '@jux/ui/components/common/mui';
import { DisableDeleteReturnType } from '../../interface';
export const DeleteTokenActionItem: FC<{
  onDeleteToken: () => void;
  shouldDisableDeleteToken: () => DisableDeleteReturnType;
}> = ({
  shouldDisableDeleteToken,
  onDeleteToken
}) => {
  const disableDeleteToken = shouldDisableDeleteToken();
  return <Tooltip content={disableDeleteToken.reason || ''} data-sentry-element="Tooltip" data-sentry-component="DeleteTokenActionItem" data-sentry-source-file="DeleteTokenActionItem.tsx">
      <MuiBox data-sentry-element="MuiBox" data-sentry-source-file="DeleteTokenActionItem.tsx">
        <MuiMenuItem onClick={onDeleteToken} disabled={disableDeleteToken.disabled} disableRipple data-sentry-element="MuiMenuItem" data-sentry-source-file="DeleteTokenActionItem.tsx">
          <Typography variant="body-regular" data-sentry-element="Typography" data-sentry-source-file="DeleteTokenActionItem.tsx">Delete</Typography>
        </MuiMenuItem>
      </MuiBox>
    </Tooltip>;
};