import { FC } from 'react';
import { TooltipTypography, ToggleSwitch, useToggleSwitch } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
export const ParentContextHeader: FC<{
  title: string;
  checked: boolean;
  onToggleClick: (checked: boolean) => void;
}> = ({
  title,
  checked,
  onToggleClick
}) => {
  const toggleSwitchProps = useToggleSwitch({
    checked,
    onToggleClick
  });
  return <MuiGrid container item sx={{
    padding: '16px 0',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'nowrap',
    columnGap: '8px'
  }} data-sentry-element="MuiGrid" data-sentry-component="ParentContextHeader" data-sentry-source-file="ParentContextHeader.tsx">
      <TooltipTypography variant="label-s-medium" sx={{
      width: 'calc(100% - 40px)'
    }} data-sentry-element="TooltipTypography" data-sentry-source-file="ParentContextHeader.tsx">
        {title}
      </TooltipTypography>
      <ToggleSwitch {...toggleSwitchProps} data-sentry-element="ToggleSwitch" data-sentry-source-file="ParentContextHeader.tsx" />
    </MuiGrid>;
};