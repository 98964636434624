import { MutableRefObject, forwardRef, useMemo } from 'react';
import { useOverflowDetector } from 'react-detectable-overflow';
import { mergeRefs } from 'react-merge-refs';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';
import { Tooltip } from '@jux/ui/components';
import { Typography, TypographyElement } from '../typography';
import { TooltipTypographyProps } from './TooltipTypography.interface';
export const TooltipTypography = forwardRef<TypographyElement, TooltipTypographyProps>(({
  children,
  tooltipContent,
  tooltipHeader,
  placement,
  sx,
  tooltipProps,
  shouldEllipsis = true,
  shouldWordBreak = false,
  disabled,
  ...props
}, ref) => {
  const {
    overflow: isOverflowed,
    ref: overflowDetectorRef
  } = useOverflowDetector({});
  const typographyRef = mergeRefs([ref, overflowDetectorRef as MutableRefObject<TypographyElement>]);
  const tooltipResolvedContent = useMemo(() =>
  // If content is not overflowed, do not show the tooltip, unless function is provided
  typeof tooltipContent === 'function' ? tooltipContent(isOverflowed) : isOverflowed ? tooltipContent ?? children : undefined, [children, isOverflowed, tooltipContent]);
  return <Tooltip content={tooltipResolvedContent} header={tooltipHeader} placement={placement} {...tooltipProps}>
        <Typography ref={typographyRef} sx={mergeTyped(shouldEllipsis ? {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      wordBreak: shouldWordBreak ? 'break-all' : 'initial'
    } : {}, sx)} {...props} display="inline-block" // to make ellipsis work and make sure we can't override the display props (cause some of them don't work let ellipsis work)
    >
          {children}
        </Typography>
      </Tooltip>;
});