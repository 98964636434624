import { FC } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Grid as MuiGrid } from '@mui/material';
import { withImportant } from '@jux/ui/utils/css';
import { TAB_HEADER_ITEM_SELECTED_COLOR, TAB_HEADER_ITEM_COLOR, TAB_HEADER_ITEM_HOVERED_COLOR } from '@jux/ui/theme/palette';
import { Typography } from '../typography';
export type TabData = {
  title: string;
};
export const Tab: FC<TabData & {
  selected: boolean;
  onClick: () => void;
}> = ({
  title,
  selected,
  onClick
}) => <MuiGrid item sx={{
  cursor: 'pointer',
  margin: '16px 0'
}} onClick={onClick} data-sentry-element="MuiGrid" data-sentry-component="Tab" data-sentry-source-file="Tab.tsx">
    <Typography variant="label-s-bold" sx={{
    color: selected ? withImportant(TAB_HEADER_ITEM_SELECTED_COLOR) : TAB_HEADER_ITEM_COLOR,
    '&:hover': {
      color: TAB_HEADER_ITEM_HOVERED_COLOR
    }
  }} data-sentry-element="Typography" data-sentry-source-file="Tab.tsx">
      {title}
    </Typography>
  </MuiGrid>;