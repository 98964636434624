/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const MaxHeightDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M10.75 3C10.75 3.41421 10.4142 3.75 10 3.75L6 3.75C5.58579 3.75 5.25 3.41421 5.25 3C5.25 2.58579 5.58579 2.25 6 2.25L10 2.25C10.4142 2.25 10.75 2.58579 10.75 3ZM10.75 5.5C10.75 5.91421 10.4142 6.25 10 6.25H8.75V9.75H10C10.4142 9.75 10.75 10.0858 10.75 10.5C10.75 10.9142 10.4142 11.25 10 11.25H6C5.58579 11.25 5.25 10.9142 5.25 10.5C5.25 10.0858 5.58579 9.75 6 9.75H7.25V6.25H6C5.58579 6.25 5.25 5.91421 5.25 5.5C5.25 5.08579 5.58579 4.75 6 4.75L10 4.75C10.4142 4.75 10.75 5.08579 10.75 5.5ZM10.75 13C10.75 13.4142 10.4142 13.75 10 13.75H6C5.58579 13.75 5.25 13.4142 5.25 13C5.25 12.5858 5.58579 12.25 6 12.25H10C10.4142 12.25 10.75 12.5858 10.75 13Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="MaxHeightDDPIcon" data-sentry-source-file="MaxHeightDDPIcon.tsx" />;