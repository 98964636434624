/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '../core/CustomIcon';
export const VariantInstanceLayerIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M2.34473 8.00184L8.00157 2.345L13.6584 8.00184L8.00157 13.6587L2.34473 8.00184Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="VariantInstanceLayerIcon" data-sentry-source-file="VariantInstanceLayerIcon.tsx" />;