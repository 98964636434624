import { FC } from 'react';
import { ModulesKeys } from '../../DDP.interface';
import { Module } from '../../base';
import { useShouldRenderModuleByKey } from '../../hooks/useShouldRenderModuleByKey';
import { Opacity as OpacitySubModule } from './sub-modules';
const MODULE_KEY = ModulesKeys.opacity;
export const Opacity: FC = () => {
  const {
    shouldRender
  } = useShouldRenderModuleByKey(MODULE_KEY);
  if (!shouldRender) return null;
  return <Module moduleKey={MODULE_KEY} title="Element opacity" data-sentry-element="Module" data-sentry-component="Opacity" data-sentry-source-file="Opacity.tsx">
      <OpacitySubModule data-sentry-element="OpacitySubModule" data-sentry-source-file="Opacity.tsx" />
    </Module>;
};