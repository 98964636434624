import { FC, PropsWithChildren } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { TooltipTypography } from '@jux/ui/components';
import { EDITOR_DDP_CHILD_FIELDS_INACTIVE_VALUE_COLOR } from '@jux/ui/theme/palette';
import { TokenFieldContentWrapper } from './TokenFieldContentWrapper';
import { TokenFieldContentWrapperProps } from './TokenFieldContentWrapper.interface';
export const TokenFieldContent: FC<PropsWithChildren<TokenFieldContentWrapperProps & {
  tokenName: string;
}>> = ({
  onClick,
  isComputed,
  isFieldDisabled,
  tokenName,
  children
}) => <TokenFieldContentWrapper onClick={onClick} isFieldDisabled={isFieldDisabled} data-sentry-element="TokenFieldContentWrapper" data-sentry-component="TokenFieldContent" data-sentry-source-file="TokenFieldContent.tsx">
    <TooltipTypography variant="label-s-regular" color={isFieldDisabled || isComputed ? EDITOR_DDP_CHILD_FIELDS_INACTIVE_VALUE_COLOR : undefined} flex={1} tooltipContent={hasContentOverflow => hasContentOverflow ? tokenName : undefined} data-sentry-element="TooltipTypography" data-sentry-source-file="TokenFieldContent.tsx">
      {tokenName}
    </TooltipTypography>

    {children && <MuiGrid display="flex" alignItems="center" flex={0}>
        {children}
      </MuiGrid>}
  </TokenFieldContentWrapper>;