// eslint-disable-next-line @jux/must-import-FC-in-tsx-files
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const BoxShadowInsetTrueDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M3 4C3 3.44771 3.44772 3 4 3H13C13.5523 3 14 3.44772 14 4V13C14 13.5523 13.5523 14 13 14H4C3.44771 14 3 13.5523 3 13V4ZM4.5 12.5V4.5H12.5V12.5H4.5Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M11.5 5.5L10 7V10H7L5.5 11.5H11.5V5.5Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="BoxShadowInsetTrueDDPIcon" data-sentry-source-file="BoxShadowInsetTrueDDPIcon.tsx" />;