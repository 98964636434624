import { FC, useCallback, useRef } from 'react';
import * as UI from '@jux/ui-core';
import { KeyboardShortcutPopover, Tooltip, useMenuPopper, useOpenInviteMembersModal } from '@jux/ui/components';
import { useTheme } from '@jux/ui/components/common/mui';
import { useToggleSidebar, useTrackEvents } from '@jux/ui/hooks';
import { SegmentEventSourcePage } from '@jux/data-access/analytics/track-events-properties';
import { LiveOrEdit } from '../panels';
import { MiddleSlot } from './components';
import { useAddElementToCanvasOnClick, useAssetsPopper, useComponentsPopper, useElementsPopper } from './hooks';
import { slotTooltipContent } from './components/slotTooltipContent';
export const Navigation: FC = () => {
  const theme = useTheme();
  const elementsRef = useRef<HTMLDivElement | null>(null);
  const {
    openElementsPopper,
    isElementPopperOpen
  } = useElementsPopper();
  const componentsRef = useRef<HTMLDivElement | null>(null);
  const {
    openComponentsPopper,
    isComponentsPopperOpen
  } = useComponentsPopper();
  const assetsRef = useRef<HTMLDivElement | null>(null);
  const {
    openAssetsPopper,
    isAssetsPopperOpen
  } = useAssetsPopper();
  const divIconButtonRef = useRef<HTMLButtonElement>(null);
  const textIconButtonRef = useRef<HTMLButtonElement>(null);
  const slotIconButtonRef = useRef<HTMLButtonElement>(null);
  const inviteUsersIconButtonRef = useRef<HTMLButtonElement>(null);
  const menuVerticalIconButtonRef = useRef<HTMLButtonElement>(null);
  const {
    open: openSidebar
  } = useToggleSidebar();
  const {
    handleAddElementToCanvasOnClick
  } = useAddElementToCanvasOnClick();
  const {
    openInviteMembersModal
  } = useOpenInviteMembersModal(SegmentEventSourcePage.editor);
  const {
    openMenuPopper
  } = useMenuPopper();
  const {
    trackOpenSidebar,
    trackNavigationClick
  } = useTrackEvents();
  const handleDivIconClick = useCallback(() => {
    handleAddElementToCanvasOnClick('JuxDiv');
  }, [handleAddElementToCanvasOnClick]);
  const handleTextIconClick = useCallback(() => {
    handleAddElementToCanvasOnClick('JuxText');
  }, [handleAddElementToCanvasOnClick]);
  const handleSlotClick = useCallback(() => {
    handleAddElementToCanvasOnClick('JuxSlot');
  }, [handleAddElementToCanvasOnClick]);
  const handleInviteUsersIconClick = useCallback(() => {
    openInviteMembersModal();
  }, [openInviteMembersModal]);
  const handleLogoClick = useCallback(() => {
    openSidebar();
    trackOpenSidebar();
  }, [openSidebar, trackOpenSidebar]);
  const handleMenuVerticalIconClick = useCallback(() => {
    openMenuPopper({
      sourceElementRef: menuVerticalIconButtonRef
    });
  }, [openMenuPopper]);
  const handleElementsClick = useCallback(() => {
    openElementsPopper(elementsRef);
    trackNavigationClick({
      name: 'elements'
    });
  }, [openElementsPopper, trackNavigationClick]);
  const handleComponentsClick = useCallback(() => {
    openComponentsPopper(componentsRef);
    trackNavigationClick({
      name: 'components'
    });
  }, [openComponentsPopper, trackNavigationClick]);
  const handleAssetsClick = useCallback(() => {
    openAssetsPopper(assetsRef);
    trackNavigationClick({
      name: 'assets'
    });
  }, [openAssetsPopper, trackNavigationClick]);
  return <>
      <UI.Navigation assetsRef={assetsRef} componentsRef={componentsRef} divIconButtonRef={divIconButtonRef} editLiveSwitchSlot={<LiveOrEdit />} elementsRef={elementsRef} inviteUsersIconButtonRef={inviteUsersIconButtonRef} isAssetsOpen={isAssetsPopperOpen} isComponentsOpen={isComponentsPopperOpen} isElementsOpen={isElementPopperOpen} menuVerticalIconButtonRef={menuVerticalIconButtonRef} middleSlot={<MiddleSlot />} onAssetsClick={handleAssetsClick} onComponentsClick={handleComponentsClick} onDivIconClick={handleDivIconClick} onElementsClick={handleElementsClick} onInviteUsersIconClick={handleInviteUsersIconClick} onLogoClick={handleLogoClick} onMenuVerticalIconClick={handleMenuVerticalIconClick} onSlotClick={handleSlotClick} onTextIconClick={handleTextIconClick} slotIconButtonRef={slotIconButtonRef} textIconButtonRef={textIconButtonRef} data-sentry-element="unknown" data-sentry-source-file="Navigation.tsx" />
      <KeyboardShortcutPopover offset={theme.drimz.size.editor.ddp.width} data-sentry-element="KeyboardShortcutPopover" data-sentry-source-file="Navigation.tsx" />
      <Tooltip content="Div" reference={divIconButtonRef} data-sentry-element="Tooltip" data-sentry-source-file="Navigation.tsx" />
      <Tooltip content="Text" reference={textIconButtonRef} data-sentry-element="Tooltip" data-sentry-source-file="Navigation.tsx" />
      <Tooltip content={slotTooltipContent} maxWidth="224px" header="Dynamic content" reference={slotIconButtonRef} data-sentry-element="Tooltip" data-sentry-source-file="Navigation.tsx" />
      <Tooltip content="Invite new members" reference={inviteUsersIconButtonRef} data-sentry-element="Tooltip" data-sentry-source-file="Navigation.tsx" />
      <Tooltip content="Knowledge base and more..." reference={menuVerticalIconButtonRef} data-sentry-element="Tooltip" data-sentry-source-file="Navigation.tsx" />
    </>;
};