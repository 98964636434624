import { FC, forwardRef, useState } from 'react';
import * as I from '../../imported/components';
const CLOSE_ICON = `<svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.198 3.80199C11.9098 3.51372 11.4424 3.51372 11.1541 3.80199L8.00003 6.95607L4.84594 3.80199C4.55767 3.51372 4.09029 3.51372 3.80202 3.80199C3.51375 4.09026 3.51375 4.55764 3.80202 4.84591L6.95611 8L3.80202 11.1541C3.51375 11.4424 3.51375 11.9097 3.80202 12.198C4.09029 12.4863 4.55767 12.4863 4.84594 12.198L8.00003 9.04392L11.1541 12.198C11.4424 12.4863 11.9098 12.4863 12.198 12.198C12.4863 11.9097 12.4863 11.4424 12.198 11.1541L9.04395 8L12.198 4.84591C12.4863 4.55764 12.4863 4.09026 12.198 3.80199Z" fill="currentColor"/>
</svg>
`;
type AddToLibraryModalProps = {
  componentName: string;
  dependenciesNames: string;
  dependenciesCount: number;
  componentsToAddCount: number;
  onConfirm: () => void;
  onCancel?: () => void;
};
export const AddToLibraryModal: FC<AddToLibraryModalProps> = forwardRef<HTMLDivElement, AddToLibraryModalProps>(({
  componentName,
  dependenciesNames,
  dependenciesCount,
  componentsToAddCount,
  onConfirm,
  onCancel
}, ref) => {
  const [isCancelIconFocused, setIsCancelIconFocused] = useState(false);
  return <I.AddToLibraryModal AddToLibraryModal_header_Props={{
    // @ts-expect-error - Ignore warning that you have to supply all variants values - should allow Partial in the generated code
    IconButton_Props: {
      onClick: onCancel,
      style: {
        // focus state
        outline: 'none',
        ...(isCancelIconFocused && {
          borderColor: '{color.core.grays_800}'
        })
      },
      onFocus: () => setIsCancelIconFocused(true),
      onBlur: () => setIsCancelIconFocused(false),
      IconButton_Icon_Props: {
        content: CLOSE_ICON,
        color: '{color.core.grays_800}'
      }
    },
    AddToLibraryModal_header_header_Props: {
      text: `Add ${componentsToAddCount} components to library`
    }
  }} AddToLibraryModal_body_Props={{
    AddToLibraryModal_body_main_text_Props: {
      text: `By adding ‘${componentName}’ to the library, the following source components of its ${dependenciesCount} nested instances will be added as well`
    },
    AddToLibraryModal_body_more_info_Props: {
      text: `${dependenciesNames}`
    }
  }} AddToLibraryModal_footer_Props={{
    // @ts-expect-error - Ignore warning that you have to supply all variants values - should allow Partial in the generated code
    JuxButton_1_Props: {
      JuxButton_text_Props: {
        text: "I've changed my mind"
      },
      onClick: onCancel
    },
    // @ts-expect-error - Ignore warning that you have to supply all variants values - should allow Partial in the generated code
    JuxButton_2_Props: {
      JuxButton_text_Props: {
        text: `Add to library (${componentsToAddCount})`
      },
      onClick: onConfirm
    }
  }} ref={ref} />;
});