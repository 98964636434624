/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '../core/CustomIcon';
export const TooltipLayerIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M5 6.5C5 6.22386 5.22386 6 5.5 6H10.5C10.7761 6 11 6.22386 11 6.5C11 6.77614 10.7761 7 10.5 7H5.5C5.22386 7 5 6.77614 5 6.5Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M5.5 8C5.22386 8 5 8.22386 5 8.5C5 8.77614 5.22386 9 5.5 9H10.5C10.7761 9 11 8.77614 11 8.5C11 8.22386 10.7761 8 10.5 8H5.5Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M3 3C2.44772 3 2 3.44772 2 4V11C2 11.5523 2.44772 12 3 12H5.26581C5.4142 12 5.55493 12.0659 5.64992 12.1799L7.61589 14.5391C7.81579 14.7789 8.18421 14.7789 8.38411 14.5391L10.3501 12.1799C10.4451 12.0659 10.5858 12 10.7342 12H13C13.5523 12 14 11.5523 14 11V4C14 3.44772 13.5523 3 13 3H3ZM3.5 4.5V10.5H5.26581C5.85937 10.5 6.42227 10.7636 6.80225 11.2196L8 12.6569L9.19774 11.2196C9.57773 10.7636 10.1406 10.5 10.7342 10.5H12.5V4.5H3.5Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="TooltipLayerIcon" data-sentry-source-file="TooltipLayerIcon.tsx" />;