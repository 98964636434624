import { FC } from 'react';
import { ComponentPropType, VariantsConfig } from '@jux/data-entities';
import { EnumPropField } from '@jux/ui/components/editor/components/panels/DDP/modules/props/fields/EnumPropField';
import logger from '@jux/ui-logger';
export const DynamicProperties: FC<{
  variantsConfig: VariantsConfig;
  nodeId: string;
  forceRender?: boolean;
}> = ({
  variantsConfig,
  nodeId
}) => <>
    {variantsConfig.map(componentProp => {
    switch (componentProp.propType) {
      case ComponentPropType.Enum:
        return <EnumPropField key={componentProp.variant} nodeId={nodeId} propName={componentProp.variant} displayName={componentProp?.displayName || componentProp.variant} options={componentProp.options} />;
      default:
        logger.warn(`Unsupported prop: ${componentProp.variant} of type ${componentProp.propType}`);
        return null;
    }
  })}
  </>;