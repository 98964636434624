import { useCallback } from 'react';
import { Typography, useOpenConfirmationModal } from '@jux/ui/components';
export const useDeleteGroupConfirmationModal = () => {
  const {
    openConfirmationModal
  } = useOpenConfirmationModal();
  const openDeleteGroupConfirmationModal = useCallback(({
    onConfirm,
    groupName,
    totalTokens
  }: {
    onConfirm: () => void;
    groupName: string;
    totalTokens: number;
  }) => {
    openConfirmationModal({
      title: `Delete ‘${groupName}’`,
      confirmText: 'Delete group',
      cancelText: 'Cancel',
      children: <Typography variant="body-regular" color={theme => theme.drimz.palette.text.secondary}>
            Deleting this group, will delete all its {totalTokens} tokens
            <br />
            Are you sure?
          </Typography>,
      onConfirm
    });
  }, [openConfirmationModal]);
  return {
    openDeleteGroupConfirmationModal
  };
};