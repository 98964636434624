import { FC, useRef } from 'react';
import { useEventListener } from 'usehooks-ts';
import { MuiDrawer, MuiPaper, SxProps, Theme, useTheme } from '@jux/ui/components/common/mui';
import { useTokenDrawer } from '@jux/ui/components/tokens/token-drawer/useTokenDrawer';
import { Floors, useOverlaysZIndex } from '@jux/ui/hooks';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';
import { isInsidePreventCloseElement } from '../utils/isInsideTokenDrawerBlacklistArea';
export const TokenDrawer: FC<{
  hideBackdrop?: boolean;
}> = ({
  hideBackdrop = false
}) => {
  const {
    isOpen,
    close,
    content
  } = useTokenDrawer();
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const zIndex = useOverlaysZIndex({
    id: 'token-drawer',
    floor: Floors.tokenDrawer
  });
  useEventListener('keydown', event => {
    if (!hideBackdrop || !ref.current || ref.current.contains(event.target as HTMLElement) || isInsidePreventCloseElement(event.target as HTMLElement)) {
      return;
    }
    close();
  });
  return <MuiDrawer ref={ref} anchor="right" open={isOpen} onClose={close} hideBackdrop={hideBackdrop} ModalProps={{
    hideBackdrop
  }} sx={mergeTyped<SxProps<Theme>>(hideBackdrop ? {
    '&.MuiDrawer-root': {
      left: 'unset'
    }
  } : {}, {
    '&.MuiDrawer-root': {
      zIndex
    }
  })} data-sentry-element="MuiDrawer" data-sentry-component="TokenDrawer" data-sentry-source-file="TokenDrawer.tsx">
      <MuiPaper sx={{
      ...theme.drimz.size.layout.tokenDrawer.container,
      backgroundColor: theme.drimz.palette.transparent,
      position: 'fixed',
      zIndex,
      borderRadius: 0,
      borderLeft: `1px solid ${theme.drimz.palette.divider.primary}`
    }} data-sentry-element="MuiPaper" data-sentry-source-file="TokenDrawer.tsx">
        {content}
      </MuiPaper>
    </MuiDrawer>;
};