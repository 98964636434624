/**
 *  THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 */

/* eslint-disable */
// @ts-nocheck

import { createDrimz, type PropsOf } from '@jux/dui';
import React, { FC, forwardRef } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType } from '@jux/data-entities';
import { JuxReactElements } from '@jux/react';
const {
  styled
} = createDrimz();
type List_action_Expand_root_Props = PropsOf<typeof JuxReactElements.JuxSvg>;
const List_action_Expand_root: FC<List_action_Expand_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxSvg {...props} ref={ref} />);
List_action_Expand_root.displayName = 'List_action_Expand_root';
List_action_Expand_root.defaultProps = {
  rootElement: styled('svg', {
    styles: {
      root: {
        color: 'rgba(97,97,97,1)'
      }
    }
  }) as List_action_Expand_root_Props['rootElement']
};
export type List_action_Expand_Props = List_action_Expand_root_Props;
export const List_action_Expand: FC<List_action_Expand_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxSvg>, List_action_Expand_Props>((props, ref) => <List_action_Expand_root {...props} ref={ref} />);
List_action_Expand.displayName = 'List_action_Expand';
List_action_Expand.defaultProps = {};
type List_action_button_label_root_Props = PropsOf<typeof JuxReactElements.JuxText>;
const List_action_button_label_root: FC<List_action_button_label_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxText {...props} ref={ref} />);
List_action_button_label_root.displayName = 'List_action_button_label_root';
List_action_button_label_root.defaultProps = {
  rootElement: styled('p', {
    styles: {
      root: {
        fontFamily: 'Inter',
        fontWeight: '500',
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        display: 'inline-block'
      }
    }
  }) as List_action_button_label_root_Props['rootElement']
};
export type List_action_button_label_Props = List_action_button_label_root_Props;
export const List_action_button_label: FC<List_action_button_label_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxText>, List_action_button_label_Props>((props, ref) => <List_action_button_label_root {...props} ref={ref} />);
List_action_button_label.displayName = 'List_action_button_label';
List_action_button_label.defaultProps = {
  text: 'New canvas'
};
type List_action_root_Props = PropsOf<typeof JuxReactElements.JuxButton> & {
  disabled: true | false;
  hierarchy: 'primary' | 'secondary';
};
const List_action_root: FC<List_action_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxButton {...props} ref={ref} />);
List_action_root.displayName = 'List_action_root';
List_action_root.defaultProps = {
  rootElement: styled('button', {
    styles: {
      root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#FFFFFF',
        width: 'auto',
        height: '36px',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '20px',
        padding: '8px 16px',
        borderRadius: '4px',
        borderStyle: 'none',
        borderColor: '',
        gap: '8px',
        cursor: 'pointer',
        outline: 'none',
        '&:active': {
          outline: 'none'
        }
      },
      variants: [{
        styles: {
          backgroundColor: '{color.core.grays_800}',
          borderRadius: '2px',
          padding: '8px 8px 8px 8px',
          justifyContent: 'flex-start',
          display: 'flex',
          '[data-jux-context="de3cdead-df56-41c8-be0a-e21ef318b9a3"]': {
            color: '{color.core.grays_500}'
          },
          '[data-jux-context="10d4c145-36b3-44d8-bf7c-e18f55a5d114"]': {
            color: '{color.core.grays_500}'
          },
          '&:hover': {
            backgroundColor: '{color.core.grays_800}',
            '[data-jux-context="10d4c145-36b3-44d8-bf7c-e18f55a5d114"]': {
              color: '{color.core.grays_0}'
            },
            '[data-jux-context="de3cdead-df56-41c8-be0a-e21ef318b9a3"]': {
              color: '{color.core.grays_0}'
            }
          },
          '&:active': {
            backgroundColor: '{color.core.grays_800}',
            justifyContent: 'flex-start',
            display: 'flex',
            '[data-jux-context="10d4c145-36b3-44d8-bf7c-e18f55a5d114"]': {
              color: '{color.core.grays_0}'
            },
            '[data-jux-context="de3cdead-df56-41c8-be0a-e21ef318b9a3"]': {
              color: '{color.core.grays_0}'
            }
          }
        },
        propsValues: {
          hierarchy: 'primary',
          disabled: false
        }
      }, {
        styles: {
          backgroundColor: '#9C9C9C',
          '&:hover': {
            backgroundColor: '#6A6A6A'
          },
          '&:active': {
            backgroundColor: '#3B3B3B'
          }
        },
        propsValues: {
          hierarchy: 'secondary',
          disabled: false
        }
      }, {
        styles: {
          backgroundColor: '#98A6F9',
          cursor: 'default'
        },
        propsValues: {
          hierarchy: 'primary',
          disabled: true
        }
      }, {
        styles: {
          backgroundColor: '#C8C8C8',
          cursor: 'default'
        },
        propsValues: {
          hierarchy: 'secondary',
          disabled: true
        }
      }]
    }
  }) as List_action_root_Props['rootElement']
};
export type List_action_Props = List_action_root_Props & {
  List_action_Expand_Props?: List_action_Expand_Props;
  List_action_button_label_Props?: List_action_button_label_Props;
};
export const List_action: FC<List_action_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxButton>, List_action_Props>(({
  List_action_Expand_Props,
  List_action_button_label_Props,
  ...props
}, ref) => <List_action_root {...props} ref={ref}>
      {props.children || <>
          <List_action_Expand {...List_action_Expand_Props} data-jux-context="de3cdead-df56-41c8-be0a-e21ef318b9a3" />
          <List_action_button_label {...List_action_button_label_Props} data-jux-context="10d4c145-36b3-44d8-bf7c-e18f55a5d114" />
        </>}
    </List_action_root>);
List_action.displayName = 'List_action';
List_action.defaultProps = {
  hierarchy: 'primary',
  disabled: false
};