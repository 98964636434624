import { CSSProperties, FC, useCallback, useMemo } from 'react';
import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';
import { useFieldValues, useSetFieldValue } from '../../../../hooks';
import { ButtonGroupField, GroupButton } from '../../../../base';
const FIELD_KEY = DDPPrimitiveFieldsKeys.direction;
const DirectionValue: { [direction in Extract<CSSProperties['direction'], 'ltr' | 'rtl'>]: direction } = {
  ltr: 'ltr',
  rtl: 'rtl'
} as const;
export const DirectionField: FC = () => {
  const {
    value,
    initialValue
  } = useFieldValues(FIELD_KEY);
  const {
    saveValue: setFieldValue
  } = useSetFieldValue(FIELD_KEY);
  const selectedDirection = useMemo(() => value ?? initialValue, [initialValue, value]);
  const isGroupButtonSelected = useCallback((groupButtonValue: keyof typeof DirectionValue) => selectedDirection === groupButtonValue, [selectedDirection]);
  return <ButtonGroupField data-sentry-element="ButtonGroupField" data-sentry-component="DirectionField" data-sentry-source-file="DirectionField.tsx">
      <GroupButton icon="DDP_FIELD_LTR_DIRECTION" onClick={() => setFieldValue(DirectionValue.ltr)} isSelected={isGroupButtonSelected(DirectionValue.ltr)} data-sentry-element="GroupButton" data-sentry-source-file="DirectionField.tsx" />
      <GroupButton icon="DDP_FIELD_RTL_DIRECTION" onClick={() => setFieldValue(DirectionValue.rtl)} isSelected={isGroupButtonSelected(DirectionValue.rtl)} data-sentry-element="GroupButton" data-sentry-source-file="DirectionField.tsx" />
    </ButtonGroupField>;
};