import { FC } from 'react';
import { CardContentValue, CardContentValueWrapper, FontFamilyTokenProps } from './base';
export const FontFamilyToken: FC<FontFamilyTokenProps> = ({
  value,
  aliasProps: {
    isAlias,
    aliasName
  }
}) => <CardContentValueWrapper data-sentry-element="CardContentValueWrapper" data-sentry-component="FontFamilyToken" data-sentry-source-file="FontFamilyToken.tsx">
    <CardContentValue value={value} aliasName={aliasName} isAlias={isAlias} data-sentry-element="CardContentValue" data-sentry-source-file="FontFamilyToken.tsx" />
  </CardContentValueWrapper>;