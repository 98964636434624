import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { BorderColorField } from './BorderColorField';
export const BorderColor: FC = () => {
  const {
    shouldRender
  } = useSubModule(DDPSubModulesKeys.borderColor);
  if (!shouldRender) return null;
  return <SubModule title="Color" hideHeader data-sentry-element="SubModule" data-sentry-component="BorderColor" data-sentry-source-file="BorderColor.tsx">
      <BorderColorField data-sentry-element="BorderColorField" data-sentry-source-file="BorderColor.tsx" />
    </SubModule>;
};