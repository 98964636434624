import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { TooltipTypography, Typography } from '@jux/ui/components';
import { TokenFieldContentWrapper } from '../tokenFieldContent';
import { ColorIndicatorDiv } from './ColorIndicator';
export const ColorTokenFieldContent: FC<{
  rgbaColor: string;
  tokenDisplayName: string;
  tokenHexValue: string;
  openTokens?: () => void;
}> = ({
  rgbaColor,
  openTokens,
  tokenDisplayName,
  tokenHexValue
}) => <TokenFieldContentWrapper onClick={openTokens} data-sentry-element="TokenFieldContentWrapper" data-sentry-component="ColorTokenFieldContent" data-sentry-source-file="ColorTokenFieldContent.tsx">
    <MuiGrid item flex={0} data-sentry-element="MuiGrid" data-sentry-source-file="ColorTokenFieldContent.tsx">
      <ColorIndicatorDiv color={rgbaColor} tokenHexValue={tokenHexValue} isTokenized data-sentry-element="ColorIndicatorDiv" data-sentry-source-file="ColorTokenFieldContent.tsx" />
    </MuiGrid>
    <TooltipTypography variant="label-s-regular" flex={1} tooltipContent={hasContentOverflow => hasContentOverflow ? tokenDisplayName : undefined} data-sentry-element="TooltipTypography" data-sentry-source-file="ColorTokenFieldContent.tsx">
      {tokenDisplayName}
    </TooltipTypography>
    <Typography variant="label-s-medium" color={theme => theme.drimz.palette.text.secondary} flex={0} data-sentry-element="Typography" data-sentry-source-file="ColorTokenFieldContent.tsx">
      {tokenHexValue}
    </Typography>
  </TokenFieldContentWrapper>;