import { useSelect } from 'downshift';
import { FC, useMemo } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { preventClickPropagation } from '@jux/ui/utils/preventClickPropagation';
import { UnitType, filteredUnits } from '../../../../hooks';
import { Option } from './Option';
import { parseUnitStr } from './parseUnitStr';
import * as S from './UnitSelection.style';
export const UnitSelection: FC<{
  unit?: UnitType;
  onUnitChange: (newUnit: UnitType) => void;
  fieldClassName: string;
  disabled?: boolean;
  isFieldDisabled?: boolean;
  disablePercentValue?: boolean;
}> = ({
  unit,
  onUnitChange,
  fieldClassName,
  disabled,
  isFieldDisabled,
  disablePercentValue
}) => {
  const units = useMemo(() => filteredUnits({
    disablePercentValue
  }), [disablePercentValue]);
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps
  } = useSelect({
    items: units,
    selectedItem: unit || '',
    onSelectedItemChange: ({
      selectedItem
    }) => onUnitChange(selectedItem as UnitType)
  });
  return <MuiGrid onClick={preventClickPropagation} data-sentry-element="MuiGrid" data-sentry-component="UnitSelection" data-sentry-source-file="UnitSelection.tsx">
      <S.UnitSelectionSelector {...getToggleButtonProps()} isOpen={isOpen} fieldClassName={fieldClassName} disabled={disabled} isFieldDisabled={isFieldDisabled} data-sentry-element="unknown" data-sentry-source-file="UnitSelection.tsx">
        {parseUnitStr(unit)}
      </S.UnitSelectionSelector>
      <S.OptionsWrapper {...getMenuProps()} hidden={disabled || isFieldDisabled || !isOpen} data-sentry-element="unknown" data-sentry-source-file="UnitSelection.tsx">
        {units.map((unitOption, index) => <Option key={`${unitOption}${index}`} isHighlighted={highlightedIndex === index} {...getItemProps({
        item: unitOption,
        index
      })}>
            {parseUnitStr(unitOption)}
          </Option>)}
      </S.OptionsWrapper>
    </MuiGrid>;
};