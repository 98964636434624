import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { ComponentTagName } from '@jux/data-entities';
import { PropertyField } from './PropertyField';
import { useParsedProperties, useHandleAddPropValue, useHandleDeleteProp, useHandleDeletePropValue, useHandleRenamePropName, useHandleRenamePropValue } from './hooks';
export const PropertiesFields: FC<{
  componentId: string | undefined;
  tagName: ComponentTagName | undefined;
}> = ({
  componentId,
  tagName
}) => {
  const {
    parsedProperties,
    propertiesNames,
    hasProperties
  } = useParsedProperties();
  const {
    handleDeleteProp
  } = useHandleDeleteProp({
    componentId,
    tagName
  });
  const {
    handleDeletePropValue
  } = useHandleDeletePropValue({
    componentId,
    tagName
  });
  const {
    handleAddPropValue
  } = useHandleAddPropValue({
    componentId,
    tagName
  });
  const {
    handleRenamePropName
  } = useHandleRenamePropName({
    componentId,
    tagName
  });
  const {
    handleRenamePropValue
  } = useHandleRenamePropValue({
    componentId,
    tagName
  });
  if (!hasProperties) return null;
  return <MuiGrid container item gap="16px" padding="24px 20px 0 20px" data-sentry-element="MuiGrid" data-sentry-component="PropertiesFields" data-sentry-source-file="PropertiesFields.tsx">
      {parsedProperties.map(({
      title: propName,
      options,
      isFixed,
      defaultValue
    }) => <PropertyField key={propName} title={propName} valueOptions={options} disableEditing={isFixed} propertiesNames={propertiesNames} onEditProp={newPropName => handleRenamePropName(propName, newPropName)} onEditPropValue={newPropValueName => handleRenamePropValue(propName, newPropValueName)} onAddPropValue={() => handleAddPropValue(propName, options.map(o => o.label))} onDeletePropValue={optionLabelToDelete => handleDeletePropValue({
      optionLabelToDelete,
      propName,
      defaultValue,
      options
    })} onDeleteProp={() => {
      handleDeleteProp({
        defaultValue,
        propName
      });
    }} />)}
    </MuiGrid>;
};