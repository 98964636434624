import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { Module } from '../../base';
import { useShouldRenderModuleByKey } from '../../hooks/useShouldRenderModuleByKey';
import { ModulesKeys } from '../../DDP.interface';
import { Margin, Padding, FlexDirectionAndWrap, AlignItems, JustifyContent, Gap, Display } from './sub-modules';
const MODULE_KEY = ModulesKeys.layout;
export const Layout: FC = () => {
  const {
    shouldRender
  } = useShouldRenderModuleByKey(MODULE_KEY);
  if (!shouldRender) return null;
  return <Module moduleKey={MODULE_KEY} title="Layout" data-sentry-element="Module" data-sentry-component="Layout" data-sentry-source-file="Layout.tsx">
      <Padding data-sentry-element="Padding" data-sentry-source-file="Layout.tsx" />
      <Margin data-sentry-element="Margin" data-sentry-source-file="Layout.tsx" />
      <MuiGrid container pt="8px" data-sentry-element="MuiGrid" data-sentry-source-file="Layout.tsx">
        <Display data-sentry-element="Display" data-sentry-source-file="Layout.tsx" />
      </MuiGrid>
      <FlexDirectionAndWrap data-sentry-element="FlexDirectionAndWrap" data-sentry-source-file="Layout.tsx" />
      <AlignItems data-sentry-element="AlignItems" data-sentry-source-file="Layout.tsx" />
      <JustifyContent data-sentry-element="JustifyContent" data-sentry-source-file="Layout.tsx" />
      <Gap data-sentry-element="Gap" data-sentry-source-file="Layout.tsx" />
    </Module>;
};