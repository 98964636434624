import { FC, useMemo, useRef } from 'react';
import { BaseFieldLayout } from '../common';
import { ColorFieldProps, DDP_FIELD_CLASS_NAME, DDP_FIELD_NAME_PREFIX } from './ColorField.interface';
import { ColorFieldContent } from './ColorFieldContent';
import { ColorFieldOptionRenderer } from './ColorFieldOptionRenderer';
import { ColorMixedFieldContent } from './ColorMixedFieldContent';
export const ColorField: FC<ColorFieldProps> = ({
  tokensProps,
  parsedValue,
  isComputed,
  popupState,
  icon,
  alphaValueInputRegister,
  hexValueInputRegister,
  setValueByRGBA,
  tooltipTitle,
  hexInputProps,
  alphaInputProps,
  hasMultipleValues = false
}) => {
  const fieldRef = useRef<HTMLDivElement>(null);
  const hexNameWithPrefix = useMemo(() => `${DDP_FIELD_NAME_PREFIX}${hexValueInputRegister().name}`, [hexValueInputRegister]);
  const alphaNameWithPrefix = useMemo(() => `${DDP_FIELD_NAME_PREFIX}${alphaValueInputRegister().name}`, [alphaValueInputRegister]);
  return <BaseFieldLayout icon={icon} tooltipTitle={tooltipTitle} className={DDP_FIELD_CLASS_NAME} htmlFor={hexNameWithPrefix} tokensProps={tokensProps} renderTokenOption={ColorFieldOptionRenderer} fieldRef={fieldRef} isComputed={isComputed} isFieldDisabled={hasMultipleValues} data-sentry-element="BaseFieldLayout" data-sentry-component="ColorField" data-sentry-source-file="ColorField.tsx">
      {hasMultipleValues ? <ColorMixedFieldContent /> : <ColorFieldContent tokensProps={tokensProps} parsedValue={parsedValue} isComputed={isComputed} popupState={popupState} hexValueInputRegister={hexValueInputRegister} alphaValueInputRegister={alphaValueInputRegister} setValueByRGBA={setValueByRGBA} hexInputProps={{
      ...hexInputProps,
      id: hexNameWithPrefix
    }} alphaInputProps={{
      ...alphaInputProps,
      id: alphaNameWithPrefix
    }} />}
    </BaseFieldLayout>;
};