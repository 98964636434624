import { FC } from 'react';
import { LoadingButtonWithIcon as Button } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
export const TokenDrawerActions: FC<{
  onCancel: () => void;
}> = ({
  onCancel
}) => <MuiGrid sx={{
  display: 'flex',
  mx: '24px',
  my: '16px'
}} justifyContent="flex-end" data-sentry-element="MuiGrid" data-sentry-component="TokenDrawerActions" data-sentry-source-file="TokenDrawerActions.tsx">
    <Button sx={{
    background: theme => theme.drimz.palette.background.secondary,
    color: theme => theme.drimz.palette.text.primary,
    '&:hover': {
      background: theme => theme.drimz.palette.background.secondaryHover
    }
  }} onClick={onCancel} disableTooltip data-sentry-element="Button" data-sentry-source-file="TokenDrawerActions.tsx">
      Cancel
    </Button>
    <Button type="submit" disableTooltip variant="contained" sx={{
    background: theme => theme.drimz.palette.primary.main,
    color: theme => theme.drimz.palette.text.button,
    ml: '10px',
    '&:hover': {
      background: theme => theme.drimz.palette.primary.main // TODO: make primaryHover
    }
  }} data-sentry-element="Button" data-sentry-source-file="TokenDrawerActions.tsx">
      Save
    </Button>
  </MuiGrid>;