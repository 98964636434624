import { FC, ReactNode } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { Typography } from '@jux/ui/components/';
type TextOrChildrenType = {
  text: string;
  children?: never;
} | {
  text?: never;
  children: ReactNode;
};
export const DDPMessage: FC<TextOrChildrenType> = ({
  text,
  children
}) => <MuiGrid container flexDirection="column" alignItems="center" justifyContent="center" height="100%" maxWidth="80%" data-sentry-element="MuiGrid" data-sentry-component="DDPMessage" data-sentry-source-file="DDPMessage.tsx">
    <MuiGrid data-sentry-element="MuiGrid" data-sentry-source-file="DDPMessage.tsx">
      <Typography variant="label-s-regular" pt={4} pb={1} px={2} textAlign="center" whiteSpace="pre-wrap" data-sentry-element="Typography" data-sentry-source-file="DDPMessage.tsx">
        <span>{children || text}</span>
      </Typography>
    </MuiGrid>
  </MuiGrid>;