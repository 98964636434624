import { FC } from 'react';
import { ButtonGroupField, GroupButton } from '../../../../base';
import { useJustifyContentField } from './useJustifyContentField';
export const JustifyContentField: FC = () => {
  const {
    isFlexStartSelected,
    handleFlexStartClick,
    isCenterSelected,
    handleCenterClick,
    isFlexEndSelected,
    handleFlexEndClick,
    isSpaceAroundSelected,
    handleSpaceAroundClick,
    isSpaceBetweenSelected,
    handleSpaceBetweenClick,
    isFlexDirectionIsColumn
  } = useJustifyContentField();
  return <ButtonGroupField data-sentry-element="ButtonGroupField" data-sentry-component="JustifyContentField" data-sentry-source-file="JustifyContentField.tsx">
      <GroupButton icon={isFlexDirectionIsColumn ? 'DDP_FIELD_JUSTIFY_CONTENT_FLEX_START_COLUMN' : 'DDP_FIELD_JUSTIFY_CONTENT_FLEX_START_ROW'} isSelected={isFlexStartSelected} onClick={handleFlexStartClick} tooltipTitle="Flex start" data-sentry-element="GroupButton" data-sentry-source-file="JustifyContentField.tsx" />
      <GroupButton icon={isFlexDirectionIsColumn ? 'DDP_FIELD_JUSTIFY_CONTENT_CENTER_COLUMN' : 'DDP_FIELD_JUSTIFY_CONTENT_CENTER_ROW'} isSelected={isCenterSelected} onClick={handleCenterClick} tooltipTitle="Center" data-sentry-element="GroupButton" data-sentry-source-file="JustifyContentField.tsx" />
      <GroupButton icon={isFlexDirectionIsColumn ? 'DDP_FIELD_JUSTIFY_CONTENT_FLEX_END_COLUMN' : 'DDP_FIELD_JUSTIFY_CONTENT_FLEX_END_ROW'} isSelected={isFlexEndSelected} onClick={handleFlexEndClick} tooltipTitle="Flex end" data-sentry-element="GroupButton" data-sentry-source-file="JustifyContentField.tsx" />
      <GroupButton icon={isFlexDirectionIsColumn ? 'DDP_FIELD_JUSTIFY_CONTENT_SPACE_BETWEEN_COLUMN' : 'DDP_FIELD_JUSTIFY_CONTENT_SPACE_BETWEEN_ROW'} isSelected={isSpaceBetweenSelected} onClick={handleSpaceBetweenClick} tooltipTitle="Space between" data-sentry-element="GroupButton" data-sentry-source-file="JustifyContentField.tsx" />
      <GroupButton icon={isFlexDirectionIsColumn ? 'DDP_FIELD_JUSTIFY_CONTENT_SPACE_AROUND_COLUMN' : 'DDP_FIELD_JUSTIFY_CONTENT_SPACE_AROUND_ROW'} isSelected={isSpaceAroundSelected} onClick={handleSpaceAroundClick} tooltipTitle="Space around" data-sentry-element="GroupButton" data-sentry-source-file="JustifyContentField.tsx" />
    </ButtonGroupField>;
};