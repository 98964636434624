import { FC, ReactNode } from 'react';
import { MuiFormControl, MuiFormHelperText, FormControlProps } from '@jux/ui/components/common/mui';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';
export const FieldContainer: FC<Omit<FormControlProps, 'error'> & {
  error?: ReactNode;
}> = ({
  children,
  sx,
  error
}) => <MuiFormControl sx={mergeTyped(theme => theme.drimz.size.layout.tokenDrawer.field, sx)} error={Boolean(error)} data-sentry-element="MuiFormControl" data-sentry-component="FieldContainer" data-sentry-source-file="FieldContainer.tsx">
    {children}
    <MuiFormHelperText sx={{
    // error
    '&.MuiFormHelperText-root.Mui-error': {
      color: theme => theme.drimz.palette.error.main
    }
  }} data-sentry-element="MuiFormHelperText" data-sentry-source-file="FieldContainer.tsx">
      {error}
    </MuiFormHelperText>
  </MuiFormControl>;