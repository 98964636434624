/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const ConstraintsTopDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M10.75 10C10.75 10.4142 10.4142 10.75 10 10.75H6C5.58579 10.75 5.25 10.4142 5.25 10C5.25 9.58579 5.58579 9.25 6 9.25H7.25V6.5H3.5H2V6C2 5.44772 2.44772 5 3 5H13C13.5523 5 14 5.44772 14 6V6.5H12.5H8.75V9.25H10C10.4142 9.25 10.75 9.58579 10.75 10Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="ConstraintsTopDDPIcon" data-sentry-source-file="ConstraintsTopDDPIcon.tsx" />;