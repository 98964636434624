import { ComponentType, MouseEvent, forwardRef, useCallback, useState } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType, ComponentProps } from '@jux/data-entities';
type RadioComponentType = {
  Component: ComponentType<ComponentProps<typeof COMPONENT_TAG_NAME.JuxRadio>>;
};
type RadioLiveModeProps = RadioComponentType & ComponentProps<typeof COMPONENT_TAG_NAME.JuxRadio>;
export const RadioLiveMode = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxRadio>, RadioLiveModeProps>(({
  children,
  Component,
  onClick,
  checked,
  ...props
}, ref) => {
  const [isChecked, setIsChecked] = useState(checked);
  const handleClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    onClick?.(e);
    setIsChecked(!isChecked);
  }, [onClick, isChecked]);
  return <Component {...props} ref={ref} onClick={handleClick} checked={isChecked}>
      {children}
    </Component>;
});