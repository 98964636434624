import { FC, useCallback, useMemo } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { ButtonGroupField, GroupButton, InnerSubModuleHeader } from '../../../../base';
import { useShadowsFields } from './useShadowsFields';
export const ShadowInsetField: FC = () => {
  const {
    value,
    initialValue,
    saveChanges
  } = useShadowsFields();
  const {
    inset
  } = value || {};
  const {
    inset: initialInset
  } = initialValue || {};
  const setFieldValue = useCallback((newValue: boolean) => {
    saveChanges({
      inset: newValue
    });
  }, [saveChanges]);
  const isInset = useMemo(() => Boolean(inset ?? initialInset), [inset, initialInset]);
  const isNormal = useMemo(() => !isInset, [isInset]);
  const handleInsetClick = useCallback(() => {
    setFieldValue(true);
  }, [setFieldValue]);
  const handleNormalClick = useCallback(() => {
    setFieldValue(false);
  }, [setFieldValue]);
  return <MuiGrid container item alignItems="center" justifyContent="space-between" data-sentry-element="MuiGrid" data-sentry-component="ShadowInsetField" data-sentry-source-file="ShadowInsetField.tsx">
      <InnerSubModuleHeader data-sentry-element="InnerSubModuleHeader" data-sentry-source-file="ShadowInsetField.tsx">Drop/inner shadow</InnerSubModuleHeader>

      <ButtonGroupField data-sentry-element="ButtonGroupField" data-sentry-source-file="ShadowInsetField.tsx">
        <GroupButton icon="DDP_FIELD_BOX_SHADOW_INSET_FALSE" isSelected={isNormal} onClick={handleNormalClick} tooltipTitle="Normal" data-sentry-element="GroupButton" data-sentry-source-file="ShadowInsetField.tsx" />
        <GroupButton icon="DDP_FIELD_BOX_SHADOW_INSET_TRUE" isSelected={isInset} onClick={handleInsetClick} tooltipTitle="Inset" data-sentry-element="GroupButton" data-sentry-source-file="ShadowInsetField.tsx" />
      </ButtonGroupField>
    </MuiGrid>;
};