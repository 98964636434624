import { formatToAlias } from '@juxio/design-tokens';

const TOKEN_NAME_PATTERN = '[a-zA-Z0-9_:+-]+';

// must start with { and end with }
// each token can be separated by .
// after every . there must be a token
// eg. {tokens.button.primary}
const TOKEN_PATH_PATTERN = new RegExp(
  `^{(${TOKEN_NAME_PATTERN})((.${TOKEN_NAME_PATTERN})+)?}$`
);

export const isValidTokenPath = (tokenPath: string) =>
  tokenPath.match(TOKEN_PATH_PATTERN);

// in order to find and/or replace a token path in a stringified styles object
// we need to format the token path to match the stringified styles object
// 1. 'color.primary' > '{color.primary}'
export const prepareTokenPathForStringifyActions = (tokenPath: string) =>
  formatToAlias(tokenPath);

// must start with { and end with .
// each token can be separated by .
// after every . there must be a token - other than the last one
// eg. {tokens.button.primary.
const TOKEN_GROUP_PATH_START_PATTERN = new RegExp(
  `^{(${TOKEN_NAME_PATTERN})((.${TOKEN_NAME_PATTERN})+)?[.]$`
);

export const isValidGroupPath = (groupPath: string) =>
  groupPath.match(TOKEN_GROUP_PATH_START_PATTERN);

// in order to find and/or replace a group path in a stringified styles object
// we need to format the group path to match the stringified styles object
// 1. 'color.primary' > '{color.primary}'
// 2. '{color.primary}' > '{color.primary.'
export const prepareGroupPathForStringifyActions = (groupPath: string) =>
  formatToAlias(groupPath).replace(/}$/, '.');

// custom validator util function to make sure that
// the value doesn't have alias characters in it - '{' & '}'
export const hasAliasCharacters = (value: string) =>
  value.match(/[{}]/) !== null;
