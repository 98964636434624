import { FC } from 'react';
import { Tooltip, Typography } from '@jux/ui/components';
import { MuiMenuItem } from '@jux/ui/components/common/mui';
import { withImportant } from '@jux/ui/utils/css';
import { useDeleteComponent } from './hooks';
export const DeleteComponentMenuItem: FC<{
  componentUuid: string;
}> = ({
  componentUuid
}) => {
  const {
    isDeletable,
    handleComponentDelete
  } = useDeleteComponent(componentUuid);
  return <Tooltip multiline content={!isDeletable ? 'In order to delete, remove source component and instances from canvas' : undefined} data-sentry-element="Tooltip" data-sentry-component="DeleteComponentMenuItem" data-sentry-source-file="DeleteComponentMenuItem.tsx">
      {/* Adding additional layer of div, so the tooltip can render this element */}
      <div>
        <MuiMenuItem disabled={!isDeletable} disableRipple onClick={handleComponentDelete} sx={{
        // In order to make sure the styles are correct, we need to add !important, as there are default styles that are being applied
        cursor: withImportant(isDeletable ? 'pointer' : 'not-allowed'),
        backgroundColor: isDeletable ? 'auto' : withImportant('transparent'),
        pointerEvents: withImportant('auto')
      }} data-sentry-element="MuiMenuItem" data-sentry-source-file="DeleteComponentMenuItem.tsx">
          <Typography variant="body-regular" data-sentry-element="Typography" data-sentry-source-file="DeleteComponentMenuItem.tsx">Delete</Typography>
        </MuiMenuItem>
      </div>
    </Tooltip>;
};