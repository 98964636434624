import { FocusEvent, KeyboardEvent, forwardRef, useCallback } from 'react';
import { InputProps } from './Input.interface';
import * as S from './Input.style';

// TODO: give this component a more specific name
export const Input = forwardRef<HTMLInputElement, InputProps>(({
  onEscape,
  onEnter,
  onKeyDown,
  onFocus,
  isComputed,
  isFieldDisabled,
  id,
  ...props
}, ref) => {
  const handleKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Escape':
        onEscape?.(e);
        break;
      case 'Enter':
        onEnter?.(e);
        break;
      default:
        break;
    }
    onKeyDown?.(e);
  }, [onEscape, onEnter, onKeyDown]);
  const handleOnFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
    e.currentTarget.select();
    onFocus?.(e);
  }, [onFocus]);
  return <S.Input id={id} isComputed={isComputed} isFieldDisabled={isFieldDisabled} formNoValidate autoComplete="off" onKeyDown={handleKeyDown} onFocus={handleOnFocus} {...props} ref={ref} {...isFieldDisabled && {
    disabled: true,
    readOnly: true
  }} />;
});