import { FC } from 'react';
import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';
import { ColorField } from '../../../../base';
import { useFieldValues, useColorField, useSetFieldValue } from '../../../../hooks';
const FIELD_KEY = DDPPrimitiveFieldsKeys.borderColor;
export const BorderColorField: FC = () => {
  const {
    value,
    initialValue
  } = useFieldValues(FIELD_KEY);
  const {
    saveValue
  } = useSetFieldValue(FIELD_KEY);
  const props = useColorField({
    value,
    initialValue,
    fieldName: FIELD_KEY,
    saveChanges: saveValue
  });
  return <ColorField icon="DDP_FIELD_COLOR" tooltipTitle="Border color" {...props} data-sentry-element="ColorField" data-sentry-component="BorderColorField" data-sentry-source-file="BorderColorField.tsx" />;
};