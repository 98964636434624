/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const CompositeIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="12" height="12" attr={{
  viewBox: '0 0 12 12'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M0.5 3C0.5 1.61929 1.61929 0.5 3 0.5H9C10.3807 0.5 11.5 1.61929 11.5 3V9C11.5 10.3807 10.3807 11.5 9 11.5H3C1.61929 11.5 0.5 10.3807 0.5 9V3ZM3 1.5C2.17157 1.5 1.5 2.17157 1.5 3V5.5H5.5V1.5H3ZM6.5 1.5V5.5H10.5V3C10.5 2.17157 9.82843 1.5 9 1.5H6.5ZM10.5 6.5H6.5V10.5H9C9.82843 10.5 10.5 9.82843 10.5 9V6.5ZM5.5 10.5V6.5H1.5V9C1.5 9.82843 2.17157 10.5 3 10.5H5.5Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="CompositeIcon" data-sentry-source-file="CompositeIcon.tsx" />;