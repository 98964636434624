/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const DisplayNoneDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M12.4339 3.3841C12.2386 3.18884 11.922 3.18884 11.7268 3.3841L3.20206 11.9088C3.00679 12.1041 3.00679 12.4207 3.20206 12.6159C3.39732 12.8112 3.7139 12.8112 3.90916 12.6159L12.4339 4.09121C12.6291 3.89595 12.6291 3.57936 12.4339 3.3841Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M8 4C8.66104 4 9.26745 4.10924 9.81925 4.29162L8.57299 5.53788C8.38778 5.51312 8.1968 5.5 8 5.5C6.53814 5.5 5.39717 6.22416 4.56066 7.06066C4.23206 7.38926 3.96649 7.72038 3.76622 8C3.96649 8.27962 4.23206 8.61074 4.56066 8.93934C4.66126 9.03994 4.76625 9.13891 4.87568 9.23519L3.81335 10.2975C2.60445 9.2089 2 8 2 8C2 8 4 4 8 4Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M5.91259 11.6125C6.5343 11.8518 7.23011 12 8 12C12 12 14 8 14 8C14 8 13.3307 6.66142 11.9921 5.53296L10.9269 6.59817C11.1089 6.7454 11.2797 6.90098 11.4393 7.06066C11.7679 7.38926 12.0335 7.72038 12.2338 8C12.0335 8.27962 11.7679 8.61074 11.4393 8.93934C10.6028 9.77585 9.46186 10.5 8 10.5C7.69116 10.5 7.39663 10.4677 7.11628 10.4088L5.91259 11.6125Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="DisplayNoneDDPIcon" data-sentry-source-file="DisplayNoneDDPIcon.tsx" />;