import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { WidthField } from './WidthField';
import { MaxWidthField } from './MaxWidthField';
import { MinWidthField } from './MinWidthField';
export const Width: FC = () => {
  const {
    shouldRender
  } = useSubModule(DDPSubModulesKeys.width);
  if (!shouldRender) return null;
  return <SubModule title="Width" data-sentry-element="SubModule" data-sentry-component="Width" data-sentry-source-file="Width.tsx">
      <WidthField data-sentry-element="WidthField" data-sentry-source-file="Width.tsx" />
      <MaxWidthField data-sentry-element="MaxWidthField" data-sentry-source-file="Width.tsx" />
      <MinWidthField data-sentry-element="MinWidthField" data-sentry-source-file="Width.tsx" />
    </SubModule>;
};