/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const ConstraintsRightDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M5.75 10.75C5.33579 10.75 5 10.4142 5 10V6C5 5.58579 5.33579 5.25 5.75 5.25C6.16421 5.25 6.5 5.58579 6.5 6V7.25H9.25V3.5V2H9.75C10.3023 2 10.75 2.44772 10.75 3V13C10.75 13.5523 10.3023 14 9.75 14H9.25V12.5V8.75H6.5V10C6.5 10.4142 6.16421 10.75 5.75 10.75Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="ConstraintsRightDDPIcon" data-sentry-source-file="ConstraintsRightDDPIcon.tsx" />;