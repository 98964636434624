import { CSSProperties, FC, ReactNode, useRef } from 'react';
import { useHover } from 'usehooks-ts';
import { mergeRefs } from 'react-merge-refs';
import { Svg } from '@jux/react';
import { Tooltip } from '@jux/ui/components';
import { formatTimeDistance } from '@jux/ui/utils/formatTimeDistance';
import { CANVAS_ITEMS, useDragItem } from '@jux/ui/components/editor/hooks';
import { AssetData } from '@jux/data-entities';
import { convertAssetNameToComponentName } from '@jux/ui/utils/assets';
import { TEST_ID_ASSET_PREVIEW_ITEM } from '@jux/constants';
import { MuiGrid } from '../../mui';
import { ComponentPreviewItemActionsMenu } from '../../previewItem/ComponentPreviewItemActionsMenu';
import * as S from './AssetPreviewItem.style';
import { getAssetPreviewItemElementId } from './AssetPreviewItem.utils';
export const AssetPreviewItem: FC<{
  assetId: string;
  assetData: AssetData;
  actions?: ReactNode;
  onAssetClick: () => void;
  isDraggable?: boolean;
  selected?: boolean;
}> = ({
  assetData,
  assetId,
  actions,
  onAssetClick,
  isDraggable,
  selected
}) => {
  const {
    name,
    content,
    createdAt,
    initialStyles
  } = assetData;

  // used to control the drag mechanism of the component
  const {
    dragRef: assetPreviewItemWrapperRef,
    dragPreviewRef: assetItemRef,
    isDragging
  } = useDragItem(CANVAS_ITEMS.Asset, {
    assetData,
    assetId,
    type: CANVAS_ITEMS.Asset
  });
  const assetPreviewItemWrapperHoverRef = useRef<HTMLDivElement>(null);
  const mergedRefs = mergeRefs([assetPreviewItemWrapperHoverRef,
  // @ts-expect-error - react-dnd has type issues with React 18.3.1
  assetPreviewItemWrapperRef]);
  const isPreviewItemHovered = useHover(assetPreviewItemWrapperHoverRef);
  const actionsMenuRef = useRef<HTMLDivElement>(null);
  const isActionsMenuHovered = useHover(actionsMenuRef);
  return <Tooltip data-id="asset-preview-item-tooltip"
  // we are converting the asset name to component name cause some current assets still have .svg in their name
  header={convertAssetNameToComponentName(name ?? '')} content={createdAt ? `Uploaded ${formatTimeDistance(createdAt)}` : ''} disabled={isDragging} visible={isPreviewItemHovered && !isActionsMenuHovered} data-sentry-element="Tooltip" data-sentry-component="AssetPreviewItem" data-sentry-source-file="AssetPreviewItem.tsx">
      <S.AssetPreviewItemWrapper item ref={mergedRefs} id={getAssetPreviewItemElementId(assetId)} onClick={onAssetClick} isDraggable={isDraggable} selected={selected} isDragging={isDragging} data-testid={TEST_ID_ASSET_PREVIEW_ITEM} data-sentry-element="unknown" data-sentry-source-file="AssetPreviewItem.tsx">
        {actions && <MuiGrid ref={actionsMenuRef} display="contents" onClick={e => e.stopPropagation()}>
            <ComponentPreviewItemActionsMenu isVisible={isPreviewItemHovered}>
              {actions}
            </ComponentPreviewItemActionsMenu>
          </MuiGrid>}
        {/* @ts-expect-error - react-dnd has type issues with React 18.3.1 */}
        <S.AssetPreviewItemContent item ref={assetItemRef} data-sentry-element="unknown" data-sentry-source-file="AssetPreviewItem.tsx">
          <Svg content={content} style={initialStyles as CSSProperties} data-sentry-element="Svg" data-sentry-source-file="AssetPreviewItem.tsx" />
        </S.AssetPreviewItemContent>
      </S.AssetPreviewItemWrapper>
    </Tooltip>;
};