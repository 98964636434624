import { FC, useMemo, useRef } from 'react';
import { BaseFieldLayout } from '../common';
import { DimensionTokenOptionRenderer } from './DimensionTokenOptionRenderer';
import { DDP_DIMENSION_FIELD_CLASS_NAME, DDP_DIMENSION_FIELD_NAME_PREFIX } from './Dimension.constant';
import { DimensionFieldProps } from './DimensionField.interface';
import { DimensionFieldContent } from './DimensionFieldContent';
export const DimensionField: FC<DimensionFieldProps> = ({
  value,
  isFieldDisabled,
  unit,
  isComputed,
  icon,
  onUnitChange,
  tooltipTitle,
  isMultiDirectional,
  rawValue,
  activateUnit,
  tokensProps,
  inputProps,
  disablePercentValue
}) => {
  const fieldRef = useRef<HTMLDivElement>(null);
  const nameWithPrefix = useMemo(() => `${DDP_DIMENSION_FIELD_NAME_PREFIX}${inputProps.inputRegister().name}`, [inputProps]);
  return <BaseFieldLayout icon={icon} isComputed={isComputed} isFieldDisabled={isFieldDisabled} tooltipTitle={tooltipTitle} className={DDP_DIMENSION_FIELD_CLASS_NAME} htmlFor={nameWithPrefix} tokensProps={tokensProps} renderTokenOption={DimensionTokenOptionRenderer} fieldRef={fieldRef} data-sentry-element="BaseFieldLayout" data-sentry-component="DimensionField" data-sentry-source-file="DimensionField.tsx">
      <DimensionFieldContent htmlForTarget={nameWithPrefix} isComputed={isComputed} value={value} isFieldDisabled={isFieldDisabled} unit={unit} rawValue={rawValue} activateUnit={activateUnit} onUnitChange={onUnitChange} isMultiDirectional={isMultiDirectional} inputProps={inputProps} tokensProps={tokensProps} disablePercentValue={disablePercentValue} data-sentry-element="DimensionFieldContent" data-sentry-source-file="DimensionField.tsx" />
    </BaseFieldLayout>;
};