import { FC } from 'react';
import { MuiGrid, styled } from '@jux/ui/components/common/mui';
import { IconButton, Icons, Typography } from '../components';
import { ToastComponentProps, ToastType } from './toast.interface';
import 'react-toastify/dist/ReactToastify.css';
import './Toast.style.css';
const ToastWrapper = styled(MuiGrid)(({
  theme
}) => ({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: theme.drimz.palette.background.default,
  border: '1px solid',
  borderColor: theme.drimz.palette.divider.hover,
  borderRadius: theme.drimz.size.borderRadius.main,
  color: theme.drimz.palette.text.primary,
  maxWidth: theme.drimz.size.common.toast.maxWidth,
  width: 'fit-content'
}));
export const Toast: FC<ToastComponentProps> = ({
  message,
  type,
  closeToast
}) => {
  const isAction = type === ToastType.Action;
  const isSuccess = type === ToastType.Success;
  return <ToastWrapper container sx={{
    cursor: isAction ? 'auto' : 'default',
    padding: isAction ? '16px 42px 16px 20px' : '14px 16px'
  }} data-sentry-element="ToastWrapper" data-sentry-component="Toast" data-sentry-source-file="Toast.tsx">
      {isAction && <MuiGrid sx={{
      position: 'absolute',
      right: '13px',
      top: '12px'
    }}>
          <IconButton onClick={closeToast} variant="icon" size="small" icon="CLOSE" />
        </MuiGrid>}

      {!isAction && <MuiGrid item>
          <Icons variant={isSuccess ? 'STATUS_SUCCESS' : 'STATUS_FAIL'} color={theme => theme.drimz.palette.text.primary} />
        </MuiGrid>}
      <MuiGrid item data-sentry-element="MuiGrid" data-sentry-source-file="Toast.tsx">
        <Typography variant="label-s-medium" color={theme => theme.drimz.palette.text.primary} data-sentry-element="Typography" data-sentry-source-file="Toast.tsx">
          {message}
        </Typography>
      </MuiGrid>
    </ToastWrapper>;
};