import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { useSelectedNodeComponent } from '@jux/ui/components/editor/hooks';
import { NodeType } from '@jux/data-entities';
import { PropertiesActions } from './PropertiesActions';
import { PropertiesFields } from './PropertiesFields';
export const PropertiesPanelContent: FC = () => {
  const {
    component,
    sourceComponent
  } = useSelectedNodeComponent();
  let componentToUse = component;
  if (component?.type === NodeType.VARIANTS_GROUP) {
    componentToUse = sourceComponent;
  }
  return <MuiGrid container item data-sentry-element="MuiGrid" data-sentry-component="PropertiesPanelContent" data-sentry-source-file="PropertiesPanelContent.tsx">
      <PropertiesFields componentId={componentToUse?.id} tagName={componentToUse?.tagName} data-sentry-element="PropertiesFields" data-sentry-source-file="PropertiesPanelContent.tsx" />
      <PropertiesActions componentId={componentToUse?.id} tagName={componentToUse?.tagName} data-sentry-element="PropertiesActions" data-sentry-source-file="PropertiesPanelContent.tsx" />
    </MuiGrid>;
};