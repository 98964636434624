/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '../core/CustomIcon';
export const AssetLayerIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M8.29741 7.4978C8.29741 8.32501 7.62683 8.99559 6.79962 8.99559C5.97241 8.99559 5.30182 8.32501 5.30182 7.4978C5.30182 6.67059 5.97241 6 6.79962 6C7.62683 6 8.29741 6.67059 8.29741 7.4978Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M3 4C3 3.44772 3.44772 3 4 3H12C12.5523 3 13 3.44772 13 4V12C13 12.5523 12.5523 13 12 13H4C3.44772 13 3 12.5523 3 12V4ZM4.5 11.5V4.5H11.5V9.1966L9.93714 7.97939L6.72267 11.5H4.5ZM8.75087 11.5H11.5V11.0951L10.1094 10.0121L8.75087 11.5Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="AssetLayerIcon" data-sentry-source-file="AssetLayerIcon.tsx" />;