import { get } from 'lodash';
import { Path } from 'react-hook-form';
import { z } from 'zod';
import { TokenFormProps } from '@jux/ui/components/tokens/token-drawer/forms/types';
import { useZodForm } from '@jux/ui/hooks/useZodForm';
export const useInitialValues = <T extends z.ZodType, V extends TokenFormProps<T>['initialValues'] = TokenFormProps<T>['initialValues']>(paths: Array<Path<V>>, {
  form,
  initialValues
}: {
  form: ReturnType<typeof useZodForm>;
  initialValues: V;
}) => paths.map(p => get(form.formState.dirtyFields, p) ? form.watch(p) : get(initialValues, p) || '');