/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const RTLDirectionDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    d: 'M14 3.5C14 3.22386 13.7761 3 13.5 3C13.2239 3 13 3.22386 13 3.5V12.5C13 12.7761 13.2239 13 13.5 13C13.7761 13 14 12.7761 14 12.5V3.5Z',
    fill: 'currentColor'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    d: 'M5.46967 11.5303C5.76256 11.8232 6.23744 11.8232 6.53033 11.5303C6.82322 11.2374 6.82322 10.7626 6.53033 10.4697L4.81066 8.75L10.5 8.75C10.9142 8.75 11.25 8.41421 11.25 8C11.25 7.58579 10.9142 7.25 10.5 7.25H4.81066L6.53033 5.53033C6.82322 5.23744 6.82322 4.76256 6.53033 4.46967C6.23744 4.17678 5.76256 4.17678 5.46967 4.46967L2.46967 7.46967C2.17678 7.76256 2.17678 8.23744 2.46967 8.53033L5.46967 11.5303Z',
    fill: 'currentColor'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="RTLDirectionDDPIcon" data-sentry-source-file="RTLDirectionDDPIcon.tsx" />;