import { FC } from 'react';
import { Dimensions } from '@jux/canjux/core';
import { Typography } from '@jux/ui/components';
import { palette } from '@jux/ui/theme/palette';
export type NodeToolbarProps = {
  nodeDimensions: Dimensions;
  // used to calculate the size of the toolbar and keep it the same size between zoom levels
  zoomLevel: number;
};
export const NodeSizeToolbar: FC<NodeToolbarProps> = ({
  nodeDimensions,
  zoomLevel
}) => {
  function getDisplayDimensions(num: number) {
    const displayNum = Number.isInteger(num) ? num.toString() : num.toFixed(2);

    // We sometime get a number like 100.00000000000001, so we round it
    return displayNum.endsWith('.00') ? Number(displayNum).toFixed(0) : displayNum;
  }
  return <div style={{
    zIndex: 5,
    position: 'absolute',
    width: 'auto',
    height: 'auto',
    left: `50%`,
    top: `calc(100% + ${4 / zoomLevel}px)`,
    transform: `translateX(-50%)`
  }} data-sentry-component="NodeSizeToolbar" data-sentry-source-file="NodeSizeToolbar.tsx">
      <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '4px 6px',
      backgroundColor: palette.naturals800,
      borderRadius: '4px',
      width: 'auto',
      cursor: 'default',
      height: 'auto',
      zIndex: 4,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      pointerEvents: 'none',
      transform: `scale(${1 / zoomLevel})`,
      transformOrigin: 'center top',
      userSelect: 'none'
    }}>
        <Typography color={palette.naturals0} variant="label-xs-medium" sx={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        userSelect: 'none',
        // This is a hack to prevent the text from being selected
        // when dragging/selecting in the canvas
        ':after': {
          content: `"${getDisplayDimensions(nodeDimensions.width)} x ${getDisplayDimensions(nodeDimensions.height)}"`
        }
      }} data-sentry-element="Typography" data-sentry-source-file="NodeSizeToolbar.tsx" />
      </div>
    </div>;
};