/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconType, IconBaseProps } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const ChipElementIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M4 8C4 7.44772 4.44772 7 5 7H8C8.55228 7 9 7.44772 9 8C9 8.55228 8.55228 9 8 9H5C4.44772 9 4 8.55228 4 8Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M11 7C10.4477 7 10 7.44772 10 8C10 8.55228 10.4477 9 11 9C11.5523 9 12 8.55228 12 8C12 7.44772 11.5523 7 11 7Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M5 4C2.79086 4 1 5.79086 1 8C1 10.2091 2.79086 12 5 12H11C13.2091 12 15 10.2091 15 8C15 5.79086 13.2091 4 11 4H5ZM11 5.5H5C3.61929 5.5 2.5 6.61929 2.5 8C2.5 9.38071 3.61929 10.5 5 10.5H11C12.3807 10.5 13.5 9.38071 13.5 8C13.5 6.61929 12.3807 5.5 11 5.5Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="ChipElementIcon" data-sentry-source-file="ChipElementIcon.tsx" />;