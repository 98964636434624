/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const TextElementIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M13 3.5C13 3.22386 12.7761 3 12.5 3H3.5C3.22386 3 3 3.22386 3 3.5V4.5C3 4.77614 3.22386 5 3.5 5L7 5V12.5C7 12.7761 7.22386 13 7.5 13H8.5C8.77614 13 9 12.7761 9 12.5V5L12.5 5C12.7761 5 13 4.77614 13 4.5V3.5Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="TextElementIcon" data-sentry-source-file="TextElementIcon.tsx" />;