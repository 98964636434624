import { useCallback } from 'react';
import { useOpenSubmitModal } from '@jux/ui/components';
import { SegmentEventSourcePage } from '@jux/data-access/analytics/track-events-properties';
import { InviteMembersContent } from './InviteMembersContent';
import { useTrackInviteMembers } from './useTrackInviteMembers';
import { useInviteMembersForm } from './useInviteMembersForm';
export const useOpenInviteMembersModal = (sourcePage: SegmentEventSourcePage) => {
  const {
    openSubmitModal,
    closeSubmitModal
  } = useOpenSubmitModal();
  const {
    trackOpenInviteMembers
  } = useTrackInviteMembers(sourcePage);
  const {
    registerEmailField,
    discardEmailChanges,
    getEmailValue,
    setEmailValue
  } = useInviteMembersForm();
  const openInviteMembersModal = useCallback(() => {
    trackOpenInviteMembers();
    openSubmitModal({
      title: 'It’s better together',
      children: <InviteMembersContent sourcePage={sourcePage} registerEmailField={registerEmailField} getEmailValue={getEmailValue} setEmailValue={setEmailValue} closeSubmitModal={closeSubmitModal} />,
      onClose: discardEmailChanges
    });
  }, [closeSubmitModal, discardEmailChanges, getEmailValue, openSubmitModal, registerEmailField, setEmailValue, sourcePage, trackOpenInviteMembers]);
  return {
    openInviteMembersModal
  };
};