import { FC } from 'react';
import { Icons, IconsVariantsType, Typography } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { TOKENS_TOKEN_TYPE_TEXT_COLOR } from '@jux/ui/theme/palette';
export const TokenTypeNavItem: FC<{
  icon: IconsVariantsType;
  title: string;
  onClick?: () => void;
  isSelected?: boolean;
}> = ({
  icon,
  title,
  onClick,
  isSelected
}) => <MuiGrid item container onClick={onClick} sx={{
  width: 'fit-content',
  cursor: 'pointer',
  flexDirection: 'column'
}} data-sentry-element="MuiGrid" data-sentry-component="TokenTypeNavItem" data-sentry-source-file="TokenTypeNavItem.tsx">
    <MuiGrid item display="flex" alignItems="center" gap="8px" data-sentry-element="MuiGrid" data-sentry-source-file="TokenTypeNavItem.tsx">
      <Icons variant={icon} sx={{
      color: theme => isSelected ? theme.drimz.palette.primary.main : theme.drimz.palette.text.secondary
    }} data-sentry-element="Icons" data-sentry-source-file="TokenTypeNavItem.tsx" />
      <Typography variant={isSelected ? 'body-bold' : 'body-regular'} sx={{
      color: theme => isSelected ? theme.drimz.palette.primary.main : TOKENS_TOKEN_TYPE_TEXT_COLOR,
      transition: theme => theme.drimz.transitions.all
    }} data-sentry-element="Typography" data-sentry-source-file="TokenTypeNavItem.tsx">
        {title}
      </Typography>
    </MuiGrid>
  </MuiGrid>;