import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { ZIndexField } from './ZIndexField';
export const ZIndex: FC = () => {
  const {
    shouldRender
  } = useSubModule(DDPSubModulesKeys.zIndex);
  if (!shouldRender) return null;
  return <SubModule title="Z index" data-sentry-element="SubModule" data-sentry-component="ZIndex" data-sentry-source-file="ZIndex.tsx">
      <ZIndexField data-sentry-element="ZIndexField" data-sentry-source-file="ZIndex.tsx" />
    </SubModule>;
};