import { FC, useRef, useState } from 'react';
import { Background, Canjux } from '@jux/canjux/core';
import { ResizablePanel } from '@jux/ui/components';
import { useTheme } from '@jux/ui/components/common/mui';
import { CANVAS_BACKGROUND_COLOR } from '@jux/ui/theme/palette';
import { useCanvasDropItem } from '../../hooks';
import { DDP, LayersPanel, Magnifier, PropertiesPanel } from '../panels';
import { CanvasProps } from './Canvas.interface';
import * as S from './Canvas.style';
import { Layout } from './Layout';
export const Canvas: FC<CanvasProps> = ({
  defaultViewport,
  isLive,
  fitView
}) => {
  const theme = useTheme();
  const canvasLayoutRef = useRef<HTMLDivElement>(null);
  const {
    dropRef: canjuxRef
  } = useCanvasDropItem(canvasLayoutRef);
  const [ddpPanelWidth, setDDPPanelWidth] = useState<number>(theme.drimz.size.editor.ddp.width);
  const [layersPanelWidth, setLayerPanelWidth] = useState<number>(theme.drimz.size.editor.layers.width);
  return <Layout ref={canvasLayoutRef} data-sentry-element="Layout" data-sentry-component="Canvas" data-sentry-source-file="Canvas.tsx">
      <Canjux ref={canjuxRef} minZoom={0.2} maxZoom={20} defaultViewport={defaultViewport} disableNodesInteraction={isLive} snapToGrid snapGrid={{
      x: 1,
      y: 1
    }} fitView={fitView} data-sentry-element="Canjux" data-sentry-source-file="Canvas.tsx">
        <Background color={CANVAS_BACKGROUND_COLOR} size={100} gap={[1, 1]} data-sentry-element="Background" data-sentry-source-file="Canvas.tsx" />

        <S.StyledPanelForProperties position="top-right" ddpPanelWidth={ddpPanelWidth} sx={{
        zIndex: 6
      }} data-sentry-element="unknown" data-sentry-source-file="Canvas.tsx">
          <PropertiesPanel data-sentry-element="PropertiesPanel" data-sentry-source-file="Canvas.tsx" />
        </S.StyledPanelForProperties>

        <S.Panel position="bottom-center" sx={{
        marginBottom: '4px'
      }} data-sentry-element="unknown" data-sentry-source-file="Canvas.tsx">
          <Magnifier data-sentry-element="Magnifier" data-sentry-source-file="Canvas.tsx" />
        </S.Panel>

        <ResizablePanel position="top-right" fullHeight width={ddpPanelWidth} setWidth={setDDPPanelWidth} minWidth={theme.drimz.size.editor.ddp.minWidth} maxWidth={theme.drimz.size.editor.ddp.maxWidth} enableResizeFrom={{
        left: true
      }} data-sentry-element="ResizablePanel" data-sentry-source-file="Canvas.tsx">
          <DDP data-sentry-element="DDP" data-sentry-source-file="Canvas.tsx" />
        </ResizablePanel>

        <ResizablePanel position="top-left" fullHeight width={layersPanelWidth} setWidth={setLayerPanelWidth} minWidth={theme.drimz.size.editor.layers.minWidth} maxWidth={theme.drimz.size.editor.layers.maxWidth} enableResizeFrom={{
        right: true
      }} data-sentry-element="ResizablePanel" data-sentry-source-file="Canvas.tsx">
          <LayersPanel data-sentry-element="LayersPanel" data-sentry-source-file="Canvas.tsx" />
        </ResizablePanel>
      </Canjux>
    </Layout>;
};