import { FC } from 'react';
import { IconButton, Typography } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { useTokenDrawer } from '../useTokenDrawer';
export const TokenDrawerHeader: FC<{
  title: string;
}> = ({
  title
}) => {
  const {
    close
  } = useTokenDrawer();
  return <MuiGrid height={theme => theme.drimz.size.layout.tokenDrawer.header.height} p="15px 20px" display="flex" justifyContent="space-between" alignItems="center" sx={{
    borderBottom: 1,
    borderColor: theme => theme.drimz.palette.divider.primary
  }} data-sentry-element="MuiGrid" data-sentry-component="TokenDrawerHeader" data-sentry-source-file="TokenDrawerHeader.tsx">
      <Typography variant="label-s-regular" data-sentry-element="Typography" data-sentry-source-file="TokenDrawerHeader.tsx">{title}</Typography>
      <IconButton variant="icon" tooltipTitle="Close" onClick={close} icon="CLOSE" data-sentry-element="IconButton" data-sentry-source-file="TokenDrawerHeader.tsx" />
    </MuiGrid>;
};