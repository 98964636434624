import { FC, useRef } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { Icons, TooltipTypography, Typography, TypographyElement } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { EDITOR_DDP_RELATIVE_TO_BACKGROUND_COLOR, EDITOR_DDP_RELATIVE_TO_BORDER_COLOR } from '@jux/ui/theme/palette';
import { useMaxWidthRelativeToRef } from '@jux/ui/hooks';
import { withPx } from '@jux/ui/utils/css';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { useRelativeToComponent } from './useRelativeToComponent';
import { useInteractionsRelativeToComponent } from './useInteractionsRelativeToComponent';
const ICON_SIZE = 16;
const ICON_LABEL_GAP = 8;
const CALC_TOTAL = ICON_SIZE + ICON_LABEL_GAP;
export const RelativeTo: FC = () => {
  const {
    shouldRender
  } = useSubModule(DDPSubModulesKeys.relativeTo);
  const {
    relativeToComponent
  } = useRelativeToComponent();
  const labelRef = useRef<TypographyElement>(null);
  const componentNameMaxWidth = useMaxWidthRelativeToRef({
    relativeRef: labelRef,
    spacing: 3
  });
  const {
    handleMouseEnter,
    handleMouseLeave,
    handleClick
  } = useInteractionsRelativeToComponent(relativeToComponent.componentId);
  if (!shouldRender) return null;
  return <SubModule title="" renderInline renderFieldToMax data-sentry-element="SubModule" data-sentry-component="RelativeTo" data-sentry-source-file="RelativeTo.tsx">
      <MuiGrid sx={{
      gap: withPx(ICON_LABEL_GAP),
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      padding: '0 8px',
      backgroundColor: EDITOR_DDP_RELATIVE_TO_BACKGROUND_COLOR,
      border: '1px solid',
      borderColor: EDITOR_DDP_RELATIVE_TO_BORDER_COLOR,
      height: theme => theme.drimz.size.editor.ddp.relativeTo.height,
      width: '100%'
    }} data-sentry-element="MuiGrid" data-sentry-source-file="RelativeTo.tsx">
        <MuiGrid item data-sentry-element="MuiGrid" data-sentry-source-file="RelativeTo.tsx">
          <Icons variant="DDP_FIELD_RELATIVE_TO" color={theme => theme.drimz.palette.text.secondary} data-sentry-element="Icons" data-sentry-source-file="RelativeTo.tsx" />
        </MuiGrid>
        <MuiGrid item sx={{
        alignItems: 'center',
        display: 'flex',
        gap: '3px',
        maxWidth: `calc(100% - ${withPx(CALC_TOTAL)})`
      }} data-sentry-element="MuiGrid" data-sentry-source-file="RelativeTo.tsx">
          <Typography ref={labelRef} variant="label-s-medium" color={theme => theme.drimz.palette.text.secondary} whiteSpace="nowrap" data-sentry-element="Typography" data-sentry-source-file="RelativeTo.tsx">
            Relative to
          </Typography>
          <TooltipTypography variant={relativeToComponent.isRelativeToRoot ? 'label-s-medium' : 'label-s-bold'} color={theme => theme.drimz.palette.text.secondary} sx={{
          width: componentNameMaxWidth,
          cursor: relativeToComponent.isRelativeToRoot ? 'auto' : 'pointer'
        }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick} data-sentry-element="TooltipTypography" data-sentry-source-file="RelativeTo.tsx">
            {relativeToComponent.componentName}
          </TooltipTypography>
        </MuiGrid>
      </MuiGrid>
    </SubModule>;
};