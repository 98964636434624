import { FC } from 'react';
import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';
import { DimensionField } from '../../../../base';
import { useDimensionField, useFieldValues, useSetFieldValue } from '../../../../hooks';
const FIELD_KEY = DDPPrimitiveFieldsKeys.height;
export const HeightField: FC = () => {
  const {
    value,
    initialValue
  } = useFieldValues(FIELD_KEY);
  const {
    saveValue: setFieldValue
  } = useSetFieldValue(FIELD_KEY);
  const props = useDimensionField({
    value,
    initialValue,
    saveChanges: setFieldValue,
    fieldName: FIELD_KEY,
    options: {
      supportAutoValue: true
    }
  });
  return <DimensionField icon="DDP_FIELD_HEIGHT" tooltipTitle="Height" {...props} data-sentry-element="DimensionField" data-sentry-component="HeightField" data-sentry-source-file="HeightField.tsx" />;
};