/**
 *  THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 */

/* eslint-disable */
// @ts-nocheck

import { createDrimz, type PropsOf } from '@jux/dui';
import React, { FC, forwardRef } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType } from '@jux/data-entities';
import { JuxReactElements } from '@jux/react';
const {
  styled
} = createDrimz();
type AddToLibraryModal_header_header_root_Props = PropsOf<typeof JuxReactElements.JuxText>;
const AddToLibraryModal_header_header_root: FC<AddToLibraryModal_header_header_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxText {...props} ref={ref} />);
AddToLibraryModal_header_header_root.displayName = 'AddToLibraryModal_header_header_root';
AddToLibraryModal_header_header_root.defaultProps = {
  rootElement: styled('p', {
    styles: {
      root: {
        color: '#000000',
        width: 'auto',
        height: 'auto',
        display: 'inline-block',
        fontSize: '{typography.semantic.header_2.fontSize}',
        fontFamily: '{typography.semantic.header_2.fontFamily}',
        fontWeight: '{typography.semantic.header_2.fontWeight}',
        lineHeight: '{typography.semantic.header_2.lineHeight}',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        letterSpacing: '{typography.semantic.header_2.letterSpacing}'
      }
    }
  }) as AddToLibraryModal_header_header_root_Props['rootElement']
};
export type AddToLibraryModal_header_header_Props = AddToLibraryModal_header_header_root_Props;
export const AddToLibraryModal_header_header: FC<AddToLibraryModal_header_header_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxText>, AddToLibraryModal_header_header_Props>((props, ref) => <AddToLibraryModal_header_header_root {...props} ref={ref} />);
AddToLibraryModal_header_header.displayName = 'AddToLibraryModal_header_header';
AddToLibraryModal_header_header.defaultProps = {
  text: 'Add 4 components to library'
};
import { IconButton, type IconButton_Props } from '../';
type AddToLibraryModal_header_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const AddToLibraryModal_header_root: FC<AddToLibraryModal_header_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
AddToLibraryModal_header_root.displayName = 'AddToLibraryModal_header_root';
AddToLibraryModal_header_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        width: '461px',
        height: '26px',
        display: 'flex',
        padding: '0px 0px 0px 0px',
        alignItems: 'stretch',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF'
      }
    }
  }) as AddToLibraryModal_header_root_Props['rootElement']
};
export type AddToLibraryModal_header_Props = AddToLibraryModal_header_root_Props & {
  AddToLibraryModal_header_header_Props?: AddToLibraryModal_header_header_Props;
  IconButton_Props?: IconButton_Props;
};
export const AddToLibraryModal_header: FC<AddToLibraryModal_header_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, AddToLibraryModal_header_Props>(({
  AddToLibraryModal_header_header_Props,
  IconButton_Props,
  ...props
}, ref) => <AddToLibraryModal_header_root {...props} ref={ref} data-jux-context="68307a43-909f-4e72-9c63-901684f67a45">
        {props.children || <>
            <AddToLibraryModal_header_header {...AddToLibraryModal_header_header_Props} data-jux-context="20621f74-bd3b-4399-b799-2c2197dbb6f5" />
            <IconButton disabled={false} hierarchy={'secondary'} {...IconButton_Props} />
          </>}
      </AddToLibraryModal_header_root>);
AddToLibraryModal_header.displayName = 'AddToLibraryModal_header';
AddToLibraryModal_header.defaultProps = {};
type AddToLibraryModal_body_main_text_root_Props = PropsOf<typeof JuxReactElements.JuxText>;
const AddToLibraryModal_body_main_text_root: FC<AddToLibraryModal_body_main_text_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxText {...props} ref={ref} />);
AddToLibraryModal_body_main_text_root.displayName = 'AddToLibraryModal_body_main_text_root';
AddToLibraryModal_body_main_text_root.defaultProps = {
  rootElement: styled('p', {
    styles: {
      root: {
        color: '#000000',
        width: '475px',
        height: '55px',
        display: 'inline-block',
        fontSize: '{typography.core.14_regular.fontSize}',
        fontFamily: '{typography.core.14_regular.fontFamily}',
        fontWeight: '{typography.core.14_regular.fontWeight}',
        lineHeight: '{typography.core.14_regular.lineHeight}',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        letterSpacing: '{typography.core.14_regular.letterSpacing}'
      }
    }
  }) as AddToLibraryModal_body_main_text_root_Props['rootElement']
};
export type AddToLibraryModal_body_main_text_Props = AddToLibraryModal_body_main_text_root_Props;
export const AddToLibraryModal_body_main_text: FC<AddToLibraryModal_body_main_text_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxText>, AddToLibraryModal_body_main_text_Props>((props, ref) => <AddToLibraryModal_body_main_text_root {...props} ref={ref} />);
AddToLibraryModal_body_main_text.displayName = 'AddToLibraryModal_body_main_text';
AddToLibraryModal_body_main_text.defaultProps = {
  text: 'By adding ‘component-name’ to the library, the following source components of its 3 nested instances will be added as well:'
};
type AddToLibraryModal_body_more_info_root_Props = PropsOf<typeof JuxReactElements.JuxText>;
const AddToLibraryModal_body_more_info_root: FC<AddToLibraryModal_body_more_info_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxText {...props} ref={ref} />);
AddToLibraryModal_body_more_info_root.displayName = 'AddToLibraryModal_body_more_info_root';
AddToLibraryModal_body_more_info_root.defaultProps = {
  rootElement: styled('p', {
    styles: {
      root: {
        color: '#000000',
        width: 'auto',
        height: 'auto',
        display: 'inline-block',
        fontSize: '{typography.core.14_regular.fontSize}',
        fontFamily: '{typography.core.14_regular.fontFamily}',
        fontWeight: '{typography.core.14_regular.fontWeight}',
        lineHeight: '{typography.core.14_regular.lineHeight}',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        letterSpacing: '{typography.core.14_regular.letterSpacing}'
      }
    }
  }) as AddToLibraryModal_body_more_info_root_Props['rootElement']
};
export type AddToLibraryModal_body_more_info_Props = AddToLibraryModal_body_more_info_root_Props;
export const AddToLibraryModal_body_more_info: FC<AddToLibraryModal_body_more_info_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxText>, AddToLibraryModal_body_more_info_Props>((props, ref) => <AddToLibraryModal_body_more_info_root {...props} ref={ref} />);
AddToLibraryModal_body_more_info.displayName = 'AddToLibraryModal_body_more_info';
AddToLibraryModal_body_more_info.defaultProps = {
  text: '‘[component-name]‘, ‘[component-name]‘, ‘[component-name]’'
};
type AddToLibraryModal_body_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const AddToLibraryModal_body_root: FC<AddToLibraryModal_body_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
AddToLibraryModal_body_root.displayName = 'AddToLibraryModal_body_root';
AddToLibraryModal_body_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        padding: '0px 0px 0px 0px',
        alignItems: 'flex-start',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF'
      }
    }
  }) as AddToLibraryModal_body_root_Props['rootElement']
};
export type AddToLibraryModal_body_Props = AddToLibraryModal_body_root_Props & {
  AddToLibraryModal_body_main_text_Props?: AddToLibraryModal_body_main_text_Props;
  AddToLibraryModal_body_more_info_Props?: AddToLibraryModal_body_more_info_Props;
};
export const AddToLibraryModal_body: FC<AddToLibraryModal_body_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, AddToLibraryModal_body_Props>(({
  AddToLibraryModal_body_main_text_Props,
  AddToLibraryModal_body_more_info_Props,
  ...props
}, ref) => <AddToLibraryModal_body_root {...props} ref={ref} data-jux-context="67f329ec-e4d1-4f2c-a309-ed254218ba90">
        {props.children || <>
            <AddToLibraryModal_body_main_text {...AddToLibraryModal_body_main_text_Props} data-jux-context="6f0a9fd5-7a54-4d29-9cea-82b0984b772d" />
            <AddToLibraryModal_body_more_info {...AddToLibraryModal_body_more_info_Props} data-jux-context="d3fa6806-0aa0-4cdc-8db7-0c0a93b71386" />
          </>}
      </AddToLibraryModal_body_root>);
AddToLibraryModal_body.displayName = 'AddToLibraryModal_body';
AddToLibraryModal_body.defaultProps = {};
import { JuxButton, type JuxButton_Props } from '../';
type AddToLibraryModal_footer_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const AddToLibraryModal_footer_root: FC<AddToLibraryModal_footer_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
AddToLibraryModal_footer_root.displayName = 'AddToLibraryModal_footer_root';
AddToLibraryModal_footer_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        padding: '80px',
        alignItems: 'center',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF'
      }
    }
  }) as AddToLibraryModal_footer_root_Props['rootElement']
};
export type AddToLibraryModal_footer_Props = AddToLibraryModal_footer_root_Props & {
  JuxButton_1_Props?: JuxButton_Props;
  JuxButton_2_Props?: JuxButton_Props;
};
export const AddToLibraryModal_footer: FC<AddToLibraryModal_footer_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, AddToLibraryModal_footer_Props>(({
  JuxButton_1_Props,
  JuxButton_2_Props,
  ...props
}, ref) => <AddToLibraryModal_footer_root {...props} ref={ref} data-jux-context="cccadfdc-0056-4444-b63d-bb118a137cbc">
      {props.children || <>
          <JuxButton hierarchy={'secondary'} disabled={false} size={'big'} {...JuxButton_1_Props} />
          <JuxButton hierarchy={'Primary'} disabled={false} size={'big'} {...JuxButton_2_Props} />
        </>}
    </AddToLibraryModal_footer_root>);
AddToLibraryModal_footer.displayName = 'AddToLibraryModal_footer';
AddToLibraryModal_footer.defaultProps = {};
type AddToLibraryModal_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const AddToLibraryModal_root: FC<AddToLibraryModal_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
AddToLibraryModal_root.displayName = 'AddToLibraryModal_root';
AddToLibraryModal_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        gap: '24px normal',
        width: '420px',
        height: 'auto',
        display: 'flex',
        padding: '24px 24px 24px 24px',
        boxShadow: '0px 0px 12px 2px rgba(0, 0, 0, 0.15)',
        alignItems: 'stretch',
        borderColor: '',
        borderStyle: 'none',
        borderRadius: '4px',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        backgroundColor: '#FFFFFF'
      },
      variants: [{
        propsValues: {},
        styles: {
          '[data-jux-context="67f329ec-e4d1-4f2c-a309-ed254218ba90"]': {
            gap: '{dimension.core.size_sm} normal',
            display: 'flex',
            alignItems: 'stretch'
          },
          '[data-jux-context="68307a43-909f-4e72-9c63-901684f67a45"]': {
            width: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          },
          '[data-jux-context="6f0a9fd5-7a54-4d29-9cea-82b0984b772d"]': {
            color: '{color.core.grays_500}',
            width: 'auto',
            fontSize: '{typography.core.14_regular.fontSize}',
            fontFamily: '{typography.core.14_regular.fontFamily}',
            fontWeight: '{typography.core.14_regular.fontWeight}',
            lineHeight: '{typography.core.14_regular.lineHeight}',
            letterSpacing: '{typography.core.14_regular.letterSpacing}'
          },
          '[data-jux-context="cccadfdc-0056-4444-b63d-bb118a137cbc"]': {
            gap: '0px',
            display: 'flex',
            padding: '0px 0px 0px 0px',
            justifyContent: 'space-between'
          },
          '[data-jux-context="d3fa6806-0aa0-4cdc-8db7-0c0a93b71386"]': {
            color: '{color.core.grays_500}',
            fontSize: '{typography.core.14_semi.fontSize}',
            fontFamily: '{typography.core.14_semi.fontFamily}',
            fontWeight: '{typography.core.14_semi.fontWeight}',
            lineHeight: '{typography.core.14_semi.lineHeight}',
            letterSpacing: '{typography.core.14_semi.letterSpacing}'
          },
          '[data-jux-context="20621f74-bd3b-4399-b799-2c2197dbb6f5"]': {
            fontSize: '{typography.semantic.header_2.fontSize}',
            fontFamily: '{typography.semantic.header_2.fontFamily}',
            fontWeight: '{typography.semantic.header_2.fontWeight}',
            lineHeight: '{typography.semantic.header_2.lineHeight}',
            letterSpacing: '{typography.semantic.header_2.letterSpacing}'
          }
        }
      }]
    }
  }) as AddToLibraryModal_root_Props['rootElement']
};
export type AddToLibraryModal_Props = AddToLibraryModal_root_Props & {
  AddToLibraryModal_header_Props?: AddToLibraryModal_header_Props;
  AddToLibraryModal_body_Props?: AddToLibraryModal_body_Props;
  AddToLibraryModal_footer_Props?: AddToLibraryModal_footer_Props;
};
export const AddToLibraryModal: FC<AddToLibraryModal_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, AddToLibraryModal_Props>(({
  AddToLibraryModal_header_Props,
  AddToLibraryModal_body_Props,
  AddToLibraryModal_footer_Props,
  ...props
}, ref) => <AddToLibraryModal_root {...props} ref={ref} data-jux-context="9121f6e4-74da-40bb-8e84-a03ccebc0602">
      {props.children || <>
          <AddToLibraryModal_header {...AddToLibraryModal_header_Props} data-jux-context="68307a43-909f-4e72-9c63-901684f67a45" />
          <AddToLibraryModal_body {...AddToLibraryModal_body_Props} data-jux-context="67f329ec-e4d1-4f2c-a309-ed254218ba90" />
          <AddToLibraryModal_footer {...AddToLibraryModal_footer_Props} data-jux-context="cccadfdc-0056-4444-b63d-bb118a137cbc" />
        </>}
    </AddToLibraryModal_root>);
AddToLibraryModal.displayName = 'AddToLibraryModal';
AddToLibraryModal.defaultProps = {};