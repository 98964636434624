import { FC } from 'react';
import { selectedNodeContextParentId, selectedNodeContextParentVariants, useStore } from '@jux/canjux/core';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { DynamicProperties } from '../../../props/sub-modules/DynamicProperties';
import { InteractiveState, useInteractiveState } from '../../../props/sub-modules';
export const ParentContextContent: FC = () => {
  const selectedNodeParentContextId = useStore(selectedNodeContextParentId);
  const selectedNodeContextVariants = useStore(selectedNodeContextParentVariants);
  const {
    nodeInteractiveState,
    handleNodeInteractiveStateChange,
    options
  } = useInteractiveState({
    nodeId: selectedNodeParentContextId
  });
  return <MuiGrid item container flexDirection="column" gap="4px" data-sentry-element="MuiGrid" data-sentry-component="ParentContextContent" data-sentry-source-file="ParentContextContent.tsx">
      <InteractiveState nodeInteractiveState={nodeInteractiveState} handleNodeInteractiveStateChange={handleNodeInteractiveStateChange} options={options} forceRender data-sentry-element="InteractiveState" data-sentry-source-file="ParentContextContent.tsx" />
      <DynamicProperties // Rendering the DynamicProperties component of the parent context node
    variantsConfig={selectedNodeContextVariants || []} nodeId={selectedNodeParentContextId || ''} forceRender data-sentry-element="DynamicProperties" data-sentry-source-file="ParentContextContent.tsx" />
    </MuiGrid>;
};