import { FC, MouseEvent, useCallback, useRef } from 'react';
import { EditableTypography, IconButton, TypographyElement } from '@jux/ui/components';
import { TextValidatorFunction } from '@jux/ui/utils/textValidators';
import * as S from './Chip.style';
export const Chip: FC<{
  value: string;
  onDelete: (e: MouseEvent<HTMLButtonElement>) => void;
  onSaveChanges: (newValue: string) => boolean;
  disableEditing?: boolean;
  maxLength?: number;
  customValidators: Array<TextValidatorFunction>;
}> = ({
  value,
  onSaveChanges,
  onDelete,
  disableEditing,
  maxLength,
  customValidators
}) => {
  const editableTypographyRef = useRef<TypographyElement>(null);
  const handleWrapperClick = useCallback(() => {
    editableTypographyRef.current?.focus();
  }, []);
  const handleDeleteClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onDelete(e);
  }, [onDelete]);
  return <S.Chip onClick={handleWrapperClick} disableEditing={disableEditing} data-sentry-element="unknown" data-sentry-component="Chip" data-sentry-source-file="Chip.tsx">
      <EditableTypography variant="label-s-regular" ref={editableTypographyRef} maxLength={maxLength} customValidators={customValidators} label={value} onSaveChanges={disableEditing ? undefined : onSaveChanges} disableEditing={disableEditing} data-sentry-element="EditableTypography" data-sentry-source-file="Chip.tsx" />
      {!disableEditing && <IconButton icon="CLOSE_SMALL" onClick={handleDeleteClick} variant="icon" size="small" />}
    </S.Chip>;
};