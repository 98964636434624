import { Dimensions, ResizeHorizontalPosition, ResizePosition, ResizeVerticalPosition } from '@jux/canjux/core';
import { XYPosition } from '@jux/data-entities';
import { styled } from '@jux/ui/components/common/mui';
import { FC, memo, useRef } from 'react';
import { mergeControlPosition } from './node-resizer.utils';
import useResizerDrag from './useResizerDrag';
interface HandleControlProps {
  nodeId: string;
  nodeDimensions: Dimensions;
  nodePosition: XYPosition;
  verticalPosition: ResizeVerticalPosition;
  horizontalPosition: ResizeHorizontalPosition;
  isSelected: boolean;
  handleSize: string;
  lineColor: string;
  backgroundColor: string;
  revertZoomScale: number;
  isHovered?: boolean;
}
const getHandleTransformStyles = (props: HandleControlProps) => {
  let translateCSS;
  if (props.verticalPosition === ResizePosition.top) {
    if (props.horizontalPosition === ResizePosition.left) {
      translateCSS = props.isHovered ? 'translate(-100%, -100%)' : 'translate(-50%, -50%)';
    } else if (props.horizontalPosition === ResizePosition.right) {
      translateCSS = props.isHovered ? 'translate(0%, -100%)' : 'translate(-50%, -50%)';
    }
  }
  if (props.verticalPosition === ResizePosition.bottom) {
    if (props.horizontalPosition === ResizePosition.left) {
      translateCSS = props.isHovered ? 'translate(-100%, 0%)' : 'translate(-50%, -50%)';
    } else if (props.horizontalPosition === ResizePosition.right) {
      translateCSS = props.isHovered ? 'translate(0%, 0%)' : 'translate(-50%, -50%)';
    }
  }
  return `scale(${props.revertZoomScale}) ${translateCSS}`; //revert zoom so that handle doesn't scale with zoom
};
const StyledHandleControl = styled('div', {
  shouldForwardProp: (prop: string) => !['verticalPosition', 'horizontalPosition', 'isHovered', 'revertZoomScale', 'lineColor', 'backgroundColor', 'nodeProperties', 'nodeId', 'nodeDimensions', 'nodePosition', 'handleSize', 'isSelected'].includes(prop)
})<Omit<HandleControlProps, 'nodeId' | 'nodeDimensions' | 'nodePosition' | 'nodeProperties'>>(props => ({
  position: 'absolute',
  zIndex: 5,
  pointerEvents: 'all',
  width: props.handleSize,
  height: props.handleSize,
  border: props.isHovered ? `none` : `${props.handleSize} solid ${props.lineColor}`,
  backgroundColor: props.backgroundColor,
  padding: '3px',
  transformOrigin: '0% 0%',
  top: props.verticalPosition === ResizePosition.top ? '0' : '100%',
  left: props.horizontalPosition === ResizePosition.left ? '0' : '100%',
  cursor: props.verticalPosition === ResizePosition.top && props.horizontalPosition === ResizePosition.left || props.verticalPosition === ResizePosition.bottom && props.horizontalPosition === ResizePosition.right ? 'nwse-resize' : 'nesw-resize'
}));
const HandleControl: FC<HandleControlProps> = props => {
  const resizeControlRef = useRef<HTMLDivElement>(null);
  useResizerDrag({
    resizeControlRef,
    controlPosition: mergeControlPosition({
      vertical: props.verticalPosition,
      horizontal: props.horizontalPosition
    }),
    nodeId: props.nodeId,
    nodeDimensions: props.nodeDimensions,
    nodePosition: props.nodePosition,
    enabled: props.isSelected
  });
  return <StyledHandleControl {...props} ref={resizeControlRef} style={{
    transform: getHandleTransformStyles(props)
  }} data-sentry-element="StyledHandleControl" data-sentry-component="HandleControl" data-sentry-source-file="HandleControl.tsx" />;
};
export default memo(HandleControl);