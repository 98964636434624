import { FC, useMemo, useRef } from 'react';
import { BaseFieldLayout } from '../common';
import { SelectFieldProps } from './SelectField.interface';
import { SelectFieldOptionRenderer } from './SelectFieldOptionRenderer';
import { SelectFieldContent } from './SelectFieldContent';
import { DDP_SELECT_FIELD_CLASS_NAME, DDP_SELECT_FIELD_NAME_PREFIX } from './SelectField.constant';
export const SelectField: FC<SelectFieldProps> = ({
  isComputed,
  isFieldDisabled,
  value,
  rawValue,
  icon,
  tooltipTitle,
  autocompleteProps,
  tokensProps
}) => {
  const fieldRef = useRef<HTMLDivElement>(null);
  const {
    inputName
  } = autocompleteProps ?? {};
  const nameWithPrefix = useMemo(() => `${DDP_SELECT_FIELD_NAME_PREFIX}${inputName}`, [inputName]);
  return <BaseFieldLayout isFieldDisabled={isFieldDisabled} isComputed={isComputed} icon={icon} tooltipTitle={tooltipTitle} className={DDP_SELECT_FIELD_CLASS_NAME} htmlFor={nameWithPrefix} tokensProps={tokensProps} renderTokenOption={SelectFieldOptionRenderer} fieldRef={fieldRef} data-sentry-element="BaseFieldLayout" data-sentry-component="SelectField" data-sentry-source-file="SelectField.tsx">
      <SelectFieldContent isFieldDisabled={isFieldDisabled} value={value} rawValue={rawValue} htmlForTarget={nameWithPrefix} tokensProps={tokensProps} autocompleteProps={autocompleteProps} data-sentry-element="SelectFieldContent" data-sentry-source-file="SelectField.tsx" />
    </BaseFieldLayout>;
};