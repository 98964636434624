/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const AlignRightDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    d: 'M12 3C12.5523 3 13 3.44772 13 4C13 4.55229 12.5523 5 12 5L6 5C5.44772 5 5 4.55228 5 4C5 3.44772 5.44772 3 6 3H12Z',
    fill: 'currentColor'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    d: 'M12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9H4C3.44772 9 3 8.55228 3 8C3 7.44771 3.44772 7 4 7L12 7Z',
    fill: 'currentColor'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    d: 'M13 12C13 11.4477 12.5523 11 12 11H8C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H12C12.5523 13 13 12.5523 13 12Z',
    fill: 'currentColor'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="AlignRightDDPIcon" data-sentry-source-file="AlignRightDDPIcon.tsx" />;