import { useCallback } from 'react';
import {
  PlaceholderMode,
  getContextParentId,
  getNodeComputedStyles,
  selectedNodeComponentAssetData,
  selectedNodeComponentVariantsPropsValues,
  selectedNodeId,
  storeApi,
  useStore,
  selectedNodeInteractiveState,
} from '@jux/canjux/core';
import { AssetData, NodeInteractiveState } from '@jux/data-entities';
import {
  DDPFieldsState,
  getDDPFieldsStateFromStyles,
  useDDPFieldsOptionsState,
  useDDPFieldsState,
} from '@jux/ui/components/editor/state';
import { SupportedStyles, getDDPSupportedStyles } from '../utils';
import { useNodeStyles } from './useNodeStyles';
import { useSetupShouldRenderSubModule } from './useSetupShouldRenderSubModule';

const getSelectedNodeId = () => selectedNodeId(storeApi.getState());
const getSelectedNodeComponentVariantsPropsValues = () =>
  selectedNodeComponentVariantsPropsValues(storeApi.getState());
const getSelectedNodeAssetData = () =>
  selectedNodeComponentAssetData(storeApi.getState());

export const useSetupDDPModuleState = () => {
  const { setupShouldRenderSubModulesState } = useSetupShouldRenderSubModule();
  const { setFieldsState } = useDDPFieldsState();
  const { setFieldsOptionsState } = useDDPFieldsOptionsState();
  const { getNodeStyles } = useNodeStyles();
  const selectedNodeState = useStore(selectedNodeInteractiveState);

  const getContextParentIdByNodeId = useStore(getContextParentId);

  const setInitialDDPModuleState = useCallback(
    ({
      styles,
      initialStyles,
      assetData,
    }: {
      styles: SupportedStyles;
      initialStyles: SupportedStyles;
      assetData?: AssetData;
    }) => {
      // setting up sub modules state
      setupShouldRenderSubModulesState(styles, initialStyles);

      // setting up fields state
      setFieldsOptionsState({
        color: {
          hasMultipleValues: assetData?.hasMixedColors ?? false,
        },
      });

      const fieldState = getDDPFieldsStateFromStyles(styles, initialStyles);

      if (!fieldState) return;

      // TODO: field state at the moment is used for all the fields but we need to consider using it for primitive fields only
      setFieldsState(fieldState as DDPFieldsState);
    },
    [setFieldsOptionsState, setFieldsState, setupShouldRenderSubModulesState]
  );

  const setupDDPValuesStateBySelectedNodeId = useCallback(
    ({
      nodeState = selectedNodeState,
    }: {
      nodeState?: NodeInteractiveState;
    }) => {
      const nodeId = getSelectedNodeId();
      const variantsValues = getSelectedNodeComponentVariantsPropsValues();

      if (!nodeId || !variantsValues) return;

      // TODO: parse shorthand values to detailed values
      // get the selected node styles
      const parentContextId = getContextParentIdByNodeId(nodeId);
      const styles = getDDPSupportedStyles(
        getNodeStyles({
          id: nodeId,
          variantsValues,
          nodeState,
          parentContextId,
          isPlaceholderStyles:
            storeApi.getState().placeholderMode[nodeId] ===
            PlaceholderMode.placeholder,
        })
      );

      const initialStyles = getDDPSupportedStyles(
        getNodeComputedStyles(nodeId)
      );

      // set the DDP state
      setInitialDDPModuleState({
        styles,
        initialStyles,
        assetData: getSelectedNodeAssetData(),
      });
    },
    [
      getContextParentIdByNodeId,
      getNodeStyles,
      selectedNodeState,
      setInitialDDPModuleState,
    ]
  );

  return {
    setupDDPValuesStateBySelectedNodeId,
  };
};
