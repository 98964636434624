/**
 *  THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 */

/* eslint-disable */
// @ts-nocheck

import { createDrimz, type PropsOf } from '@jux/dui';
import React, { FC, forwardRef } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType } from '@jux/data-entities';
import { JuxReactElements } from '@jux/react';
const {
  styled
} = createDrimz();
import { Subcategory, type Subcategory_Props } from '../';
import { KeyboardListItem, type KeyboardListItem_Props } from '../';
type KeyboardCategory_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const KeyboardCategory_root: FC<KeyboardCategory_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
KeyboardCategory_root.displayName = 'KeyboardCategory_root';
KeyboardCategory_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        gap: '{dimension.core.size_xs} normal',
        width: 'auto',
        height: 'auto',
        display: 'flex',
        padding: '16px 20px 16px 20px',
        alignItems: 'stretch',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: 'rgba(255,255,255,1)'
      }
    }
  }) as KeyboardCategory_root_Props['rootElement']
};
export type KeyboardCategory_Props = KeyboardCategory_root_Props & {
  Subcategory_Props?: Subcategory_Props;
  KeyboardListItem_1_Props?: KeyboardListItem_Props;
  KeyboardListItem_2_Props?: KeyboardListItem_Props;
  KeyboardListItem_3_Props?: KeyboardListItem_Props;
  KeyboardListItem_4_Props?: KeyboardListItem_Props;
};
export const KeyboardCategory: FC<KeyboardCategory_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, KeyboardCategory_Props>(({
  Subcategory_Props,
  KeyboardListItem_1_Props,
  KeyboardListItem_2_Props,
  KeyboardListItem_3_Props,
  KeyboardListItem_4_Props,
  ...props
}, ref) => <KeyboardCategory_root {...props} ref={ref} data-jux-context="5623b40a-054f-42c3-abaa-234105a8d95d">
      {props.children || <>
          <Subcategory {...Subcategory_Props} />
          <KeyboardListItem {...KeyboardListItem_1_Props} />
          <KeyboardListItem {...KeyboardListItem_2_Props} />
          <KeyboardListItem {...KeyboardListItem_3_Props} />
          <KeyboardListItem {...KeyboardListItem_4_Props} />
        </>}
    </KeyboardCategory_root>);
KeyboardCategory.displayName = 'KeyboardCategory';
KeyboardCategory.defaultProps = {};