import { DesignTokenTypeEnum, isAlias } from '@juxio/design-tokens';
import { FC, useMemo } from 'react';
import { useStoreActions } from '@jux/canjux/core';
import { CORE } from '@jux/types';
import { FieldContainer, StyledSelectField, StyledTextField, TITLE_CREATE_NEW_TOKEN, TITLE_EDIT_TOKEN, TokenDrawerFormLayout, TokenDrawerIconLabel, TokenNameField } from '@jux/ui/components/tokens/token-drawer/base';
import { ALIAS_PLACEHOLDER_TEXT, FONT_FAMILY_PATH_PREFIX, fontWeightOptions, formatAliasPath, formatGroupPath, getSelectFieldPathOptions, parseTypographyTokenValue, renderFontWeightOption, TYPOGRAPHY_PATH_PREFIX, useFontFamilyOptions, useFormValueAlias, useInitialValues, useRegisterFieldFns, useValidateTokenName } from '@jux/ui/components/tokens/token-drawer/forms/helpers';
import { formatFontFamilyTokenValue } from '@jux/ui/components/tokens/token-drawer/forms/helpers/formatFontFamilyTokenValue';
import { useCurrentTokenSetData } from '@jux/ui/components/tokens/token-drawer/forms/helpers/useCurrentTokenSetData';
import { TypographyTokenFormProps } from '@jux/ui/components/tokens/token-drawer/forms/types';
import { useTokenDrawer } from '@jux/ui/components/tokens/token-drawer/useTokenDrawer';
import { getUpdatedTokenData } from '@jux/ui/components/tokens/token-drawer/utils/getUpdatedTokenData';
import { useZodForm } from '@jux/ui/hooks/useZodForm';
import { typographyTokenInputSchema } from '@jux/ui/trpc/validations';
import { createPath } from '@jux/ui/utils/tokensPath';
export const TypographyTokenForm: FC<TypographyTokenFormProps> = ({
  initialValues,
  groupPathOptions,
  groupPathCoreOptions,
  isCoreTokenSet,
  isCreatedFromDDP,
  componentsThatUseToken
}) => {
  const {
    tokenSetsActions: {
      setToken
    }
  } = useStoreActions();
  const defaultValues = {
    ...initialValues,
    value: parseTypographyTokenValue(initialValues.value)
  };
  const form = useZodForm({
    schema: typographyTokenInputSchema,
    defaultValues
  });
  const {
    handlers
  } = useTokenDrawer();
  const [registerTokenNameField, registerGroupPathField, registerAliasField, registerFontFamilyField, registerFontWeightField, registerFontSizeField, registerLineHeightField, registerLetterSpacingField, registerDescriptionField] = useRegisterFieldFns(form, ['tokenName', 'groupPath', {
    alias: {
      onChange: e => handlers.onChange?.({
        newValue: e.target.value
      })
    }
  }, {
    'value.fontFamily': {
      onChange: e => handlers.onChange?.({
        newValue: e.target.value,
        subFieldName: 'fontFamily'
      })
    }
  }, {
    'value.fontWeight': {
      onChange: e => handlers.onChange?.({
        newValue: e.target.value,
        subFieldName: 'fontWeight'
      })
    }
  }, {
    'value.fontSize': {
      onChange: e => handlers.onChange?.({
        newValue: e.target.value,
        subFieldName: 'fontSize'
      })
    }
  }, {
    'value.lineHeight': {
      onChange: e => handlers.onChange?.({
        newValue: e.target.value,
        subFieldName: 'lineHeight'
      })
    }
  }, {
    'value.letterSpacing': {
      onChange: e => handlers.onChange?.({
        newValue: e.target.value,
        subFieldName: 'letterSpacing'
      })
    }
  }, 'description']);

  // using controlled fields to support aliases.
  // each field has a default/initial value, but if the user has changed the value,
  // we want to use the value they have changed it to, rather than the default value.
  const [fontFamilyValue, fontWeightValue, fontSizeValue, lineHeightValue, letterSpacingValue] = useInitialValues(['value.fontFamily', 'value.fontWeight', 'value.fontSize', 'value.lineHeight', 'value.letterSpacing'], {
    form,
    initialValues: defaultValues
  });
  const {
    hasPaths: hasGroups,
    optionsProps: groupsOptionsProps
  } = useMemo(() => getSelectFieldPathOptions({
    existingPaths: groupPathOptions,
    existingCorePaths: isCoreTokenSet || isCreatedFromDDP ? groupPathCoreOptions : [],
    forceCoreOnly: isCoreTokenSet && !isCreatedFromDDP
  }), [groupPathOptions, isCoreTokenSet, isCreatedFromDDP, groupPathCoreOptions]);
  const {
    aliasOptionsProps,
    hasAliases,
    aliasValue,
    isAliasToken,
    validateCircularAlias
  } = useFormValueAlias<typeof form>({
    form,
    isCoreTokenSet,
    isCreatedFromDDP,
    parseValue: parseTypographyTokenValue,
    prefix: TYPOGRAPHY_PATH_PREFIX
  });
  const validateTokenName = useValidateTokenName({
    form
  });
  const {
    options: fontFamilies,
    fallbacks: fontFamilyFallbacks
  } = useFontFamilyOptions();
  const handleSubmit = useMemo(() => form.handleSubmit(values => {
    if (validateTokenName() && validateCircularAlias()) {
      const fontFamily = isAlias(values.value.fontFamily) ? values.value.fontFamily : formatFontFamilyTokenValue({
        value: values.value.fontFamily,
        fallbacks: fontFamilyFallbacks[values.value.fontFamily as unknown as string]
      });
      setToken({
        data: {
          ...values,
          alias: formatAliasPath(values.alias),
          isCore: isCoreTokenSet,
          value: {
            ...values.value,
            fontFamily
          }
        },
        type: DesignTokenTypeEnum.typography
      });
      handlers.onSave(getUpdatedTokenData({
        tokenPath: createPath([values.groupPath, values.tokenName]),
        tokenSetId: values.tokenSetId
      }));
    }
  }, () => validateTokenName() && validateCircularAlias()), [form, validateTokenName, validateCircularAlias, fontFamilyFallbacks, setToken, isCoreTokenSet, handlers]);
  const {
    existingTokenPaths
  } = useCurrentTokenSetData();
  const existingFontFamilyTokenPaths = useMemo(() => {
    if (isCoreTokenSet) {
      return existingTokenPaths.filter(p => p.startsWith(createPath([CORE, FONT_FAMILY_PATH_PREFIX])));
    }
    return existingTokenPaths.filter(p => p.startsWith(FONT_FAMILY_PATH_PREFIX));
  }, [existingTokenPaths, isCoreTokenSet]);
  const groupedFontFamilyOptions = useMemo(() => ({
    tokens: existingFontFamilyTokenPaths.map(p => ({
      label: formatGroupPath(p, isCoreTokenSet && !isCreatedFromDDP),
      value: formatAliasPath(p)
    })),
    'font family': fontFamilies.map(f => ({
      label: f,
      value: f
    }))
  }), [existingFontFamilyTokenPaths, fontFamilies, isCoreTokenSet, isCreatedFromDDP]);
  return <TokenDrawerFormLayout title={initialValues.tokenName ? TITLE_EDIT_TOKEN : TITLE_CREATE_NEW_TOKEN} componentsThatUseToken={componentsThatUseToken} onSubmit={handleSubmit} data-sentry-element="TokenDrawerFormLayout" data-sentry-component="TypographyTokenForm" data-sentry-source-file="TypographyTokenForm.tsx">
      <TokenDrawerIconLabel iconVariant="TYPOGRAPHY" labelText="Typography" data-sentry-element="TokenDrawerIconLabel" data-sentry-source-file="TypographyTokenForm.tsx" />
      <FieldContainer error={form.formState.errors.tokenName?.message} data-sentry-element="FieldContainer" data-sentry-source-file="TypographyTokenForm.tsx">
        <TokenNameField error={Boolean(form.formState.errors.tokenName)} inputRef={registerTokenNameField().ref} {...registerTokenNameField()} data-sentry-element="TokenNameField" data-sentry-source-file="TypographyTokenForm.tsx" />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.groupPath?.message} data-sentry-element="FieldContainer" data-sentry-source-file="TypographyTokenForm.tsx">
        <StyledSelectField placeholder="Folder" defaultValue={initialValues.groupPath} renderValue={item => formatGroupPath(item, isCoreTokenSet && !isCreatedFromDDP)} error={Boolean(form.formState.errors.groupPath)} inputRef={registerGroupPathField().ref} disabled={!hasGroups} disableMenuPortal {...groupsOptionsProps} {...registerGroupPathField()} data-sentry-element="StyledSelectField" data-sentry-source-file="TypographyTokenForm.tsx" />
      </FieldContainer>
      <FieldContainer data-sentry-element="FieldContainer" data-sentry-source-file="TypographyTokenForm.tsx">
        <StyledSelectField disabled={!hasAliases} placeholder={ALIAS_PLACEHOLDER_TEXT} value={aliasValue} renderValue={item => formatGroupPath(item, isCoreTokenSet && !isCreatedFromDDP)} error={Boolean(form.formState.errors.alias)} inputRef={registerAliasField().ref} disableMenuPortal {...aliasOptionsProps} {...registerAliasField()} data-sentry-element="StyledSelectField" data-sentry-source-file="TypographyTokenForm.tsx" />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.value?.fontFamily?.message} data-sentry-element="FieldContainer" data-sentry-source-file="TypographyTokenForm.tsx">
        <StyledSelectField disabled={isAliasToken} placeholder="Font family" value={fontFamilyValue} error={Boolean(form.formState.errors.value?.fontFamily)} groupedOptions={groupedFontFamilyOptions} inputRef={registerFontFamilyField().ref} disableMenuPortal {...registerFontFamilyField()} data-sentry-element="StyledSelectField" data-sentry-source-file="TypographyTokenForm.tsx" />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.value?.fontWeight?.message} data-sentry-element="FieldContainer" data-sentry-source-file="TypographyTokenForm.tsx">
        <StyledSelectField disabled={isAliasToken} placeholder="Font weight" value={fontWeightValue} error={Boolean(form.formState.errors.value?.fontWeight)} options={fontWeightOptions} renderValue={renderFontWeightOption} inputRef={registerFontWeightField().ref} disableMenuPortal {...registerFontWeightField()} data-sentry-element="StyledSelectField" data-sentry-source-file="TypographyTokenForm.tsx" />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.value?.fontSize?.message} data-sentry-element="FieldContainer" data-sentry-source-file="TypographyTokenForm.tsx">
        <StyledTextField disabled={isAliasToken} value={fontSizeValue} inputProps={{
        placeholder: 'Font size'
      }} error={Boolean(form.formState.errors.value?.fontSize)} inputRef={registerFontSizeField().ref} {...registerFontSizeField()} data-sentry-element="StyledTextField" data-sentry-source-file="TypographyTokenForm.tsx" />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.value?.lineHeight?.message} data-sentry-element="FieldContainer" data-sentry-source-file="TypographyTokenForm.tsx">
        <StyledTextField disabled={isAliasToken} value={lineHeightValue} inputProps={{
        placeholder: 'Line height'
      }} error={Boolean(form.formState.errors.value?.lineHeight)} inputRef={registerLineHeightField().ref} {...registerLineHeightField()} data-sentry-element="StyledTextField" data-sentry-source-file="TypographyTokenForm.tsx" />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.value?.letterSpacing?.message} data-sentry-element="FieldContainer" data-sentry-source-file="TypographyTokenForm.tsx">
        <StyledTextField disabled={isAliasToken} value={letterSpacingValue} inputProps={{
        placeholder: 'Letter spacing'
      }} error={Boolean(form.formState.errors.value?.letterSpacing)} inputRef={registerLetterSpacingField().ref} {...registerLetterSpacingField()} data-sentry-element="StyledTextField" data-sentry-source-file="TypographyTokenForm.tsx" />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.description?.message} data-sentry-element="FieldContainer" data-sentry-source-file="TypographyTokenForm.tsx">
        <StyledTextField inputProps={{
        placeholder: 'Token description'
      }} error={Boolean(form.formState.errors.description)} inputRef={registerDescriptionField().ref} {...registerDescriptionField()} data-sentry-element="StyledTextField" data-sentry-source-file="TypographyTokenForm.tsx" />
      </FieldContainer>
    </TokenDrawerFormLayout>;
};