import { FC, memo } from 'react';
import { DDPMessage } from './DDPMessage';
import { useDDPContent } from './hooks';
import { InstanceModulesMapping } from './InstanceModulesMapping';
import { LiveModeIsOn } from './LiveModeIsOn';
import { ModulesMapping } from './ModulesMapping';
export const DDPContent: FC = memo(() => {
  const {
    isDynamicSlotInInstance,
    isLive,
    isImmutable,
    isComponentInstance,
    isLogicalSlot,
    multipleSelected,
    nonSelected
  } = useDDPContent();
  if (isLive) return <LiveModeIsOn />;
  if (nonSelected) return <DDPMessage text="Select an object from the canvas in order to edit its UI" />;
  if (isLogicalSlot) return <DDPMessage text="This is a logic layer, select a different object from the canvas in order to edit its UI" />;
  if (multipleSelected) return <DDPMessage text="Currently, multi-object selection does not support styling options" />;
  if (isDynamicSlotInInstance) {
    return <DDPMessage>
        The selected element is a dynamic content slot that allows adding and
        removing objects from it inside instances. To style it, go to the source
        component.
      </DDPMessage>;
  }
  if (isImmutable) return <DDPMessage>
        Selected element is a part of an instance of component.
        <br />
        Go to source component to edit.
      </DDPMessage>;
  if (isComponentInstance) return <InstanceModulesMapping />;
  return <ModulesMapping />;
});