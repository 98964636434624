import { FC } from 'react';
import { Tooltip, Typography } from '@jux/ui/components';
import { MuiMenuItem } from '@jux/ui/components/common/mui';
import { useDeleteAsset } from './useDeleteAsset';
export const DeleteAssetMenuItem: FC<{
  assetId: string;
}> = ({
  assetId
}) => {
  const {
    isDeletable,
    handleAssetDelete
  } = useDeleteAsset(assetId);
  return <Tooltip multiline content={!isDeletable ? 'In order to delete, remove source component and instances from canvas' : undefined} data-sentry-element="Tooltip" data-sentry-component="DeleteAssetMenuItem" data-sentry-source-file="DeleteAssetMenuItem.tsx">
      {/* Adding additional layer of div, so the tooltip can render this element */}
      <div>
        <MuiMenuItem disabled={!isDeletable} disableRipple onClick={handleAssetDelete} data-sentry-element="MuiMenuItem" data-sentry-source-file="DeleteAssetMenuItem.tsx">
          <Typography variant="body-regular" data-sentry-element="Typography" data-sentry-source-file="DeleteAssetMenuItem.tsx">Delete</Typography>
        </MuiMenuItem>
      </div>
    </Tooltip>;
};