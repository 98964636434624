/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const MinHeightDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M10.75 5.5C10.75 5.91421 10.4142 6.25 10 6.25H6C5.58579 6.25 5.25 5.91421 5.25 5.5C5.25 5.08579 5.58579 4.75 6 4.75H7.25V3C7.25 2.58579 7.58579 2.25 8 2.25C8.41421 2.25 8.75 2.58579 8.75 3V4.75H10C10.4142 4.75 10.75 5.08579 10.75 5.5ZM10.75 8C10.75 8.41421 10.4142 8.75 10 8.75H6C5.58579 8.75 5.25 8.41421 5.25 8C5.25 7.58579 5.58579 7.25 6 7.25H10C10.4142 7.25 10.75 7.58579 10.75 8ZM10.75 10.5C10.75 10.9142 10.4142 11.25 10 11.25H8.75V13C8.75 13.4142 8.41421 13.75 8 13.75C7.58579 13.75 7.25 13.4142 7.25 13L7.25 11.25H6C5.58579 11.25 5.25 10.9142 5.25 10.5C5.25 10.0858 5.58579 9.75 6 9.75H10C10.4142 9.75 10.75 10.0858 10.75 10.5Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="MinHeightDDPIcon" data-sentry-source-file="MinHeightDDPIcon.tsx" />;