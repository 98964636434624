import { FC, useCallback } from 'react';
import { UserAssets, SystemAssets, useAssetsTabs, AssetsHeader, Typography, UploadAssets } from '@jux/ui/components';
import { selectSystemAssets, selectUserAssets, useStore } from '@jux/canjux/core';
import { CANVAS_ITEMS, useAddCanvasItem, useGetTargetCenterPosition } from '@jux/ui/components/editor/hooks';
import { getAssetDimensions } from '@jux/ui/utils/assets';
import { AssetData } from '@jux/data-entities';
import { DeleteAssetMenuItem } from '@jux/ui/components/common/assets/DeleteAssetMenuItem';
import { NAVIGATOR_MENU_TAB_CONTENT_DESCRIPTION_COLOR } from '@jux/ui/theme/palette';
import { MuiGrid, useTheme } from '@jux/ui/components/common/mui';
import { useUploadAsset } from '@jux/ui/hooks';
import { NavigationPopper, S } from '../common';
const TEST_ID_ASSETS_POPPER = 'assets-popper';
const USER_ASSETS_TAB_DESCRIPTION = 'Upload SVG assets to Jux, then drag them on to the canvas to use in your design.';
export const AssetsPopper: FC = () => {
  const theme = useTheme();
  const systemAssets = useStore(selectSystemAssets);
  const userAssets = useStore(selectUserAssets);
  const {
    isSystemAssetsTab,
    isUserAssetsTab,
    setActiveSystemAssetsTab,
    setActiveUserAssetsTab
  } = useAssetsTabs();
  const {
    addItemToCanvas
  } = useAddCanvasItem();
  const {
    handleImportButtonClick,
    importFiles,
    inputRef,
    isDoneImporting
  } = useUploadAsset();
  const {
    getTargetCenterPositionByDimensions
  } = useGetTargetCenterPosition();
  const handleAssetClick = useCallback(({
    assetData,
    assetId
  }: {
    assetId: string;
    assetData: AssetData;
  }) => {
    const {
      height,
      width
    } = getAssetDimensions(assetData);
    const centerPosition = getTargetCenterPositionByDimensions({
      width,
      height
    });
    addItemToCanvas({
      item: {
        assetData,
        assetId,
        type: CANVAS_ITEMS.Asset
      },
      dropPosition: centerPosition
    });
  }, [addItemToCanvas, getTargetCenterPositionByDimensions]);
  return <NavigationPopper testId={TEST_ID_ASSETS_POPPER} header={<AssetsHeader isSystemAssets={isSystemAssetsTab} setSystemAssets={setActiveSystemAssetsTab} isUserAssets={isUserAssetsTab} setUserAssets={setActiveUserAssetsTab} />} data-sentry-element="NavigationPopper" data-sentry-component="AssetsPopper" data-sentry-source-file="AssetsPopper.tsx">
      {isUserAssetsTab && <S.NavigationItemGrid container sx={{
      gap: '16px',
      position: 'sticky',
      top: 0,
      backgroundColor: theme.drimz.palette.background.default,
      zIndex: 11,
      // bigger than ComponentPreviewItemActionsMenu
      paddingTop: '16px',
      paddingBottom: '16px'
    }}>
          <MuiGrid item>
            <Typography variant="label-xs-regular" color={NAVIGATOR_MENU_TAB_CONTENT_DESCRIPTION_COLOR}>
              {USER_ASSETS_TAB_DESCRIPTION}
            </Typography>
          </MuiGrid>
          <MuiGrid item>
            <UploadAssets inputRef={inputRef} handleImportButtonClick={handleImportButtonClick} importFiles={importFiles} isDoneImporting={isDoneImporting} />
          </MuiGrid>
        </S.NavigationItemGrid>}

      <S.ItemsListGrid zIndex={1} paddingTop={isUserAssetsTab ? 0 : theme.drimz.size.editor.navbar.menuTab.content.padding} data-sentry-element="unknown" data-sentry-source-file="AssetsPopper.tsx">
        {isSystemAssetsTab && <SystemAssets assets={systemAssets} handleAssetClick={handleAssetClick} isDraggable />}
        {isUserAssetsTab && <UserAssets assets={userAssets} handleAssetClick={handleAssetClick} actions={assetId => <DeleteAssetMenuItem assetId={assetId} />} isDraggable />}
      </S.ItemsListGrid>
    </NavigationPopper>;
};