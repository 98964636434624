import { useCanvasLiveOrEdit } from '@jux/ui/components/editor/hooks';
import { useCopyPasteCanvasItems } from '@jux/ui/components/editor/hooks';
import { useUserDefaultCanvasOptions } from './useUserDefaultCanvasOptions';
import { CanvasProps } from './Canvas.interface';
import { useSetDefaultViewportEffect } from './useSetDefaultViewportEffect';
export const useCanvas = (): CanvasProps => {
  const {
    isLive
  } = useCanvasLiveOrEdit();
  const {
    defaultViewport,
    fitView
  } = useUserDefaultCanvasOptions();
  useSetDefaultViewportEffect();
  useCopyPasteCanvasItems();
  return {
    isLive,
    defaultViewport,
    fitView
  };
};