/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const ElementsIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="20" height="20" attr={{
  viewBox: '0 0 20 20'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M11 9V3H9V9H3V11H9V17H11V11H17V9H11Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="ElementsIcon" data-sentry-source-file="ElementsIcon.tsx" />;