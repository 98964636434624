/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const FontFamilyIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M3 14L4.93182 2.36363H12.6364L12.2955 4.39204H7.05114L6.59659 7.16477H10.3295L9.98864 9.19318H6.25568L5.46023 14H3Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="FontFamilyIcon" data-sentry-source-file="FontFamilyIcon.tsx" />;