import { COMPONENT_TAG_NAME, ComponentElementType, ComponentProps } from '@jux/data-entities';
import { ChangeEvent, MouseEvent, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { mergeRefs } from 'react-merge-refs';
const INDETERMINATE = 'indeterminate' as const;
const TRUE = 'true' as const;
const FALSE = 'false' as const;
type OnState = boolean | undefined;
type ToggleElementState = OnState | typeof INDETERMINATE;
type BooleanString = typeof TRUE | typeof FALSE;
const getToggleElementState = (value: ToggleElementState | BooleanString): ToggleElementState => {
  if (value === TRUE) return true;
  if (value === FALSE) return false;
  return value;
};
const getIsOn = (value: ToggleElementState | BooleanString): OnState => {
  const state = getToggleElementState(value);
  if (state === undefined || state === INDETERMINATE) return undefined;
  return state;
};
export const Toggle = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxToggle>, ComponentProps<typeof COMPONENT_TAG_NAME.JuxToggle>>(({
  children,
  onChange,
  checked: value,
  name,
  inputRef,
  disabled,
  rootElement: RootElement = 'button',
  onClick,
  ...props
}, forwardedRef) => {
  const isDisabled = disabled || false;
  const isControlled = useMemo(() => value !== undefined, [value]);
  const [internalState, setInternalState] = useState(getIsOn(value));
  const state = useMemo(() => isControlled ? value : internalState, [value, internalState, isControlled]);
  const on = useMemo(() => getIsOn(state), [state]);
  const isIndeterminate = useMemo(() => !on && value === INDETERMINATE, [on, value]);
  const innerRef = useRef<HTMLInputElement>(null);
  const combinedRef = mergeRefs([innerRef, inputRef || null]);
  const setOnInput = (isOn: boolean) => {
    if (innerRef.current?.checked !== isOn) {
      innerRef.current?.click?.();
    }
  };

  // sync on state with input
  useEffect(() => {
    if (isControlled) return;
    setOnInput(Boolean(on));
    if (onChange && innerRef.current) {
      onChange({
        type: 'change',
        target: innerRef.current
      } as ChangeEvent<HTMLInputElement>);
    }
  }, [isControlled, on, onChange]);
  const handleButtonClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    onClick?.(e);
    if (isControlled) {
      setOnInput(!on);
      return;
    }
    setInternalState(!on);
  }, [onClick, isControlled, on]);
  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (isControlled) {
      onChange?.(e);
      return;
    }
    setInternalState(e.target.checked);
  }, [isControlled, onChange]);
  return <RootElement {...props} ref={forwardedRef} type="button" role="checkbox" onClick={handleButtonClick} checked={on || false} aria-checked={on || false} disabled={isDisabled} aria-disabled={isDisabled}>
        <input type="checkbox" style={{
      display: 'none'
    }} aria-hidden={true} tabIndex={-1} ref={combinedRef} name={name} data-indeterminate={isIndeterminate} onChange={handleInputChange} checked={on} disabled={isDisabled} />
        {children}
      </RootElement>;
});