import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../base';
import { useSubModule } from '../../../hooks';
import { BackgroundColorField } from './BackgroundColorField';
export const BackgroundColor: FC = () => {
  const {
    shouldRender
  } = useSubModule(DDPSubModulesKeys.backgroundColor);
  if (!shouldRender) return null;
  return <SubModule title="Background" hideHeader data-sentry-element="SubModule" data-sentry-component="BackgroundColor" data-sentry-source-file="BackgroundColor.tsx">
      <BackgroundColorField data-sentry-element="BackgroundColorField" data-sentry-source-file="BackgroundColor.tsx" />
    </SubModule>;
};