/**
 *  THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 */

/* eslint-disable */
// @ts-nocheck

import { createDrimz, type PropsOf } from '@jux/dui';
import React, { FC, forwardRef } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType } from '@jux/data-entities';
import { JuxReactElements } from '@jux/react';
const {
  styled
} = createDrimz();
type Navigation_tokens_Logoicon_root_Props = PropsOf<typeof JuxReactElements.JuxSvg>;
const Navigation_tokens_Logoicon_root: FC<Navigation_tokens_Logoicon_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxSvg {...props} ref={ref} />);
Navigation_tokens_Logoicon_root.displayName = 'Navigation_tokens_Logoicon_root';
Navigation_tokens_Logoicon_root.defaultProps = {
  rootElement: styled('svg', {
    styles: {
      root: {}
    }
  }) as Navigation_tokens_Logoicon_root_Props['rootElement']
};
export type Navigation_tokens_Logoicon_Props = Navigation_tokens_Logoicon_root_Props;
export const Navigation_tokens_Logoicon: FC<Navigation_tokens_Logoicon_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxSvg>, Navigation_tokens_Logoicon_Props>((props, ref) => <Navigation_tokens_Logoicon_root {...props} ref={ref} />);
Navigation_tokens_Logoicon.displayName = 'Navigation_tokens_Logoicon';
Navigation_tokens_Logoicon.defaultProps = {
  content: '<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">\n<g clip-path="url(#clip0_1702_77511)">\n<rect width="28" height="28" rx="2" fill="#1A1A1A"></rect>\n<path d="M14.1265 14L10 20H14.7522L18.8 14L14.7522 8H10L14.1265 14Z" fill="white"></path>\n</g>\n<defs>\n<clipPath id="clip0_1702_77511">\n<rect width="28" height="28" rx="2" fill="white"></rect>\n</clipPath>\n</defs>\n</svg>\n'
};
type Navigation_tokens_slot_text_root_Props = PropsOf<typeof JuxReactElements.JuxText>;
const Navigation_tokens_slot_text_root: FC<Navigation_tokens_slot_text_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxText {...props} ref={ref} />);
Navigation_tokens_slot_text_root.displayName = 'Navigation_tokens_slot_text_root';
Navigation_tokens_slot_text_root.defaultProps = {
  rootElement: styled('p', {
    styles: {
      root: {
        color: '#000000',
        width: 'auto',
        height: 'auto',
        display: 'inline-block',
        fontSize: '{typography.core.12_regular.fontSize}',
        fontFamily: '{typography.core.12_regular.fontFamily}',
        fontWeight: '{typography.core.12_regular.fontWeight}',
        lineHeight: '{typography.core.12_regular.lineHeight}',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        letterSpacing: '{typography.core.12_regular.letterSpacing}'
      }
    }
  }) as Navigation_tokens_slot_text_root_Props['rootElement']
};
export type Navigation_tokens_slot_text_Props = Navigation_tokens_slot_text_root_Props;
export const Navigation_tokens_slot_text: FC<Navigation_tokens_slot_text_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxText>, Navigation_tokens_slot_text_Props>((props, ref) => <Navigation_tokens_slot_text_root {...props} ref={ref} />);
Navigation_tokens_slot_text.displayName = 'Navigation_tokens_slot_text';
Navigation_tokens_slot_text.defaultProps = {
  text: 'Token Management'
};
type Navigation_tokens_slot_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const Navigation_tokens_slot_root: FC<Navigation_tokens_slot_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
Navigation_tokens_slot_root.displayName = 'Navigation_tokens_slot_root';
Navigation_tokens_slot_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        padding: '0px 0px 0px 0px',
        alignItems: 'center',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF'
      }
    }
  }) as Navigation_tokens_slot_root_Props['rootElement']
};
export type Navigation_tokens_slot_Props = Navigation_tokens_slot_root_Props & {
  Navigation_tokens_slot_text_Props?: Navigation_tokens_slot_text_Props;
};
export const Navigation_tokens_slot: FC<Navigation_tokens_slot_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, Navigation_tokens_slot_Props>(({
  Navigation_tokens_slot_text_Props,
  ...props
}, ref) => <Navigation_tokens_slot_root {...props} ref={ref} data-jux-context="868aeff0-04cb-4420-a207-7cac62e5571c">
      {props.children || <>
          <Navigation_tokens_slot_text {...Navigation_tokens_slot_text_Props} data-jux-context="bd2259b9-59c3-47c6-8f7f-8fc700be90a5" />
        </>}
    </Navigation_tokens_slot_root>);
Navigation_tokens_slot.displayName = 'Navigation_tokens_slot';
Navigation_tokens_slot.defaultProps = {};
import { Import_button, type Import_button_Props } from '../';
type Navigation_tokens_right_actions_slot_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const Navigation_tokens_right_actions_slot_root: FC<Navigation_tokens_right_actions_slot_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
Navigation_tokens_right_actions_slot_root.displayName = 'Navigation_tokens_right_actions_slot_root';
Navigation_tokens_right_actions_slot_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        padding: '10px 10px 10px 10px',
        alignItems: 'center',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF'
      }
    }
  }) as Navigation_tokens_right_actions_slot_root_Props['rootElement']
};
export type Navigation_tokens_right_actions_slot_Props = Navigation_tokens_right_actions_slot_root_Props & {
  Import_button_Props?: Import_button_Props;
};
export const Navigation_tokens_right_actions_slot: FC<Navigation_tokens_right_actions_slot_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, Navigation_tokens_right_actions_slot_Props>(({
  Import_button_Props,
  ...props
}, ref) => <Navigation_tokens_right_actions_slot_root {...props} ref={ref} data-jux-context="c3681437-e4e7-45f3-8731-7d1b4e8934cc">
      {props.children || <>
          <Import_button disabled={false} hierarchy={'primary'} Size={'big'} {...Import_button_Props} />
        </>}
    </Navigation_tokens_right_actions_slot_root>);
Navigation_tokens_right_actions_slot.displayName = 'Navigation_tokens_right_actions_slot';
Navigation_tokens_right_actions_slot.defaultProps = {};
import { IconButton, type IconButton_Props } from '../';
type Navigation_tokens_right_actions_actions_slot_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const Navigation_tokens_right_actions_actions_slot_root: FC<Navigation_tokens_right_actions_actions_slot_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
Navigation_tokens_right_actions_actions_slot_root.displayName = 'Navigation_tokens_right_actions_actions_slot_root';
Navigation_tokens_right_actions_actions_slot_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        gap: 'normal {dimension.core.size_sm}',
        width: 'auto',
        height: 'auto',
        display: 'flex',
        padding: '0px 0px 0px 0px',
        alignItems: 'center',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF'
      }
    }
  }) as Navigation_tokens_right_actions_actions_slot_root_Props['rootElement']
};
export type Navigation_tokens_right_actions_actions_slot_Props = Navigation_tokens_right_actions_actions_slot_root_Props & {
  IconButton_1_Props?: IconButton_Props;
  IconButton_2_Props?: IconButton_Props;
};
export const Navigation_tokens_right_actions_actions_slot: FC<Navigation_tokens_right_actions_actions_slot_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, Navigation_tokens_right_actions_actions_slot_Props>(({
  IconButton_1_Props,
  IconButton_2_Props,
  ...props
}, ref) => <Navigation_tokens_right_actions_actions_slot_root {...props} ref={ref} data-jux-context="d5580229-6f6e-43e4-b2c3-110cafc4461f">
      {props.children || <>
          <IconButton disabled={false} hierarchy={'primary'} {...IconButton_1_Props} />
          <IconButton disabled={false} hierarchy={'primary'} {...IconButton_2_Props} />
        </>}
    </Navigation_tokens_right_actions_actions_slot_root>);
Navigation_tokens_right_actions_actions_slot.displayName = 'Navigation_tokens_right_actions_actions_slot';
Navigation_tokens_right_actions_actions_slot.defaultProps = {};
type Navigation_tokens_right_actions_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const Navigation_tokens_right_actions_root: FC<Navigation_tokens_right_actions_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
Navigation_tokens_right_actions_root.displayName = 'Navigation_tokens_right_actions_root';
Navigation_tokens_right_actions_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        gap: 'normal {dimension.core.size_sm}',
        width: 'auto',
        height: 'auto',
        display: 'flex',
        padding: '0px 0px 0px 0px',
        alignItems: 'center',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF'
      }
    }
  }) as Navigation_tokens_right_actions_root_Props['rootElement']
};
export type Navigation_tokens_right_actions_Props = Navigation_tokens_right_actions_root_Props & {
  Navigation_tokens_right_actions_slot_Props?: Navigation_tokens_right_actions_slot_Props;
  Navigation_tokens_right_actions_actions_slot_Props?: Navigation_tokens_right_actions_actions_slot_Props;
};
export const Navigation_tokens_right_actions: FC<Navigation_tokens_right_actions_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, Navigation_tokens_right_actions_Props>(({
  Navigation_tokens_right_actions_slot_Props,
  Navigation_tokens_right_actions_actions_slot_Props,
  ...props
}, ref) => <Navigation_tokens_right_actions_root {...props} ref={ref} data-jux-context="6534d782-225d-46a0-8518-ba11e6d8a050">
        {props.children || <>
            <Navigation_tokens_right_actions_slot {...Navigation_tokens_right_actions_slot_Props} data-jux-context="c3681437-e4e7-45f3-8731-7d1b4e8934cc" />
            <Navigation_tokens_right_actions_actions_slot {...Navigation_tokens_right_actions_actions_slot_Props} data-jux-context="d5580229-6f6e-43e4-b2c3-110cafc4461f" />
          </>}
      </Navigation_tokens_right_actions_root>);
Navigation_tokens_right_actions.displayName = 'Navigation_tokens_right_actions';
Navigation_tokens_right_actions.defaultProps = {};
type Navigation_tokens_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const Navigation_tokens_root: FC<Navigation_tokens_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
Navigation_tokens_root.displayName = 'Navigation_tokens_root';
Navigation_tokens_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        width: '1440px',
        height: '48px',
        display: 'flex',
        padding: '0px 10px 0px 10px',
        alignItems: 'center',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF'
      },
      variants: [{
        propsValues: {},
        styles: {
          '[data-jux-context="c3681437-e4e7-45f3-8731-7d1b4e8934cc"]': {
            padding: '0px 0px 0px 0px'
          }
        }
      }]
    }
  }) as Navigation_tokens_root_Props['rootElement']
};
export type Navigation_tokens_Props = Navigation_tokens_root_Props & {
  Navigation_tokens_Logoicon_Props?: Navigation_tokens_Logoicon_Props;
  Navigation_tokens_slot_Props?: Navigation_tokens_slot_Props;
  Navigation_tokens_right_actions_Props?: Navigation_tokens_right_actions_Props;
};
export const Navigation_tokens: FC<Navigation_tokens_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, Navigation_tokens_Props>(({
  Navigation_tokens_Logoicon_Props,
  Navigation_tokens_slot_Props,
  Navigation_tokens_right_actions_Props,
  ...props
}, ref) => <Navigation_tokens_root {...props} ref={ref} data-jux-context="9ab946b1-cd36-4f38-9bf5-31f36c6a51d3">
      {props.children || <>
          <Navigation_tokens_Logoicon {...Navigation_tokens_Logoicon_Props} data-jux-context="d3492140-b5cb-4127-95e5-bcdf6d5593f6" />
          <Navigation_tokens_slot {...Navigation_tokens_slot_Props} data-jux-context="868aeff0-04cb-4420-a207-7cac62e5571c" />
          <Navigation_tokens_right_actions {...Navigation_tokens_right_actions_Props} data-jux-context="6534d782-225d-46a0-8518-ba11e6d8a050" />
        </>}
    </Navigation_tokens_root>);
Navigation_tokens.displayName = 'Navigation_tokens';
Navigation_tokens.defaultProps = {};