import { useCallback } from 'react';
import { useModal } from '../base-hooks';
import { ConfirmationModalContent } from './ConfirmationModalContent';
import { OpenConfirmationModalProps, UseConfirmationModalProps } from './ConfirmationModal.interface';
export const useOpenConfirmationModal = () => {
  const {
    open,
    close
  } = useModal<UseConfirmationModalProps>();
  const openConfirmationModal = useCallback(({
    onClose,
    onCancel,
    onConfirm,
    ...props
  }: OpenConfirmationModalProps) => {
    const handleCancel = () => {
      onCancel?.();
      close();
    };
    const handleConfirm = () => {
      onConfirm?.();
      close();
    };
    open({
      handlers: {
        onConfirm,
        onClose: onClose || close,
        onCancel: onCancel || close
      },
      content: <ConfirmationModalContent {...props} onCancel={handleCancel} onConfirm={handleConfirm} />
    });
  }, [close, open]);
  return {
    openConfirmationModal
  };
};