/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const StatusSuccessIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M12.5606 4L13.6213 5.06066L7.09096 11.591L3.00001 7.56066L4.06067 6.5L7.09096 9.46967L12.5606 4Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="StatusSuccessIcon" data-sentry-source-file="StatusSuccessIcon.tsx" />;