import { FC, useLayoutEffect, useRef, useState } from 'react';
import { useHover } from 'usehooks-ts';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { AutocompleteProps } from './Autocomplete.interface';
import * as S from './Autocomplete.style';
import { AutocompleteInput } from './AutocompleteInput';
import { useTokenSelectionAutocomplete } from './useTokenSelectionAutocomplete';
import { useOptionsMenu } from './useOptionsMenu';
import { HIGHLIGHTED_OPTION_STYLE } from './Autocomplete.style';
const WRAPPER_Z_INDEX = 2;
export const TokenSelectionAutocomplete: FC<AutocompleteProps> = ({
  value,
  options: initialOptions,
  inputName,
  hideSearchIcon,
  isComputed,
  renderOption,
  onSelect,
  onBlur,
  onEnter,
  onEscape,
  inputProps,
  inputWrapperProps,
  wrapperProps,
  optionsWrapperProps
}) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const isHovered = useHover<HTMLDivElement>(wrapperRef);
  const {
    getMenuProps,
    getInputProps,
    handleClear,
    options,
    getItemProps,
    triggerInputBlur,
    triggerInputFocus,
    highlightedIndex,
    optionsWrapperRef
  } = useTokenSelectionAutocomplete({
    initialOptions,
    selectedOption: value,
    onSelect
  });
  const {
    handleOnBlur,
    handleOnFocus,
    handleSelection
  } = useOptionsMenu({
    onBlur,
    triggerInputBlur,
    setIsInputFocused
  });
  useLayoutEffect(() => {
    triggerInputFocus();
  }, [triggerInputFocus]);
  return <MuiGrid container ref={wrapperRef} zIndex={WRAPPER_Z_INDEX} {...wrapperProps} data-sentry-element="MuiGrid" data-sentry-component="TokenSelectionAutocomplete" data-sentry-source-file="TokenSelectionAutocomplete.tsx">
      <AutocompleteInput wrapperProps={inputWrapperProps} {...inputProps} {...getInputProps()} isComputed={isComputed} name={inputName} onFocus={handleOnFocus} onEnter={onEnter} onEscape={onEscape} onBlur={handleOnBlur} onClear={handleClear} isHovered={isHovered} isFocused={isInputFocused} hideSearchIcon={hideSearchIcon} data-sentry-element="AutocompleteInput" data-sentry-source-file="TokenSelectionAutocomplete.tsx" />
      <S.AutocompleteOptions {...optionsWrapperProps} {...getMenuProps({
      onClick: handleSelection,
      ref: optionsWrapperRef
    })} data-sentry-element="unknown" data-sentry-source-file="TokenSelectionAutocomplete.tsx">
        {options.length > 0 ? options.map((option, index) => <S.AutocompleteOptionWrapper item container key={`${option.label}${index}`} sx={{
        ...(highlightedIndex === index && HIGHLIGHTED_OPTION_STYLE)
      }} {...getItemProps({
        item: option,
        index
      })}>
              {renderOption({
          option,
          isSelected: Boolean(value) && option.label.includes(value)
        })}
            </S.AutocompleteOptionWrapper>) : <MuiGrid item container>
            <S.AutocompleteNoOptionsTypography>
              Nothing found
            </S.AutocompleteNoOptionsTypography>
          </MuiGrid>}
      </S.AutocompleteOptions>
    </MuiGrid>;
};