import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { AlignField } from './AlignField';
import { DirectionField } from './DirectionField';
export const AlignAndDirection: FC = () => {
  const {
    shouldRender
  } = useSubModule(DDPSubModulesKeys.alignment);
  if (!shouldRender) return null;
  return <SubModule title="Align / Direction" data-sentry-element="SubModule" data-sentry-component="AlignAndDirection" data-sentry-source-file="AlignAndDirection.tsx">
      <MuiGrid container sx={{
      flexDirection: 'row',
      alignItems: 'center',
      gap: '24px'
    }} data-sentry-element="MuiGrid" data-sentry-source-file="AlignAndDirection.tsx">
        <AlignField data-sentry-element="AlignField" data-sentry-source-file="AlignAndDirection.tsx" />
        <DirectionField data-sentry-element="DirectionField" data-sentry-source-file="AlignAndDirection.tsx" />
      </MuiGrid>
    </SubModule>;
};