import { FC } from 'react';
import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';
import { DimensionField } from '../../../../base';
import { useDimensionField, useFieldValues, useSetFieldValue } from '../../../../hooks';
const FIELD_KEY = DDPPrimitiveFieldsKeys.minWidth;
export const MinWidthField: FC = () => {
  const {
    value,
    initialValue
  } = useFieldValues(FIELD_KEY);
  const {
    saveValue: setFieldValue
  } = useSetFieldValue(FIELD_KEY);
  const props = useDimensionField({
    value,
    initialValue,
    saveChanges: setFieldValue,
    fieldName: FIELD_KEY,
    options: {
      supportAutoValue: true
    }
  });
  return <DimensionField icon="DDP_FIELD_MIN_WIDTH" tooltipTitle="Min width" {...props} data-sentry-element="DimensionField" data-sentry-component="MinWidthField" data-sentry-source-file="MinWidthField.tsx" />;
};