/**
 *  THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 */

/* eslint-disable */
// @ts-nocheck

import { createDrimz, type PropsOf } from '@jux/dui';
import React, { FC, forwardRef } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType } from '@jux/data-entities';
import { JuxReactElements } from '@jux/react';
const {
  styled
} = createDrimz();
type TopNavigation_item_text_root_Props = PropsOf<typeof JuxReactElements.JuxText>;
const TopNavigation_item_text_root: FC<TopNavigation_item_text_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxText {...props} ref={ref} />);
TopNavigation_item_text_root.displayName = 'TopNavigation_item_text_root';
TopNavigation_item_text_root.defaultProps = {
  rootElement: styled('p', {
    styles: {
      root: {
        color: '#000000',
        width: 'auto',
        height: 'auto',
        display: 'inline-block',
        fontSize: '20px',
        fontFamily: 'Inter',
        lineHeight: '130%',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word'
      },
      variants: [{
        propsValues: {
          text: 'Text Element'
        },
        styles: {
          fontSize: '13px'
        }
      }, {
        propsValues: {
          text: 'Navigation item'
        },
        styles: {
          fontSize: '13px'
        }
      }, {
        propsValues: {
          text: 'Elements'
        },
        styles: {
          fontSize: '13px'
        }
      }]
    }
  }) as TopNavigation_item_text_root_Props['rootElement']
};
export type TopNavigation_item_text_Props = TopNavigation_item_text_root_Props;
export const TopNavigation_item_text: FC<TopNavigation_item_text_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxText>, TopNavigation_item_text_Props>((props, ref) => <TopNavigation_item_text_root {...props} ref={ref} />);
TopNavigation_item_text.displayName = 'TopNavigation_item_text';
TopNavigation_item_text.defaultProps = {
  text: 'Nav item'
};
type TopNavigation_item_Dropdown_arrow_small_root_Props = PropsOf<typeof JuxReactElements.JuxSvg>;
const TopNavigation_item_Dropdown_arrow_small_root: FC<TopNavigation_item_Dropdown_arrow_small_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxSvg {...props} ref={ref} />);
TopNavigation_item_Dropdown_arrow_small_root.displayName = 'TopNavigation_item_Dropdown_arrow_small_root';
TopNavigation_item_Dropdown_arrow_small_root.defaultProps = {
  rootElement: styled('svg', {
    styles: {
      root: {
        color: 'rgba(97,97,97,1)'
      }
    }
  }) as TopNavigation_item_Dropdown_arrow_small_root_Props['rootElement']
};
export type TopNavigation_item_Dropdown_arrow_small_Props = TopNavigation_item_Dropdown_arrow_small_root_Props;
export const TopNavigation_item_Dropdown_arrow_small: FC<TopNavigation_item_Dropdown_arrow_small_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxSvg>, TopNavigation_item_Dropdown_arrow_small_Props>((props, ref) => <TopNavigation_item_Dropdown_arrow_small_root {...props} ref={ref} />);
TopNavigation_item_Dropdown_arrow_small.displayName = 'TopNavigation_item_Dropdown_arrow_small';
TopNavigation_item_Dropdown_arrow_small.defaultProps = {
  content: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path fill-rule="evenodd" clip-rule="evenodd" d="M11.2104 6.14645C11.4056 6.34171 11.4056 6.65829 11.2104 6.85355L8.02838 10.0355L4.8464 6.85355C4.65114 6.65829 4.65114 6.34171 4.8464 6.14645C5.04166 5.95118 5.35824 5.95118 5.5535 6.14645L8.02838 8.62132L10.5033 6.14645C10.6985 5.95118 11.0151 5.95118 11.2104 6.14645Z" fill="currentColor"></path>\n</svg>\n'
};
type TopNavigation_item_root_Props = PropsOf<typeof JuxReactElements.JuxDiv> & {
  selected: boolean;
};
const TopNavigation_item_root: FC<TopNavigation_item_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
TopNavigation_item_root.displayName = 'TopNavigation_item_root';
TopNavigation_item_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        gap: 'normal 2px',
        width: 'auto',
        height: '48px',
        display: 'flex',
        padding: '10px {dimension.core.size_xs} 10px {dimension.core.size_sm}',
        alignItems: 'center',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF',
        '&:hover': {
          backgroundColor: '{color.core.grays_150}'
        }
      },
      variants: [{
        propsValues: {
          selected: true
        },
        styles: {
          backgroundColor: '{color.core.grays_150}',
          padding: '0px {dimension.core.size_xs} 0px {dimension.core.size_sm}',
          '[data-jux-context="b0a4def1-05f1-42d8-9980-babb96bd1f74"]': {
            fontSize: '12px'
          }
        }
      }, {
        propsValues: {
          selected: false
        },
        styles: {
          padding: '0px {dimension.core.size_xs} 0px {dimension.core.size_sm}',
          '[data-jux-context="b0a4def1-05f1-42d8-9980-babb96bd1f74"]': {
            fontSize: '12px'
          },
          '&:hover': {
            backgroundColor: '{color.core.grays_150}'
          }
        }
      }]
    }
  }) as TopNavigation_item_root_Props['rootElement']
};
export type TopNavigation_item_Props = TopNavigation_item_root_Props & {
  TopNavigation_item_text_Props?: TopNavigation_item_text_Props;
  TopNavigation_item_Dropdown_arrow_small_Props?: TopNavigation_item_Dropdown_arrow_small_Props;
};
export const TopNavigation_item: FC<TopNavigation_item_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, TopNavigation_item_Props>(({
  TopNavigation_item_text_Props,
  TopNavigation_item_Dropdown_arrow_small_Props,
  ...props
}, ref) => <TopNavigation_item_root {...props} ref={ref} data-jux-context="2de89539-d323-4b57-8523-de72454cf869">
      {props.children || <>
          <TopNavigation_item_text {...TopNavigation_item_text_Props} data-jux-context="b0a4def1-05f1-42d8-9980-babb96bd1f74" />
          <TopNavigation_item_Dropdown_arrow_small {...TopNavigation_item_Dropdown_arrow_small_Props} data-jux-context="e3ae5aac-b601-488a-b696-9164c3e08535" />
        </>}
    </TopNavigation_item_root>);
TopNavigation_item.displayName = 'TopNavigation_item';
TopNavigation_item.defaultProps = {
  selected: 'false'
};