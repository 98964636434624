import type { Draft as WritableDraft } from 'mutative';
import { CanjuxState, JuxStore } from '@jux/canjux/core';
import {
  JuxComponentData,
  ComponentTagNames,
  NodeType,
} from '@jux/data-entities';
import logger from '@jux/ui-logger';
import { StateValidator } from './validator.interface';
import { deleteNode } from '../actions';
import { initAssetData } from '@jux/ui/hooks';
import { systemAssets } from '@jux/elements';

const recoverSvgElmentOrSourceComponent = ({
  component,
  assetContent,
  assetName,
  assets,
}: {
  component: WritableDraft<JuxComponentData>;
  assetName?: string;
  assetContent: string;
  assets: WritableDraft<JuxStore['assets']>;
}) => {
  // Get potential new asset data - includes serialized content
  const { assetData: newAssetData } = initAssetData({
    assetName: `${assetName}.svg` || 'Untitled Asset.svg',
    assetContent: assetContent,
  });

  // if recovering an svg Library component - add the source id connection,
  // for svg source component - this would soon be depracated and there would be non - svg elements will direct to asset data only
  // until then we recreate asset data from them and the connection is through the same id
  if (component.type === NodeType.LIBRARY_COMPONENT) {
    // Just add as a new asset
    assets[component.id] = newAssetData;
    return;
  }

  // Check if there is an asset with the same content (after serialization)
  const assetId = Object.entries(assets).find(
    ([, assetData]) => assetData.content === newAssetData.content
  )?.[0];

  if (assetId) {
    logger.info(
      `data recovery: Found asset for missing connected asset ${component.id} -> ${assetId}`
    );

    component.sourceComponentId = assetId;
  } else {
    logger.info(
      `data recovery: Creating asset ${newAssetData.name} with content length ${newAssetData?.content?.length}`
    );
  }
};

const addSystemAssets = (state: WritableDraft<CanjuxState>) => {
  Object.entries(systemAssets).forEach(([assetId, assetData]) => {
    state.assets[assetId] = assetData;
  });
};

export const validateAssets: StateValidator = ({
  state,
  recover = false,
}: {
  state: WritableDraft<CanjuxState>;
  recover?: boolean;
}) => {
  if (recover) {
    addSystemAssets(state);
  }

  const svgCanvasComponents = Object.values(state.components).filter(
    (c) =>
      c.type === NodeType.ELEMENT && c?.tagName === ComponentTagNames.JuxSvg
  );

  // Validate all svgs are library components
  const svgLocalComponents = Object.values(state.components).filter(
    (c) =>
      c.type === NodeType.LOCAL_COMPONENT &&
      c?.tagName === ComponentTagNames.JuxSvg
  );
  for (const svgLocalComponent of svgLocalComponents) {
    svgLocalComponent.type = NodeType.LIBRARY_COMPONENT;
  }

  const svgLibraryComponents = Object.values(state.components).filter(
    (c) =>
      c.type === NodeType.LIBRARY_COMPONENT &&
      c?.tagName === ComponentTagNames.JuxSvg
  );
  for (const svgLibraryComponent of svgLibraryComponents) {
    const props = svgLibraryComponent.config?.props;
    const assetData = state.assets[svgLibraryComponent.id];
    if (!assetData) {
      if (recover) {
        if (props && props?.content) {
          logger.info(
            `data recovery: recovering library component svg id ${svgLibraryComponent.id} that does not have asset data`
          );
          // Validate source components
          recoverSvgElmentOrSourceComponent({
            component: svgLibraryComponent,
            assetContent: props?.content,
            assetName: svgLibraryComponent.displayName,
            assets: state.assets,
          });
        } else {
          logger.info(
            `data recovery: deleting damanged library component svg id ${svgLibraryComponent.id} that does not have asset data`
          );
          const instances = Object.values(state.components).filter(
            (c) => c?.sourceComponentId === svgLibraryComponent.id
          );
          for (const instance of instances) {
            deleteNode({
              canvasName: state.currentCanvasName,
              nodeId: instance.id,
              state,
            });
          }

          delete state.components[svgLibraryComponent.id];
        }
      } else {
        throw new Error('Invalid asset: no asset data found');
      }
    }
  }

  // Validate canvas nodes
  for (const c of svgCanvasComponents) {
    const sourceSvg = c?.sourceComponentId
      ? state.assets[c.sourceComponentId]
      : undefined;
    if (sourceSvg) {
      // This asset is valid
      continue;
    }

    const sourceComponent = c.sourceComponentId
      ? state.components[c.sourceComponentId]
      : undefined;

    if (recover) {
      if (sourceComponent && sourceComponent.config?.props?.content) {
        logger.info(
          `data recovery: recovering damaged svg element id ${c.id} that does not have asset data`
        );
        recoverSvgElmentOrSourceComponent({
          component: sourceComponent,
          assetContent: sourceComponent.config?.props?.content,
          assetName: sourceComponent.displayName,
          assets: state.assets,
        });
      } else if (c.config?.props?.content) {
        logger.info(
          `data recovery: recovering damaged svg element id ${c.id} that does not have asset data but has content`
        );
        recoverSvgElmentOrSourceComponent({
          component: c,
          assetContent: c.config.props.content,
          assetName: c.displayName,
          assets: state.assets,
        });
      } else {
        logger.info(
          `data recovery: deleting damaged svg element id ${c.id} that does not have asset data`
        );
        deleteNode({
          canvasName: state.currentCanvasName,
          nodeId: c.id,
          state,
        });
      }
    } else {
      throw new Error('Invalid asset: no source found');
    }
  }
};
