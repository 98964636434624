/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { FC } from 'react';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
import { IconBaseProps } from '../../Icons.interface';
export const TextareaFieldElementPanelIcon: FC<IconBaseProps> = ({
  backgroundColor = '#1F1F1F',
  ...props
}) => <CustomIcon tag="svg" width="68" height="49" attr={{
  viewBox: '0 0 68 49'
}} child={[{
  tag: 'rect',
  attr: {
    y: '0.5',
    width: '21',
    height: '4',
    rx: '2',
    fill: 'white'
  },
  child: []
}, {
  tag: 'rect',
  attr: {
    y: '10.5',
    width: '68',
    height: '38',
    rx: '4',
    fill: 'white'
  },
  child: []
}, {
  tag: 'rect',
  attr: {
    x: '8',
    y: '16.5',
    width: '52',
    height: '6',
    rx: '3',
    fill: backgroundColor
  },
  child: []
}, {
  tag: 'rect',
  attr: {
    x: '8',
    y: '26.5',
    width: '52',
    height: '6',
    rx: '3',
    fill: backgroundColor
  },
  child: []
}, {
  tag: 'rect',
  attr: {
    x: '8',
    y: '36.5',
    width: '23',
    height: '6',
    rx: '3',
    fill: backgroundColor
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="TextareaFieldElementPanelIcon" data-sentry-source-file="TextareaFieldElementPanelIcon.tsx" />;