import { ComponentProps, forwardRef } from 'react';
import { LoadingButtonWithIcon as Button } from '@jux/ui/components/common';
import { EDITOR_DDP_FIELD_DETACH_TOKEN } from '@jux/ui/theme/palette';
import { withImportant } from '@jux/ui/utils/css';
const BUTTON_CLASS_NAME = 'token-selection-button';
export const TokenSelectionButton = forwardRef<HTMLButtonElement, {
  isTokenized?: boolean;
} & ComponentProps<typeof Button>>(({
  isTokenized,
  onClick,
  ...props
}, ref) => <Button tabIndex={0} ref={ref} onClick={onClick} className={BUTTON_CLASS_NAME} icon={isTokenized ? 'DDP_DETACH_TOKEN' : 'DDP_TOKEN'} iconProps={{
  sx: {
    height: theme => theme.drimz.size.editor.ddp.field.token.button.iconWrapper.height,
    minWidth: theme => theme.drimz.size.editor.ddp.field.token.button.iconWrapper.width,
    borderRadius: theme => theme.drimz.size.borderRadius.main,
    [`.${BUTTON_CLASS_NAME}:hover &, .${BUTTON_CLASS_NAME}:focus-visible &`]: {
      backgroundColor: theme => theme.drimz.palette.background.hover
    }
  }
}} sx={{
  height: theme => theme.drimz.size.editor.ddp.field.token.button.height,
  minWidth: theme => theme.drimz.size.editor.ddp.field.token.button.width,
  borderRadius: theme => theme.drimz.size.borderRadius.main,
  backgroundColor: theme => withImportant(theme.drimz.palette.transparent),
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0
}} color={EDITOR_DDP_FIELD_DETACH_TOKEN} {...props} />);