import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { DDPTypographyField } from './DDPTypographyField';
export const Typography: FC = () => {
  const {
    shouldRender
  } = useSubModule(DDPSubModulesKeys.typography);
  if (!shouldRender) return null;
  return <SubModule title="Typography" data-sentry-element="SubModule" data-sentry-component="Typography" data-sentry-source-file="Typography.tsx">
      <DDPTypographyField data-sentry-element="DDPTypographyField" data-sentry-source-file="Typography.tsx" />
    </SubModule>;
};