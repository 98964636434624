import { CANJUX_NODES_CONTAINER, CanvasNode, useGlobalMutationObserver, useGlobalResizeObserver } from '@jux/canjux/core';
import { FC, memo, useEffect, useRef } from 'react';
import { useVisibleNodes } from '../../hooks/useVisibleNodes';

// install observer to track each node's dimensions
export const NodeRenderer: FC = memo(() => {
  const rootNodes = useVisibleNodes();
  const ref = useRef<HTMLDivElement>(null);
  const resizeObserver = useGlobalResizeObserver();
  const mutationObserver = useGlobalMutationObserver();
  useEffect(() => {
    if (ref.current) {
      mutationObserver.observe(ref.current, {
        attributes: true,
        attributeOldValue: true,
        childList: true,
        subtree: true,
        characterData: true
      });
    }
    return () => mutationObserver.disconnect();
  }, [mutationObserver]);
  return <div id={CANJUX_NODES_CONTAINER} ref={ref} style={{
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    pointerEvents: 'none',
    transformOrigin: '0 0'
  }}>
      {rootNodes?.toReversed().map(nodeId => <CanvasNode key={nodeId} nodeId={nodeId} resizeObserver={resizeObserver} />)}
    </div>;
});
NodeRenderer.displayName = 'NodeRenderer';