/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const WidthDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M2 5.25C2.41421 5.25 2.75 5.58579 2.75 6V7.25H13.25V6C13.25 5.58579 13.5858 5.25 14 5.25C14.4142 5.25 14.75 5.58579 14.75 6V10C14.75 10.4142 14.4142 10.75 14 10.75C13.5858 10.75 13.25 10.4142 13.25 10V8.75H2.75V10C2.75 10.4142 2.41421 10.75 2 10.75C1.58579 10.75 1.25 10.4142 1.25 10V6C1.25 5.58579 1.58579 5.25 2 5.25Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="WidthDDPIcon" data-sentry-source-file="WidthDDPIcon.tsx" />;