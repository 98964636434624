/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const AlignItemsCenterColumnDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M7.5 14C7.77614 14 8 13.7761 8 13.5V11H10C10.5523 11 11 10.5523 11 10C11 9.44772 10.5523 9 10 9H8V7L12 7C12.5523 7 13 6.55228 13 6C13 5.44772 12.5523 5 12 5L8 5V2.5C8 2.22386 7.77614 2 7.5 2C7.22386 2 7 2.22386 7 2.5L7 5L4 5C3.44772 5 3 5.44772 3 6C3 6.55229 3.44772 7 4 7H7V9H6C5.44772 9 5 9.44772 5 10C5 10.5523 5.44772 11 6 11H7L7 13.5C7 13.7761 7.22386 14 7.5 14Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="AlignItemsCenterColumnDDPIcon" data-sentry-source-file="AlignItemsCenterColumnDDPIcon.tsx" />;