import { FC } from 'react';
import { Icons, Typography } from '@jux/ui/components/common';
import { MuiGrid, styled } from '@jux/ui/components/common/mui';
const ContentBody = styled(MuiGrid)(() => ({
  width: '85px',
  position: 'relative',
  ':after': {
    content: '"-"',
    position: 'absolute',
    right: 0
  }
}));
export const ColorMixedFieldContent: FC = () => <MuiGrid item display="flex" alignItems="center" gap="8px" data-sentry-element="MuiGrid" data-sentry-component="ColorMixedFieldContent" data-sentry-source-file="ColorMixedFieldContent.tsx">
    <MuiGrid item data-sentry-element="MuiGrid" data-sentry-source-file="ColorMixedFieldContent.tsx">
      <Icons variant="DDP_FIELD_TEXT_MIXED_COLOR" data-sentry-element="Icons" data-sentry-source-file="ColorMixedFieldContent.tsx" />
    </MuiGrid>
    <ContentBody item data-sentry-element="ContentBody" data-sentry-source-file="ColorMixedFieldContent.tsx">
      <Typography variant="label-s-regular" data-sentry-element="Typography" data-sentry-source-file="ColorMixedFieldContent.tsx">Mixed</Typography>
    </ContentBody>
  </MuiGrid>;