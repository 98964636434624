import { JuxStore } from '@jux/canjux/core';
import { NodeType } from '@jux/data-entities';

const recursiveGetLocalComponentDependencies = ({
  componentId,
  components,
}: {
  componentId: string;
  components: JuxStore['components'];
}) => {
  const component = components[componentId];
  if (!component) return [];

  let localComponentInstances: string[] = [];
  switch (component.type) {
    case NodeType.INSTANCE:
      if (component.sourceComponentId) {
        const sourceComponent = components[component.sourceComponentId];
        if (sourceComponent?.type === NodeType.LOCAL_COMPONENT) {
          localComponentInstances.push(component.sourceComponentId);

          // Get the dependencies of the inner component as well
          localComponentInstances = localComponentInstances.concat(
            recursiveGetLocalComponentDependencies({
              componentId: component.sourceComponentId,
              components,
            })
          );
        }
      }
      break;
    case NodeType.LOCAL_COMPONENT:
    case NodeType.LOGICAL_SLOT:
    case NodeType.ELEMENT:
    case NodeType.DYNAMIC_SLOT:
      for (const childId of component.children) {
        localComponentInstances = localComponentInstances.concat(
          recursiveGetLocalComponentDependencies({
            componentId: childId,
            components,
          })
        );
      }
      break;
    case NodeType.LIBRARY_COMPONENT:
      // cannot have local component dependencies - dont search the tree
      break;
    default:
      throw new Error(`Unknown component type: ${component.type}`);
  }

  return localComponentInstances;
};

const removeDups = (arr: string[]) => {
  return [...new Set(arr)];
};

export const getLocalComponentDependencies = ({
  componentId,
  components,
}: {
  componentId: string;
  components: JuxStore['components'];
}): string[] => {
  return removeDups(
    recursiveGetLocalComponentDependencies({
      componentId,
      components,
    })
  );
};
