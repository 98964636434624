import { ComponentConfigWithStates } from '@jux/types';

export const findPathInStyles = ({
  path,
  styles,
}: {
  path: string;
  styles: ComponentConfigWithStates;
}): boolean => {
  const stringifiedStyles = JSON.stringify(styles);

  return stringifiedStyles.includes(path);
};
