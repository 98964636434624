import { ChangeEvent, ComponentType, forwardRef, useCallback, useState } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType, ComponentProps } from '@jux/data-entities';
type TextareaComponentType = {
  Component: ComponentType<ComponentProps<typeof COMPONENT_TAG_NAME.JuxTextarea>>;
};
type TextareaLiveModeProps = TextareaComponentType & ComponentProps<typeof COMPONENT_TAG_NAME.JuxTextarea>;
export const TextareaLiveMode = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxTextarea>, TextareaLiveModeProps>(({
  Component,
  onChange,
  value,
  ...props
}, ref) => {
  const [val, setVal] = useState(value);
  const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.(e);
    setVal(e.target.value);
  }, [onChange]);
  return <Component {...props} ref={ref} onChange={handleChange} value={val} />;
});