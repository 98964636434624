import { FC, useCallback } from 'react';
import { useDimensionField } from '../../../../hooks';
import { DimensionField } from '../../../../base';
import { useShadowsFields } from './useShadowsFields';
export const ShadowXField: FC = () => {
  const {
    value,
    initialValue,
    saveChanges
  } = useShadowsFields();
  const {
    offsetX
  } = value || {};
  const {
    offsetX: initialOffsetX
  } = initialValue || {};
  const setFieldValue = useCallback((newValue: string | undefined) => {
    saveChanges({
      offsetX: newValue
    });
  }, [saveChanges]);
  const props = useDimensionField({
    value: offsetX ?? '',
    initialValue: initialOffsetX ?? '',
    saveChanges: setFieldValue,
    fieldName: 'boxShadowX',
    options: {
      supportNegativeValue: true
    }
  });
  return <DimensionField icon="DDP_FIELD_BOX_SHADOW_X" tooltipTitle="X offset" {...props} data-sentry-element="DimensionField" data-sentry-component="ShadowXField" data-sentry-source-file="ShadowXField.tsx" />;
};