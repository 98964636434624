import { RefObject, useCallback } from 'react';
import { PoppersKey, usePopper } from '@jux/ui/components';
import { ComponentsPopper } from '../components';
export const useComponentsPopper = () => {
  const {
    open,
    isOpen
  } = usePopper(PoppersKey.components);
  const openComponentsPopper = useCallback((sourceElementRef: RefObject<HTMLElement>) => {
    open({
      content: <ComponentsPopper />,
      sourceElement: sourceElementRef.current
    });
  }, [open]);
  return {
    openComponentsPopper,
    isComponentsPopperOpen: isOpen()
  };
};