import { forwardRef } from 'react';
import { LoadingButtonWithIcon as Button, LoadingButtonWithIconProps as ButtonProps } from '@jux/ui/components/common';
import { LIVE_OR_EDIT_HOVER_BACKGROUND_COLOR } from '@jux/ui/theme/palette';
import { withImportant } from '@jux/ui/utils/css';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';
const BUTTON_HEIGHT = 28;
export const LiveOrEditButton = forwardRef<HTMLButtonElement, ButtonProps>(({
  children,
  sx,
  ...props
}, ref) => <Button ref={ref} color={theme => withImportant(props.disabled ? theme.drimz.palette.text.primary : theme.drimz.palette.text.secondary)} sx={mergeTyped({
  padding: '9px',
  paddingBottom: '10px',
  border: '1px solid',
  height: BUTTON_HEIGHT,
  borderColor: theme => theme.drimz.palette.divider.primary,
  // TODO: use the right color
  backgroundColor: theme => props.disabled ? theme.drimz.palette.background.hover : theme.drimz.palette.background.default,
  '&:hover': {
    backgroundColor: LIVE_OR_EDIT_HOVER_BACKGROUND_COLOR
  }
}, sx)} typographyVariant="liveOrEditButtonTypography" {...props}>
      {children}
    </Button>);