/**
 *  THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 */

/* eslint-disable */
// @ts-nocheck

import { createDrimz, type PropsOf } from '@jux/dui';
import React, { FC, forwardRef } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType } from '@jux/data-entities';
import { JuxReactElements } from '@jux/react';
const {
  styled
} = createDrimz();
type FeedbackModal_header_text_root_Props = PropsOf<typeof JuxReactElements.JuxText>;
const FeedbackModal_header_text_root: FC<FeedbackModal_header_text_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxText {...props} ref={ref} />);
FeedbackModal_header_text_root.displayName = 'FeedbackModal_header_text_root';
FeedbackModal_header_text_root.defaultProps = {
  rootElement: styled('p', {
    styles: {
      root: {
        color: '#000000',
        width: 'auto',
        height: 'auto',
        display: 'inline-block',
        fontSize: '20px',
        fontFamily: 'Inter',
        lineHeight: '130%',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word'
      }
    }
  }) as FeedbackModal_header_text_root_Props['rootElement']
};
export type FeedbackModal_header_text_Props = FeedbackModal_header_text_root_Props;
export const FeedbackModal_header_text: FC<FeedbackModal_header_text_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxText>, FeedbackModal_header_text_Props>((props, ref) => <FeedbackModal_header_text_root {...props} ref={ref} />);
FeedbackModal_header_text.displayName = 'FeedbackModal_header_text';
FeedbackModal_header_text.defaultProps = {
  text: 'Help us improve'
};
import { IconButton, type IconButton_Props } from '../';
type FeedbackModal_header_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const FeedbackModal_header_root: FC<FeedbackModal_header_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
FeedbackModal_header_root.displayName = 'FeedbackModal_header_root';
FeedbackModal_header_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        padding: '0px 0px 0px 0px',
        alignItems: 'center',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF'
      }
    }
  }) as FeedbackModal_header_root_Props['rootElement']
};
export type FeedbackModal_header_Props = FeedbackModal_header_root_Props & {
  FeedbackModal_header_text_Props?: FeedbackModal_header_text_Props;
  IconButton_Props?: IconButton_Props;
};
export const FeedbackModal_header: FC<FeedbackModal_header_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, FeedbackModal_header_Props>(({
  FeedbackModal_header_text_Props,
  IconButton_Props,
  ...props
}, ref) => <FeedbackModal_header_root {...props} ref={ref} data-jux-context="c10276a8-4b2e-43a3-927d-8279edc94618">
    {props.children || <>
        <FeedbackModal_header_text {...FeedbackModal_header_text_Props} data-jux-context="f3d9f383-a84d-4ff4-a841-0640f80786fc" />
        <IconButton disabled={false} hierarchy={'primary'} {...IconButton_Props} />
      </>}
  </FeedbackModal_header_root>);
FeedbackModal_header.displayName = 'FeedbackModal_header';
FeedbackModal_header.defaultProps = {};
type FeedbackModal_body_text_root_Props = PropsOf<typeof JuxReactElements.JuxText>;
const FeedbackModal_body_text_root: FC<FeedbackModal_body_text_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxText {...props} ref={ref} />);
FeedbackModal_body_text_root.displayName = 'FeedbackModal_body_text_root';
FeedbackModal_body_text_root.defaultProps = {
  rootElement: styled('p', {
    styles: {
      root: {
        color: '{color.core.grays_500}',
        width: 'auto',
        height: 'auto',
        display: 'inline-block',
        fontSize: '{typography.core.14_regular.fontSize}',
        fontFamily: '{typography.core.14_regular.fontFamily}',
        fontWeight: '{typography.core.14_regular.fontWeight}',
        lineHeight: '{typography.core.14_regular.lineHeight}',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        letterSpacing: '{typography.core.14_regular.letterSpacing}'
      }
    }
  }) as FeedbackModal_body_text_root_Props['rootElement']
};
export type FeedbackModal_body_text_Props = FeedbackModal_body_text_root_Props;
export const FeedbackModal_body_text: FC<FeedbackModal_body_text_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxText>, FeedbackModal_body_text_Props>((props, ref) => <FeedbackModal_body_text_root {...props} ref={ref} />);
FeedbackModal_body_text.displayName = 'FeedbackModal_body_text';
FeedbackModal_body_text.defaultProps = {
  text: 'Your voice is our progress'
};
import { Input, type Input_Props } from '../';
type FeedbackModal_body_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const FeedbackModal_body_root: FC<FeedbackModal_body_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
FeedbackModal_body_root.displayName = 'FeedbackModal_body_root';
FeedbackModal_body_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        gap: '{dimension.core.size_xs} normal',
        width: 'auto',
        height: 'auto',
        display: 'flex',
        padding: '0px 0px 0px 0px',
        alignItems: 'stretch',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF'
      }
    }
  }) as FeedbackModal_body_root_Props['rootElement']
};
export type FeedbackModal_body_Props = FeedbackModal_body_root_Props & {
  FeedbackModal_body_text_Props?: FeedbackModal_body_text_Props;
  Input_Props?: Input_Props;
};
export const FeedbackModal_body: FC<FeedbackModal_body_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, FeedbackModal_body_Props>(({
  FeedbackModal_body_text_Props,
  Input_Props,
  ...props
}, ref) => <FeedbackModal_body_root {...props} ref={ref} data-jux-context="8347e3c6-8bb5-40c5-a832-9d5ceffa008f">
    {props.children || <>
        <FeedbackModal_body_text {...FeedbackModal_body_text_Props} data-jux-context="c861d0e7-f4cc-403a-9927-a99fc077adc6" />
        <Input disabled={false} {...Input_Props} />
      </>}
  </FeedbackModal_body_root>);
FeedbackModal_body.displayName = 'FeedbackModal_body';
FeedbackModal_body.defaultProps = {};
import { JuxButton, type JuxButton_Props } from '../';
type FeedbackModal_footer_div_1_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const FeedbackModal_footer_div_1_root: FC<FeedbackModal_footer_div_1_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
FeedbackModal_footer_div_1_root.displayName = 'FeedbackModal_footer_div_1_root';
FeedbackModal_footer_div_1_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        padding: 0,
        width: '178px',
        height: '36px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        flexDirection: 'column'
      }
    }
  }) as FeedbackModal_footer_div_1_root_Props['rootElement']
};
export type FeedbackModal_footer_div_1_Props = FeedbackModal_footer_div_1_root_Props & {
  JuxButton_Props?: JuxButton_Props;
};
export const FeedbackModal_footer_div_1: FC<FeedbackModal_footer_div_1_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, FeedbackModal_footer_div_1_Props>(({
  JuxButton_Props,
  ...props
}, ref) => <FeedbackModal_footer_div_1_root {...props} ref={ref} data-jux-context="316f65ef-ccc4-4f6c-82ad-b5d21b7d9a12">
      {props.children || <>
          <JuxButton hierarchy={'secondary'} disabled={false} size={'big'} {...JuxButton_Props} />
        </>}
    </FeedbackModal_footer_div_1_root>);
FeedbackModal_footer_div_1.displayName = 'FeedbackModal_footer_div_1';
FeedbackModal_footer_div_1.defaultProps = {};
import { Import_button, type Import_button_Props } from '../';
type FeedbackModal_footer_div_2_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const FeedbackModal_footer_div_2_root: FC<FeedbackModal_footer_div_2_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
FeedbackModal_footer_div_2_root.displayName = 'FeedbackModal_footer_div_2_root';
FeedbackModal_footer_div_2_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        padding: 0,
        width: '178px',
        height: '36px',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        flexDirection: 'column'
      }
    }
  }) as FeedbackModal_footer_div_2_root_Props['rootElement']
};
export type FeedbackModal_footer_div_2_Props = FeedbackModal_footer_div_2_root_Props & {
  Import_button_Props?: Import_button_Props;
};
export const FeedbackModal_footer_div_2: FC<FeedbackModal_footer_div_2_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, FeedbackModal_footer_div_2_Props>(({
  Import_button_Props,
  ...props
}, ref) => <FeedbackModal_footer_div_2_root {...props} ref={ref} data-jux-context="45df66ed-5308-4674-b66c-149bd505533c">
      {props.children || <>
          <Import_button disabled={false} hierarchy={'primary'} Size={'big'} {...Import_button_Props} />
        </>}
    </FeedbackModal_footer_div_2_root>);
FeedbackModal_footer_div_2.displayName = 'FeedbackModal_footer_div_2';
FeedbackModal_footer_div_2.defaultProps = {};
type FeedbackModal_footer_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const FeedbackModal_footer_root: FC<FeedbackModal_footer_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
FeedbackModal_footer_root.displayName = 'FeedbackModal_footer_root';
FeedbackModal_footer_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        padding: '0px 0px 0px 0px',
        alignItems: 'stretch',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF',
        gap: 'normal 0px'
      }
    }
  }) as FeedbackModal_footer_root_Props['rootElement']
};
export type FeedbackModal_footer_Props = FeedbackModal_footer_root_Props & {
  FeedbackModal_footer_div_1_Props?: FeedbackModal_footer_div_1_Props;
  FeedbackModal_footer_div_2_Props?: FeedbackModal_footer_div_2_Props;
};
export const FeedbackModal_footer: FC<FeedbackModal_footer_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, FeedbackModal_footer_Props>(({
  FeedbackModal_footer_div_1_Props,
  FeedbackModal_footer_div_2_Props,
  ...props
}, ref) => <FeedbackModal_footer_root {...props} ref={ref} data-jux-context="adbb329c-af1d-4c1e-a0c9-0c14a0996a69">
      {props.children || <>
          <FeedbackModal_footer_div_1 {...FeedbackModal_footer_div_1_Props} data-jux-context="316f65ef-ccc4-4f6c-82ad-b5d21b7d9a12" />
          <FeedbackModal_footer_div_2 {...FeedbackModal_footer_div_2_Props} data-jux-context="45df66ed-5308-4674-b66c-149bd505533c" />
        </>}
    </FeedbackModal_footer_root>);
FeedbackModal_footer.displayName = 'FeedbackModal_footer';
FeedbackModal_footer.defaultProps = {};
type FeedbackModal_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const FeedbackModal_root: FC<FeedbackModal_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
FeedbackModal_root.displayName = 'FeedbackModal_root';
FeedbackModal_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        gap: '{dimension.core.size_ml} normal',
        width: '420px',
        height: 'auto',
        display: 'flex',
        padding: '24px 24px {dimension.core.size_ml} 24px',
        boxShadow: '0px 0px 12px 2px rgba(0, 0, 0, 0.15)',
        alignItems: 'stretch',
        borderColor: '',
        borderStyle: 'none',
        borderRadius: '4px',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF'
      }
    }
  }) as FeedbackModal_root_Props['rootElement']
};
export type FeedbackModal_Props = FeedbackModal_root_Props & {
  FeedbackModal_header_Props?: FeedbackModal_header_Props;
  FeedbackModal_body_Props?: FeedbackModal_body_Props;
  FeedbackModal_footer_Props?: FeedbackModal_footer_Props;
};
export const FeedbackModal: FC<FeedbackModal_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, FeedbackModal_Props>(({
  FeedbackModal_header_Props,
  FeedbackModal_body_Props,
  FeedbackModal_footer_Props,
  ...props
}, ref) => <FeedbackModal_root {...props} ref={ref} data-jux-context="7330a651-71d2-4780-acba-b8b2c6472ef3">
      {props.children || <>
          <FeedbackModal_header {...FeedbackModal_header_Props} data-jux-context="c10276a8-4b2e-43a3-927d-8279edc94618" />
          <FeedbackModal_body {...FeedbackModal_body_Props} data-jux-context="8347e3c6-8bb5-40c5-a832-9d5ceffa008f" />
          <FeedbackModal_footer {...FeedbackModal_footer_Props} data-jux-context="adbb329c-af1d-4c1e-a0c9-0c14a0996a69" />
        </>}
    </FeedbackModal_root>);
FeedbackModal.displayName = 'FeedbackModal';
FeedbackModal.defaultProps = {};