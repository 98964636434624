import {
  COMPONENT_TAG_NAME,
  ComponentTagName,
  NodeType,
} from '@jux/data-entities';
import {
  DDPSubModulesKeys,
  DDPSubModulesState,
  DDPSubModulesStateKeys,
} from '@jux/ui/components/editor/state';
import { logger } from '@jux/ui-logger';
import { SupportedStyles, isKeyExistInSupportedStyles } from '../utils';

const DEFAULT_IS_OPEN = false;
export const isOpenPrevValueOrDefault = (
  prev: DDPSubModulesState,
  key: DDPSubModulesStateKeys
) => prev[key]?.isOpen ?? DEFAULT_IS_OPEN;

const isKeyExistsInSupportedSubModules = (
  subModules: Array<DDPSubModulesStateKeys>,
  key: DDPSubModulesStateKeys
) => subModules.includes(key);

const getSupportedSubModules = ({
  tagName,
  type,
}: {
  tagName: ComponentTagName;
  type: NodeType;
}): Array<DDPSubModulesStateKeys> => {
  const isComponent =
    type === NodeType.LOCAL_COMPONENT || type === NodeType.LIBRARY_COMPONENT;
  const isInstance = type === NodeType.INSTANCE;

  const positionSubModules = [
    DDPSubModulesKeys.position,
    DDPSubModulesKeys.relativeTo,
    DDPSubModulesKeys.constraints,
    DDPSubModulesKeys.zIndex,
  ];

  const defaultSubModules = [
    DDPSubModulesKeys.padding,
    DDPSubModulesKeys.margin,
    DDPSubModulesKeys.height,
    DDPSubModulesKeys.width,
    DDPSubModulesKeys.opacity,
    ...positionSubModules,
  ] as const;

  const displaySubModules = [
    DDPSubModulesKeys.display,
    DDPSubModulesKeys.flexDirectionAndWrapping,
    DDPSubModulesKeys.alignItems,
    DDPSubModulesKeys.justifyContent,
    DDPSubModulesKeys.gap,
  ] as const;

  const borderSubModules = [
    DDPSubModulesKeys.borderColor,
    DDPSubModulesKeys.borderRadius,
    DDPSubModulesKeys.borderStyle,
    DDPSubModulesKeys.borderWidth,
  ] as const;

  switch (tagName) {
    case COMPONENT_TAG_NAME.JuxTextField:
    case COMPONENT_TAG_NAME.JuxSlot:
    case COMPONENT_TAG_NAME.JuxDivider:
    case COMPONENT_TAG_NAME.JuxDiv: {
      const juxDivComponentSubModules = isComponent
        ? [
            DDPSubModulesKeys.dynamicProperties,
            DDPSubModulesKeys.nodeInteractiveState,
            DDPSubModulesKeys.editProperties,
          ]
        : [];
      const juxDivInstanceSubModules = isInstance
        ? [DDPSubModulesKeys.dynamicProperties]
        : [];

      return [
        ...juxDivComponentSubModules,
        ...juxDivInstanceSubModules,
        ...defaultSubModules,
        ...displaySubModules,
        ...borderSubModules,
        DDPSubModulesKeys.parentContext,
        DDPSubModulesKeys.backgroundColor,
        DDPSubModulesKeys.boxShadow,
      ];
    }
    case COMPONENT_TAG_NAME.JuxText:
      return [
        ...defaultSubModules,
        ...displaySubModules,
        DDPSubModulesKeys.parentContext,
        DDPSubModulesKeys.textContent,
        DDPSubModulesKeys.alignment,
        DDPSubModulesKeys.backgroundColor,
        DDPSubModulesKeys.textColor,
        DDPSubModulesKeys.typography,
      ];
    case COMPONENT_TAG_NAME.JuxInput:
    case COMPONENT_TAG_NAME.JuxTextarea:
    case COMPONENT_TAG_NAME.JuxTextareaField:
      return [
        ...defaultSubModules,
        ...borderSubModules,
        DDPSubModulesKeys.dynamicProperties,
        DDPSubModulesKeys.editProperties,
        DDPSubModulesKeys.nodeInteractiveState,
        DDPSubModulesKeys.alignment,
        DDPSubModulesKeys.backgroundColor,
        DDPSubModulesKeys.boxShadow,
        DDPSubModulesKeys.textColor,
        DDPSubModulesKeys.typography,
      ];
    case COMPONENT_TAG_NAME.JuxButton:
    case COMPONENT_TAG_NAME.JuxIconButton:
    case COMPONENT_TAG_NAME.JuxCheckboxField:
    case COMPONENT_TAG_NAME.JuxCheckbox:
    case COMPONENT_TAG_NAME.JuxChip:
    case COMPONENT_TAG_NAME.JuxToggle:
    case COMPONENT_TAG_NAME.JuxRadio: {
      const juxSpecialNotAnInstanceSubModules = !isInstance
        ? [DDPSubModulesKeys.editProperties]
        : [];

      return [
        ...juxSpecialNotAnInstanceSubModules,
        ...defaultSubModules,
        ...displaySubModules,
        ...borderSubModules,
        DDPSubModulesKeys.dynamicProperties,
        DDPSubModulesKeys.nodeInteractiveState,
        DDPSubModulesKeys.backgroundColor,
        DDPSubModulesKeys.boxShadow,
        DDPSubModulesKeys.margin,
        DDPSubModulesKeys.padding,
      ];
    }
    case COMPONENT_TAG_NAME.JuxSvg:
      return [
        ...defaultSubModules,
        ...displaySubModules,
        DDPSubModulesKeys.assetContent,
        DDPSubModulesKeys.parentContext,
        DDPSubModulesKeys.color,
      ];

    // Note: no need to add default because of type-safety all tag names must be dealt with
  }

  return [];
};

export const isTagNameSupportsSubModule = ({
  subModuleKey,
  tagName,
  type,
}: {
  tagName: ComponentTagName;
  subModuleKey: DDPSubModulesStateKeys;
  type: NodeType;
}): boolean => {
  const supportedSubModules = getSupportedSubModules({ tagName, type });
  return isKeyExistsInSupportedSubModules(supportedSubModules, subModuleKey);
};

export const isStyleExistInStyles = (
  styles: SupportedStyles,
  initialStyles: SupportedStyles | undefined,
  keyValues: Partial<{
    [key in keyof SupportedStyles]: SupportedStyles[key];
  }>
) =>
  Object.entries(keyValues).every(([key, value]) => {
    if (!isKeyExistInSupportedStyles(key)) {
      logger.warn(`key ${key} is not in SupportedStyles`);
      return false;
    }
    return styles[key] !== undefined
      ? styles[key] === value
      : initialStyles?.[key] === value;
  });
