import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { Module } from '../../base';
import { useShouldRenderModuleByKey } from '../../hooks/useShouldRenderModuleByKey';
import { ModulesKeys } from '../../DDP.interface';
import { Constraints, Height, Position, RelativeTo, Width, ZIndex } from './sub-modules';
const MODULE_KEY = ModulesKeys.size;
export const Size: FC = () => {
  const {
    shouldRender
  } = useShouldRenderModuleByKey(MODULE_KEY);
  if (!shouldRender) return null;
  return <Module moduleKey={MODULE_KEY} title="Size & Position" data-sentry-element="Module" data-sentry-component="Size" data-sentry-source-file="Size.tsx">
      <Width data-sentry-element="Width" data-sentry-source-file="Size.tsx" />
      <Height data-sentry-element="Height" data-sentry-source-file="Size.tsx" />
      <MuiGrid container pt="8px" data-sentry-element="MuiGrid" data-sentry-source-file="Size.tsx">
        <Position data-sentry-element="Position" data-sentry-source-file="Size.tsx" />
      </MuiGrid>
      <RelativeTo data-sentry-element="RelativeTo" data-sentry-source-file="Size.tsx" />
      <Constraints data-sentry-element="Constraints" data-sentry-source-file="Size.tsx" />
      <ZIndex data-sentry-element="ZIndex" data-sentry-source-file="Size.tsx" />
    </Module>;
};