import { RefObject, useCallback } from 'react';
import { PoppersKey, usePopper } from '@jux/ui/components';
import { MenuPopper } from './MenuPopper';
export const useMenuPopper = () => {
  const {
    open,
    isOpen,
    close
  } = usePopper(PoppersKey.menu);
  const openMenuPopper = useCallback(({
    sourceElementRef
  }: {
    sourceElementRef: RefObject<HTMLElement>;
  }) => {
    open({
      content: <MenuPopper direction="rtl" />,
      sourceElement: sourceElementRef.current,
      styles: {
        direction: 'rtl',
        positioning: 'bottom-right',
        offset: {
          top: 10
        }
      }
    });
  }, [open]);
  const closeMenuPopper = useCallback(() => {
    close({
      options: {
        shouldResetPreviouslyOpenedKey: true
      }
    });
  }, [close]);
  return {
    openMenuPopper,
    isMenuPopperOpen: isOpen(),
    closeMenuPopper
  };
};