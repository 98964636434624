import { FC, useMemo } from 'react';
import { DDPCompositeFieldsKeys } from '@jux/ui/components/editor/state';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { LineHeightField } from '@jux/ui/components/editor/components/panels/DDP/modules/text/sub-modules/typography/LineHeightField';
import { LetterSpacingField } from '@jux/ui/components/editor/components/panels/DDP/modules/text/sub-modules/typography/LetterSpacingField';
import { FontWeightField } from '@jux/ui/components/editor/components/panels/DDP/modules/text/sub-modules/typography/FontWeightField';
import { FontSizeField } from '@jux/ui/components/editor/components/panels/DDP/modules/text/sub-modules/typography/FontSizeField';
import { FontFamilyField } from '@jux/ui/components/editor/components/panels/DDP/modules/text/sub-modules/typography/FontFamilyField';
import { EDITOR_DDP_TYPOGRAPHY_FIELD_BORDER_COLOR } from '@jux/ui/theme/palette';
import { useTypographyField, useFieldValues, useSetFieldValue } from '../../../../hooks';
import { TypographyField } from '../../../../base';
export const DDPTypographyField: FC = () => {
  // TODO: remove this, cause it's unnecessary (we aren't really using the field value state with typography field)
  const typography = useFieldValues(DDPCompositeFieldsKeys.typography);
  const {
    value: typographyValue,
    initialValue: typographyInitialValue
  } = typography || {};
  const {
    saveValueWithoutStyleChanges: typographySetFieldValue
  } = useSetFieldValue(DDPCompositeFieldsKeys.typography);
  const typographyFieldProps = useTypographyField({
    value: typographyValue,
    initialValue: typographyInitialValue,
    fieldName: DDPCompositeFieldsKeys.typography,
    saveChanges: typographySetFieldValue
  });
  const isSubFieldsDisabled = useMemo(() => typographyFieldProps.tokensProps?.isTokenized, [typographyFieldProps.tokensProps?.isTokenized]);
  return <MuiGrid container flexDirection="column" gap="4px" data-sentry-element="MuiGrid" data-sentry-component="DDPTypographyField" data-sentry-source-file="DDPTypographyField.tsx">
      <TypographyField icon="DDP_FIELD_TYPOGRAPHY" tooltipTitle="Typography" {...typographyFieldProps} data-sentry-element="TypographyField" data-sentry-source-file="DDPTypographyField.tsx" />
      <MuiGrid display="flex" paddingLeft="12px" flexDirection="column" gap="4px" borderLeft="1px solid" borderColor={EDITOR_DDP_TYPOGRAPHY_FIELD_BORDER_COLOR} width="100%" data-sentry-element="MuiGrid" data-sentry-source-file="DDPTypographyField.tsx">
        <FontFamilyField isFieldDisabled={isSubFieldsDisabled} data-sentry-element="FontFamilyField" data-sentry-source-file="DDPTypographyField.tsx" />
        <FontWeightField isFieldDisabled={isSubFieldsDisabled} data-sentry-element="FontWeightField" data-sentry-source-file="DDPTypographyField.tsx" />
        <FontSizeField isFieldDisabled={isSubFieldsDisabled} data-sentry-element="FontSizeField" data-sentry-source-file="DDPTypographyField.tsx" />
        <LetterSpacingField isFieldDisabled={isSubFieldsDisabled} data-sentry-element="LetterSpacingField" data-sentry-source-file="DDPTypographyField.tsx" />
        <LineHeightField isFieldDisabled={isSubFieldsDisabled} data-sentry-element="LineHeightField" data-sentry-source-file="DDPTypographyField.tsx" />
      </MuiGrid>
    </MuiGrid>;
};