/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const PositionRelativeDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M2 10V9.125H3.5L3.875 9.5V11H3C2.44772 11 2 10.5523 2 10Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M3.5 7.375H2V5.625H3.5V7.375Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M3.5 3.875H2V3C2 2.44772 2.44772 2 3 2H3.875V3.5L3.5 3.875Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M5.625 3.5V2H7.375V3.5H5.625Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M9.125 3.5V2H10C10.5523 2 11 2.44772 11 3V3.875H9.5L9.125 3.5Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M9.5 9.125H11V10C11 10.5523 10.5523 11 10 11H9.125V9.5L9.5 9.125Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M5 6C5 5.44772 5.44772 5 6 5H13C13.5523 5 14 5.44772 14 6V13C14 13.5523 13.5523 14 13 14H6C5.44772 14 5 13.5523 5 13V6ZM9.5 6.5V7.375H11V6.5H12.5V12.5H6.5V11H7.375V9.5H6.5V6.5H9.5Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="PositionRelativeDDPIcon" data-sentry-source-file="PositionRelativeDDPIcon.tsx" />;