import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { CANVAS_ITEMS } from '@jux/ui/components/editor/hooks';
import { ComponentPreview } from './ComponentPreview';
import { ComponentPreviewItemProps } from './ComponentPreviewItem.interface';
import * as S from './ComponentPreviewItem.style';
import { ComponentPreviewItemActionsMenu } from './ComponentPreviewItemActionsMenu';
import { ComponentPreviewItemTooltip } from './componentPreviewItemTooltip/ComponentPreviewItemTooltip';
import { useComponentPreviewActions, useComponentPreviewItem } from './hooks';
const TEST_ID_COMPONENT_PREVIEW_ITEM = 'component-preview-item';
export const ComponentPreviewItem: FC<ComponentPreviewItemProps> = ({
  actions,
  componentId,
  component,
  loadingRenderer,
  previewPortalContainer,
  wrapperProps
}) => {
  const {
    componentContainerRef,
    componentDragRef,
    componentPreviewItemWrapperRef,
    componentRectRef,
    isDragging,
    isPreviewItemHovered,
    handleClick,
    showLoading,
    originStyles,
    transform
  } = useComponentPreviewItem({
    component: {
      id: componentId,
      data: {
        ...component
      },
      displayName: component.displayName,
      type: CANVAS_ITEMS.Component
    }
  });
  const {
    actionsMenuRef,
    isActionsMenuHovered
  } = useComponentPreviewActions();
  return <ComponentPreviewItemTooltip displayName={component.displayName ?? ''} updatedAt={component.updatedAt} visible={isPreviewItemHovered && !isActionsMenuHovered} disabled={isDragging} data-sentry-element="ComponentPreviewItemTooltip" data-sentry-component="ComponentPreviewItem" data-sentry-source-file="ComponentPreviewItem.tsx">
      <S.ComponentPreviewItemWrapper isDragging={isDragging} onClick={handleClick} data-testid={TEST_ID_COMPONENT_PREVIEW_ITEM} {...wrapperProps} data-sentry-element="unknown" data-sentry-source-file="ComponentPreviewItem.tsx">
        <MuiGrid ref={componentPreviewItemWrapperRef} width="100%" height="100%" padding="8px" data-sentry-element="MuiGrid" data-sentry-source-file="ComponentPreviewItem.tsx">
          {actions && <MuiGrid ref={actionsMenuRef} display="contents" onClick={e => e.stopPropagation()}>
              <ComponentPreviewItemActionsMenu isVisible={isPreviewItemHovered}>
                {actions}
              </ComponentPreviewItemActionsMenu>
            </MuiGrid>}
          <ComponentPreview ref={componentContainerRef} componentId={componentId} componentDragRef={componentDragRef as any} componentRectRef={componentRectRef} loadingRenderer={loadingRenderer} previewStyles={originStyles} previewPortalContainer={previewPortalContainer} showLoading={showLoading} transform={transform} data-sentry-element="ComponentPreview" data-sentry-source-file="ComponentPreviewItem.tsx" />
        </MuiGrid>
      </S.ComponentPreviewItemWrapper>
    </ComponentPreviewItemTooltip>;
};