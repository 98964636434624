/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const AlignLeftDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    d: 'M10 3C10.5523 3 11 3.44772 11 4C11 4.55229 10.5523 5 10 5L4 5C3.44772 5 3 4.55228 3 4C3 3.44772 3.44772 3 4 3H10Z',
    fill: 'currentColor'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    d: 'M12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9H4C3.44772 9 3 8.55228 3 8C3 7.44771 3.44772 7 4 7L12 7Z',
    fill: 'currentColor'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    d: 'M9 12C9 11.4477 8.55228 11 8 11H4C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13H8C8.55228 13 9 12.5523 9 12Z',
    fill: 'currentColor'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="AlignLeftDDPIcon" data-sentry-source-file="AlignLeftDDPIcon.tsx" />;