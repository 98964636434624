/**
 *  THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 */

/* eslint-disable */
// @ts-nocheck

import { createDrimz, type PropsOf } from '@jux/dui';
import React, { FC, forwardRef } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType } from '@jux/data-entities';
import { JuxReactElements } from '@jux/react';
const {
  styled
} = createDrimz();
import { KeyboardHeader, type KeyboardHeader_Props } from '../';
import { KeyboardCategory, type KeyboardCategory_Props } from '../';
type KeyboardPopper_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const KeyboardPopper_root: FC<KeyboardPopper_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
KeyboardPopper_root.displayName = 'KeyboardPopper_root';
KeyboardPopper_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        width: '280px',
        height: 'auto',
        display: 'flex',
        padding: '0px 0px 0px 0px',
        alignItems: 'stretch',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF'
      }
    }
  }) as KeyboardPopper_root_Props['rootElement']
};
export type KeyboardPopper_Props = KeyboardPopper_root_Props & {
  KeyboardHeader_Props?: KeyboardHeader_Props;
  KeyboardCategory_Props?: KeyboardCategory_Props;
};
export const KeyboardPopper: FC<KeyboardPopper_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, KeyboardPopper_Props>(({
  KeyboardHeader_Props,
  KeyboardCategory_Props,
  ...props
}, ref) => <KeyboardPopper_root {...props} ref={ref} data-jux-context="279bfa51-be0f-443f-a498-1f71ee8d380a">
    {props.children || <>
        <KeyboardHeader {...KeyboardHeader_Props} />
        <KeyboardCategory {...KeyboardCategory_Props} />
      </>}
  </KeyboardPopper_root>);
KeyboardPopper.displayName = 'KeyboardPopper';
KeyboardPopper.defaultProps = {};