/**
 *  THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 */

/* eslint-disable */
// @ts-nocheck

import { createDrimz, type PropsOf } from '@jux/dui';
import React, { FC, forwardRef } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType } from '@jux/data-entities';
import { JuxReactElements } from '@jux/react';
const {
  styled
} = createDrimz();
type Subcategory_text_root_Props = PropsOf<typeof JuxReactElements.JuxText>;
const Subcategory_text_root: FC<Subcategory_text_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxText {...props} ref={ref} />);
Subcategory_text_root.displayName = 'Subcategory_text_root';
Subcategory_text_root.defaultProps = {
  rootElement: styled('p', {
    styles: {
      root: {
        color: '{color.core.grays_400}',
        width: 'auto',
        height: 'auto',
        display: 'inline-block',
        fontSize: '{typography.core.12_regular.fontSize}',
        fontFamily: '{typography.core.12_regular.fontFamily}',
        fontWeight: '{typography.core.12_regular.fontWeight}',
        lineHeight: '{typography.core.12_regular.lineHeight}',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        letterSpacing: '{typography.core.12_regular.letterSpacing}'
      }
    }
  }) as Subcategory_text_root_Props['rootElement']
};
export type Subcategory_text_Props = Subcategory_text_root_Props;
export const Subcategory_text: FC<Subcategory_text_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxText>, Subcategory_text_Props>((props, ref) => <Subcategory_text_root {...props} ref={ref} />);
Subcategory_text.displayName = 'Subcategory_text';
Subcategory_text.defaultProps = {
  text: 'Subheader'
};
type Subcategory_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const Subcategory_root: FC<Subcategory_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
Subcategory_root.displayName = 'Subcategory_root';
Subcategory_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        width: 'auto',
        height: '16px',
        display: 'flex',
        padding: '0px 0px 0px 0px',
        alignItems: 'center',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        backgroundColor: '#FFFFFF'
      }
    }
  }) as Subcategory_root_Props['rootElement']
};
export type Subcategory_Props = Subcategory_root_Props & {
  Subcategory_text_Props?: Subcategory_text_Props;
};
export const Subcategory: FC<Subcategory_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, Subcategory_Props>(({
  Subcategory_text_Props,
  ...props
}, ref) => <Subcategory_root {...props} ref={ref} data-jux-context="de43d0f6-3c52-4fea-96ec-1d004ac2d67b">
    {props.children || <>
        <Subcategory_text {...Subcategory_text_Props} data-jux-context="6bbcaef2-f7fc-477e-8391-1f57329c9fc6" />
      </>}
  </Subcategory_root>);
Subcategory.displayName = 'Subcategory';
Subcategory.defaultProps = {};