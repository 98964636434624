/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const LetterSpacingDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M2.5 3C2.77614 3 3 3.22386 3 3.5L3 12.5C3 12.7761 2.77614 13 2.5 13C2.22386 13 2 12.7761 2 12.5V3.5C2 3.22386 2.22386 3 2.5 3Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M5.88331 12H4L6.86961 4H9.13445L12 12H10.1167L9.50126 10.1758H6.49994L5.88331 12ZM7.96956 5.82812H8.0345L9.05583 8.85547H6.94624L7.96956 5.82812Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M14 3.5C14 3.22386 13.7761 3 13.5 3C13.2239 3 13 3.22386 13 3.5V12.5C13 12.7761 13.2239 13 13.5 13C13.7761 13 14 12.7761 14 12.5V3.5Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="LetterSpacingDDPIcon" data-sentry-source-file="LetterSpacingDDPIcon.tsx" />;