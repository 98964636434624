import { FC } from 'react';
import { bindTrigger, MuiGrid } from '@jux/ui/components/common/mui';
import { ChromePicker, ChromePickerPropsWithColor, Popover, Tooltip } from '@jux/ui/components';
import { ColorFieldProps } from '@jux/ui/components/editor/components/panels/DDP/base';
import { ColorIndicator, ColorIndicatorButtonWrapper } from './ColorIndicator';
import { NoneColorIndicator } from './NoneColorIndicator';
export type ColorPickerPopupProps = {
  color: string;
  handleColorPickerChange: ChromePickerPropsWithColor['onChange'];
  isTokenized?: boolean;
  tokenHexValue: string;
  isNoneValue?: boolean;
} & Pick<ColorFieldProps, 'popupState'>;
export const ColorPickerPopup: FC<ColorPickerPopupProps> = ({
  popupState,
  color,
  handleColorPickerChange,
  isTokenized,
  tokenHexValue,
  isNoneValue
}) => <MuiGrid display="inline-flex" data-sentry-element="MuiGrid" data-sentry-component="ColorPickerPopup" data-sentry-source-file="ColorPickerPopup.tsx">
    <Tooltip content="Color picker" visible={isTokenized} data-sentry-element="Tooltip" data-sentry-source-file="ColorPickerPopup.tsx">
      <div>
        <ColorIndicatorButtonWrapper {...isTokenized ? {} : bindTrigger(popupState)} data-sentry-element="ColorIndicatorButtonWrapper" data-sentry-source-file="ColorPickerPopup.tsx">
          {isNoneValue ? <NoneColorIndicator /> : <ColorIndicator color={color} isTokenized={isTokenized} tokenHexValue={tokenHexValue} />}
        </ColorIndicatorButtonWrapper>
      </div>
    </Tooltip>
    <Popover popupState={popupState} hideBackdrop anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'left'
  }} transformOrigin={{
    vertical: 'center',
    horizontal: 'right'
  }} PaperProps={{
    sx: {
      ml: -2
    }
  }} data-sentry-element="Popover" data-sentry-source-file="ColorPickerPopup.tsx">
      <ChromePicker color={color} onChange={handleColorPickerChange} data-sentry-element="ChromePicker" data-sentry-source-file="ColorPickerPopup.tsx" />
    </Popover>
  </MuiGrid>;