import { CanjuxState, getRootNodeOfNode, JuxStore } from '@jux/canjux/core';
import type { Draft as WritableDraft } from 'mutative';
import {
  addStorageNode,
  removeStorageNode,
  reorderStorageNode,
} from '../../../store.changes.utils';
import { findOneOfNodesInTree } from '../../helpers/findOneOfNodesInTree';
import { addInstanceNodes } from './addInstanceNodes';
import { deleteInstanceNodes } from './deleteInstanceNodes';
import { findInstancesOfInstances } from './findInstancesOfInstances';
import { NodeType } from '@jux/data-entities';

/**
 * Moves instance nodes within the same component.
 * @param sourceNodeId - The ID of the source node.
 * @param targetNodeId - The ID of the target node.
 * @param targetIndex - The index of insertion on the target node children.
 * @param components - The draft of the store's components.
 */
const moveInstanceNodesOnSameComponent = ({
  sourceNodeId,
  targetNodeId,
  targetIndex,
  components,
}: {
  sourceNodeId: string;
  targetNodeId: string;
  targetIndex: number;
  components: WritableDraft<JuxStore['components']>;
}) => {
  const componentInstances = findInstancesOfInstances({
    sourceNodeId: sourceNodeId,
    components,
  });

  const targetNodeInstancesIds = findInstancesOfInstances({
    sourceNodeId: targetNodeId,
    components,
  }).map((component) => component.id);

  for (const instance of componentInstances) {
    const rootComponent = getRootNodeOfNode({
      nodeId: instance.id,
      components,
    });

    // Determine new target parent
    // Find which target parent instance should get current instance id
    const newTargetNode = findOneOfNodesInTree({
      rootNodeId: rootComponent.id,
      idsToSearch: targetNodeInstancesIds,
      components,
    });
    if (!newTargetNode) {
      throw new Error(
        'Failed moving node. one of the target instances doesnt exist'
      );
    }
    addStorageNode(
      components[newTargetNode].children,
      instance.id,
      targetIndex
    );

    // if instance has a parent remove itself from the parent's children
    if (instance.parentId) {
      removeStorageNode(components[instance.parentId].children, instance.id, {
        targetIndex,
      });
    }

    instance.parentId = newTargetNode;
  }
};

const isUptreeContainsDynamicSlot = (
  nodeId: string,
  components: JuxStore['components']
): boolean => {
  let currentComponent = components[nodeId];

  while (currentComponent) {
    if (currentComponent.type === NodeType.DYNAMIC_SLOT) {
      return true;
    }

    if (!currentComponent.parentId) {
      break;
    }

    currentComponent = components[currentComponent.parentId];
  }

  return false;
};

/**
 * Updates the instances of a moved node in the store.
 * @param sourceNodeId - The ID of the source node.
 * @param targetNodeId - The ID of the target node.
 * @param targetIndex - The index of insertion on the target node children.
 * @param state - The draft of the store.
 */
export const updateInstancesOnMovedNode = ({
  sourceNodeId,
  state,
  targetIndex,
  targetNodeId,
}: {
  sourceNodeId: string;
  state: WritableDraft<CanjuxState>;
  targetIndex: number;
  targetNodeId: string;
}) => {
  const { components, canvases } = state;

  const targetRootNode = getRootNodeOfNode({
    components,
    nodeId: targetNodeId,
  });
  const sourceRootNode = getRootNodeOfNode({
    components,
    nodeId: sourceNodeId,
  });

  // If one of the parent nodes is a dynamic slot, we don't need to update the instances
  const isMovedIntoDynamicSlot = isUptreeContainsDynamicSlot(
    targetNodeId,
    components
  );

  if (targetRootNode.id === sourceRootNode.id && !isMovedIntoDynamicSlot) {
    // We only have to reset the nodes and instances parent id,
    // There will still be an equal amount of instance nodes
    moveInstanceNodesOnSameComponent({
      components,
      sourceNodeId,
      targetIndex,
      targetNodeId,
    });
  } else {
    // delete instances on old parent
    deleteInstanceNodes({
      canvases,
      components,
      sourceNodeId,
    });

    if (!isMovedIntoDynamicSlot) {
      addInstanceNodes({
        sourceNodeId,
        state,
        targetIndex,
        targetNodeId,
      });
    }
  }
};

/**
 * Reorders the children of parent instances based on the instances of a moved node.
 * @param sourceNodeId - The ID of the source node.
 * @param targetIndex - The index at which the instance nodes should be inserted.
 * @param components - The draft of the store's components.
 */
export const reorderParentChildrenOnInstances = ({
  sourceNodeId,
  targetIndex,
  components,
}: {
  sourceNodeId: string;
  targetIndex: number;
  components: WritableDraft<JuxStore['components']>;
}) => {
  const parentId = components[sourceNodeId].parentId;
  if (!parentId) {
    return;
  }

  const parentInstances = findInstancesOfInstances({
    sourceNodeId: parentId,
    components,
  });

  const componentInstancesIds = findInstancesOfInstances({
    sourceNodeId: sourceNodeId,
    components,
  }).map((instance) => instance.id);

  for (const parentInstance of parentInstances) {
    const sourceInstanceToMove = componentInstancesIds.find((instanceId) =>
      parentInstance.children.includes(instanceId)
    );
    if (sourceInstanceToMove) {
      reorderStorageNode(
        parentInstance.children,
        sourceInstanceToMove,
        targetIndex
      );
    }
  }
};
