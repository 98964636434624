/**
 *  THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 */

/* eslint-disable */
// @ts-nocheck

import { createDrimz, type PropsOf } from '@jux/dui';
import React, { FC, forwardRef } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType } from '@jux/data-entities';
import { JuxReactElements } from '@jux/react';
const {
  styled
} = createDrimz();
type LeftNavHeader_Logobuttonforprototype_root_Props = PropsOf<typeof JuxReactElements.JuxSvg>;
const LeftNavHeader_Logobuttonforprototype_root: FC<LeftNavHeader_Logobuttonforprototype_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxSvg {...props} ref={ref} />);
LeftNavHeader_Logobuttonforprototype_root.displayName = 'LeftNavHeader_Logobuttonforprototype_root';
LeftNavHeader_Logobuttonforprototype_root.defaultProps = {
  rootElement: styled('svg', {
    styles: {
      root: {
        color: 'rgba(255,255,255,1)'
      }
    }
  }) as LeftNavHeader_Logobuttonforprototype_root_Props['rootElement']
};
export type LeftNavHeader_Logobuttonforprototype_Props = LeftNavHeader_Logobuttonforprototype_root_Props;
export const LeftNavHeader_Logobuttonforprototype: FC<LeftNavHeader_Logobuttonforprototype_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxSvg>, LeftNavHeader_Logobuttonforprototype_Props>((props, ref) => <LeftNavHeader_Logobuttonforprototype_root {...props} ref={ref} />);
LeftNavHeader_Logobuttonforprototype.displayName = 'LeftNavHeader_Logobuttonforprototype';
LeftNavHeader_Logobuttonforprototype.defaultProps = {
  content: '<svg width="66" height="28" viewBox="0 0 66 28" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M51.5264 14L47.3999 20H52.1521L56.1999 14L52.1521 8H47.3999L51.5264 14Z" fill="currentColor"></path>\n<path d="M51.5264 14L47.3999 20H52.1521L56.1999 14L52.1521 8H47.3999L51.5264 14Z" fill="currentColor"></path>\n<path d="M13.8 20.4C11.4371 20.4 10.4245 19.609 9 18.3318L10.9529 15.2823C11.8175 16.1884 14.6 18 14.6 14.7957V12L10.6 12V8L18.6 8.00003V15.2823C18.6 18.4 16.8404 20.4 13.8 20.4Z" fill="currentColor"></path>\n<path d="M37.0851 14L33 20H37.7046L39.4 17.4614L41.0954 20H45.8L41.7149 14L45.8 8H41.0954L39.4 10.5386L37.7046 8H33L37.0851 14Z" fill="currentColor"></path>\n<path d="M20.2 14.8C20.2 18 22.2 20.4 26.2 20.4C30.2 20.4 32.2 18 32.2 14.8V8H28.2V14.4C28.2 15.6 27.4 16.5407 26.2 16.5407C25 16.5407 24.2 15.6 24.2 14.4V8H20.2V14.8Z" fill="currentColor"></path>\n</svg>\n'
};
type LeftNavHeader_text_root_Props = PropsOf<typeof JuxReactElements.JuxText>;
const LeftNavHeader_text_root: FC<LeftNavHeader_text_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxText {...props} ref={ref} />);
LeftNavHeader_text_root.displayName = 'LeftNavHeader_text_root';
LeftNavHeader_text_root.defaultProps = {
  rootElement: styled('p', {
    styles: {
      root: {
        color: '{color.core.blues_500}',
        width: 'auto',
        height: 'auto',
        display: 'inline-block',
        fontSize: '{typography.core.12_bold.fontSize}',
        fontFamily: '{typography.core.12_bold.fontFamily}',
        fontWeight: '{typography.core.12_bold.fontWeight}',
        lineHeight: '{typography.core.12_bold.lineHeight}',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        letterSpacing: '{typography.core.12_bold.letterSpacing}',
        backgroundColor: 'rgba(0,0,0,0)'
      }
    }
  }) as LeftNavHeader_text_root_Props['rootElement']
};
export type LeftNavHeader_text_Props = LeftNavHeader_text_root_Props;
export const LeftNavHeader_text: FC<LeftNavHeader_text_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxText>, LeftNavHeader_text_Props>((props, ref) => <LeftNavHeader_text_root {...props} ref={ref} />);
LeftNavHeader_text.displayName = 'LeftNavHeader_text';
LeftNavHeader_text.defaultProps = {
  text: 'Alpha'
};
type LeftNavHeader_root_Props = PropsOf<typeof JuxReactElements.JuxDiv>;
const LeftNavHeader_root: FC<LeftNavHeader_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxDiv {...props} ref={ref} />);
LeftNavHeader_root.displayName = 'LeftNavHeader_root';
LeftNavHeader_root.defaultProps = {
  rootElement: styled('div', {
    styles: {
      root: {
        width: 'auto',
        height: '48px',
        margin: '0px 0px 0px 0px',
        display: 'flex',
        padding: '0px 20px 0px 10px',
        alignItems: 'center',
        borderColor: '',
        borderStyle: 'none',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: 'rgba(33,33,33,0)'
      }
    }
  }) as LeftNavHeader_root_Props['rootElement']
};
export type LeftNavHeader_Props = LeftNavHeader_root_Props & {
  LeftNavHeader_Logobuttonforprototype_Props?: LeftNavHeader_Logobuttonforprototype_Props;
  LeftNavHeader_text_Props?: LeftNavHeader_text_Props;
};
export const LeftNavHeader: FC<LeftNavHeader_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxDiv>, LeftNavHeader_Props>(({
  LeftNavHeader_Logobuttonforprototype_Props,
  LeftNavHeader_text_Props,
  ...props
}, ref) => <LeftNavHeader_root {...props} ref={ref} data-jux-context="fd998956-1a51-4028-936d-0e572938d818">
      {props.children || <>
          <LeftNavHeader_Logobuttonforprototype {...LeftNavHeader_Logobuttonforprototype_Props} data-jux-context="5811f2e8-ecb1-49e9-b08d-816d30d63ff7" />
          <LeftNavHeader_text {...LeftNavHeader_text_Props} data-jux-context="69decc42-6148-4127-99f1-502efd5acf51" />
        </>}
    </LeftNavHeader_root>);
LeftNavHeader.displayName = 'LeftNavHeader';
LeftNavHeader.defaultProps = {};