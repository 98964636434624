import { FC, PropsWithChildren } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
export const CardContentValueWrapper: FC<PropsWithChildren> = ({
  children
}) => <MuiGrid container item alignItems="center" sx={{
  maxWidth: 'fit-content',
  '&.MuiGrid-root': {
    maxWidth: '100%'
  }
}} wrap="nowrap" data-sentry-element="MuiGrid" data-sentry-component="CardContentValueWrapper" data-sentry-source-file="CardContentValueWrapper.tsx">
    {children}
  </MuiGrid>;