/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const AssetsIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="20" height="20" attr={{
  viewBox: '0 0 20 20'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M4 4H16V11.0357L12.9279 8.64307L6.21069 16H4V4ZM8.91894 16H16V13.5708L13.158 11.3573L8.91894 16ZM2 18V2H18V18H2ZM10.7384 8C10.7384 9.10457 9.84294 10 8.73837 10C7.6338 10 6.73837 9.10457 6.73837 8C6.73837 6.89543 7.6338 6 8.73837 6C9.84294 6 10.7384 6.89543 10.7384 8Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="AssetsIcon" data-sentry-source-file="AssetsIcon.tsx" />;