import deepmerge from 'deepmerge';
import { CSSProperties, forwardRef, useMemo } from 'react';
import { DATA_JUX_NODE_ID_ATTRIBUTE, selectResolvedComponentById, selectResolvedComponentProps, useStore } from '@jux/canjux/core';
import { ComponentProps, ComponentSourceData } from '@jux/data-entities';
import { ComponentConfigWithStates, DEFAULT_STATE, VariantsValues } from '@jux/types';
import logger from '@jux/ui-logger';
import { default as JuxElement } from '@jux/ui/components/editor/components/canvas/node/Element';
import { DATA_JUX_CONTEXT_ATTRIBUTE } from '@jux/calculate-styles';
const ROOT_ELEMENT_STYLE_OVERRIDES: CSSProperties = {
  position: 'relative',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0
};
export const ElementPreview = forwardRef<HTMLElement, {
  componentId: string;
  isRoot?: boolean;
  styleOverrides?: Partial<ComponentConfigWithStates>;
}>(({
  componentId,
  isRoot,
  styleOverrides
}, ref) => {
  const sourceComponent = useStore(selectResolvedComponentById({
    id: componentId
  }));
  const resolvedComponentProps = useStore(selectResolvedComponentProps({
    id: componentId,
    onlyVariantsProps: false
  }));
  const defaultProps = useMemo(() => {
    const defaults: Record<string, any> = {
      ...sourceComponent?.config?.props
    };
    for (const variant of sourceComponent.config?.variants ?? []) {
      defaults[variant.variant] = variant.defaultValue;
    }
    return defaults;
  }, [sourceComponent.config?.props, sourceComponent.config?.variants]);
  if (!sourceComponent?.tagName) {
    logger.error(`Element preview on panel has missing id: ${componentId}`);
    return null;
  }
  const {
    children: childrenData,
    ...rootElementProps
  } = sourceComponent as ComponentSourceData;
  const children = childrenData.map((childId, index) => <ElementPreview key={`${childId}_${index}`} componentId={childId} />);
  const finalStyles = styleOverrides ? deepmerge(rootElementProps.styles, styleOverrides) : rootElementProps.styles;
  return <JuxElement tagName={rootElementProps.tagName} styles={finalStyles} stylesState={DEFAULT_STATE} elementProps={{
    ...resolvedComponentProps,
    [DATA_JUX_CONTEXT_ATTRIBUTE]: sourceComponent?.config?.contextId ?? sourceComponent.id,
    // old nodes have contextId, new nodes should use their nodeId
    [DATA_JUX_NODE_ID_ATTRIBUTE]: sourceComponent.id,
    tabIndex: -1,
    style: {
      pointerEvents: 'none',
      ...(isRoot ? ROOT_ELEMENT_STYLE_OVERRIDES : {})
    }
  } as ComponentProps} variantsProps={defaultProps as VariantsValues} children={children.length ? children : undefined} ref={ref} />;
});