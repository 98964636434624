import { FC, useCallback } from 'react';
import { useDimensionField } from '../../../../hooks';
import { DimensionField } from '../../../../base';
import { useShadowsFields } from './useShadowsFields';
export const ShadowYField: FC = () => {
  const {
    value,
    initialValue,
    saveChanges
  } = useShadowsFields();
  const {
    offsetY
  } = value || {};
  const {
    offsetY: initialOffsetY
  } = initialValue || {};
  const setFieldValue = useCallback((newValue: string | undefined) => {
    saveChanges({
      offsetY: newValue
    });
  }, [saveChanges]);
  const props = useDimensionField({
    value: offsetY ?? '',
    initialValue: initialOffsetY ?? '',
    saveChanges: setFieldValue,
    fieldName: 'boxShadowY',
    options: {
      supportNegativeValue: true
    }
  });
  return <DimensionField icon="DDP_FIELD_BOX_SHADOW_Y" tooltipTitle="Y offset" {...props} data-sentry-element="DimensionField" data-sentry-component="ShadowYField" data-sentry-source-file="ShadowYField.tsx" />;
};