import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { BorderStyleField } from './BorderStyleField';
export const BorderStyle: FC = () => {
  const {
    shouldRender
  } = useSubModule(DDPSubModulesKeys.borderStyle);
  if (!shouldRender) return null;
  return <SubModule title="Style" hideHeader data-sentry-element="SubModule" data-sentry-component="BorderStyle" data-sentry-source-file="BorderStyle.tsx">
      <BorderStyleField data-sentry-element="BorderStyleField" data-sentry-source-file="BorderStyle.tsx" />
    </SubModule>;
};