/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconType } from 'react-icons';
import { IconBaseProps } from '../Icons.interface';
import { CustomIcon } from './core/CustomIcon';
export const UploadIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="17" height="16" attr={{
  viewBox: '0 0 17 16'
}} child={[{
  tag: 'path',
  attr: {
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M7.96088 1.21967C8.25377 0.926777 8.72865 0.926777 9.02154 1.21967L12.0215 4.21967C12.3144 4.51256 12.3144 4.98744 12.0215 5.28033C11.7286 5.57322 11.2538 5.57322 10.9609 5.28033L9.24121 3.56066V9.2467C9.24121 9.66092 8.90542 9.9967 8.49121 9.9967C8.077 9.9967 7.74121 9.66092 7.74121 9.2467V3.56066L6.02154 5.28033C5.72865 5.57322 5.25377 5.57322 4.96088 5.28033C4.66799 4.98744 4.66799 4.51256 4.96088 4.21967L7.96088 1.21967ZM4.24121 12.4825C3.827 12.4825 3.49121 12.8183 3.49121 13.2325C3.49121 13.6468 3.827 13.9825 4.24121 13.9825H12.7412C13.1554 13.9825 13.4912 13.6468 13.4912 13.2325C13.4912 12.8183 13.1554 12.4825 12.7412 12.4825H4.24121Z',
    fill: 'currentColor'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="UploadIcon" data-sentry-source-file="UploadIcon.tsx" />;