/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const DisplayBlockDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M11.5303 7.96967C11.8232 8.26256 11.8232 8.73744 11.5303 9.03033L8.53033 12.0303C8.23744 12.3232 7.76256 12.3232 7.46967 12.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967C4.76256 7.67678 5.23744 7.67678 5.53033 7.96967L7.25 9.68934L7.25 4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V9.68934L10.4697 7.96967C10.7626 7.67678 11.2374 7.67678 11.5303 7.96967Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M3 3.5C3 3.22386 3.22386 3 3.5 3H12.5C12.7761 3 13 3.22386 13 3.5C13 3.77614 12.7761 4 12.5 4H3.5C3.22386 4 3 3.77614 3 3.5Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M4 6C3.44772 6 3 6.44772 3 7V9C3 9.55228 3.44772 10 4 10H12C12.5523 10 13 9.55228 13 9V7C13 6.44772 12.5523 6 12 6H4ZM4.5 7.5V8.5H11.5V7.5H4.5Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M3.5 12C3.22386 12 3 12.2239 3 12.5C3 12.7761 3.22386 13 3.5 13H12.5C12.7761 13 13 12.7761 13 12.5C13 12.2239 12.7761 12 12.5 12H3.5Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="DisplayBlockDDPIcon" data-sentry-source-file="DisplayBlockDDPIcon.tsx" />;