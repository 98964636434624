/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const MuiSelectArrowDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="24" height="24" attr={{
  viewBox: '0 0 24 24'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M7 10l5 5 5-5z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="MuiSelectArrowDDPIcon" data-sentry-source-file="MuiSelectArrowDDPIcon.tsx" />;