/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const DisplayInlineBlockDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M3 3.5C3 3.22386 3.22386 3 3.5 3C3.77614 3 4 3.22386 4 3.5V12.5C4 12.7761 3.77614 13 3.5 13C3.22386 13 3 12.7761 3 12.5V3.5Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M6 12C6 12.5523 6.44772 13 7 13H9C9.55229 13 10 12.5523 10 12L10 4C10 3.44771 9.55228 3 9 3H7C6.44771 3 6 3.44772 6 4L6 12ZM7.5 11.5H8.5L8.5 4.5H7.5L7.5 11.5Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M12.5 3C12.2239 3 12 3.22386 12 3.5V12.5C12 12.7761 12.2239 13 12.5 13C12.7761 13 13 12.7761 13 12.5V3.5C13 3.22386 12.7761 3 12.5 3Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="DisplayInlineBlockDDPIcon" data-sentry-source-file="DisplayInlineBlockDDPIcon.tsx" />;