import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { GapRowField } from './GapRowField';
import { GapColumnField } from './GapColumnField';
export const Gap: FC = () => {
  const {
    shouldRender
  } = useSubModule(DDPSubModulesKeys.gap);
  if (!shouldRender) return null;
  return <SubModule title="Gap" data-sentry-element="SubModule" data-sentry-component="Gap" data-sentry-source-file="Gap.tsx">
      <GapRowField data-sentry-element="GapRowField" data-sentry-source-file="Gap.tsx" />
      <GapColumnField data-sentry-element="GapColumnField" data-sentry-source-file="Gap.tsx" />
    </SubModule>;
};