import { FC } from 'react';
import { Typography } from '@jux/ui/components';
import { MuiMenuItem } from '@jux/ui/components/common/mui';
import { palette } from '@jux/ui/theme/palette';
import { UserMenuItemProps } from './UserMenuItem.interface';
export const UserMenuItem: FC<UserMenuItemProps> = ({
  label,
  description,
  onClick,
  disabled
}) => <MuiMenuItem onClick={onClick} disabled={disabled} sx={{
  py: 0.75,
  display: 'flex',
  justifyContent: 'space-between'
}} data-sentry-element="MuiMenuItem" data-sentry-component="UserMenuItem" data-sentry-source-file="UserMenuItem.tsx">
    <Typography variant="body-regular" data-sentry-element="Typography" data-sentry-source-file="UserMenuItem.tsx">{label}</Typography>
    <Typography variant="body-regular" sx={{
    color: palette.naturals400
  }} data-sentry-element="Typography" data-sentry-source-file="UserMenuItem.tsx">
      {description}
    </Typography>
  </MuiMenuItem>;