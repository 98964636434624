import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { ActionButton } from '../../../editor/components/ActionButton';
import { DisableDeleteReturnType } from '../interface';
export const GroupHeaderActionsPopper: FC<{
  onCreateSubGroup: () => void;
  onDeleteGroup: () => void;
  onCreateToken: () => void;
  disableDelete: DisableDeleteReturnType;
}> = ({
  onCreateSubGroup,
  onDeleteGroup,
  onCreateToken,
  disableDelete
}) => <MuiGrid sx={{
  width: '100%',
  position: 'relative',
  padding: '3px',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme => theme.drimz.palette.background.default,
  borderRadius: theme => theme.drimz.size.borderRadius.small,
  border: 1,
  borderColor: theme => theme.drimz.palette.divider.primary,
  boxShadow: theme => theme.drimz.shadow.tokens.poppers.boxShadow
}} data-sentry-element="MuiGrid" data-sentry-component="GroupHeaderActionsPopper" data-sentry-source-file="GroupHeaderActionsPopper.tsx">
    <ActionButton icon="ADD" onClick={onCreateToken} data-sentry-element="ActionButton" data-sentry-source-file="GroupHeaderActionsPopper.tsx">
      Add token
    </ActionButton>
    <ActionButton icon="ADD" onClick={onCreateSubGroup} data-sentry-element="ActionButton" data-sentry-source-file="GroupHeaderActionsPopper.tsx">
      Create subgroup
    </ActionButton>
    <ActionButton icon="DELETE" onClick={onDeleteGroup} disabled={disableDelete.disabled} tooltipTitle={disableDelete.reason || ''} tooltipProps={{
    multiline: true,
    header: `Can't delete group`
  }} data-sentry-element="ActionButton" data-sentry-source-file="GroupHeaderActionsPopper.tsx">
      Delete group
    </ActionButton>
  </MuiGrid>;