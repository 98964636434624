import { FC } from 'react';
import { Icons, IconsVariantsType, Typography } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
export const TokenDrawerIconLabel: FC<{
  iconVariant: IconsVariantsType;
  labelText: string;
}> = ({
  iconVariant,
  labelText
}) => <MuiGrid justifyContent="flex-start" alignItems="center" sx={theme => ({
  ...theme.drimz.size.layout.tokenDrawer.field,
  color: theme.drimz.palette.text.primary
})} data-sentry-element="MuiGrid" data-sentry-component="TokenDrawerIconLabel" data-sentry-source-file="TokenDrawerIconLabel.tsx">
    <Icons variant={iconVariant} sx={{
    color: theme => theme.drimz.palette.primary.main,
    mr: '6px'
  }} data-sentry-element="Icons" data-sentry-source-file="TokenDrawerIconLabel.tsx" />
    <Typography variant="label-s-regular" data-sentry-element="Typography" data-sentry-source-file="TokenDrawerIconLabel.tsx">{labelText}</Typography>
  </MuiGrid>;