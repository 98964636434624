import { FC } from 'react';
type LinePatternProps = {
  dimensions: [number, number];
  lineWidth?: number;
  color: string;
};
export const LinePattern: FC<LinePatternProps> = ({
  color,
  dimensions,
  lineWidth
}) => {
  return <path stroke={color} strokeWidth={lineWidth} d={`M${dimensions[0] / 2} 0 V${dimensions[1]} M0 ${dimensions[1] / 2} H${dimensions[0]}`} data-sentry-element="path" data-sentry-component="LinePattern" data-sentry-source-file="Patterns.tsx" />;
};
type DotPatternProps = {
  radius: number;
  color: string;
};
export const DotPattern: FC<DotPatternProps> = ({
  color,
  radius
}) => {
  return <circle cx={radius} cy={radius} r={radius} fill={color} data-sentry-element="circle" data-sentry-component="DotPattern" data-sentry-source-file="Patterns.tsx" />;
};