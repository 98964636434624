import { FC, useMemo } from 'react';
import { COLOR_TOKEN_OPACITY_MAX, COLOR_TOKEN_OPACITY_MIN } from '@juxio/design-tokens';
import { ChromePicker, LoadingButtonWithIcon as Button, Popover, Tooltip } from '@jux/ui/components';
import { bindTrigger, MuiGrid, MuiInputAdornment } from '@jux/ui/components/common/mui';
import { FieldContainer, StyledSelectField, StyledTextField, TITLE_CREATE_NEW_TOKEN, TITLE_EDIT_TOKEN, TokenDrawerFormLayout, TokenDrawerIconLabel, TokenNameField } from '@jux/ui/components/tokens/token-drawer/base';
import { colorTokenValueParser } from '@jux/ui/components/tokens/token-drawer/forms/colorTokenForm/ColorTokenForm.utils';
import { useColorTokenForm } from '@jux/ui/components/tokens/token-drawer/forms/colorTokenForm/useColorTokenForm';
import { ALIAS_PLACEHOLDER_TEXT, formatGroupPath } from '@jux/ui/components/tokens/token-drawer/forms/helpers';
import { TokenFormProps } from '@jux/ui/components/tokens/token-drawer/forms/types';
import { colorTokenInputSchema } from '@jux/ui/trpc/validations';
import { colorIndicatorTransparencyPattern } from '@jux/ui/utils/colorIndicatorTransparencyPattern';
import { withHash } from '@jux/ui/utils/css';
export const ColorTokenForm: FC<TokenFormProps<typeof colorTokenInputSchema>> = ({
  groupPathOptions,
  groupPathCoreOptions,
  isCoreTokenSet,
  isCreatedFromDDP,
  componentsThatUseToken,
  ...props
}) => {
  /*
   * Here we parse the color value from a string to an object,
   * because it's stored in the database as a string,
   * while the form uses an object like `{ color, opacity, ... }`.
   * This component's value type has to match the one used in the backend,
   * while the actual form's value type is defined by the useColorTokenForm hook.
   */
  const initialValues = useMemo(() => ({
    ...props.initialValues,
    value: colorTokenValueParser.stringToObject.parse(props.initialValues.value)
  }), [props.initialValues]);
  const {
    registerTokenNameField,
    registerGroupPathField,
    registerAliasField,
    registerDescriptionField,
    registerValueColorField,
    registerValueOpacityField,
    form,
    handleSubmit,
    popupState,
    handleColorPickerChange,
    groupsOptionsProps,
    hasGroups,
    aliasOptionsProps,
    hasAliases,
    aliasValue,
    isAliasToken,
    colorValue,
    opacityValue,
    colorPickerRgbaValue,
    colorPickerPopperZIndex
  } = useColorTokenForm({
    initialValues,
    groupPathOptions,
    groupPathCoreOptions,
    isCoreTokenSet,
    isCreatedFromDDP
  });
  return <TokenDrawerFormLayout title={initialValues.tokenName ? TITLE_EDIT_TOKEN : TITLE_CREATE_NEW_TOKEN} componentsThatUseToken={componentsThatUseToken} onSubmit={handleSubmit} data-sentry-element="TokenDrawerFormLayout" data-sentry-component="ColorTokenForm" data-sentry-source-file="ColorTokenForm.tsx">
      <TokenDrawerIconLabel iconVariant="COLOR" labelText="Color" data-sentry-element="TokenDrawerIconLabel" data-sentry-source-file="ColorTokenForm.tsx" />
      <FieldContainer error={form.formState.errors.tokenName?.message} data-sentry-element="FieldContainer" data-sentry-source-file="ColorTokenForm.tsx">
        <TokenNameField error={Boolean(form.formState.errors.tokenName)} inputRef={registerTokenNameField().ref} {...registerTokenNameField()} data-sentry-element="TokenNameField" data-sentry-source-file="ColorTokenForm.tsx" />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.groupPath?.message} data-sentry-element="FieldContainer" data-sentry-source-file="ColorTokenForm.tsx">
        <StyledSelectField placeholder="Folder" disabled={!hasGroups} defaultValue={initialValues.groupPath} renderValue={item => formatGroupPath(item, isCoreTokenSet && !isCreatedFromDDP)} error={Boolean(form.formState.errors.groupPath)} inputRef={registerGroupPathField().ref} disableMenuPortal {...groupsOptionsProps} {...registerGroupPathField()} data-sentry-element="StyledSelectField" data-sentry-source-file="ColorTokenForm.tsx" />
      </FieldContainer>
      <FieldContainer data-sentry-element="FieldContainer" data-sentry-source-file="ColorTokenForm.tsx">
        <StyledSelectField disabled={!hasAliases} placeholder={ALIAS_PLACEHOLDER_TEXT} value={aliasValue} renderValue={item => formatGroupPath(item, isCoreTokenSet && !isCreatedFromDDP)} error={Boolean(form.formState.errors.alias)} inputRef={registerAliasField().ref} disableMenuPortal {...aliasOptionsProps} {...registerAliasField()} data-sentry-element="StyledSelectField" data-sentry-source-file="ColorTokenForm.tsx" />
      </FieldContainer>
      <MuiGrid container sx={theme => theme.drimz.size.layout.tokenDrawer.field} data-sentry-element="MuiGrid" data-sentry-source-file="ColorTokenForm.tsx">
        <MuiGrid item width={24} mr={1.5} data-sentry-element="MuiGrid" data-sentry-source-file="ColorTokenForm.tsx">
          <Tooltip content="Color picker" disabled={isAliasToken} data-sentry-element="Tooltip" data-sentry-source-file="ColorTokenForm.tsx">
            <Button {...isAliasToken ? {} : bindTrigger(popupState)} tabIndex={0} sx={{
            backgroundColor: withHash(colorValue),
            border: '1px solid',
            borderColor: theme => theme.drimz.palette.divider.primary,
            width: 24,
            minWidth: 24,
            height: 24,
            borderRadius: '50%',
            cursor: isAliasToken ? 'default' : 'pointer',
            mt: '2px',
            '&': theme => colorPickerRgbaValue ? colorIndicatorTransparencyPattern({
              borderRadius: '50%',
              color: colorPickerRgbaValue,
              theme
            }) : null,
            '&:focus, &:hover': {
              outline: 'none',
              borderColor: isAliasToken ? undefined : theme => theme.drimz.palette.primary.main,
              backgroundColor: withHash(colorValue)
            }
          }} data-sentry-element="Button" data-sentry-source-file="ColorTokenForm.tsx" />
          </Tooltip>
          <Popover popupState={popupState} hideBackdrop anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }} transformOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }} sx={{
          zIndex: colorPickerPopperZIndex,
          '& .MuiPopover-paper': {
            marginLeft: '-20px'
          }
        }} data-sentry-element="Popover" data-sentry-source-file="ColorTokenForm.tsx">
            <ChromePicker color={form.watch('value.colorPickerRgba')} onChange={handleColorPickerChange} data-sentry-element="ChromePicker" data-sentry-source-file="ColorTokenForm.tsx" />
          </Popover>
        </MuiGrid>
        <MuiGrid item width={120} mr={1.5} data-sentry-element="MuiGrid" data-sentry-source-file="ColorTokenForm.tsx">
          <FieldContainer sx={{
          m: 0
        }} error={form.formState.errors.value?.color?.message} data-sentry-element="FieldContainer" data-sentry-source-file="ColorTokenForm.tsx">
            <StyledTextField disabled={isAliasToken} label="" inputProps={{
            placeholder: 'FFFFFF',
            maxLength: 6
          }} InputProps={{
            startAdornment: <MuiInputAdornment sx={{
              width: 0,
              p: 0,
              m: 0,
              mt: '1px'
            }} position="start">
                    #
                  </MuiInputAdornment>
          }} error={Boolean(form.formState.errors.value?.color)} inputRef={registerValueColorField().ref} {...registerValueColorField()} value={colorValue} data-sentry-element="StyledTextField" data-sentry-source-file="ColorTokenForm.tsx" />
          </FieldContainer>
        </MuiGrid>
        <MuiGrid item width={70} data-sentry-element="MuiGrid" data-sentry-source-file="ColorTokenForm.tsx">
          <FieldContainer sx={{
          m: 0
        }} error={form.formState.errors.value?.opacity?.message} data-sentry-element="FieldContainer" data-sentry-source-file="ColorTokenForm.tsx">
            <StyledTextField disabled={isAliasToken} label="" inputProps={{
            placeholder: '100',
            maxLength: 3,
            min: COLOR_TOKEN_OPACITY_MIN,
            max: COLOR_TOKEN_OPACITY_MAX
          }} InputProps={{
            endAdornment: <MuiInputAdornment sx={{
              p: 0,
              m: 0
            }} position="end">
                    %
                  </MuiInputAdornment>
          }} error={Boolean(form.formState.errors.value?.opacity)} inputRef={registerValueOpacityField().ref} {...registerValueOpacityField()} type="number" value={opacityValue} data-sentry-element="StyledTextField" data-sentry-source-file="ColorTokenForm.tsx" />
          </FieldContainer>
        </MuiGrid>
      </MuiGrid>

      <FieldContainer error={form.formState.errors.description?.message} data-sentry-element="FieldContainer" data-sentry-source-file="ColorTokenForm.tsx">
        <StyledTextField inputProps={{
        placeholder: 'Token description'
      }} error={Boolean(form.formState.errors.description)} inputRef={registerDescriptionField().ref} {...registerDescriptionField()} data-sentry-element="StyledTextField" data-sentry-source-file="ColorTokenForm.tsx" />
      </FieldContainer>
    </TokenDrawerFormLayout>;
};