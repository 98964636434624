import colorString from 'color-string';
import { Theme } from '@jux/ui/components/common/mui';
const calculateTransparencyPatternOpacity = (color: string) => {
  try {
    const [,,, a] = colorString.get.rgb(color);
    return (1 - a).toFixed(4);
  } catch (error) {
    return 1;
  }
};
export const colorIndicatorTransparencyPattern = ({
  borderRadius,
  color,
  theme
}: {
  borderRadius: string;
  color: string;
  theme: Theme;
}) => ({
  '&': {
    outline: 'none',
    position: 'relative',
    overflow: 'hidden'
  },
  '&:before': {
    content: '""',
    display: 'block',
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 1,
    top: '0px',
    left: '0px',
    boxShadow: theme.drimz.shadow.editor.panels.ddp.colorField.indicator,
    borderRadius
  },
  '&:after': {
    content: '""',
    display: 'block',
    width: '50%',
    height: '100%',
    position: 'absolute',
    zIndex: 0,
    top: '0px',
    left: '50%',
    background: theme.drimz.palette.transparencyPattern.background,
    opacity: calculateTransparencyPatternOpacity(color)
  }
});