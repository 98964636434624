import { ReactNode } from 'react';
// eslint-disable-next-line no-restricted-imports
import { toast as reactToastify, ToastContentProps, ToastOptions } from 'react-toastify';
import { ToastType } from './toast.interface';
import { getToastOptions } from './getToastOptions';
import { Toast } from './Toast';
export const toast = {
  success: (message: ReactNode, options?: ToastOptions) => {
    reactToastify.success(<Toast message={message} type={ToastType.Success} />, {
      ...getToastOptions(ToastType.Success),
      ...options
    });
  },
  error: (message: ReactNode, options?: ToastOptions) => {
    reactToastify.error(<Toast message={message} type={ToastType.Error} />, {
      ...getToastOptions(ToastType.Error),
      ...options
    });
  },
  action: (message: (props: ToastContentProps<unknown>) => ReactNode, options?: ToastOptions) => {
    reactToastify.success(props => <Toast message={message(props)} type={ToastType.Action} closeToast={props.closeToast} />, {
      ...getToastOptions(ToastType.Action),
      ...options
    });
  }
};