import { FC } from 'react';
import { IconButton, Typography } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
export const PropertiesPanelHeader: FC<{
  onClose: () => void;
}> = ({
  onClose
}) => <MuiGrid container item sx={{
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '14px 20px',
  borderBottom: '1px solid',
  borderColor: theme => theme.drimz.palette.divider.primary,
  position: 'sticky',
  top: 0,
  zIndex: 1,
  background: theme => theme.drimz.palette.background.default
}} data-sentry-element="MuiGrid" data-sentry-component="PropertiesPanelHeader" data-sentry-source-file="PropertiesPanelHeader.tsx">
    <MuiGrid item data-sentry-element="MuiGrid" data-sentry-source-file="PropertiesPanelHeader.tsx">
      <Typography variant="label-s-bold" data-sentry-element="Typography" data-sentry-source-file="PropertiesPanelHeader.tsx">Edit properties</Typography>
    </MuiGrid>
    <MuiGrid item display="flex" data-sentry-element="MuiGrid" data-sentry-source-file="PropertiesPanelHeader.tsx">
      <IconButton icon="CLOSE" variant="icon" size="medium" onClick={onClose} data-sentry-element="IconButton" data-sentry-source-file="PropertiesPanelHeader.tsx" />
    </MuiGrid>
  </MuiGrid>;