import { FC } from 'react';
import { SubModule } from '../../../../base';
import * as S from './TextContent.style';
import { useTextContent } from './useTextContent';
export const TextContent: FC = () => {
  const {
    id,
    save,
    discard,
    registerField
  } = useTextContent();
  return <S.TextContentLabel data-sentry-element="unknown" data-sentry-component="TextContent" data-sentry-source-file="TextContent.tsx">
      <SubModule title="Text content" renderInline data-sentry-element="SubModule" data-sentry-source-file="TextContent.tsx">
        <S.TextContentInput id={id} onEscape={discard} onEnter={save} {...registerField()} onBlurCapture={save} data-sentry-element="unknown" data-sentry-source-file="TextContent.tsx" />
      </SubModule>
    </S.TextContentLabel>;
};