import { FC } from 'react';
import { MultiDirectionalDimensionField } from '../../../../base';
import { useConstraintsFields } from './useConstraintsFields';
export const AllConstraintsField: FC = () => {
  const {
    allConstraintsFieldProps,
    rawConstraintsValue,
    parsedRawConstraintsValue,
    isConstraintsComputed
  } = useConstraintsFields();
  return <MultiDirectionalDimensionField icon="DDP_FIELD_CONSTRAINTS_ALL" tooltipTitle="All Constraints" {...allConstraintsFieldProps} rawValue={rawConstraintsValue} parsedRawValue={parsedRawConstraintsValue} isComputed={isConstraintsComputed} data-sentry-element="MultiDirectionalDimensionField" data-sentry-component="AllConstraintsField" data-sentry-source-file="AllConstraintsField.tsx" />;
};