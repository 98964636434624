/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const InviteUserIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="31" height="22" attr={{
  viewBox: '0 0 31 22'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M23 13C23 12.4477 23.4477 12 24 12H30C30.5523 12 31 12.4477 31 13C31 13.5523 30.5523 14 30 14H24C23.4477 14 23 13.5523 23 13Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M27 9C27.5523 9 28 9.44772 28 10V16C28 16.5523 27.5523 17 27 17C26.4477 17 26 16.5523 26 16V10C26 9.44772 26.4477 9 27 9Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M12.5 2C8.91015 2 6 4.91015 6 8.5C6 12.0899 8.91015 15 12.5 15C16.0899 15 19 12.0899 19 8.5C19 4.91015 16.0899 2 12.5 2ZM4 8.5C4 3.80558 7.80558 0 12.5 0C17.1944 0 21 3.80558 21 8.5C21 13.1944 17.1944 17 12.5 17C7.80558 17 4 13.1944 4 8.5Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M12.5 16.9985C10.6016 16.9985 8.72624 17.4143 7.00572 18.2166C5.2852 19.019 3.76128 20.1884 2.54107 21.6427C2.18609 22.0658 1.55534 22.121 1.13225 21.766C0.709157 21.411 0.653946 20.7803 1.00893 20.3572C2.41686 18.6792 4.17523 17.3298 6.16044 16.4041C8.14566 15.4783 10.3095 14.9985 12.5 14.9985C14.6905 14.9985 16.8543 15.4783 18.8396 16.4041C20.8248 17.3298 22.5831 18.6792 23.9911 20.3572C24.3461 20.7803 24.2908 21.411 23.8678 21.766C23.4447 22.121 22.8139 22.0658 22.4589 21.6427C21.2387 20.1884 19.7148 19.019 17.9943 18.2166C16.2738 17.4143 14.3984 16.9985 12.5 16.9985Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="InviteUserIcon" data-sentry-source-file="InviteUserIcon.tsx" />;