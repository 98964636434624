import { FC } from 'react';
import { ButtonGroupField, GroupButton } from '../../../../../base';
import { useFlexDirectionField } from './useFlexDirectionField';
export const FlexDirectionField: FC = () => {
  const {
    isColumnSelected,
    handleColumnClick,
    isRowSelected,
    handleRowClick,
    isReverseSelected,
    handleReverseClick
  } = useFlexDirectionField();
  return <>
      <ButtonGroupField data-sentry-element="ButtonGroupField" data-sentry-source-file="FlexDirectionField.tsx">
        <GroupButton icon="DDP_FIELD_FLEX_DIRECTION_COLUMN" isSelected={isColumnSelected} onClick={handleColumnClick} tooltipTitle="Column" data-sentry-element="GroupButton" data-sentry-source-file="FlexDirectionField.tsx" />
        <GroupButton icon="DDP_FIELD_FLEX_DIRECTION_ROW" isSelected={isRowSelected} onClick={handleRowClick} tooltipTitle="Row" data-sentry-element="GroupButton" data-sentry-source-file="FlexDirectionField.tsx" />
      </ButtonGroupField>
      <ButtonGroupField data-sentry-element="ButtonGroupField" data-sentry-source-file="FlexDirectionField.tsx">
        <GroupButton icon="DDP_FIELD_FLEX_DIRECTION_REVERSE" onClick={handleReverseClick} isSelected={isReverseSelected} tooltipTitle="Reverse" allowToggle data-sentry-element="GroupButton" data-sentry-source-file="FlexDirectionField.tsx" />
      </ButtonGroupField>
    </>;
};