/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '../core/CustomIcon';
export const DropArrowClosedIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M10 8L6 12L6 4L10 8Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="DropArrowClosedIcon" data-sentry-source-file="DropArrowClosedIcon.tsx" />;