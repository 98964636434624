// eslint-disable-next-line @jux/must-import-FC-in-tsx-files
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const BoxShadowSpreadDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M6.80278 6.5L4.80278 9.5H11.1972L9.19723 6.5H6.80278ZM6.53518 5H9.46482C9.79917 5 10.1114 5.1671 10.2969 5.4453L12.9635 9.4453C13.4066 10.1099 12.9302 11 12.1315 11H3.86852C3.06982 11 2.59343 10.1099 3.03647 9.4453L5.70313 5.4453C5.8886 5.1671 6.20083 5 6.53518 5Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="BoxShadowSpreadDDPIcon" data-sentry-source-file="BoxShadowSpreadDDPIcon.tsx" />;