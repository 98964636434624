/**
 *  THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 */

/* eslint-disable */
// @ts-nocheck

import { createDrimz, type PropsOf } from '@jux/dui';
import React, { FC, forwardRef } from 'react';
import { COMPONENT_TAG_NAME, ComponentElementType } from '@jux/data-entities';
import { JuxReactElements } from '@jux/react';
const {
  styled
} = createDrimz();
type Token_button_button_label_root_Props = PropsOf<typeof JuxReactElements.JuxText>;
const Token_button_button_label_root: FC<Token_button_button_label_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxText {...props} ref={ref} />);
Token_button_button_label_root.displayName = 'Token_button_button_label_root';
Token_button_button_label_root.defaultProps = {
  rootElement: styled('p', {
    styles: {
      root: {
        fontFamily: 'Inter',
        fontWeight: '500',
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        display: 'inline-block'
      }
    }
  }) as Token_button_button_label_root_Props['rootElement']
};
export type Token_button_button_label_Props = Token_button_button_label_root_Props;
export const Token_button_button_label: FC<Token_button_button_label_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxText>, Token_button_button_label_Props>((props, ref) => <Token_button_button_label_root {...props} ref={ref} />);
Token_button_button_label.displayName = 'Token_button_button_label';
Token_button_button_label.defaultProps = {
  text: 'Token management'
};
type Token_button_root_Props = PropsOf<typeof JuxReactElements.JuxButton> & {
  disabled: true | false;
  hierarchy: 'primary' | 'secondary';
};
const Token_button_root: FC<Token_button_root_Props> = forwardRef((props, ref) => <JuxReactElements.JuxButton {...props} ref={ref} />);
Token_button_root.displayName = 'Token_button_root';
Token_button_root.defaultProps = {
  rootElement: styled('button', {
    styles: {
      root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#FFFFFF',
        width: 'auto',
        height: '36px',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '20px',
        padding: '8px 16px',
        borderRadius: '4px',
        borderStyle: 'none',
        borderColor: '',
        gap: '8px',
        cursor: 'pointer',
        outline: 'none',
        '&:active': {
          outline: 'none'
        }
      },
      variants: [{
        styles: {
          backgroundColor: '{color.core.grays_800}',
          borderRadius: '1px',
          height: '32px',
          padding: '6px 8px 6px 8px',
          justifyContent: 'flex-start',
          display: 'flex',
          width: '338px',
          '[data-jux-context="75eb09c4-8ede-4b03-9193-377b779a964c"]': {
            color: '{color.core.grays_200}',
            fontWeight: '400'
          },
          '&:hover': {
            backgroundColor: '{color.core.grays_650}',
            '[data-jux-context="75eb09c4-8ede-4b03-9193-377b779a964c"]': {
              color: '{color.core.grays_0}'
            }
          },
          '&:active': {
            backgroundColor: '{color.core.grays_650}'
          }
        },
        propsValues: {
          hierarchy: 'primary',
          disabled: false
        }
      }, {
        styles: {
          backgroundColor: '#9C9C9C',
          '&:hover': {
            backgroundColor: '#6A6A6A'
          },
          '&:active': {
            backgroundColor: '#3B3B3B'
          }
        },
        propsValues: {
          hierarchy: 'secondary',
          disabled: false
        }
      }, {
        styles: {
          backgroundColor: '#98A6F9',
          cursor: 'default'
        },
        propsValues: {
          hierarchy: 'primary',
          disabled: true
        }
      }, {
        styles: {
          backgroundColor: '#C8C8C8',
          cursor: 'default'
        },
        propsValues: {
          hierarchy: 'secondary',
          disabled: true
        }
      }]
    }
  }) as Token_button_root_Props['rootElement']
};
export type Token_button_Props = Token_button_root_Props & {
  Token_button_button_label_Props?: Token_button_button_label_Props;
};
export const Token_button: FC<Token_button_Props> = forwardRef<ComponentElementType<typeof COMPONENT_TAG_NAME.JuxButton>, Token_button_Props>(({
  Token_button_button_label_Props,
  ...props
}, ref) => <Token_button_root {...props} ref={ref}>
    {props.children || <>
        <Token_button_button_label {...Token_button_button_label_Props} data-jux-context="75eb09c4-8ede-4b03-9193-377b779a964c" />
      </>}
  </Token_button_root>);
Token_button.displayName = 'Token_button';
Token_button.defaultProps = {
  hierarchy: 'primary',
  disabled: false
};