import { FC, PropsWithChildren } from 'react';
import { MenuList } from '@jux/ui/components';
import { MuiBox } from '@jux/ui/components/common/mui';
import { NAVIGATOR_COMPONENT_ITEM_ACTIONS_ICON_COLOR } from '@jux/ui/theme/palette';
import { withImportant } from '@jux/ui/utils/css';
export const ComponentPreviewItemActionsMenu: FC<PropsWithChildren<{
  isVisible?: boolean;
}>> = ({
  isVisible = true,
  children
}) => <MenuList isVisible={isVisible} popperProps={{
  sx: {
    zIndex: 10
  }
}} optionsButtonProps={{
  variant: 'icon',
  disableTooltip: true,
  sx: {
    position: 'absolute',
    top: 5,
    right: 5,
    zIndex: 10,
    p: 0,
    width: withImportant('23px'),
    height: withImportant('15px')
  },
  iconProps: {
    size: 15,
    sx: {
      color: NAVIGATOR_COMPONENT_ITEM_ACTIONS_ICON_COLOR
    }
  }
}} data-sentry-element="MenuList" data-sentry-component="ComponentPreviewItemActionsMenu" data-sentry-source-file="ComponentPreviewItemActionsMenu.tsx">
    <MuiBox data-sentry-element="MuiBox" data-sentry-source-file="ComponentPreviewItemActionsMenu.tsx">{children}</MuiBox>
  </MenuList>;