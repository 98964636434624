/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const ComponentObjectIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M8.00181 6.58761L10.1231 4.46629L8.00181 2.34497L5.88049 4.46629L8.00181 6.58761Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M4.46629 10.1231L6.58761 8.00181L4.46629 5.88049L2.34497 8.00181L4.46629 10.1231Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M10.1231 11.5373L8.00181 13.6587L5.88049 11.5373L8.00181 9.41602L10.1231 11.5373Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M11.5373 10.1231L13.6587 8.00181L11.5373 5.88049L9.41602 8.00181L11.5373 10.1231Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="ComponentObjectIcon" data-sentry-source-file="ComponentObjectIcon.tsx" />;