// eslint-disable-next-line no-restricted-imports
import { Grid as MuiGrid, styled } from '@mui/material';
export const ItemBox = styled(MuiGrid, {
  shouldForwardProp: prop => prop !== 'isDragging'
})<{
  isDragging?: boolean;
}>(({
  theme,
  isDragging = false
}) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: 120,
  minHeight: 120,
  flexDirection: 'column',
  background: theme.drimz.palette.background.hover,
  cursor: isDragging ? 'grabbing' : 'grab'
}));