import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { alpha } from '@jux/ui/utils/css';
import { MagnifierControlItem } from './MagnifierControlItem';
import { useMagnifier } from './useMagnifier';
import { MagnifierZoomView } from './MagnifierZoomView';
export const Magnifier: FC = () => {
  const {
    zoomPercentage,
    showControls,
    handleZoomIn,
    handleZoomOut,
    handleFitView,
    handleResetView,
    handleMouseEnter,
    handleMouseLeave
  } = useMagnifier();
  return <MuiGrid container sx={{
    borderRadius: theme => theme.drimz.size.borderRadius.main,
    transition: theme => theme.drimz.transitions.all,
    backgroundColor: theme => showControls ? alpha(theme.drimz.palette.background.default, 0.9) : theme.drimz.palette.transparent,
    px: '10px',
    alignItems: 'center'
  }} gap="10px" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} data-sentry-element="MuiGrid" data-sentry-component="Magnifier" data-sentry-source-file="Magnifier.tsx">
      <MagnifierControlItem showControl={showControls} icon="FIT_VIEW" tooltipTitle="Fit view" onClick={handleFitView} data-sentry-element="MagnifierControlItem" data-sentry-source-file="Magnifier.tsx" />
      <MagnifierControlItem showControl={showControls} icon="RESET_VIEW" tooltipTitle="Reset view" onClick={handleResetView} data-sentry-element="MagnifierControlItem" data-sentry-source-file="Magnifier.tsx" />

      <MagnifierZoomView showControls={showControls} zoomPercentage={zoomPercentage} data-sentry-element="MagnifierZoomView" data-sentry-source-file="Magnifier.tsx" />

      <MagnifierControlItem showControl={showControls} icon="ZOOM_OUT_VIEW" tooltipTitle="Zoom out" onClick={handleZoomOut} data-sentry-element="MagnifierControlItem" data-sentry-source-file="Magnifier.tsx" />
      <MagnifierControlItem showControl={showControls} icon="ZOOM_IN_VIEW" tooltipTitle="Zoom in" onClick={handleZoomIn} data-sentry-element="MagnifierControlItem" data-sentry-source-file="Magnifier.tsx" />
    </MuiGrid>;
};