import { CSSProperties, FC, useCallback, useMemo } from 'react';
import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';
import { useFieldValues, useSetFieldValue } from '../../../../hooks';
import { ButtonGroupField, GroupButton } from '../../../../base';
const FIELD_KEY = DDPPrimitiveFieldsKeys.textAlign;
const AlignmentValue: { [alignment in Extract<CSSProperties['textAlign'], 'left' | 'center' | 'right' | 'justify'>]: alignment } = {
  left: 'left',
  center: 'center',
  right: 'right',
  justify: 'justify'
} as const;
export const AlignField: FC = () => {
  const {
    value,
    initialValue
  } = useFieldValues(FIELD_KEY);
  const {
    saveValue: setFieldValue
  } = useSetFieldValue(FIELD_KEY);
  const selectedTextAlign = useMemo(() => value ?? initialValue, [initialValue, value]);
  const isGroupButtonSelected = useCallback((groupButtonValue: keyof typeof AlignmentValue) => selectedTextAlign === groupButtonValue, [selectedTextAlign]);
  return <ButtonGroupField data-sentry-element="ButtonGroupField" data-sentry-component="AlignField" data-sentry-source-file="AlignField.tsx">
      <GroupButton icon="DDP_FIELD_TEXT_ALIGN_LEFT" onClick={() => setFieldValue(AlignmentValue.left)} isSelected={isGroupButtonSelected(AlignmentValue.left)} data-sentry-element="GroupButton" data-sentry-source-file="AlignField.tsx" />
      <GroupButton icon="DDP_FIELD_TEXT_ALIGN_CENTER" onClick={() => setFieldValue(AlignmentValue.center)} isSelected={isGroupButtonSelected(AlignmentValue.center)} data-sentry-element="GroupButton" data-sentry-source-file="AlignField.tsx" />
      <GroupButton icon="DDP_FIELD_TEXT_ALIGN_RIGHT" onClick={() => setFieldValue(AlignmentValue.right)} isSelected={isGroupButtonSelected(AlignmentValue.right)} data-sentry-element="GroupButton" data-sentry-source-file="AlignField.tsx" />
      <GroupButton icon="DDP_FIELD_TEXT_ALIGN_JUSTIFY" onClick={() => setFieldValue(AlignmentValue.justify)} isSelected={isGroupButtonSelected(AlignmentValue.justify)} data-sentry-element="GroupButton" data-sentry-source-file="AlignField.tsx" />
    </ButtonGroupField>;
};