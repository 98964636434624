/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const DisplayInlineDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M3.5 3C3.22386 3 3 3.22386 3 3.5V12.5C3 12.7761 3.22386 13 3.5 13C3.77614 13 4 12.7761 4 12.5V3.5C4 3.22386 3.77614 3 3.5 3Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M8.18256 4L5 12H6.61308L7.21253 10.4333H10.7875L11.3869 12H13L9.81744 4H8.18256ZM10.2752 9.08889H7.7248L9 5.72222L10.2752 9.08889Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="DisplayInlineDDPIcon" data-sentry-source-file="DisplayInlineDDPIcon.tsx" />;