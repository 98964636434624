import React, { FC } from 'react';
import { MultiSelectionBox } from '../../components/MultiSelectionBox';
import { useStore } from '../../hooks';
import * as S from './PaneIndicators.style';
export const PaneIndicators: FC = () => {
  const transform = useStore(state => state.transform);
  return <S.PaneIndicatorsWrapper
  // All indicators will be rendered here - node resizer & node toolbar
  id={'jux_pane_indicators'} {...transform} data-sentry-element="unknown" data-sentry-component="PaneIndicators" data-sentry-source-file="PaneIndicators.tsx">
      <MultiSelectionBox data-sentry-element="MultiSelectionBox" data-sentry-source-file="PaneIndicators.tsx" />
    </S.PaneIndicatorsWrapper>;
};