import { FC } from 'react';
import { LayersPanelLayout } from './layout';
import { ObjectNavigator } from './ObjectNavigator';
import { useExpandToLayerOnCanvasNodeClick, useLayersPanelExpansion, useLayersPanelItems } from './state';
export const LayersPanel: FC = () => {
  const items = useLayersPanelItems();
  const {
    hideGlobalExpanders,
    showGlobalExpanders
  } = useLayersPanelExpansion({
    items
  });
  useExpandToLayerOnCanvasNodeClick({
    items
  });

  // Default to ObjectNavigator for now (in the future, we'll also have Layers Search, etc.)
  return <LayersPanelLayout onMouseEnter={showGlobalExpanders} onMouseLeave={hideGlobalExpanders} data-sentry-element="LayersPanelLayout" data-sentry-component="LayersPanel" data-sentry-source-file="LayersPanel.tsx">
      <ObjectNavigator items={items} data-sentry-element="ObjectNavigator" data-sentry-source-file="LayersPanel.tsx" />
    </LayersPanelLayout>;
};