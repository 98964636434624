import { FC, FocusEvent, KeyboardEvent, useCallback, useState } from 'react';
import { Input } from '../input';
import { TokenFieldContent } from '../tokenFieldContent';
import { DimensionFieldTokenContentValue } from './DimensionFieldTokenContentValue';
import { UnitSelection } from './unitSelection/UnitSelection';
import { DimensionFieldContentProps } from './DimensionFieldContent.interface';
import { DDP_DIMENSION_FIELD_CLASS_NAME } from './Dimension.constant';
import { DimensionFieldMultiDirectionalTooltip } from './DimensionFieldMultiDirectionalTooltip';
export const DimensionFieldContent: FC<DimensionFieldContentProps> = ({
  inputProps: {
    onBlur,
    onEnter,
    onEscape,
    onFocus,
    inputRegister,
    ...inputProps
  },
  tokensProps,
  unit,
  isFieldDisabled,
  isComputed,
  onUnitChange,
  activateUnit,
  rawValue,
  htmlForTarget,
  isMultiDirectional,
  disablePercentValue
}) => {
  const {
    tokensPopperProps,
    isTokenized,
    tokenDisplayName,
    tokenParsedValue
  } = tokensProps ?? {};
  const {
    openTokens
  } = tokensPopperProps ?? {};
  const [inputFocused, setInputFocused] = useState(false);
  const handleOnEnter = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    onEnter(e);
    e.currentTarget.blur();
  }, [onEnter]);
  const handleOnEscape = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    onEscape(e);
    e.currentTarget.blur();
  }, [onEscape]);
  const handleOnBlur = useCallback((e: FocusEvent<HTMLInputElement>) => {
    onBlur(e);
    setInputFocused(false);
  }, [onBlur]);
  const handleOnFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
    onFocus?.(e);
    setInputFocused(true);
  }, [onFocus]);
  if (isTokenized && tokenDisplayName) {
    return <TokenFieldContent isFieldDisabled={isFieldDisabled} onClick={openTokens} tokenName={tokenDisplayName}>
        <DimensionFieldTokenContentValue parsedTokenValue={tokenParsedValue} unit={unit} />
      </TokenFieldContent>;
  }
  return <>
      <DimensionFieldMultiDirectionalTooltip value={rawValue} isMultiDirectional={isMultiDirectional} data-sentry-element="DimensionFieldMultiDirectionalTooltip" data-sentry-source-file="DimensionFieldContent.tsx">
        <Input id={htmlForTarget} isComputed={isComputed} onEscape={handleOnEscape} onEnter={handleOnEnter} {...inputProps} {...inputRegister()}
      // make sure to render onBlur after inputRegister() to override it, otherwise it will be not call the correct onBlur
      // due to the fact we have specific logic we have with Escape that it both discardChanges and blur the element and on this case we don't want to save (when save is the default behavior of onBlur from react-hook-form)
      onBlur={handleOnBlur} onFocus={handleOnFocus} isFieldDisabled={isFieldDisabled} data-sentry-element="Input" data-sentry-source-file="DimensionFieldContent.tsx" />
      </DimensionFieldMultiDirectionalTooltip>
      {(activateUnit || inputFocused) && <UnitSelection unit={unit} onUnitChange={onUnitChange} fieldClassName={DDP_DIMENSION_FIELD_CLASS_NAME} isFieldDisabled={isFieldDisabled} disablePercentValue={disablePercentValue} />}
    </>;
};