import { FC } from 'react';
import * as UI from '@jux/ui-core';
import { FocusElementForTrap } from '@jux/ui/components';
import { MuiDrawer, MuiGrid } from '@jux/ui/components/common/mui';
import { TEST_ID_SIDEBAR } from '@jux/constants';
import { SideBarItems } from './SidebarItems';
import { useSidebarProps } from './useSidebarProps';
const IN_DELAY = 200;
const OUT_DELAY = 100;
export const Sidebar: FC = () => {
  const {
    canvasItemsSidebarProps,
    close,
    isTokenSelected,
    handleTokenClick,
    isCreationMode,
    isSidebarOpen,
    onSaveNewCanvasName,
    setIsCreationMode,
    sidebarRef,
    zIndex
  } = useSidebarProps();
  return <MuiDrawer anchor="left" onClose={close} open={isSidebarOpen} SlideProps={{
    timeout: {
      enter: IN_DELAY,
      exit: OUT_DELAY
    }
  }} sx={{
    '&.MuiDrawer-root': {
      zIndex
    }
  }} variant="persistent" data-sentry-element="MuiDrawer" data-sentry-component="Sidebar" data-sentry-source-file="Sidebar.tsx">
      <MuiGrid ref={sidebarRef} data-testid={TEST_ID_SIDEBAR} data-sentry-element="MuiGrid" data-sentry-source-file="Sidebar.tsx">
        <FocusElementForTrap tabIndex={1} data-sentry-element="FocusElementForTrap" data-sentry-source-file="Sidebar.tsx" />
        <UI.Sidebar onTokenClick={handleTokenClick} isTokensSelected={isTokenSelected} sideBarItems={<SideBarItems canvasItems={canvasItemsSidebarProps} onNewCanvasClick={() => setIsCreationMode(true)} isCanvasCreationMode={isCreationMode} onSaveNewCanvasName={onSaveNewCanvasName} onDiscardCreation={() => setIsCreationMode(false)} />} data-sentry-element="unknown" data-sentry-source-file="Sidebar.tsx" />
      </MuiGrid>
    </MuiDrawer>;
};