/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const ArrowPointsLeftIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" attr={{
  viewBox: '0 0 4.21 7'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'm0,3.5L3.35.15c.2-.2.51-.2.71,0s.2.51,0,.71L1.41,3.5l2.65,2.65c.2.2.2.51,0,.71s-.51.2-.71,0L0,3.5Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="ArrowPointsLeftIcon" data-sentry-source-file="ArrowPointsLeftIcon.tsx" />;