import { ComponentProps, FC, useMemo } from 'react';
import { styled } from '@jux/ui/components/common/mui';
import { toInteger } from 'lodash';
import { useStore } from '@jux/canjux/core';
import { useSettingsState } from '@jux/ui/state';
function standardizeColor(str: string): string {
  const ctx = document.createElement('canvas').getContext('2d');
  if (!ctx) {
    return '';
  }
  ctx.fillStyle = str;
  return ctx.fillStyle;
}
function getColor(rgb: RGB) {
  if (!rgb) {
    return;
  }
  const {
    r,
    g,
    b
  } = rgb;
  if (r && g && b) {
    const isLight = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return isLight < 0.5;
  }
  return false;
}
function hexToRgb(hex: string): RGB {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}
export function getContrastingColor(col: string) {
  if (typeof window === 'undefined') {
    return;
  }
  const useBlack = getColor(hexToRgb(standardizeColor(col)));
  return useBlack ? '#000000' : '#ffffff';
}
type RGB = {
  r: number;
  g: number;
  b: number;
} | null;
interface Props extends Omit<ComponentProps<'div'>, 'color'> {
  color: string;
  name: string;
  x: number;
  y: number;
}
const CursorContainer = styled('div')({
  position: 'absolute',
  zIndex: 100,
  top: 0,
  left: 0,
  pointerEvents: 'none',
  transition: 'transform 0.15s linear',
  userSelect: 'none'
});
const Pointer = styled('svg')({
  position: 'absolute',
  top: 0,
  left: 0
});
const NameContainer = styled('div')({
  position: 'absolute',
  top: '1rem',
  left: '1rem',
  overflow: 'hidden',
  padding: '0.375rem 0.75rem',
  borderRadius: '0.4rem',
  fontSize: '0.875rem',
  fontWeight: 500,
  whiteSpace: 'nowrap'
});
export const Cursor: FC<Props> = ({
  x,
  y,
  color,
  name
}) => {
  const textColor = useMemo(() => color ? getContrastingColor(color) : undefined, [color]);
  const zoomLevel = useStore(s => s.transform.zoom);
  return <CursorContainer style={{
    transform: `translate(${x}px,${y}px) scale(${1 / zoomLevel})`
  }} data-sentry-element="CursorContainer" data-sentry-component="Cursor" data-sentry-source-file="index.tsx">
      <Pointer fill="none" height="44" viewBox="0 0 24 36" width="32" data-sentry-element="Pointer" data-sentry-source-file="index.tsx">
        <path d="M0.928548 2.18278C0.619075 1.37094 1.42087 0.577818 2.2293 0.896107L14.3863 5.68247C15.2271 6.0135 15.2325 7.20148 14.3947 7.54008L9.85984 9.373C9.61167 9.47331 9.41408 9.66891 9.31127 9.91604L7.43907 14.4165C7.09186 15.2511 5.90335 15.2333 5.58136 14.3886L0.928548 2.18278Z" fill={color} data-sentry-element="path" data-sentry-source-file="index.tsx" />
      </Pointer>
      <NameContainer style={{
      background: color,
      color: textColor
    }} data-sentry-element="NameContainer" data-sentry-source-file="index.tsx">
        {name}
      </NameContainer>
    </CursorContainer>;
};
const COLORS = ['#DC2626', '#D97706', '#059669', '#7C3AED', '#DB2777'];
const Cursors: FC = () => {
  const activeUsers = useStore(s => s.liveblocks.others);
  const {
    settings: {
      featureFlags: {
        presence
      }
    }
  } = useSettingsState();
  if (!presence) return null;
  return <>
      {activeUsers.map(user => {
      if (!user.presence.cursor) {
        return null;
      }

      // TODO: Fix this (cursors are not synced properly)
      return <Cursor color={COLORS[toInteger(user.id) % COLORS.length]} name={user.info.name} x={user.presence.cursor.x} y={user.presence.cursor.y} />;
    })}
    </>;
};
export default Cursors;