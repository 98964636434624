// eslint-disable-next-line @jux/must-import-FC-in-tsx-files
import { IconType, IconBaseProps } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const RadioElementPanelIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="32" height="33" attr={{
  viewBox: '0 0 32 33'
}} child={[{
  tag: 'rect',
  attr: {
    x: '5',
    y: '5.5',
    width: '22',
    height: '22',
    rx: '11',
    stroke: 'currentColor',
    strokeWidth: '10',
    fill: 'none'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="RadioElementPanelIcon" data-sentry-source-file="RadioElementPanelIcon.tsx" />;