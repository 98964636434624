import { FC, useCallback, useState } from 'react';
import { ChromePicker as BaseChromePicker, ChromePickerProps, Color, ColorResult } from 'react-color';
import { useTheme } from '@jux/ui/components/common/mui';
export type CustomColorChangeHandler = (rgba: string, isChangeComplete: boolean) => void;
export type ChromePickerPropsWithColor = Omit<ChromePickerProps, 'onChange' | 'onChangeComplete'> & {
  color?: Color | undefined;
  className?: string | undefined;
  onChange?: CustomColorChangeHandler | undefined;
};
export const CHROME_PICKER_WIDTH = 270;
export const ChromePicker: FC<ChromePickerPropsWithColor> = ({
  onChange,
  ...props
}) => {
  const theme = useTheme();
  const [currentColor, setCurrentColor] = useState(props.color);
  const handleOnChange = useCallback(({
    rgba: {
      r,
      g,
      b,
      a
    },
    isChangeComplete
  }: {
    rgba: ColorResult['rgb'];
    isChangeComplete: boolean;
  }) => {
    setCurrentColor({
      r,
      g,
      b,
      a
    });

    // we're doing this in order to get the opacity value as a complete number between 0 and 1
    const opacity = Math.floor((a ?? 1) * 100);
    const alpha = opacity / 100;
    const rgba = `rgba(${r}, ${g}, ${b}, ${alpha})`;
    if (onChange) {
      onChange(rgba || '', isChangeComplete);
    }
  }, [onChange]);
  return <BaseChromePicker styles={{
    default: {
      picker: {
        // TODO: support dark mode
        width: CHROME_PICKER_WIDTH,
        boxShadow: 'none',
        borderRadius: theme.drimz.size.borderRadius.main,
        height: CHROME_PICKER_WIDTH
      },
      saturation: {
        borderRadius: `${theme.drimz.size.borderRadius.main} ${theme.drimz.size.borderRadius.main} 0 0`
      },
      active: {
        boxShadow: 'none'
      },
      swatch: {
        borderRadius: 10,
        width: 30,
        height: 30,
        marginRight: 8,
        marginLeft: -6,
        marginTop: -2,
        border: '1px solid #dadada'
      }
    }
  }} {...props} color={currentColor} onChange={color => {
    handleOnChange({
      rgba: color.rgb,
      isChangeComplete: false
    });
  }} onChangeComplete={color => {
    handleOnChange({
      rgba: color.rgb,
      isChangeComplete: true
    });
  }} data-sentry-element="BaseChromePicker" data-sentry-component="ChromePicker" data-sentry-source-file="ChromePicker.tsx" />;
};