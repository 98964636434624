// Every component that has a parent should be listed as a child of the parent
import type { Draft as WritableDraft } from 'mutative';
import { CanjuxState } from '@jux/canjux/core';
import logger from '@jux/ui-logger';
import { StateValidator } from './validator.interface';
import { deleteNode } from '../actions';

export const validateNoDetachedNodes: StateValidator = ({
  state,
  recover = false,
}: {
  state: WritableDraft<CanjuxState>;
  recover?: boolean;
}) => {
  for (const component of Object.values(state.components)) {
    // Validate parent
    if (component.parentId) {
      const parent = state.components[component.parentId];
      if (!parent) {
        if (recover) {
          logger.info(
            `data recovery: removing bad node ${component.id} that has a parent ${component.parentId} that does not exist`
          );
          deleteNode({
            canvasName: state.currentCanvasName,
            nodeId: component.id,
            shouldForceDelete: true,
            state,
          });
        } else {
          throw new Error(
            `Node ${component.id} has a parent id ${component.parentId} that does not exist`
          );
        }
      }

      if (!parent?.children.includes(component.id)) {
        if (recover) {
          logger.info(
            `data recovery: removing bad node ${component.id} that has parent that doesn't exist ${component.parentId}`
          );
          deleteNode({
            canvasName: state.currentCanvasName,
            nodeId: component.id,
            shouldForceDelete: true,
            state,
          });
        } else {
          throw new Error(`Node ${component.id} is not a child of its parent`);
        }
      }
    }

    // Validate children. old svg's used to have undefined children,
    // but now we have consistency and it should be always empty array
    try {
      if (component.children === undefined) {
        throw new Error('children is undefined');
      }
    } catch {
      if (recover) {
        component.children = [];
      }
    }

    for (const childId of component.children) {
      const child = state.components[childId];
      if (!child) {
        if (recover) {
          // delete detached node and it's children
          logger.info(
            `data recovery: removing bad node ${component.id} that has child id ${childId} that does not exist`
          );
          component.children = component.children.filter(
            (id) => id !== childId
          );
        } else {
          throw new Error(
            `Node ${component.id} has a child id ${childId} that does not exist`
          );
        }
        // Else, if child exist but is not connected to the parent
      } else if (child?.parentId !== component.id) {
        if (recover) {
          // Remove child from this parent
          component.children = component.children.filter(
            (id) => id !== childId
          );
        } else {
          throw new Error(`Node ${childId} is not a child of its parent`);
        }
      }
    }
  }
};
