import { useCallback } from 'react';
import { useStoreActions } from '@jux/canjux/core';
import { useSelectedNodeId } from '@jux/ui/components/editor/hooks';
import { toast } from '@jux/ui/toast';
export const useCreateComponent = () => {
  const selectedNodeId = useSelectedNodeId();
  const {
    componentActions: {
      createComponentFromElement
    }
  } = useStoreActions();
  const handleCreateComponent = useCallback(async () => {
    if (!selectedNodeId) return;
    try {
      await createComponentFromElement({
        nodeId: selectedNodeId
      });
    } catch (error) {
      toast.error('Failed to create component');
    }
  }, [createComponentFromElement, selectedNodeId]);
  return {
    handleCreateComponent
  };
};