import { FC, memo, MouseEventHandler } from 'react';
import { EditableTypography, EditableTypographyProps, Typography, TypographyProps } from '@jux/ui/components';
import { textValidators } from '@jux/ui/utils/textValidators';
import { selectOrgComponentsNames, storeApi } from '@jux/canjux/core';
import { NodeType } from '@jux/data-entities';
export type ObjectNavigatorItemNameProps = {
  objectType: NodeType;
  text: string;
  isEditing: boolean;
  onBlur: EditableTypographyProps['onBlur'];
  onDoubleClick: MouseEventHandler<HTMLElement>;
};
const getOrgComponentNames = () => selectOrgComponentsNames(storeApi.getState());
export const ObjectNavigatorItemName: FC<ObjectNavigatorItemNameProps> = memo(({
  objectType,
  text,
  isEditing = true,
  onBlur,
  onDoubleClick
}) => {
  const typographyProps = {
    variant: 'label-xs-medium' as TypographyProps['variant'],
    sx: {
      height: '15px',
      color: 'inherit',
      overflow: 'hidden',
      textOverflow: isEditing ? 'auto' : 'ellipsis',
      whiteSpace: 'nowrap'
    }
  };
  const nameAlreadyExistsValidator = textValidators.nameAlreadyExistsValidator({
    namesArray: getOrgComponentNames,
    errorMessages: {
      nameAlreadyExists: 'Component name already exists',
      namesArrayUndefined: 'org components names are not loaded'
    }
  });
  const isComponent = objectType === NodeType.LOCAL_COMPONENT || objectType === NodeType.LIBRARY_COMPONENT;
  const customValidators = isComponent ? [nameAlreadyExistsValidator] : undefined;

  // TODO: Handle text overflow and scrolling
  return isEditing ? <EditableTypography {...typographyProps} tooltipProps={{
    disabled: true
  }} customValidators={customValidators} label={text} onBlur={onBlur} onPaste={event => {
    event.stopPropagation();
  }} autoFocus /> : <Typography {...typographyProps} onDoubleClick={onDoubleClick}>
        {text}
      </Typography>;
});