/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const FitViewIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="17" height="11" attr={{
  viewBox: '0 0 17 11'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M10.7929 0.792893C11.1834 0.402369 11.8166 0.402369 12.2071 0.792893L16.2071 4.79289C16.5976 5.18342 16.5976 5.81658 16.2071 6.20711L12.2071 10.2071C11.8166 10.5976 11.1834 10.5976 10.7929 10.2071C10.4024 9.81658 10.4024 9.18342 10.7929 8.79289L13.0858 6.5H3.91421L6.20711 8.79289C6.59763 9.18342 6.59763 9.81658 6.20711 10.2071C5.81658 10.5976 5.18342 10.5976 4.79289 10.2071L0.792893 6.20711C0.402369 5.81658 0.402369 5.18342 0.792893 4.79289L4.79289 0.792894C5.18342 0.402369 5.81658 0.402369 6.20711 0.792894C6.59763 1.18342 6.59763 1.81658 6.20711 2.20711L3.91421 4.5L13.0858 4.5L10.7929 2.20711C10.4024 1.81658 10.4024 1.18342 10.7929 0.792893Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="FitViewIcon" data-sentry-source-file="FitViewIcon.tsx" />;