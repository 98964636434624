/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const PositionStaticDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M11.5 2.5L4.5 2.5L4.5 1.5H3V3C3 3.55228 3.44772 4 4 4L12 4C12.5523 4 13 3.55229 13 3V1.5H11.5V2.5Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M4 5.5C3.44772 5.5 3 5.94772 3 6.5V9.5C3 10.0523 3.44772 10.5 4 10.5H12C12.5523 10.5 13 10.0523 13 9.5V6.5C13 5.94772 12.5523 5.5 12 5.5H4ZM4.5 7V9H11.5V7H4.5Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M11.5 13.5H4.5V14.5H3V13C3 12.4477 3.44772 12 4 12H12C12.5523 12 13 12.4477 13 13V14.5H11.5V13.5Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="PositionStaticDDPIcon" data-sentry-source-file="PositionStaticDDPIcon.tsx" />;