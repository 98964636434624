import { DATA_JUX_CONTEXT_ATTRIBUTE } from '@jux/calculate-styles';
import { DATA_JUX_NODE_ID_ATTRIBUTE, PlaceholderMode, isNodeSelected, NodeToolbar, selectIsLiveMode, selectIsNodeInLibrary, selectIsTextNodeInEditMode, selectNodeChildren, selectNodeComponent, selectNodePosition, selectNodeProperties, selectResolvedComponentById, selectResolvedComponentProps, selectIsNodeShouldTriggerLayersUpdate, useStore, useStoreActions } from '@jux/canjux/core';
import { COMPONENT_TAG_NAME, ComponentProps, NodeType } from '@jux/data-entities';
import { DEFAULT_STATE, VariantsValues } from '@jux/types';
import { default as JuxControlledElement } from '@jux/ui/components/editor/components/canvas/node/ControlledElement';
import { default as JuxElement } from '@jux/ui/components/editor/components/canvas/node/Element';
import { useSaveNodeTextContent } from '@jux/ui/components/editor/hooks/useSaveNodeTextContent';
import { isVisible } from '@jux/ui/components/editor/utils/isVisible';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';
import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { EditableTextElement } from './EditableTextElement';
import { getCanvasNodeElementStyle } from './utils';
type CanvasNodeProps = {
  resizeObserver: ResizeObserver;
  nodeId: string;
};
export const CanvasNode: FC<CanvasNodeProps> = memo(({
  resizeObserver,
  nodeId
}) => {
  const component = useStore(selectNodeComponent(nodeId));
  const componentToRender = useStore(selectResolvedComponentById({
    id: nodeId,
    shouldStopOnVariantMatrix: true
  }));
  const resolvedComponentProps = useStore(selectResolvedComponentProps({
    id: nodeId,
    onlyVariantsProps: false
  }));
  const variantsPropsValues = useStore(selectResolvedComponentProps({
    id: nodeId,
    onlyVariantsProps: true
  })) as VariantsValues;
  const nodeChildren = useStore(selectNodeChildren(nodeId));
  const nodeProperties = useStore(selectNodeProperties(nodeId));
  const nodePosition = useStore(selectNodePosition(nodeId));
  const isInLibrary = useStore(selectIsNodeInLibrary(nodeId));
  const isLive = useStore(selectIsLiveMode());
  const getIsTextNodeInEditMode = useStore(selectIsTextNodeInEditMode);
  const isSelected = useStore(isNodeSelected(nodeId));
  const isNodeShouldTriggerLayersUpdate = useStore(selectIsNodeShouldTriggerLayersUpdate(nodeId));
  const {
    commonActions: {
      setEditModeInTextNode,
      setUserSelection,
      updateNodesDimensions,
      triggerLayersUpdate
    }
  } = useStoreActions();
  const [paneIndicatorsRootElement, setPaneIndicatorsRootElement] = useState<HTMLElement | null>(null);
  const elementRef = useRef<HTMLDivElement>(null);
  const nodeHtmlElement = elementRef.current;
  const isNodeVisible = nodeHtmlElement ? isVisible(nodeId) : false;
  const {
    saveTextContent
  } = useSaveNodeTextContent();
  const textNodeInEditMode = Boolean(getIsTextNodeInEditMode(nodeId));
  const storeInputMode = useStore(s => s.placeholderMode[nodeId]);
  const calculatedElementProps = useMemo<ComponentProps>(() => {
    if (!nodePosition || !nodeProperties) return {} as ComponentProps;
    if ((component?.tagName === COMPONENT_TAG_NAME.JuxInput || component?.tagName === COMPONENT_TAG_NAME.JuxTextarea) && storeInputMode === PlaceholderMode.placeholder && !isLive) {
      // override input value so placeholder can be styled
      resolvedComponentProps.value = '';
    }
    return mergeTyped(resolvedComponentProps, {
      [DATA_JUX_CONTEXT_ATTRIBUTE]: componentToRender?.config?.contextId ?? componentToRender?.id,
      // old nodes have contextId, new nodes should use their nodeId
      [DATA_JUX_NODE_ID_ATTRIBUTE]: nodeId,
      'data-jux-position-x': nodePosition.x,
      'data-jux-position-y': nodePosition.y,
      id: nodeId,
      style: getCanvasNodeElementStyle({
        isDragged: nodeProperties.isDragged,
        isLive,
        isRoot: !component?.parentId,
        isSelected: isSelected,
        nodeParentId: component?.parentId,
        positionX: nodePosition.x,
        positionY: nodePosition.y,
        textNodeInEditMode
      })
    }) as ComponentProps;
  }, [component?.parentId, component?.tagName, isLive, isSelected, nodeId, nodePosition, nodeProperties, resolvedComponentProps, componentToRender?.config?.contextId, componentToRender?.id, storeInputMode, textNodeInEditMode]);
  const handleEditableTextDimensionsChange = useCallback((width: number, height: number) => {
    /**
     * updating the dimensions of the editable text node in order to update it׳s frame to be rendered correctly.
     * It is necessary so that the selection frame will be synced with the actual computed dimensions of the text node.
     */
    updateNodesDimensions({
      payload: [{
        nodeId,
        dimensions: {
          width,
          height
        }
      }]
    });
  }, [nodeId, updateNodesDimensions]);
  const handleEditableTextChange = useCallback((text: string) => {
    saveTextContent({
      nodeId,
      newText: text
    });
  }, [nodeId, saveTextContent]);
  const handleEditableTextMouseDown = useCallback(
  /**
   * setting user selection active to true when the user starts selecting text in order to prevent the node from being deselected
   * when the user clicks outside the node while the user select text.
   */
  () => setUserSelection({
    textNodeUserSelectionActive: true
  }), [setUserSelection]);
  const handleEditableTextMouseUp = useCallback(
  /**
   * setting user selection active to false when the user finish selecting text in order to turn back to initial state and allow the node from being deselected
   * when the user clicks outside the node.
   */
  () => setUserSelection({
    textNodeUserSelectionActive: false
  }), [setUserSelection]);
  const handleEditableTextBlur = useCallback(() => {
    /**
     * when the text node is being blurred for any reason such like user clicks outside the node, we need to exit the text node in edit mode
     * and let the user works with the node as a normal node again.
     */
    setEditModeInTextNode({
      nodeId,
      editable: false
    });

    /**
     * setting the user selection active to false so that the user will be able to work with the node as a normal node again and deselect it.
     */
    setUserSelection({
      textNodeUserSelectionActive: false
    });
  }, [nodeId, setEditModeInTextNode, setUserSelection]);
  useEffect(() => {
    setPaneIndicatorsRootElement(document.getElementById('jux_pane_indicators'));
  }, []);
  useEffect(() => {
    if (nodeHtmlElement) {
      resizeObserver.observe(nodeHtmlElement, {
        box: 'border-box'
      });
    }
  }, [nodeHtmlElement, resizeObserver]);
  useEffect(() => {
    if (isNodeShouldTriggerLayersUpdate) {
      triggerLayersUpdate({
        nodeId
      });
    }
  }, [nodeId, isNodeShouldTriggerLayersUpdate, triggerLayersUpdate]);
  const isDynamicSlotWithoutChildrenAndWithParent = componentToRender?.parentId && componentToRender?.type === NodeType.DYNAMIC_SLOT && !nodeChildren?.length;
  const isLogicalSlotWithoutChildren = componentToRender?.type === NodeType.LOGICAL_SLOT && !nodeChildren?.length;
  if (!componentToRender || !componentToRender?.tagName || isLogicalSlotWithoutChildren || isDynamicSlotWithoutChildrenAndWithParent || !nodeProperties || nodeProperties.isHidden) {
    return null;
  }
  return <>
        {paneIndicatorsRootElement && isNodeVisible && createPortal(<NodeToolbar nodeId={nodeId} nodeProperties={nodeProperties} disableNodesInteraction={isLive} isInLibrary={isInLibrary} />, paneIndicatorsRootElement, nodeId)}
        {textNodeInEditMode && componentToRender.tagName === COMPONENT_TAG_NAME.JuxText ? <EditableTextElement ref={elementRef} text={resolvedComponentProps.text || ''} styles={componentToRender.styles} stylesState={component?.config.interactiveState ?? DEFAULT_STATE} onDimensionsChange={handleEditableTextDimensionsChange} onTextChange={handleEditableTextChange} onMouseDown={handleEditableTextMouseDown} onMouseUp={handleEditableTextMouseUp} onBlur={handleEditableTextBlur} {...calculatedElementProps as any} data-text-editable /> : isLive ? <JuxControlledElement ref={elementRef} tagName={componentToRender.tagName} styles={componentToRender.styles} nodeId={nodeId} elementProps={calculatedElementProps}>
            {nodeChildren?.length ? nodeChildren.map(childId => <CanvasNode key={childId} nodeId={childId} resizeObserver={resizeObserver} />) : null}
          </JuxControlledElement> : <JuxElement ref={elementRef} tagName={componentToRender.tagName} styles={componentToRender.styles} stylesState={component?.config?.interactiveState ?? DEFAULT_STATE} elementProps={calculatedElementProps} variantsProps={variantsPropsValues}>
            {nodeChildren?.length ? nodeChildren.map(childId => <CanvasNode key={childId} nodeId={childId} resizeObserver={resizeObserver} />) : null}
          </JuxElement>}
      </>;
});
export default CanvasNode;