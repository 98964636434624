/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from './core/CustomIcon';
export const SpacingIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" attr={{
  viewBox: '0 0 17 18'
}} child={[{
  tag: 'path',
  attr: {
    d: 'M11.75 1H5',
    strokeWidth: '1',
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    d: 'M16.375 12.375L16.375 5.625',
    strokeWidth: '1',
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    d: 'M11.75 17H5',
    strokeWidth: '1',
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    d: 'M0.375 12.375L0.375 5.625',
    strokeWidth: '1',
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="SpacingIcon" data-sentry-source-file="SpacingIcon.tsx" />;