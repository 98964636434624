import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { MagnifierControlIconButton } from './MagnifierControlIconButton';
import { MagnifierControlIconButtonProps } from './MagnifierControlIconButton.interface';
export const MagnifierControlItem: FC<{
  showControl: boolean;
  onClick: () => void;
  icon: MagnifierControlIconButtonProps['icon'];
  tooltipTitle?: string;
}> = ({
  showControl,
  onClick,
  icon,
  tooltipTitle
}) => <MuiGrid item sx={{
  opacity: showControl ? 1 : 0,
  transition: theme => theme.drimz.transitions.all
}} data-sentry-element="MuiGrid" data-sentry-component="MagnifierControlItem" data-sentry-source-file="MagnifierControlItem.tsx">
    <MagnifierControlIconButton icon={icon} tooltipTitle={tooltipTitle} onClick={onClick} data-sentry-element="MagnifierControlIconButton" data-sentry-source-file="MagnifierControlItem.tsx" />
  </MuiGrid>;