import { FC, useCallback, useMemo } from 'react';
import { sortByCreatedAtAsc } from '@jux/canjux/core';
import { CORE } from '@jux/types';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { useTokenSetState } from '@jux/ui/state';
import { withPx } from '@jux/ui/utils/css';
import { generateName } from '@jux/ui/utils/generateName';
import { textValidators } from '@jux/ui/utils/textValidators';
import { createPath } from '@jux/ui/utils/tokensPath';
import { useTokenDrawer } from '../../token-drawer/useTokenDrawer';
import { useCreateGroup, useCreateTokenSet, useDeleteTokenSet, useRenameTokenSet } from '../hooks';
import { useDeleteTokenSetConfirmationModal } from '../hooks/useDeleteTokenSetConfirmationModal';
import { useSelectedTokenState } from '../state';
import { CreateNewTokenSetButton } from './CreateNewTokenSetButton';
import { TokenSetsTab } from './TokenSetsTab';
const ADD_TOKEN_SET_BUTTON_WIDTH = 48;
const TOKEN_SETS_TABS_GAP = '16px';
export const TokenSetsTabs: FC = () => {
  const {
    tokenSets,
    currentTokenType,
    tokenSetData,
    currentTokenSetKey,
    setCurrentTokenSet
  } = useTokenSetState();
  const {
    deselectToken
  } = useSelectedTokenState();
  const {
    renameTokenSet
  } = useRenameTokenSet();
  const {
    deleteTokenSet
  } = useDeleteTokenSet();
  const {
    createGroup
  } = useCreateGroup();
  const {
    createTokenSet
  } = useCreateTokenSet();
  const {
    close: closeTokenDrawer
  } = useTokenDrawer();
  const {
    openDeleteTokenSetConfirmationModal
  } = useDeleteTokenSetConfirmationModal();
  const tokenSetNames = useMemo(() => Object.values(tokenSets)?.map(tokenSet => tokenSet?.name ?? '').filter(Boolean), [tokenSets]);
  const nameAlreadyExistsValidator = textValidators.nameAlreadyExistsValidator({
    namesArray: tokenSetNames,
    errorMessages: {
      nameAlreadyExists: 'Token set name already exists',
      namesArrayUndefined: 'Token sets are undefined'
    }
  });
  const isCurrentTokenSetKey = useCallback((key: string) => currentTokenSetKey === key, [currentTokenSetKey]);
  const handleCreateGroup = useCallback((isCore: boolean) => {
    if (!tokenSetData) return;
    const coreGroups = tokenSetData.core.groupsByType[currentTokenType].groups;
    const groups = tokenSetData.groupsByType[currentTokenType].groups;
    const groupNames = (isCore ? coreGroups : groups).map(group => group.name);
    const generatedName = generateName({
      baseName: 'new_group',
      namesArray: groupNames,
      separator: '_'
    });
    const groupPath = createPath([isCore ? CORE : undefined, currentTokenType]);
    createGroup({
      newGroupName: generatedName,
      groupPath
    });
  }, [createGroup, currentTokenType, tokenSetData]);
  const handleDeleteTokenSet = useCallback(({
    id,
    name,
    totalTokens
  }: {
    name: string;
    id: string;
    totalTokens: number;
  }) => {
    openDeleteTokenSetConfirmationModal({
      tokenSetName: name,
      totalTokens,
      onConfirm: () => deleteTokenSet(id)
    });
  }, [deleteTokenSet, openDeleteTokenSetConfirmationModal]);
  const handleClick = useCallback(({
    key,
    id
  }: {
    key: string;
    id: string;
  }) => {
    setCurrentTokenSet({
      key,
      id
    });
    deselectToken();
    closeTokenDrawer();
  }, [deselectToken, setCurrentTokenSet, closeTokenDrawer]);
  return <MuiGrid container item sx={{
    gap: TOKEN_SETS_TABS_GAP,
    borderBottom: 1,
    borderColor: theme => theme.drimz.palette.divider.secondary,
    height: theme => theme.drimz.size.layout.tokens.set.height
  }} data-sentry-element="MuiGrid" data-sentry-component="TokenSetsTabs" data-sentry-source-file="TokenSetsTabs.tsx">
      <MuiGrid item sx={{
      display: 'flex',
      alignItems: 'center',
      overflowX: 'auto',
      paddingLeft: theme => theme.drimz.size.layout.tokens.spacing.mainPadding,
      maxWidth: 'fit-content',
      width: `calc(100% - ${withPx(ADD_TOKEN_SET_BUTTON_WIDTH)} - ${TOKEN_SETS_TABS_GAP})`
    }} data-sentry-element="MuiGrid" data-sentry-source-file="TokenSetsTabs.tsx">
        {Object.entries(tokenSets)?.toSorted(([, a], [, b]) => sortByCreatedAtAsc(a, b)).map(([key, tokenSet]) => <TokenSetsTab key={key} setKey={key} id={tokenSet.id} name={tokenSet.name} validators={[nameAlreadyExistsValidator]} isCurrentTokenSet={isCurrentTokenSetKey(key)} hasGroups={tokenSet.hasGroups} handleRenameTokenSet={renameTokenSet} handleClick={() => handleClick({
        key,
        id: tokenSet.id
      })} handleDeleteTokenSet={(tokenSetId: string) => handleDeleteTokenSet({
        id: tokenSetId,
        name: tokenSet.name,
        totalTokens: tokenSet.totalTokens
      })} handleCreateGroup={() => {
        handleClick({
          key,
          id: tokenSet.id
        });
        handleCreateGroup(tokenSet.isCore);
      }} />)}
      </MuiGrid>
      <CreateNewTokenSetButton createTokenSet={createTokenSet} width={ADD_TOKEN_SET_BUTTON_WIDTH} data-sentry-element="CreateNewTokenSetButton" data-sentry-source-file="TokenSetsTabs.tsx" />
    </MuiGrid>;
};