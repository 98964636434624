import { FC } from 'react';
import { IconButton, ModalWrapper, Typography } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { ConfirmationModalContentProps } from './ConfirmationModal.interface';
export const ConfirmationModalContent: FC<ConfirmationModalContentProps> = ({
  title,
  children,
  cancelText,
  confirmText,
  onCancel,
  onConfirm
}) => <ModalWrapper onClose={onCancel} data-sentry-element="ModalWrapper" data-sentry-component="ConfirmationModalContent" data-sentry-source-file="ConfirmationModalContent.tsx">
    <MuiGrid item container data-sentry-element="MuiGrid" data-sentry-source-file="ConfirmationModalContent.tsx">
      <Typography variant="h2" color={theme => theme.drimz.palette.text.primary} data-sentry-element="Typography" data-sentry-source-file="ConfirmationModalContent.tsx">
        {title}
      </Typography>
    </MuiGrid>
    {children && <MuiGrid item container marginTop="24px">
        {children}
      </MuiGrid>}
    <MuiGrid display="flex" marginTop="24px" gap="16px" alignItems="flex-start" alignSelf="stretch" width="100%" data-sentry-element="MuiGrid" data-sentry-source-file="ConfirmationModalContent.tsx">
      <IconButton fullWidth onClick={onCancel} tabIndex={1} variant="secondary" size="big" data-sentry-element="IconButton" data-sentry-source-file="ConfirmationModalContent.tsx">
        {cancelText}
      </IconButton>
      <IconButton fullWidth onClick={onConfirm} tabIndex={1} variant="primary" size="big" data-sentry-element="IconButton" data-sentry-source-file="ConfirmationModalContent.tsx">
        {confirmText}
      </IconButton>
    </MuiGrid>
  </ModalWrapper>;