import { canvasDimensions, getRectOfNodes, LINE_OVERFLOW, LINE_THICKNESS, lineControls, LineType, useNodeInteractions, useStore, useStoreActions } from '@jux/canjux/core';
import { NODE_TOOLBAR_SELECTED_COLOR } from '@jux/ui/theme/palette';
import React from 'react';
import LineControl from '../NodeResizer/LineControl';
import { selectCurrentCanvas } from '../../store/wip/selectors/selectCurrentCanvas';

/*
 * This component is responsible for rendering selection box when multiple nodes are selected on the canvas.
 */
export function MultiSelectionBox() {
  const transform = useStore(canvasDimensions);

  // TODO: Move to custom hook `useMultiSelectionState`
  const {
    rect,
    selectedRootNodes,
    showMultiSelectionBox
  } = useStore(s => {
    const currentCanvas = selectCurrentCanvas(s);
    const relevantNodeIds = s.selectedNodesStack.filter(id => currentCanvas.rootNodesOrder.includes(id));
    const isDragging = relevantNodeIds.map(id => currentCanvas.nodes[id]?.properties).some(({
      isDragged
    }) => isDragged);
    return {
      rect: getRectOfNodes({
        nodeIds: relevantNodeIds,
        nodeOrigin: s.nodeOrigin
      }),
      selectedRootNodes: relevantNodeIds,
      showMultiSelectionBox: relevantNodeIds.length > 1 && !isDragging
    };
  });
  const {
    commonActions: {
      resetSelectedNodes
    }
  } = useStoreActions();
  const {
    elementRef
  } = useNodeInteractions({
    // TODO: refactor this to use the selected nodes directly without the need to pass a single node id
    nodeId: selectedRootNodes[0],
    onClick: resetSelectedNodes
  });
  if (!showMultiSelectionBox) {
    return null;
  }
  return <div style={{
    height: rect.height,
    position: 'absolute',
    transform: `translate(${rect.x}px, ${rect.y}px)`,
    width: rect.width
  }} data-sentry-component="MultiSelectionBox" data-sentry-source-file="MultiSelectionBox.tsx">
      <div ref={elementRef as React.RefObject<HTMLDivElement>} style={{
      inset: 0,
      pointerEvents: 'all',
      position: 'absolute'
    }} />
      {lineControls.map((c, index) => <LineControl hoverLineType={LineType.PARENT} key={`${c}-${index}`} isDynamicSlot={false} isHovered={false} isSelected={true} isResizable={false} // TODO: add logic for this
    lineColor={NODE_TOOLBAR_SELECTED_COLOR} lineOverflow={LINE_OVERFLOW} lineThickness={LINE_THICKNESS} nodeDimensions={{
      height: rect.height,
      width: rect.width
    }} nodeId={'multi-selection'} nodePosition={{
      x: rect.x,
      y: rect.y
    }} position={c} revertZoomScale={1 / transform.zoom} />)}
    </div>;
}