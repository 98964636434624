import { FC } from 'react';
import { ButtonGroupField, GroupButton } from '../../../../base';
import { useAlignItemsField } from './useAlignItemsField';
export const AlignItemsField: FC = () => {
  const {
    isFlexStartSelected,
    handleFlexStartClick,
    isCenterSelected,
    handleCenterClick,
    isFlexEndSelected,
    handleFlexEndClick,
    isStretchSelected,
    handleStretchClick,
    isBaselineSelected,
    handleBaselineClick,
    isFlexDirectionIsColumn
  } = useAlignItemsField();
  return <ButtonGroupField data-sentry-element="ButtonGroupField" data-sentry-component="AlignItemsField" data-sentry-source-file="AlignItemsField.tsx">
      <GroupButton icon={isFlexDirectionIsColumn ? 'DDP_FIELD_ALIGN_ITEMS_FLEX_START_COLUMN' : 'DDP_FIELD_ALIGN_ITEMS_FLEX_START_ROW'} isSelected={isFlexStartSelected} onClick={handleFlexStartClick} tooltipTitle="Flex start" data-sentry-element="GroupButton" data-sentry-source-file="AlignItemsField.tsx" />
      <GroupButton icon={isFlexDirectionIsColumn ? 'DDP_FIELD_ALIGN_ITEMS_CENTER_COLUMN' : 'DDP_FIELD_ALIGN_ITEMS_CENTER_ROW'} isSelected={isCenterSelected} onClick={handleCenterClick} tooltipTitle="Center" data-sentry-element="GroupButton" data-sentry-source-file="AlignItemsField.tsx" />
      <GroupButton icon={isFlexDirectionIsColumn ? 'DDP_FIELD_ALIGN_ITEMS_FLEX_END_COLUMN' : 'DDP_FIELD_ALIGN_ITEMS_FLEX_END_ROW'} isSelected={isFlexEndSelected} onClick={handleFlexEndClick} tooltipTitle="Flex end" data-sentry-element="GroupButton" data-sentry-source-file="AlignItemsField.tsx" />
      <GroupButton icon={isFlexDirectionIsColumn ? 'DDP_FIELD_ALIGN_ITEMS_STRETCH_COLUMN' : 'DDP_FIELD_ALIGN_ITEMS_STRETCH_ROW'} isSelected={isStretchSelected} onClick={handleStretchClick} tooltipTitle="Stretch" data-sentry-element="GroupButton" data-sentry-source-file="AlignItemsField.tsx" />
      <GroupButton icon={isFlexDirectionIsColumn ? 'DDP_FIELD_ALIGN_ITEMS_BASELINE_COLUMN' : 'DDP_FIELD_ALIGN_ITEMS_BASELINE_ROW'} isSelected={isBaselineSelected} onClick={handleBaselineClick} tooltipTitle="Baseline" data-sentry-element="GroupButton" data-sentry-source-file="AlignItemsField.tsx" />
    </ButtonGroupField>;
};