import { FC } from 'react';
import { Icons, Typography } from '@jux/ui/components';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';
import { NAVIGATOR_MENU_TAB_CONTENT_DESCRIPTION_COLOR } from '@jux/ui/theme/palette';
import { MuiGrid, useTheme } from '@jux/ui/components/common/mui';
import { NavigationPopper, S } from '../common';
import { ElementPreviewItem } from './ElementPreviewItem';
import { useElementsTab } from './useElementsTab';
const TEST_ID_ELEMENTS_POPPER = 'elements-popper';
const ELEMENTS_TAB_DESCRIPTION = 'Elements are UI code based building blocks. Start designing by clicking an element and adjusting it on the canvas.';
export const ElementsPopper: FC = () => {
  const elements = useElementsTab();
  const theme = useTheme();
  return <NavigationPopper testId={TEST_ID_ELEMENTS_POPPER} data-sentry-element="NavigationPopper" data-sentry-component="ElementsPopper" data-sentry-source-file="ElementsPopper.tsx">
      <MuiGrid container gap="16px" paddingTop={theme.drimz.size.editor.navbar.menuTab.content.padding} data-sentry-element="MuiGrid" data-sentry-source-file="ElementsPopper.tsx">
        <S.NavigationItemGrid item data-sentry-element="unknown" data-sentry-source-file="ElementsPopper.tsx">
          <Typography variant="label-xs-regular" color={NAVIGATOR_MENU_TAB_CONTENT_DESCRIPTION_COLOR} data-sentry-element="Typography" data-sentry-source-file="ElementsPopper.tsx">
            {ELEMENTS_TAB_DESCRIPTION}
          </Typography>
        </S.NavigationItemGrid>

        <S.ItemsListGrid item data-sentry-element="unknown" data-sentry-source-file="ElementsPopper.tsx">
          {elements.map(({
          icon: {
            sx,
            ...elementIconProps
          },
          displayName,
          tagName
        }, index) => <ElementPreviewItem key={`${displayName}_${index}`} displayName={displayName} tagName={tagName}>
                <Icons size={32} sx={mergeTyped({
            color: theme.drimz.palette.primary.contrastText
          }, sx)} {...elementIconProps} />
              </ElementPreviewItem>)}
        </S.ItemsListGrid>
      </MuiGrid>
    </NavigationPopper>;
};