/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '../core/CustomIcon';
export const ExpandIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M11.6997 9.40006C11.3683 8.95823 10.7415 8.86869 10.2997 9.20006L7.99973 11.05L5.69967 9.20006C5.25784 8.86869 4.63104 8.95823 4.29967 9.40006C3.9683 9.84189 4.05784 10.4687 4.49967 10.8001L7.99973 13.55L11.4997 10.8001C11.9415 10.4687 12.031 9.84189 11.6997 9.40006Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M11.7002 6.65C11.3688 7.09183 10.742 7.18137 10.3002 6.85L8.00022 5.00006L5.70016 6.85C5.25833 7.18137 4.63153 7.09183 4.30016 6.65C3.96879 6.20817 4.05833 5.58137 4.50016 5.25L8.00022 2.50006L11.5002 5.25C11.942 5.58137 12.0315 6.20817 11.7002 6.65Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="ExpandIcon" data-sentry-source-file="ExpandIcon.tsx" />;