/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const AlignCenterDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16',
  fill: 'none'
}} child={[{
  tag: 'path',
  attr: {
    d: 'M10 3C10.5523 3 11 3.44772 11 4C11 4.55229 10.5523 5 10 5L6 5C5.44772 5 5 4.55228 5 4C5 3.44772 5.44772 3 6 3H10Z',
    fill: 'currentColor'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    d: 'M12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9H4C3.44772 9 3 8.55228 3 8C3 7.44771 3.44772 7 4 7L12 7Z',
    fill: 'currentColor'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    d: 'M11 12C11 11.4477 10.5523 11 10 11H6C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13H10C10.5523 13 11 12.5523 11 12Z',
    fill: 'currentColor'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="AlignCenterDDPIcon" data-sentry-source-file="AlignCenterDDPIcon.tsx" />;