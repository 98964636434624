import { FC, useCallback } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { TooltipTypography } from '@jux/ui/components/common';
import { ModuleToggleButton } from '../ModuleToggleButton';
export const SubModuleHeader: FC<{
  title?: string;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  renderInline?: boolean;
}> = ({
  renderInline,
  title,
  isOpen,
  setIsOpen
}) => {
  const handleOpen = useCallback(() => setIsOpen && setIsOpen(true), [setIsOpen]);
  const handleClose = useCallback(() => setIsOpen && setIsOpen(false), [setIsOpen]);
  return <MuiGrid item display="flex" justifyContent="space-between" alignItems="center" gap="8px" pt={renderInline ? '8px' : '12px'} pb={renderInline ? '8px' : '4px'} height={theme => theme.drimz.size.editor.ddp.subModule.header.height} flex={1} data-sentry-element="MuiGrid" data-sentry-component="SubModuleHeader" data-sentry-source-file="SubModuleHeader.tsx">
      {title && <MuiGrid item display="flex" flex={1} width="100%">
          <TooltipTypography color={theme => theme.drimz.palette.text.secondary} variant="label-s-medium">
            {title}
          </TooltipTypography>
        </MuiGrid>}
      {isOpen !== undefined && Boolean(setIsOpen) && <MuiGrid item display="flex" flex={1} pr={theme => theme.drimz.size.editor.ddp.subModule.header.paddingRight} justifyContent="flex-end">
          <ModuleToggleButton icon="DDP_UNIFIED_SIDES_SUB_MODULE" isSelected={!isOpen} onClick={handleClose} />
          <ModuleToggleButton icon="DDP_SEPARATED_SUB_MODULE" isSelected={isOpen} onClick={handleOpen} />
        </MuiGrid>}
    </MuiGrid>;
};