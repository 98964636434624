import { FC } from 'react';
import { useStore } from '@jux/canjux/core';
import { IconButton, ModalWrapper, SelectField, Typography } from '@jux/ui/components';
import { MuiGrid, MuiMenuItem } from '@jux/ui/components/common/mui';
export type ThemeSwitcherProps = {
  onChange: (theme: {
    id: string;
    name: string;
  }) => void;
  onClose: () => void;
};
export const ThemeSwitcher: FC<ThemeSwitcherProps> = ({
  onChange,
  onClose
}) => {
  const {
    editorTokenSetId,
    options
  } = useStore(s => ({
    editorTokenSetId: s.editorTokenSetId,
    options: Object.values(s.tokenSets).map(({
      id,
      name
    }) => ({
      id,
      name
    }))
  }));
  return <ModalWrapper onClose={onClose} data-sentry-element="ModalWrapper" data-sentry-component="ThemeSwitcher" data-sentry-source-file="ThemeSwitcher.tsx">
      <MuiGrid item container data-sentry-element="MuiGrid" data-sentry-source-file="ThemeSwitcher.tsx">
        <Typography variant="h2" color={theme => theme.drimz.palette.text.primary} data-sentry-element="Typography" data-sentry-source-file="ThemeSwitcher.tsx">
          Swap token set to change the theme of the components
        </Typography>
      </MuiGrid>
      <MuiGrid item container marginTop="24px" gap="16px" flexDirection="column" data-sentry-element="MuiGrid" data-sentry-source-file="ThemeSwitcher.tsx">
        <MuiGrid item container data-sentry-element="MuiGrid" data-sentry-source-file="ThemeSwitcher.tsx">
          <SelectField name="theme" type="text" value={editorTokenSetId} onChange={e => {
          const selectedTheme = options.find(({
            id
          }) => id === e.target.value);
          if (selectedTheme) {
            onChange(selectedTheme);
          }
        }} MenuProps={{
          disablePortal: true
        }} data-sentry-element="SelectField" data-sentry-source-file="ThemeSwitcher.tsx">
            {options.map(({
            id,
            name
          }) => <MuiMenuItem key={id} value={id}>
                {name}
              </MuiMenuItem>)}
          </SelectField>
        </MuiGrid>
        <MuiGrid item alignSelf="flex-end" data-sentry-element="MuiGrid" data-sentry-source-file="ThemeSwitcher.tsx">
          <IconButton fullWidth onClick={onClose} tabIndex={1} variant="primary" size="big" data-sentry-element="IconButton" data-sentry-source-file="ThemeSwitcher.tsx">
            Close
          </IconButton>
        </MuiGrid>
      </MuiGrid>
    </ModalWrapper>;
};