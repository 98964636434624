/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const AlignItemsFlexStartRowDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M13.5 4C13.7761 4 14 4.22386 14 4.5C14 4.77614 13.7761 5 13.5 5L2.5 5C2.22386 5 2 4.77614 2 4.5C2 4.22386 2.22386 4 2.5 4H13.5Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M6 9C5.44772 9 5 8.55228 5 8C5 7.44772 5.44772 7 6 7L10 7C10.5523 7 11 7.44771 11 8C11 8.55228 10.5523 9 10 9L6 9Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M5 11C5 11.5523 5.44772 12 6 12L10 12C10.5523 12 11 11.5523 11 11C11 10.4477 10.5523 10 10 10L6 10C5.44772 10 5 10.4477 5 11Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="AlignItemsFlexStartRowDDPIcon" data-sentry-source-file="AlignItemsFlexStartRowDDPIcon.tsx" />;