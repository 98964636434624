import { FC, useCallback, useRef } from 'react';
import * as UI from '@jux/ui-core';
import { Tooltip, Typography, useMenuPopper, useOpenInviteMembersModal, ImportTokensButton, KeyboardShortcutPopover } from '@jux/ui/components';
import { useToggleSidebar } from '@jux/ui/hooks';
import { SegmentEventSourcePage } from '@jux/data-access/analytics/track-events-properties';
export const NavigationToken: FC = () => {
  const inviteUsersIconButtonRef = useRef<HTMLButtonElement>(null);
  const menuVerticalIconButtonRef = useRef<HTMLButtonElement>(null);
  const {
    open: openSidebar
  } = useToggleSidebar();
  const {
    openInviteMembersModal
  } = useOpenInviteMembersModal(SegmentEventSourcePage.editor);
  const {
    openMenuPopper
  } = useMenuPopper();
  const handleInviteUsersIconClick = useCallback(() => {
    openInviteMembersModal();
  }, [openInviteMembersModal]);
  const handleLogoClick = useCallback(() => {
    openSidebar();
  }, [openSidebar]);
  const handleMenuVerticalIconClick = useCallback(() => {
    openMenuPopper({
      sourceElementRef: menuVerticalIconButtonRef
    });
  }, [openMenuPopper]);
  return <>
      <UI.NavigationToken inviteUsersIconButtonRef={inviteUsersIconButtonRef} menuVerticalIconButtonRef={menuVerticalIconButtonRef} onInviteUsersIconClick={handleInviteUsersIconClick} onLogoClick={handleLogoClick} onMenuVerticalIconClick={handleMenuVerticalIconClick} middleSlot={<Typography variant="label-s-regular">Tokens management</Typography>} importButtonSlot={<ImportTokensButton label="Import tokens" />} data-sentry-element="unknown" data-sentry-source-file="NavigationToken.tsx" />
      {/* TODO: Unify with the Editor page's <Navigation /> component */}
      <KeyboardShortcutPopover data-sentry-element="KeyboardShortcutPopover" data-sentry-source-file="NavigationToken.tsx" />
      <Tooltip content="Invite new members" reference={inviteUsersIconButtonRef} data-sentry-element="Tooltip" data-sentry-source-file="NavigationToken.tsx" />
      <Tooltip content="Knowledge base and more..." reference={menuVerticalIconButtonRef} data-sentry-element="Tooltip" data-sentry-source-file="NavigationToken.tsx" />
    </>;
};