/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const ComponentsIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="20" height="20" attr={{
  viewBox: '0 0 20 20'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M9.99967 8.43625L13.1269 5.30898L9.99967 2.18171L6.8724 5.30898L9.99967 8.43625Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M8.43593 9.99998L5.30867 13.1273L2.1814 9.99998L5.30867 6.87271L8.43593 9.99998Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M14.6907 13.1273L17.8179 9.99998L14.6907 6.87271L11.5634 9.99998L14.6907 13.1273Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M9.99967 17.8183L13.1269 14.691L9.99967 11.5637L6.8724 14.691L9.99967 17.8183Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="ComponentsIcon" data-sentry-source-file="ComponentsIcon.tsx" />;