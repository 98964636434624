import { FC, RefObject } from 'react';
import { PropertiesPanelHeader } from './PropertiesPanelHeader';
import { PropertiesPanelContent } from './PropertiesPanelContent';
import * as S from './PropertiesPanelWrapper.style';
export const PropertiesPanelWrapper: FC<{
  wrapperRef: RefObject<HTMLDivElement>;
  onClose: () => void;
}> = ({
  wrapperRef,
  onClose
}) => <S.PropertiesPanelWrapper ref={wrapperRef} data-sentry-element="unknown" data-sentry-component="PropertiesPanelWrapper" data-sentry-source-file="PropertiesPanelWrapper.tsx">
    <PropertiesPanelHeader onClose={onClose} data-sentry-element="PropertiesPanelHeader" data-sentry-source-file="PropertiesPanelWrapper.tsx" />
    <PropertiesPanelContent data-sentry-element="PropertiesPanelContent" data-sentry-source-file="PropertiesPanelWrapper.tsx" />
  </S.PropertiesPanelWrapper>;