import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import {
  getDefaultCanvas,
  storeApi,
  useStore,
  useStoreActions,
} from '@jux/canjux/core';
import { PATHS } from '@jux/ui/utils/paths';
import { LIVEBLOCKS_READY_CLASS } from '@jux/constants';

export const useSelectCanvasByRoute = () => {
  const { query, replace } = useRouter();
  const canvasId = query.canvasId;

  const {
    commonActions: { setCurrentCanvasName },
  } = useStoreActions();
  const { liveblocksStatus, isStorageLoading } = useStore((s) => ({
    liveblocksStatus: s.liveblocks.status,
    isStorageLoading: s.liveblocks.isStorageLoading,
  }));

  const selectCanvasByRouteId = useCallback(
    ({ routeCanvasId }: { routeCanvasId?: string | string[] }) => {
      if (Array.isArray(routeCanvasId)) return;
      const { canvases, currentCanvasName } = storeApi.getState();
      const currentCanvas = canvases[currentCanvasName];
      const correctCanvas =
        Object.values(canvases).find((c) => c.id === routeCanvasId) ??
        getDefaultCanvas(canvases);

      if (correctCanvas && correctCanvas.id !== currentCanvas?.id) {
        replace(`${PATHS.EDITOR}/${correctCanvas.id}`, undefined, {
          shallow: true,
        });

        setCurrentCanvasName({ canvasName: correctCanvas.name });
      }

      return;
    },
    [replace, setCurrentCanvasName]
  );

  useEffect(() => {
    selectCanvasByRouteId({
      routeCanvasId: canvasId,
    });

    if (!document.body.classList.contains(LIVEBLOCKS_READY_CLASS)) {
      document.body.classList.add(LIVEBLOCKS_READY_CLASS);
    }
  }, [canvasId, isStorageLoading, liveblocksStatus, selectCanvasByRouteId]);
};
