import { FC, useCallback, useMemo, useState } from 'react';
import { EditableTypography, TooltipTypography } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { useClickAndDoubleClick } from '@jux/ui/hooks';
import { textValidators } from '@jux/ui/utils/textValidators';
import { formatTokenNameKeyPress, formatTokenNamePaste } from '@jux/ui/utils/tokens/tokenNameFormat';
import { DisableDeleteReturnType } from '../../interface';
import { TokenCardActionsMenu } from './TokenCardActionsMenu';
import { TokenIcons } from './TokenIcons';
export const TokenCardHeader: FC<{
  name: string;
  isAlias: boolean;
  aliasInside?: boolean;
  isTokenCardHovered?: boolean;
  onEditToken: () => void;
  onDeleteToken: () => void;
  onRenameToken: (newName: string) => Promise<boolean>;
  existingTokenNames: Array<string>;
  shouldDisableDeleteToken: () => DisableDeleteReturnType;
}> = ({
  name,
  shouldDisableDeleteToken,
  isAlias,
  aliasInside,
  isTokenCardHovered,
  onDeleteToken,
  onEditToken,
  onRenameToken,
  existingTokenNames
}) => {
  const tokenNameValidator = useMemo(() => textValidators.nameAlreadyExistsValidator({
    namesArray: existingTokenNames,
    errorMessages: {
      nameAlreadyExists: 'Token name already exists',
      namesArrayUndefined: 'Token is undefined'
    }
  }), [existingTokenNames]);
  const [editing, setEditing] = useState(false);
  const {
    handleClick
  } = useClickAndDoubleClick({
    onClick: onEditToken,
    onDoubleClick: useCallback(() => setEditing(true), [])
  });
  return <MuiGrid alignItems="center" item container justifyContent="space-between" wrap="nowrap" height="48px" data-sentry-element="MuiGrid" data-sentry-component="TokenCardHeader" data-sentry-source-file="TokenCardHeader.tsx">
      {editing ? <EditableTypography label={name} autoFocus onSaveChanges={onRenameToken} customValidators={[tokenNameValidator]} onKeyDown={formatTokenNameKeyPress} onPaste={formatTokenNamePaste} onBlur={() => setEditing(false)} onClick={e => e.stopPropagation()} variant="body-regular" cursor="pointer" sx={{
      color: theme => theme.drimz.palette.text.primary,
      paddingTop: '16px',
      paddingLeft: '16px',
      height: '100%',
      width: '100%',
      whiteSpace: 'nowrap',
      overflowY: 'hidden'
    }} /> : <TooltipTypography variant="body-regular" onClick={e => {
      e.stopPropagation();
      handleClick(e);
    }} tooltipContent={hasContentOverflow => hasContentOverflow ? name : 'Rename token'} sx={{
      cursor: 'pointer',
      userSelect: 'none',
      color: theme => theme.drimz.palette.text.primary,
      paddingTop: '16px',
      paddingLeft: '16px',
      height: '100%',
      width: 'auto'
    }}>
          {name}
        </TooltipTypography>}

      <MuiGrid display="flex" alignItems="center" height="100%" minWidth="fit-content" paddingRight="8px" data-sentry-element="MuiGrid" data-sentry-source-file="TokenCardHeader.tsx">
        <TokenIcons icons={isAlias && ['ALIAS'] || aliasInside && ['ALIAS_INSIDE'] || []} data-sentry-element="TokenIcons" data-sentry-source-file="TokenCardHeader.tsx" />
        <TokenCardActionsMenu onDeleteToken={onDeleteToken} shouldDisableDeleteToken={shouldDisableDeleteToken} isTokenCardHovered={isTokenCardHovered} data-sentry-element="TokenCardActionsMenu" data-sentry-source-file="TokenCardHeader.tsx" />
      </MuiGrid>
    </MuiGrid>;
};