import { useUser } from '@auth0/nextjs-auth0/client';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { toast } from '@jux/ui/toast';
import { useStoreActions } from '@jux/canjux/core';
import { KEYBOARD_SHORTCUT_POPOVER_ID, popperControls, useMenuPopper, useOpenFeedbackModal, useThemeSwitcherModal } from '@jux/ui/components';
import { useTrackEvents } from '@jux/ui/hooks';
import { api } from '@jux/ui/trpc/client/api';
import { SecretKeyToast } from './SecretKeyToast';
import { UserMenuItemProps } from './UserMenuItem.interface';
export const useUserMenu = () => {
  const router = useRouter();
  const {
    user
  } = useUser();
  const {
    openFeedbackModal
  } = useOpenFeedbackModal();
  const {
    openThemeSwitcherModal
  } = useThemeSwitcherModal();
  const {
    closeMenuPopper
  } = useMenuPopper();
  const {
    trackContextMenuClick,
    trackFeedbackSent,
    trackFeedbackCancel,
    trackThemeChanged
  } = useTrackEvents();
  const {
    tokenSetsActions: {
      setEditorTokenSet
    }
  } = useStoreActions();
  const {
    data: userSecretKey
  } = api.user.getUserSecretKey.useQuery();
  const userNameInitial = useMemo(() => (user?.name || 'John Doe').toUpperCase().charAt(0), [user?.name]);
  const howToJUX = useCallback(() => {
    trackContextMenuClick({
      name: 'howto'
    });
    window.open('https://docs.jux.io', '_blank');
  }, [trackContextMenuClick]);
  const logout = useCallback(() => {
    trackContextMenuClick({
      name: 'logout'
    });
    router.push('/api/auth/logout');
    localStorage.clear();
  }, [router, trackContextMenuClick]);
  const sendFeedBack = api.mail.sendFeedbackEmail.useMutation();
  const handleOpenFeedbackModalClick = useCallback(() => {
    trackContextMenuClick({
      name: 'feedback'
    });
    openFeedbackModal({
      onConfirm: (feedback: string) => {
        sendFeedBack.mutate({
          feedback
        });
        trackFeedbackSent(feedback);
      },
      onCancel: () => {
        trackFeedbackCancel();
      }
    });
    closeMenuPopper();
  }, [closeMenuPopper, openFeedbackModal, sendFeedBack, trackContextMenuClick, trackFeedbackCancel, trackFeedbackSent]);
  const handleKeyboardShortcutClick = useCallback(() => {
    trackContextMenuClick({
      name: 'shortcuts'
    });
    closeMenuPopper();
    popperControls(KEYBOARD_SHORTCUT_POPOVER_ID).open();
  }, [closeMenuPopper, trackContextMenuClick]);
  const handleCopySecretKey = useCallback(() => {
    if (!userSecretKey) {
      toast.action(({
        closeToast
      }) => <SecretKeyToast closeToast={closeToast} />);
      return;
    }
    navigator.clipboard.writeText(`sk_${userSecretKey}`);
    toast.success('Secret key copied to clipboard');
    closeMenuPopper();
  }, [closeMenuPopper, userSecretKey]);
  const handleOpenThemeSwitcherClick = useCallback(() => {
    trackContextMenuClick({
      name: 'change_theme'
    });
    openThemeSwitcherModal({
      onChange: ({
        id,
        name
      }) => {
        setEditorTokenSet({
          tokenSetId: id
        });
        trackThemeChanged({
          themeId: id,
          themeName: name
        });
      }
    });
    closeMenuPopper();
  }, [closeMenuPopper, openThemeSwitcherModal, setEditorTokenSet, trackContextMenuClick, trackThemeChanged]);
  const menuList: Array<UserMenuItemProps> = useMemo(() => [{
    label: 'How to Jux?',
    onClick: howToJUX
  }, {
    label: <span>Keyboard shortcuts</span>,
    onClick: handleKeyboardShortcutClick
  }, {
    label: 'Secret key',
    description: 'Click to copy',
    onClick: handleCopySecretKey
  }, {
    label: 'Share feedback',
    onClick: handleOpenFeedbackModalClick
  }, {
    label: 'Change theme',
    onClick: handleOpenThemeSwitcherClick
  }, {
    label: 'Logout',
    description: user?.email,
    onClick: logout
  }], [howToJUX, handleKeyboardShortcutClick, handleCopySecretKey, handleOpenFeedbackModalClick, handleOpenThemeSwitcherClick, user?.email, logout]);
  return {
    hasUser: Boolean(user),
    userNameInitial,
    menuList
  };
};