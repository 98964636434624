import { forwardRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Backdrop as MuiBackdrop } from '@mui/material';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';
import { withImportant } from '@jux/ui/utils/css';
import { Theme, SxProps } from './mui';
export type BackdropProps = {
  transparent?: boolean;
  open: boolean;
  onClick: () => void;
  sx?: SxProps<Theme>;
  zIndex?: number;
  color?: string;
};
export const Backdrop = forwardRef<HTMLDivElement, BackdropProps>(({
  sx,
  transparent,
  open,
  onClick,
  color,
  zIndex
}, ref) => <MuiBackdrop open={open} onClick={onClick} ref={ref} sx={mergeTyped({
  backgroundColor: theme => withImportant(transparent ? theme.drimz.palette.transparent : color ?? theme.drimz.palette.backdrop),
  zIndex
}, sx)} />);