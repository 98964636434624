import { FC, PropsWithChildren } from 'react';
import { Typography } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
export type ModuleHeaderSize = 'small' | 'normal';
type ModuleHeaderProps = PropsWithChildren<{
  title?: string;
  headerSize: ModuleHeaderSize;
  className?: string;
}>;
export const ModuleHeader: FC<ModuleHeaderProps> = ({
  title,
  children,
  headerSize,
  className
}) => <MuiGrid container paddingRight={theme => theme.drimz.size.editor.ddp.module.header.paddingRight} alignItems="center" justifyContent="space-between" height={theme => headerSize === 'small' ? theme.drimz.size.editor.ddp.module.header.smallHeight : theme.drimz.size.editor.ddp.module.header.height} className={className} data-sentry-element="MuiGrid" data-sentry-component="ModuleHeader" data-sentry-source-file="ModuleHeader.tsx">
    <Typography variant="label-s-bold" data-sentry-element="Typography" data-sentry-source-file="ModuleHeader.tsx">{title}</Typography>
    {children}
  </MuiGrid>;