import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { AssetsComponentType } from './Assets.interface';
import { AssetPreviewItem } from './assetPreviewItem/AssetPreviewItem';
export const UserAssets: FC<AssetsComponentType> = ({
  assets,
  handleAssetClick,
  isDraggable,
  actions,
  selectedAssetId
}) => <MuiGrid container gap="16px" data-sentry-element="MuiGrid" data-sentry-component="UserAssets" data-sentry-source-file="UserAssets.tsx">
    <MuiGrid container item gap="16px" data-sentry-element="MuiGrid" data-sentry-source-file="UserAssets.tsx">
      {assets.filter(([, {
      isSystemAsset
    }]) => !isSystemAsset).map(([assetId, assetData]) => assetData?.content && <AssetPreviewItem key={assetId} assetData={assetData} assetId={assetId} onAssetClick={() => handleAssetClick({
      assetId,
      assetData
    })} actions={actions?.(assetId)} isDraggable={isDraggable} selected={Boolean(selectedAssetId && selectedAssetId === assetId)} />)}
    </MuiGrid>
  </MuiGrid>;