/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const DotsThreeHorizontalIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M2.75 9.5C3.7165 9.5 4.5 8.7165 4.5 7.75C4.5 6.7835 3.7165 6 2.75 6C1.7835 6 1 6.7835 1 7.75C1 8.7165 1.7835 9.5 2.75 9.5Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M8.25 9.5C9.2165 9.5 10 8.7165 10 7.75C10 6.7835 9.2165 6 8.25 6C7.2835 6 6.5 6.7835 6.5 7.75C6.5 8.7165 7.2835 9.5 8.25 9.5Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M13.75 9.5C14.7165 9.5 15.5 8.7165 15.5 7.75C15.5 6.7835 14.7165 6 13.75 6C12.7835 6 12 6.7835 12 7.75C12 8.7165 12.7835 9.5 13.75 9.5Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="DotsThreeHorizontalIcon" data-sentry-source-file="DotsThreeHorizontalIcon.tsx" />;