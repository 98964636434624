import { FC } from 'react';
import { DDPSubModulesKeys } from '@jux/ui/components/editor/state';
import { SubModule } from '../../../../base';
import { useSubModule } from '../../../../hooks';
import { PositionField } from './PositionField';
export const Position: FC = () => {
  const {
    shouldRender
  } = useSubModule(DDPSubModulesKeys.position);
  if (!shouldRender) return null;
  return <SubModule title="Position" renderInline data-sentry-element="SubModule" data-sentry-component="Position" data-sentry-source-file="Position.tsx">
      <PositionField data-sentry-element="PositionField" data-sentry-source-file="Position.tsx" />
    </SubModule>;
};