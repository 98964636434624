import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { Typography } from '@jux/ui/components';
export const LiveModeIsOn: FC = () => <MuiGrid container flexDirection="column" alignItems="center" justifyContent="center" height="100%" data-sentry-element="MuiGrid" data-sentry-component="LiveModeIsOn" data-sentry-source-file="LiveModeIsOn.tsx">
    <MuiGrid data-sentry-element="MuiGrid" data-sentry-source-file="LiveModeIsOn.tsx">
      <Typography variant="label-s-regular" pt={4} pb={1} px={2} textAlign="center" data-sentry-element="Typography" data-sentry-source-file="LiveModeIsOn.tsx">
        <span>It is alive</span>
        <br />
        <span>Aliveeeeee </span>
        <span role="img" aria-label="zombie">
          🧟‍♂️
        </span>
      </Typography>
    </MuiGrid>
  </MuiGrid>;