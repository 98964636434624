import { Dimensions, useNodeInteractions, useStore, useStoreActions } from '@jux/canjux/core';
import { NodeType, tagNameToNodeDisplayName } from '@jux/data-entities';
import { Icons, IconsVariantsType, LoadingButtonWithIcon as Button } from '@jux/ui/components';
import { MuiGrid, Theme } from '@jux/ui/components/common/mui';
import { useSettingsState } from '@jux/ui/state';
import { NODE_TOOLBAR_COLOR, NODE_TOOLBAR_COLOR_LIBRARY_COMPONENT, NODE_TOOLBAR_SELECTED_COLOR, NODE_TOOLBAR_SELECTED_COLOR_LIBRARY_COMPONENT } from '@jux/ui/theme/palette';
import { withPx } from '@jux/ui/utils/css';
import { FC, RefObject, useCallback, useMemo } from 'react';
import { selectNodeIdGeneralInfo } from '../../../store/wip/selectors/selectNodeIdGeneralInfo';
import { NodeToolbarLabel } from './NodeToolbarLabel';
import { useNodeToolbarLabelEditing } from './useNodeToolbarLabelEditing';
const PIXEL_DISTANCE_FROM_NODE = 16;
const MINIMUM_DISPLAY_WIDTH_PX = 16;
const LABEL_ON_MINIMUM_DISPLAY_SIZE = '...';
const ICON_LABEL_GAP_PX = 2;
const ICON_SIZE_PX = 16;
export type NodeNameToolbarProps = {
  nodeId: string;
  nodeDimensions: Dimensions;
  isSelected: boolean;
  isHovered: boolean;
  zoomLevel: number;
  isInLibrary: boolean;
};
export const NodeNameToolbar: FC<NodeNameToolbarProps> = ({
  nodeId,
  nodeDimensions,
  isSelected,
  isHovered,
  zoomLevel,
  isInLibrary
}) => {
  const nodeInfo = useStore(selectNodeIdGeneralInfo(nodeId));
  const {
    settings: {
      featureFlags: {
        variantsGroup
      },
      tools: {
        layersPanelIds
      }
    }
  } = useSettingsState();
  const {
    componentActions: {
      convertNodeToVariantsGroup,
      convertVariantsGroupToSourceComponent
    }
  } = useStoreActions();
  const {
    handleNodeToolbarLabelDoubleClick,
    handleNodeToolbarLabelBlur,
    isNodeToolbarLabelEditing
  } = useNodeToolbarLabelEditing(nodeId);
  const {
    elementRef: toolbarRef,
    registerEvents
  } = useNodeInteractions({
    nodeId
  });
  const onClickTurnToMatrix = useCallback(() => {
    switch (nodeInfo.type) {
      case NodeType.LIBRARY_COMPONENT:
      case NodeType.LOCAL_COMPONENT:
        convertNodeToVariantsGroup({
          nodeId
        });
        break;
      case NodeType.VARIANTS_GROUP:
        convertVariantsGroupToSourceComponent({
          nodeId
        });
        break;
      default:
        break;
    }
  }, [nodeId, nodeInfo.type, convertNodeToVariantsGroup, convertVariantsGroupToSourceComponent]);
  const reachedMinimumDisplaySize = useMemo(() => {
    return nodeDimensions.width * zoomLevel < MINIMUM_DISPLAY_WIDTH_PX;
  }, [nodeDimensions.width, zoomLevel]);
  const label = useMemo(() => {
    if (reachedMinimumDisplaySize) {
      return LABEL_ON_MINIMUM_DISPLAY_SIZE;
    }
    return nodeInfo?.displayName || (nodeInfo.tagName ? tagNameToNodeDisplayName(nodeInfo.tagName) : '');
  }, [nodeInfo, reachedMinimumDisplaySize]);
  const color = useMemo(() => {
    if (isSelected || isHovered) {
      return isInLibrary ? NODE_TOOLBAR_SELECTED_COLOR_LIBRARY_COMPONENT : NODE_TOOLBAR_SELECTED_COLOR;
    }
    return isInLibrary ? NODE_TOOLBAR_COLOR_LIBRARY_COMPONENT : NODE_TOOLBAR_COLOR;
  }, [isInLibrary, isHovered, isSelected]);
  const showActionButton = useMemo(() => {
    return variantsGroup && (nodeInfo.type === NodeType.LOCAL_COMPONENT || nodeInfo.type === NodeType.LIBRARY_COMPONENT || nodeInfo.type === NodeType.VARIANTS_GROUP);
  }, [nodeInfo, variantsGroup]);
  const actionButtonLabel = useMemo(() => {
    switch (nodeInfo.type) {
      case NodeType.LIBRARY_COMPONENT:
      case NodeType.LOCAL_COMPONENT:
        return 'Show Variants';
      case NodeType.VARIANTS_GROUP:
        return 'Show Component';
      default:
        return null;
    }
  }, [nodeInfo.type]);
  const icon: IconsVariantsType | null = useMemo(() => {
    switch (nodeInfo.type) {
      case NodeType.ELEMENT:
      case NodeType.DYNAMIC_SLOT:
      case NodeType.LOGICAL_SLOT:
      case NodeType.VARIANT_INSTANCE:
        return null;
      case NodeType.INSTANCE:
        return 'COMPONENT_INSTANCE_OBJECT';
      case NodeType.VARIANTS_GROUP:
      case NodeType.LOCAL_COMPONENT:
      case NodeType.LIBRARY_COMPONENT:
        return 'COMPONENT_OBJECT';
    }
  }, [nodeInfo.type]);
  const labelMaxWidth = useMemo(() => icon ? nodeDimensions.width * zoomLevel - ICON_LABEL_GAP_PX - ICON_SIZE_PX : nodeDimensions.width * zoomLevel, [icon, nodeDimensions.width, zoomLevel]);
  return <div style={{
    zIndex: 4,
    position: 'absolute',
    width: 'auto',
    height: 'auto',
    transform: `translateY(-${withPx(PIXEL_DISTANCE_FROM_NODE / zoomLevel)}) scale(${1 / zoomLevel})`,
    transformOrigin: '0 0',
    top: `calc(0% - ${4 / zoomLevel}px)`
  }} data-sentry-component="NodeNameToolbar" data-sentry-source-file="NodeNameToolbar.tsx">
      <MuiGrid sx={{
      display: 'inline-flex',
      alignItems: 'center',
      gap: '8px',
      position: 'absolute',
      color: color,
      cursor: 'default',
      height: '18px',
      width: `${withPx(nodeDimensions.width * zoomLevel)}`,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      pointerEvents: 'all',
      userSelect: 'none'
    }} {...registerEvents()} ref={toolbarRef as RefObject<HTMLDivElement>} data-sentry-element="MuiGrid" data-sentry-source-file="NodeNameToolbar.tsx">
        <MuiGrid gap={withPx(ICON_LABEL_GAP_PX)} sx={{
        display: 'flex',
        alignItems: 'center'
      }} data-sentry-element="MuiGrid" data-sentry-source-file="NodeNameToolbar.tsx">
          {icon && !reachedMinimumDisplaySize && <Icons variant={icon} size={ICON_SIZE_PX} sx={{
          color,
          alignItems: 'center'
        }} />}
          <NodeToolbarLabel label={layersPanelIds ? nodeId : label} color={color} isEditing={layersPanelIds ? false : isNodeToolbarLabelEditing} maxWidth={labelMaxWidth} onDoubleClick={handleNodeToolbarLabelDoubleClick} onBlur={handleNodeToolbarLabelBlur} data-sentry-element="NodeToolbarLabel" data-sentry-source-file="NodeNameToolbar.tsx" />
        </MuiGrid>
        {showActionButton && <Button onClick={onClickTurnToMatrix} sx={{
        display: 'flex',
        padding: '2px 6px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4px',
        borderRadius: '2px',
        border: '1px solid #9e9e9e',
        '&:hover': {
          background: (theme: Theme) => theme.drimz.palette.background.hover
        }
      }} typographyVariant="kickerActionButtonTypography" typographyProps={{
        sx: {
          color
        }
      }}>
            {actionButtonLabel}
          </Button>}
      </MuiGrid>
    </div>;
};