/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const FlexDirectionColumnDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M11.5303 7.96967C11.8232 8.26256 11.8232 8.73744 11.5303 9.03033L8.53033 12.0303C8.23744 12.3232 7.76256 12.3232 7.46967 12.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967C4.76256 7.67678 5.23744 7.67678 5.53033 7.96967L7.25 9.68934L7.25 4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V9.68934L10.4697 7.96967C10.7626 7.67678 11.2374 7.67678 11.5303 7.96967Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="FlexDirectionColumnDDPIcon" data-sentry-source-file="FlexDirectionColumnDDPIcon.tsx" />;