import { FC } from 'react';
import Link from 'next/link';
import { Typography } from '@jux/ui/components';
import { useTheme } from '@jux/ui/components/common/mui';
export const SecretKeyToast: FC<{
  closeToast?: () => void;
}> = ({
  closeToast
}) => {
  const theme = useTheme();
  return <Typography variant="body-regular" data-sentry-element="Typography" data-sentry-component="SecretKeyToast" data-sentry-source-file="SecretKeyToast.tsx">
      Secret key not found,{' '}
      <Link href="https://discord.gg/tGnt95fs" target="blank" style={{
      textDecoration: 'underline',
      color: theme.drimz.palette.primary.main
    }} onClick={closeToast} data-sentry-element="Link" data-sentry-source-file="SecretKeyToast.tsx">
        contact us
      </Link>
    </Typography>;
};