import { useStore } from '@jux/canjux/core';

/*
 * This component is responsible for rendering the user selection rectangle.
 */
function UserSelection() {
  const {
    userSelectionActive,
    userSelectionRect
  } = useStore(s => ({
    userSelectionActive: s.userSelectionActive,
    userSelectionRect: s.userSelectionRect
  }));
  const isActive = userSelectionActive && userSelectionRect;
  if (!isActive) {
    return null;
  }
  return <div id="user-selection-rect" style={{
    width: userSelectionRect.width,
    height: userSelectionRect.height,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    transform: `translate(${userSelectionRect.x}px, ${userSelectionRect.y}px)`
  }} data-sentry-component="UserSelection" data-sentry-source-file="index.tsx" />;
}
export default UserSelection;