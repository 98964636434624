/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const ConstraintsLeftDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M10 5.25C10.4142 5.25 10.75 5.58579 10.75 6L10.75 10C10.75 10.4142 10.4142 10.75 10 10.75C9.58579 10.75 9.25 10.4142 9.25 10V8.75H6.5L6.5 12.5V14H6C5.44772 14 5 13.5523 5 13V3C5 2.44772 5.44772 2 6 2H6.5V3.5L6.5 7.25H9.25V6C9.25 5.58579 9.58579 5.25 10 5.25Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="ConstraintsLeftDDPIcon" data-sentry-source-file="ConstraintsLeftDDPIcon.tsx" />;