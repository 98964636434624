import { forwardRef } from 'react';
import { MuiIcon } from '@jux/ui/components/common/mui';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';
import { IconsProps } from './Icons.interface';
import { IconsVariants } from './IconsVariants';
const DEFAULT_ICON_SIZE = 16;
export const Icons = forwardRef<HTMLElement, IconsProps>(({
  variant,
  size = DEFAULT_ICON_SIZE,
  iconBaseProps,
  width,
  height,
  color,
  ...props
}, ref) => {
  const Icon = IconsVariants[variant];
  const {
    sx,
    ...other
  } = props;
  return <MuiIcon ref={ref} sx={mergeTyped({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    m: 0,
    p: 0,
    width: width ?? size,
    height: height ?? size,
    color
  }, sx)} {...other}>
        <Icon size={size} style={{
      top: '50%',
      position: 'absolute',
      transform: 'translate(0, -50%)',
      width,
      height
    }} {...iconBaseProps} />
      </MuiIcon>;
});