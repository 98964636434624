/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const ZoomInViewIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M9 1C9 0.447723 8.55229 0 8 0C7.44771 0 7 0.447723 7 1V7H1C0.447708 7 0 7.44772 0 8C0 8.55228 0.447708 9 1 9H7V15C7 15.5523 7.44771 16 8 16C8.55229 16 9 15.5523 9 15V9H15C15.5523 9 16 8.55228 16 8C16 7.44772 15.5523 7 15 7H9V1Z',
    fillRule: 'evenodd',
    clipRule: 'evenodd'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="ZoomInViewIcon" data-sentry-source-file="ZoomInViewIcon.tsx" />;