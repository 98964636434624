import { FC, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useStore } from '@jux/canjux/core';
import { BackgroundHead, Canvas, FpsCounter, Layout, Loading, Modal, Navigation, Popper, Sidebar, useCanvas } from '@jux/ui/components';
import { useEnterRoomEffect, useLiveblocksRoom, useRepairStateOnConnection, useSelectCanvasByRoute } from '@jux/ui/components/editor/hooks';
import { useSettingsState } from '@jux/ui/state';
import { CANJUX_BACKGROUND_COLOR } from '@jux/ui/theme/palette';
import { useAnalyticsContextEffect } from '@jux/ui/hooks/useAnalyticsContextEffect';
import { useAnalytics } from '@jux/ui/hooks';
import { AnalyticsEvents } from '@jux/data-access/analytics/analytics-events';
import { TokenDrawer } from '../tokens/token-drawer/base';
export const EditorLayout: FC = () => {
  const {
    room
  } = useLiveblocksRoom();
  const {
    pageLoadedEvent,
    trackEvent
  } = useAnalytics();
  const {
    isLoading
  } = useStore(s => ({
    isLoading: s.liveblocks.isStorageLoading
  }));
  useEnterRoomEffect(room.roomId);
  useRepairStateOnConnection();
  useSelectCanvasByRoute();
  useAnalyticsContextEffect(room);
  useEffect(() => {
    pageLoadedEvent(AnalyticsEvents.CANVAS_PAGE, window.location.pathname);
    trackEvent({
      eventName: AnalyticsEvents.CANVAS_LOADED
    });
  }, [pageLoadedEvent, trackEvent]);
  const canvasProps = useCanvas();
  const {
    settings: {
      tools: {
        fpsCounter
      }
    }
  } = useSettingsState();
  return <>
      <Loading visible={isLoading} data-sentry-element="Loading" data-sentry-source-file="EditorLayout.tsx" />
      <DndProvider backend={HTML5Backend} data-sentry-element="DndProvider" data-sentry-source-file="EditorLayout.tsx">
        {fpsCounter && <FpsCounter />}
        <TokenDrawer data-sentry-element="TokenDrawer" data-sentry-source-file="EditorLayout.tsx" />
        <Modal data-sentry-element="Modal" data-sentry-source-file="EditorLayout.tsx" />
        <Popper data-sentry-element="Popper" data-sentry-source-file="EditorLayout.tsx" />
        <BackgroundHead backgroundColor={CANJUX_BACKGROUND_COLOR} data-sentry-element="BackgroundHead" data-sentry-source-file="EditorLayout.tsx" />
        <Layout sidebar={<Sidebar />} navbar={<Navigation />} content={<Canvas {...canvasProps} />} contentFullscreen data-sentry-element="Layout" data-sentry-source-file="EditorLayout.tsx" />
      </DndProvider>
    </>;
};