import { FC } from 'react';
import { Tab, Tabs } from '@jux/ui/components';
export const ComponentsHeader: FC<{
  setLocalComponents: () => void;
  setLibraryComponents: () => void;
  isLocalComponents: boolean;
  isLibraryComponents: boolean;
}> = ({
  setLocalComponents,
  setLibraryComponents,
  isLocalComponents,
  isLibraryComponents
}) => <Tabs data-sentry-element="Tabs" data-sentry-component="ComponentsHeader" data-sentry-source-file="ComponentsHeader.tsx">
    <Tab title="Local" onClick={setLocalComponents} selected={isLocalComponents} data-sentry-element="Tab" data-sentry-source-file="ComponentsHeader.tsx" />
    <Tab title="Library" onClick={setLibraryComponents} selected={isLibraryComponents} data-sentry-element="Tab" data-sentry-source-file="ComponentsHeader.tsx" />
  </Tabs>;