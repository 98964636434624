import { FC, PropsWithChildren } from 'react';
import { useHydrateAtoms } from 'jotai/utils';
import { queryClientAtom } from 'jotai-tanstack-query';
import { QueryClient } from '@tanstack/react-query';

//  This Provider initialization step is needed so that we reference the same
//  queryClient in both atomWithQuery and other parts of the app. Without this,
//  our useQueryClient() hook will return a different QueryClient object
export const HydrateAtoms: FC<PropsWithChildren<{
  queryClient: QueryClient;
}>> = ({
  children,
  queryClient
}) => {
  useHydrateAtoms([[queryClientAtom, queryClient]]);

  // we need to wrap it with a fragment because it's not allowed for children to be a ReactNode
  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/18051
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};