/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const LTRDirectionDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    d: 'M3 3.5C3 3.22386 2.77614 3 2.5 3C2.22386 3 2 3.22386 2 3.5V12.5C2 12.7761 2.22386 13 2.5 13C2.77614 13 3 12.7761 3 12.5L3 3.5Z',
    fill: 'currentColor'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    d: 'M10.0303 4.46967C9.73744 4.17678 9.26256 4.17678 8.96967 4.46967C8.67678 4.76256 8.67678 5.23744 8.96967 5.53033L10.6893 7.25H5C4.58579 7.25 4.25 7.58579 4.25 8C4.25 8.41421 4.58579 8.75 5 8.75H10.6893L8.96967 10.4697C8.67678 10.7626 8.67678 11.2374 8.96967 11.5303C9.26256 11.8232 9.73744 11.8232 10.0303 11.5303L13.0303 8.53033C13.3232 8.23744 13.3232 7.76256 13.0303 7.46967L10.0303 4.46967Z',
    fill: 'currentColor'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="LTRDirectionDDPIcon" data-sentry-source-file="LTRDirectionDDPIcon.tsx" />;