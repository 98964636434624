import { FC } from 'react';
import { TooltipTypography, Typography } from '@jux/ui/components';
import { EDITOR_DDP_COLOR_FIELD_AUTOCOMPLETE_OPTION_VALUE_COLOR, SELECT_FIELD_BOX_TEXT_COLOR } from '@jux/ui/theme/palette';
import { AutocompleteRenderOptionProps } from '@jux/ui/components/editor/components/panels/DDP/base/fields/autocomplete';
import { MuiGrid, styled } from '@jux/ui/components/common/mui';
import { parseColorPickerValueToFormValue } from '@jux/ui/components/tokens/token-drawer/forms/colorTokenForm/ColorTokenForm.utils';
import { optionWrapperStyles } from '@jux/ui/components/editor/components/panels/DDP/base/fields/common/AutocompleteOption.style';
import { ColorIndicatorDiv } from './ColorIndicator';
const AutoCompleteColorOptionWrapper = styled(MuiGrid, {
  shouldForwardProp: prop => prop !== 'isSelected'
})<{
  isSelected?: boolean;
}>(({
  theme,
  isSelected
}) => ({
  ...optionWrapperStyles(theme, isSelected),
  gap: '8px',
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%'
}));
export const AutocompleteColorOption: FC<AutocompleteRenderOptionProps> = ({
  option: {
    label,
    value
  },
  isSelected
}) => {
  const {
    color: tokenHexValue
  } = parseColorPickerValueToFormValue(value);
  return <AutoCompleteColorOptionWrapper isSelected={isSelected} data-sentry-element="AutoCompleteColorOptionWrapper" data-sentry-component="AutocompleteColorOption" data-sentry-source-file="AutocompleteColorOption.tsx">
      <MuiGrid item flex={0} data-sentry-element="MuiGrid" data-sentry-source-file="AutocompleteColorOption.tsx">
        <ColorIndicatorDiv color={value} isTokenized={true} tokenHexValue={tokenHexValue} data-sentry-element="ColorIndicatorDiv" data-sentry-source-file="AutocompleteColorOption.tsx" />
      </MuiGrid>
      <TooltipTypography variant="label-s-regular" flex={1} color={theme => isSelected ? theme.drimz.palette.text.primary : SELECT_FIELD_BOX_TEXT_COLOR} data-sentry-element="TooltipTypography" data-sentry-source-file="AutocompleteColorOption.tsx">
        {label}
      </TooltipTypography>

      <Typography variant="autoCompleteColorOptionTypography" flex={0} sx={{
      Width: '35px',
      color: theme => isSelected ? EDITOR_DDP_COLOR_FIELD_AUTOCOMPLETE_OPTION_VALUE_COLOR : theme.drimz.palette.text.secondary
    }} data-sentry-element="Typography" data-sentry-source-file="AutocompleteColorOption.tsx">
        {tokenHexValue}
      </Typography>
    </AutoCompleteColorOptionWrapper>;
};