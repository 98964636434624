/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const CheckmarkIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M10.8662 5.32328C11.0614 5.12802 11.378 5.12802 11.5733 5.32328L11.9268 5.67683C12.1221 5.8721 12.1221 6.18868 11.9268 6.38394L6.75006 11.5607L4.07328 8.88394C3.87802 8.68868 3.87802 8.3721 4.07328 8.17683L4.42683 7.82328C4.6221 7.62802 4.93868 7.62802 5.13394 7.82328L6.75006 9.4394L10.8662 5.32328Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="CheckmarkIcon" data-sentry-source-file="CheckmarkIcon.tsx" />;