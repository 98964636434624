/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '../core/CustomIcon';
export const InstanceLayerIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M2.34497 8.00181L8.00181 2.34497L13.6587 8.00181L8.00181 13.6587L2.34497 8.00181ZM4.46629 8.00181L8.00181 11.5373L11.5373 8.00181L8.00181 4.46629L4.46629 8.00181Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="InstanceLayerIcon" data-sentry-source-file="InstanceLayerIcon.tsx" />;