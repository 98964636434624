import { forwardRef } from 'react';
import { Icons, Tooltip, Typography } from '@jux/ui/components';
import { MuiGrid, MuiLoadingButton, useTheme } from '@jux/ui/components/common/mui';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';
import { LoadingButtonWithIconProps } from './Button.interface';
import { typographyDefaultIconMargin } from './typographyDefaultIconMargin.util';
export const LoadingButtonWithIcon = forwardRef<HTMLButtonElement, LoadingButtonWithIconProps>(({
  tooltipTitle,
  disableTooltip,
  icon,
  loading,
  iconPosition = 'start',
  children,
  label,
  color,
  iconProps,
  typographyProps,
  tooltipProps,
  fullWidth,
  hideIcon,
  disabled: buttonDisabled,
  wrapperProps: {
    sx: wrapperPropsSx,
    onClick: wrapperPropsOnClick,
    ...wrapperProps
  } = {},
  typographyVariant,
  height,
  ...buttonProps
}, ref) => {
  const theme = useTheme();
  const defaultColor = loading ? theme.drimz.palette.transparent : color ?? theme.drimz.palette.text.button;
  const disabled = loading || buttonDisabled;
  const hasIcon = Boolean(icon && !hideIcon);

  // doing double check for the icon to avoid the warning
  const Icon = hasIcon && icon && <Icons variant={icon} {...iconProps} sx={mergeTyped({
    color: 'currentColor'
  }, iconProps?.sx)} />;
  return <Tooltip content={tooltipTitle ?? label} disabled={disableTooltip} {...tooltipProps}>
        <MuiGrid width={fullWidth ? '100%' : 'fit-content'} display="inline-grid" sx={mergeTyped({
      cursor: disabled ? 'not-allowed' : 'pointer',
      pointerEvents: disabled ? 'none' : 'auto',
      height: 'fit-content'
    }, wrapperPropsSx)} {...wrapperProps}>
          <MuiLoadingButton disabled={buttonDisabled} loading={loading} {...buttonProps} sx={mergeTyped({
        color: defaultColor as string,
        transition: theme.drimz.transitions.all,
        minWidth: 'fit-content',
        height
      }, buttonProps?.sx)} fullWidth={fullWidth} ref={ref}>
            {iconPosition === 'start' && Icon}
            {children && <Typography color={defaultColor} variant={typographyVariant ?? 'label-s-regular'} {...typographyProps} sx={mergeTyped({
          marginLeft: typographyDefaultIconMargin({
            hasIcon,
            shouldAddMargin: iconPosition === 'start'
          }),
          marginRight: typographyDefaultIconMargin({
            hasIcon,
            shouldAddMargin: iconPosition === 'end'
          }),
          color: 'currentColor',
          transition: [theme.drimz.transitions.all, theme.transitions.create(['color'], {
            duration: 0
          })],
          whiteSpace: 'nowrap'
        }, typographyProps?.sx)}>
                {label ?? children}
              </Typography>}
            {iconPosition === 'end' && Icon}
          </MuiLoadingButton>
        </MuiGrid>
      </Tooltip>;
});