import { FC } from 'react';
import { MuiGrid, useTheme } from '@jux/ui/components/common/mui';
import { BackgroundHead } from '@jux/ui/components';
import { AcceptInvite } from './AcceptInvite';
import { InviteErrorCard } from './InviteErrorCard';
export const InviteLayout: FC<{
  orgName: string;
  inviteToken: string;
  error?: string;
}> = ({
  error,
  orgName,
  inviteToken
}) => {
  const theme = useTheme();
  return <MuiGrid sx={{
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    width: '100%'
  }} data-sentry-element="MuiGrid" data-sentry-component="InviteLayout" data-sentry-source-file="InviteLayout.tsx">
      <BackgroundHead backgroundColor={theme.drimz.palette.background.hover} data-sentry-element="BackgroundHead" data-sentry-source-file="InviteLayout.tsx" />
      {error ? <InviteErrorCard error={error} /> : <AcceptInvite orgName={orgName} inviteToken={inviteToken} />}
    </MuiGrid>;
};