import { FC } from 'react';
import { ModulesKeys } from '../../DDP.interface';
import { Module } from '../../base';
import { useShouldRenderModuleByKey } from '../../hooks/useShouldRenderModuleByKey';
import { Color as ColorSubModule } from './sub-modules/color/Color';
const MODULE_KEY = ModulesKeys.color;
export const Color: FC = () => {
  const {
    shouldRender
  } = useShouldRenderModuleByKey(MODULE_KEY);
  if (!shouldRender) return null;
  return <Module moduleKey={MODULE_KEY} title="Color" data-sentry-element="Module" data-sentry-component="Color" data-sentry-source-file="Color.tsx">
      <ColorSubModule data-sentry-element="ColorSubModule" data-sentry-source-file="Color.tsx" />
    </Module>;
};