import { useCallback } from 'react';
import { Typography, useOpenConfirmationModal } from '@jux/ui/components';
export const useDeleteTokenSetConfirmationModal = () => {
  const {
    openConfirmationModal
  } = useOpenConfirmationModal();
  const openDeleteTokenSetConfirmationModal = useCallback(({
    onConfirm,
    tokenSetName,
    totalTokens
  }: {
    onConfirm: () => void;
    tokenSetName: string;
    totalTokens: number;
  }) => {
    openConfirmationModal({
      title: `Delete ‘${tokenSetName}’`,
      confirmText: 'Delete set',
      cancelText: 'Cancel',
      children: <Typography variant="body-regular" color={theme => theme.drimz.palette.text.secondary}>
            Deleting this set, will delete all its {totalTokens} tokens
            <br />
            Are you sure?
          </Typography>,
      onConfirm
    });
  }, [openConfirmationModal]);
  return {
    openDeleteTokenSetConfirmationModal
  };
};