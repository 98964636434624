import { FC } from 'react';
import { DesignTokenValue } from '@juxio/design-tokens';
import { TooltipTypography, Typography } from '@jux/ui/components';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { UnitType } from '../../../hooks';
export const DimensionFieldTokenContentValue: FC<{
  parsedTokenValue?: DesignTokenValue;
  unit?: UnitType;
}> = ({
  parsedTokenValue = '',
  unit
}) => {
  const numericValue = parseInt(parsedTokenValue.toString());
  const isNumericValue = isNaN(numericValue) === false;

  // TODO: refactor this
  // we're using this to get the unit from the parsedTokenValue cause there is a bug in which
  // the unit is not being set when selecting a token of an alias
  const tempUnit = parsedTokenValue.toString().replace(numericValue.toString(), '').toUpperCase();
  if (!isNumericValue) return <TooltipTypography color={theme => theme.drimz.palette.text.secondary} variant="label-xs-medium">
        {parsedTokenValue.toString()}
      </TooltipTypography>;
  return <MuiGrid item display="flex" gap="4px" flex={1} alignItems="center" justifyContent="flex-end" data-sentry-element="MuiGrid" data-sentry-component="DimensionFieldTokenContentValue" data-sentry-source-file="DimensionFieldTokenContentValue.tsx">
      {numericValue && <TooltipTypography color={theme => theme.drimz.palette.text.secondary} variant="label-xs-medium">
          {numericValue.toString()}
        </TooltipTypography>}
      <Typography variant="unitDimensionFieldTokenTypography" color={theme => theme.drimz.palette.text.secondary} data-sentry-element="Typography" data-sentry-source-file="DimensionFieldTokenContentValue.tsx">
        {unit ? unit.toUpperCase() : tempUnit}
      </Typography>
    </MuiGrid>;
};