import { DesignTokenTypeEnum, STROKE_STYLE_OPTIONS } from '@juxio/design-tokens';
import { FC, useMemo } from 'react';
import { useStoreActions } from '@jux/canjux/core';
import { FieldContainer, StyledSelectField, StyledTextField, TITLE_CREATE_NEW_TOKEN, TITLE_EDIT_TOKEN, TokenDrawerFormLayout, TokenDrawerIconLabel, TokenNameField } from '@jux/ui/components/tokens/token-drawer/base';
import { ALIAS_PLACEHOLDER_TEXT, BORDER_PATH_PREFIX, formatAliasPath, formatGroupPath, getSelectFieldPathOptions, parseBorderTokenValue, STROKE_STYLE_PLACEHOLDER_TEXT, useFormValueAlias, useInitialValues, useRegisterFieldFns, useValidateTokenName } from '@jux/ui/components/tokens/token-drawer/forms/helpers';
import { BorderTokenFormProps } from '@jux/ui/components/tokens/token-drawer/forms/types';
import { useTokenDrawer } from '@jux/ui/components/tokens/token-drawer/useTokenDrawer';
import { getUpdatedTokenData } from '@jux/ui/components/tokens/token-drawer/utils/getUpdatedTokenData';
import { useZodForm } from '@jux/ui/hooks/useZodForm';
import { borderTokenInputSchema } from '@jux/ui/trpc/validations';
import { createPath } from '@jux/ui/utils/tokensPath';
export const BorderTokenForm: FC<BorderTokenFormProps> = ({
  initialValues,
  groupPathOptions,
  groupPathCoreOptions,
  isCoreTokenSet,
  isCreatedFromDDP,
  componentsThatUseToken
}) => {
  const {
    tokenSetsActions: {
      setToken
    }
  } = useStoreActions();
  const defaultValues = {
    ...initialValues,
    value: parseBorderTokenValue(initialValues.value)
  };
  const form = useZodForm({
    schema: borderTokenInputSchema,
    defaultValues
  });
  const [registerTokenNameField, registerGroupPathField, registerAliasField, registerColorField, registerWidthField, registerStyleField, registerDescriptionField] = useRegisterFieldFns(form, ['tokenName', 'groupPath', 'alias', 'value.color', 'value.width', 'value.style', 'description']);

  // using controlled fields to support aliases.
  // each field has a default/initial value, but if the user has changed the value,
  // we want to use the value they have changed it to, rather than the default value.
  const [colorValue, widthValue, styleValue] = useInitialValues(['value.color', 'value.width', 'value.style'], {
    form,
    initialValues: defaultValues
  });
  const {
    hasPaths: hasGroups,
    optionsProps: groupsOptionsProps
  } = useMemo(() => getSelectFieldPathOptions({
    existingPaths: groupPathOptions,
    existingCorePaths: isCoreTokenSet || isCreatedFromDDP ? groupPathCoreOptions : [],
    forceCoreOnly: isCoreTokenSet && !isCreatedFromDDP
  }), [groupPathOptions, isCoreTokenSet, isCreatedFromDDP, groupPathCoreOptions]);
  const {
    aliasOptionsProps,
    hasAliases,
    aliasValue,
    isAliasToken,
    validateCircularAlias
  } = useFormValueAlias<typeof form>({
    form,
    isCoreTokenSet,
    isCreatedFromDDP,
    parseValue: parseBorderTokenValue,
    prefix: BORDER_PATH_PREFIX
  });
  const {
    handlers
  } = useTokenDrawer();
  const validateTokenName = useValidateTokenName({
    form
  });
  const handleSubmit = useMemo(() => form.handleSubmit(values => {
    if (validateTokenName() && validateCircularAlias()) {
      setToken({
        data: {
          ...values,
          alias: formatAliasPath(values.alias),
          isCore: isCoreTokenSet,
          value: {
            ...values.value
          }
        },
        type: DesignTokenTypeEnum.border
      });
      handlers.onSave(getUpdatedTokenData({
        tokenPath: createPath([values.groupPath, values.tokenName]),
        tokenSetId: values.tokenSetId
      }));
    }
  }, () => validateTokenName() && validateCircularAlias()), [form, validateTokenName, validateCircularAlias, setToken, isCoreTokenSet, handlers]);

  // TODO: remove this once we get the token color picker from DDP
  const colorOptions: string[] = ['rgba(243 5 95 / 100%)'];
  return <TokenDrawerFormLayout title={initialValues.tokenName ? TITLE_EDIT_TOKEN : TITLE_CREATE_NEW_TOKEN} componentsThatUseToken={componentsThatUseToken} onSubmit={handleSubmit} data-sentry-element="TokenDrawerFormLayout" data-sentry-component="BorderTokenForm" data-sentry-source-file="BorderTokenForm.tsx">
      <TokenDrawerIconLabel iconVariant="BORDER" labelText="Border" data-sentry-element="TokenDrawerIconLabel" data-sentry-source-file="BorderTokenForm.tsx" />
      <FieldContainer error={form.formState.errors.tokenName?.message} data-sentry-element="FieldContainer" data-sentry-source-file="BorderTokenForm.tsx">
        <TokenNameField error={Boolean(form.formState.errors.tokenName)} inputRef={registerTokenNameField().ref} {...registerTokenNameField()} data-sentry-element="TokenNameField" data-sentry-source-file="BorderTokenForm.tsx" />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.groupPath?.message} data-sentry-element="FieldContainer" data-sentry-source-file="BorderTokenForm.tsx">
        <StyledSelectField placeholder="Folder" defaultValue={initialValues.groupPath} renderValue={item => formatGroupPath(item, isCoreTokenSet && !isCreatedFromDDP)} error={Boolean(form.formState.errors.groupPath)} inputRef={registerGroupPathField().ref} disabled={!hasGroups} disableMenuPortal {...groupsOptionsProps} {...registerGroupPathField()} data-sentry-element="StyledSelectField" data-sentry-source-file="BorderTokenForm.tsx" />
      </FieldContainer>
      <FieldContainer data-sentry-element="FieldContainer" data-sentry-source-file="BorderTokenForm.tsx">
        <StyledSelectField disabled={!hasAliases} placeholder={ALIAS_PLACEHOLDER_TEXT} value={aliasValue} renderValue={item => formatGroupPath(item, isCoreTokenSet && !isCreatedFromDDP)} error={Boolean(form.formState.errors.alias)} inputRef={registerAliasField().ref} disableMenuPortal {...aliasOptionsProps} {...registerAliasField()} data-sentry-element="StyledSelectField" data-sentry-source-file="BorderTokenForm.tsx" />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.value?.color?.message} data-sentry-element="FieldContainer" data-sentry-source-file="BorderTokenForm.tsx">
        <StyledSelectField disabled={isAliasToken} placeholder="Color" value={colorValue} error={Boolean(form.formState.errors.value?.color)} options={colorOptions} inputRef={registerColorField().ref} disableMenuPortal {...registerColorField()} data-sentry-element="StyledSelectField" data-sentry-source-file="BorderTokenForm.tsx" />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.value?.width?.message} data-sentry-element="FieldContainer" data-sentry-source-file="BorderTokenForm.tsx">
        <StyledTextField disabled={isAliasToken} placeholder="Width" value={widthValue} error={Boolean(form.formState.errors.value?.width)} inputRef={registerWidthField().ref} {...registerWidthField()} data-sentry-element="StyledTextField" data-sentry-source-file="BorderTokenForm.tsx" />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.value?.style?.message} data-sentry-element="FieldContainer" data-sentry-source-file="BorderTokenForm.tsx">
        <StyledSelectField disabled={isAliasToken} placeholder={STROKE_STYLE_PLACEHOLDER_TEXT} value={styleValue} inputProps={{
        placeholder: 'Stroke Style'
      }} error={Boolean(form.formState.errors.value?.style)} inputRef={registerStyleField().ref} options={STROKE_STYLE_OPTIONS} disableMenuPortal {...registerStyleField()} data-sentry-element="StyledSelectField" data-sentry-source-file="BorderTokenForm.tsx" />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.description?.message} data-sentry-element="FieldContainer" data-sentry-source-file="BorderTokenForm.tsx">
        <StyledTextField inputProps={{
        placeholder: 'Token description'
      }} error={Boolean(form.formState.errors.description)} inputRef={registerDescriptionField().ref} {...registerDescriptionField()} data-sentry-element="StyledTextField" data-sentry-source-file="BorderTokenForm.tsx" />
      </FieldContainer>
    </TokenDrawerFormLayout>;
};