/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const StatusFailIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M6.93982 7.99998L3.58105 11.3587L4.64171 12.4194L8.00048 9.06064L11.3592 12.4194L12.4199 11.3587L9.06114 7.99998L12.4199 4.64123L11.3592 3.58057L8.00048 6.93932L4.64172 3.58057L3.58106 4.64123L6.93982 7.99998Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="StatusFailIcon" data-sentry-source-file="StatusFailIcon.tsx" />;