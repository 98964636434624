/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const TokenDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M8.00011 5.50004L10.1652 6.75004V9.25004L8.00011 10.5L5.83504 9.25004V6.75004L8.00011 5.50004Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M7.37511 1.49486C7.76186 1.27157 8.23836 1.27157 8.62511 1.49486L13.3213 4.20619C13.708 4.42948 13.9463 4.84213 13.9463 5.28872V10.7114C13.9463 11.1579 13.708 11.5706 13.3213 11.7939L8.62511 14.5052C8.23836 14.7285 7.76186 14.7285 7.37511 14.5052L2.67896 11.7939C2.2922 11.5706 2.05396 11.1579 2.05396 10.7114V5.28872C2.05396 4.84214 2.2922 4.42948 2.67896 4.20619L7.37511 1.49486ZM8.00011 2.86607L3.55396 5.43305V10.567L8.00011 13.134L12.4463 10.567V5.43305L8.00011 2.86607Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="TokenDDPIcon" data-sentry-source-file="TokenDDPIcon.tsx" />;