/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const ColumnGapDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M4 3.25C3.58579 3.25 3.25 3.58579 3.25 4C3.25 4.41421 3.58579 4.75 4 4.75H5.25V11.25H4C3.58579 11.25 3.25 11.5858 3.25 12C3.25 12.4142 3.58579 12.75 4 12.75H6C6.41421 12.75 6.75 12.4142 6.75 12V4C6.75 3.58579 6.41421 3.25 6 3.25H4Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    d: 'M10 3.25C9.58579 3.25 9.25 3.58579 9.25 4V12C9.25 12.4142 9.58579 12.75 10 12.75H12C12.4142 12.75 12.75 12.4142 12.75 12C12.75 11.5858 12.4142 11.25 12 11.25H10.75V4.75H12C12.4142 4.75 12.75 4.41421 12.75 4C12.75 3.58579 12.4142 3.25 12 3.25H10Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="ColumnGapDDPIcon" data-sentry-source-file="ColumnGapDDPIcon.tsx" />;