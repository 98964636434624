import { FC } from 'react';
import { selectedNodeDirectSource, selectedNodeVariants, useStore } from '@jux/canjux/core';
import { Instance, Props } from './modules';
import * as S from './ModulesMapping.style';
export const InstanceModulesMapping: FC = () => {
  const hasVariants = Boolean(useStore(selectedNodeVariants)?.length);
  const selectedInstanceNodeDirectSource = useStore(selectedNodeDirectSource);
  return <S.ModulesMappingWrapper data-sentry-element="unknown" data-sentry-component="InstanceModulesMapping" data-sentry-source-file="InstanceModulesMapping.tsx">
      <Instance data-sentry-element="Instance" data-sentry-source-file="InstanceModulesMapping.tsx" />
      {hasVariants && <Props sourceTagName={selectedInstanceNodeDirectSource?.tagName} hasInteractiveState={false} hasEditProperties={false} />}
    </S.ModulesMappingWrapper>;
};