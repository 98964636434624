/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const FlexDirectionRowDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M7.96967 4.46967C8.26256 4.17678 8.73744 4.17678 9.03033 4.46967L12.0303 7.46967C12.3232 7.76256 12.3232 8.23744 12.0303 8.53033L9.03033 11.5303C8.73744 11.8232 8.26256 11.8232 7.96967 11.5303C7.67678 11.2374 7.67678 10.7626 7.96967 10.4697L9.68934 8.75H4C3.58579 8.75 3.25 8.41421 3.25 8C3.25 7.58579 3.58579 7.25 4 7.25H9.68934L7.96967 5.53033C7.67678 5.23744 7.67678 4.76256 7.96967 4.46967Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="FlexDirectionRowDDPIcon" data-sentry-source-file="FlexDirectionRowDDPIcon.tsx" />;