import { forwardRef } from 'react';
import { LoadingButtonWithIcon as Button, LoadingButtonWithIconProps as ButtonProps } from '@jux/ui/components';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';
import { getVariantStyles, VariantsKeys, SizesKeys, getVariantProps, DEFAULT_BUTTON_SIZE } from './button.variants';
export type IconButtonProps = {
  variant: VariantsKeys;
  allowClickFromWrapper?: boolean;
  size?: SizesKeys;
} & Omit<ButtonProps, 'variant' | 'size'> & Pick<ButtonProps, 'onClick'>;
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(({
  onClick,
  variant,
  disabled,
  sx,
  allowClickFromWrapper,
  wrapperProps: {
    sx: wrapperPropsSx,
    ...wrapperProps
  } = {},
  size = DEFAULT_BUTTON_SIZE,
  ...props
}, ref) => <Button onClick={onClick} color={theme => theme.drimz.palette.text.primary} disabled={disabled} sx={mergeTyped(allowClickFromWrapper ? {} : getVariantStyles(variant, size, disabled), sx)} wrapperProps={{
  sx: mergeTyped(allowClickFromWrapper ? getVariantStyles(variant, size, disabled, true) : {}, wrapperPropsSx),
  ...wrapperProps
}} {...getVariantProps(variant, size)} {...props} ref={ref} />);