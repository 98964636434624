/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '../core/CustomIcon';
export const ToggleLayerIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M12.5 8C12.5 9.38071 11.3807 10.5 10 10.5C8.61929 10.5 7.5 9.38071 7.5 8C7.5 6.61929 8.61929 5.5 10 5.5C11.3807 5.5 12.5 6.61929 12.5 8ZM11 8C11 8.55228 10.5523 9 10 9C9.44771 9 9 8.55228 9 8C9 7.44772 9.44771 7 10 7C10.5523 7 11 7.44772 11 8Z'
  },
  child: []
}, {
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M6 3C3.23858 3 1 5.23858 1 8C1 10.7614 3.23858 13 6 13H10C12.7614 13 15 10.7614 15 8C15 5.23858 12.7614 3 10 3H6ZM6 4.5H10C11.933 4.5 13.5 6.067 13.5 8C13.5 9.933 11.933 11.5 10 11.5H6C4.067 11.5 2.5 9.933 2.5 8C2.5 6.067 4.067 4.5 6 4.5Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="ToggleLayerIcon" data-sentry-source-file="ToggleLayerIcon.tsx" />;