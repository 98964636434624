/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const ConstraintsBottomDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M5.25 5.75C5.25 5.33579 5.58579 5 6 5H10C10.4142 5 10.75 5.33579 10.75 5.75C10.75 6.16421 10.4142 6.5 10 6.5H8.75L8.75 9.25H12.5H14V9.75C14 10.3023 13.5523 10.75 13 10.75L3 10.75C2.44772 10.75 2 10.3023 2 9.75V9.25H3.5H7.25L7.25 6.5H6C5.58579 6.5 5.25 6.16421 5.25 5.75Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="ConstraintsBottomDDPIcon" data-sentry-source-file="ConstraintsBottomDDPIcon.tsx" />;