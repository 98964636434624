import { forwardRef, useImperativeHandle, useRef } from 'react';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';
import { TooltipTypography } from '../tooltipTypography';
import { TypographyElement } from '../typography';
import { EditableTypographyProps } from './EditableTypography.interface';
import { useEditableTypography } from './useEditableTypography';
export const EditableTypography = forwardRef<TypographyElement, EditableTypographyProps>(({
  onSaveChanges,
  label,
  isInitialLabel,
  disableEditing,
  customValidators,
  tooltipProps,
  autoFocus,
  onDiscardChanges,
  tooltipContent,
  tooltipHeader,
  onKeyDown,
  minLength,
  maxLength,
  sx,
  cursor = 'text',
  cursorOnNonEditable: disableEditingCursor = 'default',
  onPaste,
  onFocus,
  onBlur,
  ...typographyProps
}, ref) => {
  const currentTypographyRef = useRef<TypographyElement>(null);
  useImperativeHandle(ref, () => currentTypographyRef.current as TypographyElement, []);
  const {
    currentLabel,
    handleOnFocus,
    handleOnBlur,
    handleKeyDown,
    handleOnPaste,
    isFocused
  } = useEditableTypography({
    initialLabel: label,
    onSaveChanges,
    isInitialLabel,
    customValidators,
    autoFocus,
    onDiscardChanges,
    onKeyDown,
    onBlur,
    onFocus,
    currentTypographyRef,
    minLength,
    maxLength
  });
  return <TooltipTypography ref={currentTypographyRef} contentEditable={!disableEditing ?? true} suppressContentEditableWarning onKeyDown={handleKeyDown} onPaste={handleOnPaste} onFocus={handleOnFocus} onBlur={handleOnBlur} spellCheck={false} shouldEllipsis={!isFocused} tooltipContent={isContentOverflow => isContentOverflow ? currentLabel : tooltipContent} tooltipHeader={tooltipHeader} tooltipProps={tooltipProps} sx={mergeTyped({
    outline: 'none',
    cursor: disableEditing ? disableEditingCursor : cursor
  }, sx)} {...typographyProps}>
        {currentLabel}
      </TooltipTypography>;
});