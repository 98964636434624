import { useCallback } from 'react';
import { Typography, useOpenConfirmationModal } from '@jux/ui/components';
export const useDeletePropConfirmationModal = () => {
  const {
    openConfirmationModal
  } = useOpenConfirmationModal();
  const openDeletePropConfirmationModal = useCallback(({
    defaultValue,
    onConfirm,
    propName
  }: {
    defaultValue: string;
    onConfirm: () => void;
    propName: string;
  }) => {
    openConfirmationModal({
      title: `Delete ‘${propName}’ property`,
      confirmText: 'Delete and apply',
      cancelText: 'I’ve changed my mind',
      children: <Typography variant="body-regular" color={theme => theme.drimz.palette.text.secondary}>
            After deleting the property ‘{propName}’ the value ‘{defaultValue}’
            will be applied on all the variants
          </Typography>,
      onConfirm
    });
  }, [openConfirmationModal]);
  return {
    openDeletePropConfirmationModal
  };
};