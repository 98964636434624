import { FC } from 'react';
import { DDPPrimitiveFieldsKeys } from '@jux/ui/components/editor/state';
import { ColorField } from '../../../../base';
import { useFieldValues, useColorField, useSetFieldValue, useFieldOptions } from '../../../../hooks';
const FIELD_KEY = DDPPrimitiveFieldsKeys.color;
export const TextColorField: FC = () => {
  const {
    value,
    initialValue
  } = useFieldValues(FIELD_KEY);
  const {
    hasMultipleValues
  } = useFieldOptions(FIELD_KEY);
  const {
    saveValue: setFieldValue
  } = useSetFieldValue(FIELD_KEY);
  const props = useColorField({
    value,
    initialValue,
    fieldName: FIELD_KEY,
    saveChanges: setFieldValue
  });
  return <ColorField icon="DDP_FIELD_COLOR" tooltipTitle="Text color" {...props} hasMultipleValues={hasMultipleValues} data-sentry-element="ColorField" data-sentry-component="TextColorField" data-sentry-source-file="TextColorField.tsx" />;
};