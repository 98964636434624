import { FC, forwardRef, MouseEvent, ReactNode, RefObject, useState } from 'react';
import * as I from '../imported/components';
const INVITE_USERS_ICON = `<svg width="16" height="17" viewBox="0 0 16 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 9C12 8.72386 12.2239 8.5 12.5 8.5H15.5C15.7761 8.5 16 8.72386 16 9C16 9.27614 15.7761 9.5 15.5 9.5H12.5C12.2239 9.5 12 9.27614 12 9Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14 7C14.2761 7 14.5 7.22386 14.5 7.5V10.5C14.5 10.7761 14.2761 11 14 11C13.7239 11 13.5 10.7761 13.5 10.5V7.5C13.5 7.22386 13.7239 7 14 7Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.75 3.5C4.95507 3.5 3.5 4.95507 3.5 6.75C3.5 8.54493 4.95507 10 6.75 10C8.54493 10 10 8.54493 10 6.75C10 4.95507 8.54493 3.5 6.75 3.5ZM2.5 6.75C2.5 4.40279 4.40279 2.5 6.75 2.5C9.09721 2.5 11 4.40279 11 6.75C11 9.09721 9.09721 11 6.75 11C4.40279 11 2.5 9.09721 2.5 6.75Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.75002 10.9993C5.80082 10.9993 4.86314 11.2072 4.00288 11.6083C3.14262 12.0095 2.38066 12.5942 1.77056 13.3214C1.59307 13.5329 1.27769 13.5605 1.06615 13.383C0.854601 13.2055 0.826996 12.8901 1.00449 12.6786C1.70845 11.8396 2.58764 11.1649 3.58024 10.702C4.57285 10.2391 5.65479 9.99927 6.75002 9.99927C7.84526 9.99927 8.9272 10.2391 9.9198 10.702C10.9124 11.1649 11.7916 11.8396 12.4956 12.6786C12.6731 12.8901 12.6454 13.2055 12.4339 13.383C12.2224 13.5605 11.907 13.5329 11.7295 13.3214C11.1194 12.5942 10.3574 12.0095 9.49717 11.6083C8.63691 11.2072 7.69923 10.9993 6.75002 10.9993Z" fill="currentColor"/>
</svg>
`;
const MENU_VERTICAL_ICON = `<svg width="16" height="17" viewBox="0 0 16 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path d="M2.75 10C3.7165 10 4.5 9.2165 4.5 8.25C4.5 7.2835 3.7165 6.5 2.75 6.5C1.7835 6.5 1 7.2835 1 8.25C1 9.2165 1.7835 10 2.75 10Z" fill="currentColor"/>
<path d="M8.25 10C9.2165 10 10 9.2165 10 8.25C10 7.2835 9.2165 6.5 8.25 6.5C7.2835 6.5 6.5 7.2835 6.5 8.25C6.5 9.2165 7.2835 10 8.25 10Z" fill="currentColor"/>
<path d="M13.75 10C14.7165 10 15.5 9.2165 15.5 8.25C15.5 7.2835 14.7165 6.5 13.75 6.5C12.7835 6.5 12 7.2835 12 8.25C12 9.2165 12.7835 10 13.75 10Z" fill="currentColor"/>
</svg>
`;
const ICON_COLOR = '#1A1A1A';
const BUTTON_FOCUS_BORDER_COLOR = '#9e9e9e';
export type NavigationTokenProps = {
  inviteUsersIconButtonRef: RefObject<HTMLButtonElement>;
  menuVerticalIconButtonRef: RefObject<HTMLButtonElement>;
  onLogoClick: (e: MouseEvent<SVGSVGElement>) => void;
  onInviteUsersIconClick: (e: MouseEvent<HTMLButtonElement>) => void;
  onMenuVerticalIconClick: (e: MouseEvent<HTMLButtonElement>) => void;
  middleSlot: ReactNode;
  importButtonSlot: ReactNode;
};
export const NavigationToken: FC<NavigationTokenProps> = forwardRef<HTMLDivElement, NavigationTokenProps>(({
  inviteUsersIconButtonRef,
  menuVerticalIconButtonRef,
  onLogoClick,
  onInviteUsersIconClick,
  onMenuVerticalIconClick,
  middleSlot,
  importButtonSlot
}, ref) => {
  const [isInviteUsersIconFocused, setIsInviteUsersIconFocused] = useState(false);
  const [isMenuVerticalIconFocused, setIsMenuVerticalIconFocused] = useState(false);
  return <I.Navigation_tokens ref={ref} style={{
    width: '100%',
    padding: 0
  }} Navigation_tokens_Logoicon_Props={{
    style: {
      cursor: 'pointer',
      // focus state
      outline: 'none'
    },
    tabIndex: 0,
    onClick: onLogoClick
  }} Navigation_tokens_slot_Props={{
    style: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: 0,
      right: 0,
      margin: '0 auto',
      width: 'fit-content'
    },
    children: middleSlot
  }} Navigation_tokens_right_actions_Props={{
    Navigation_tokens_right_actions_actions_slot_Props: {
      IconButton_1_Props: {
        ref: inviteUsersIconButtonRef,
        onClick: onInviteUsersIconClick,
        style: {
          // focus state
          outline: 'none',
          ...(isInviteUsersIconFocused && {
            borderColor: BUTTON_FOCUS_BORDER_COLOR,
            borderRadius: '2px'
          })
        },
        onFocus: () => setIsInviteUsersIconFocused(true),
        onBlur: () => setIsInviteUsersIconFocused(false),
        disabled: false,
        hierarchy: 'primary',
        IconButton_Icon_Props: {
          content: INVITE_USERS_ICON,
          color: ICON_COLOR
        }
      },
      IconButton_2_Props: {
        ref: menuVerticalIconButtonRef,
        onClick: onMenuVerticalIconClick,
        style: {
          // focus state
          outline: 'none',
          ...(isMenuVerticalIconFocused && {
            borderColor: BUTTON_FOCUS_BORDER_COLOR,
            borderRadius: '2px'
          })
        },
        onFocus: () => setIsMenuVerticalIconFocused(true),
        onBlur: () => setIsMenuVerticalIconFocused(false),
        disabled: false,
        hierarchy: 'primary',
        IconButton_Icon_Props: {
          content: MENU_VERTICAL_ICON,
          color: ICON_COLOR
        }
      }
    },
    Navigation_tokens_right_actions_slot_Props: {
      children: importButtonSlot
    }
  }} />;
});