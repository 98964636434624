import { ComponentProps } from 'react';
import { styled } from '@jux/ui/components/common/mui';
import { Typography } from '@jux/ui/components';
import { mergeTyped } from '@jux/ui/utils/mergeTyped';

// TODO: add a proper design for this
export const AutocompleteNoOptionsTypography = styled(({
  sx,
  ...props
}: Omit<ComponentProps<typeof Typography>, 'variant'>) => <Typography sx={mergeTyped({
  color: theme => theme.drimz.palette.text.secondary,
  padding: '8px'
}, sx)} variant="label-s-regular" {...props} />)(() => ({}));