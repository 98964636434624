import { FC } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { AutocompleteProps } from './Autocomplete.interface';
import * as S from './Autocomplete.style';
import { HIGHLIGHTED_OPTION_STYLE } from './Autocomplete.style';
import { AutocompleteInput } from './AutocompleteInput';
import { useSelectFieldAutocomplete } from './useSelectFieldAutocomplete';
export const SelectFieldAutocomplete: FC<AutocompleteProps> = ({
  value,
  options: initialOptions,
  inputName,
  hideSearchIcon,
  isComputed,
  renderOption,
  onSelect,
  onBlur,
  onEnter,
  onEscape,
  inputProps,
  inputWrapperProps,
  wrapperProps,
  optionsWrapperProps,
  closeMenu,
  openMenu,
  isMenuOpen
}) => {
  const {
    getMenuProps,
    getInputProps,
    getItemProps,
    handleInputBlur,
    highlightedIndex,
    isOpen,
    options,
    handleEnter,
    handleEscape,
    optionsWrapperRef
  } = useSelectFieldAutocomplete({
    initialOptions,
    selectedOption: value,
    onSelect,
    onBlur,
    onEnter,
    onEscape,
    closeMenu,
    openMenu,
    isMenuOpen
  });
  return <MuiGrid container {...wrapperProps} data-sentry-element="MuiGrid" data-sentry-component="SelectFieldAutocomplete" data-sentry-source-file="SelectFieldAutocomplete.tsx">
      <AutocompleteInput wrapperProps={inputWrapperProps} isComputed={isComputed} name={inputName} hideSearchIcon={hideSearchIcon} {...inputProps} {...getInputProps()} onEnter={handleEnter} onBlur={handleInputBlur} onEscape={handleEscape} showValue data-sentry-element="AutocompleteInput" data-sentry-source-file="SelectFieldAutocomplete.tsx" />
      <S.AutocompleteOptions {...optionsWrapperProps} {...getMenuProps({
      ref: optionsWrapperRef
    })} hidden={!isOpen} data-sentry-element="unknown" data-sentry-source-file="SelectFieldAutocomplete.tsx">
        {options.length > 0 ? options.map((option, index) => <S.AutocompleteOptionWrapper item container key={`${option.label}${index}`} sx={{
        ...(highlightedIndex === index && HIGHLIGHTED_OPTION_STYLE)
      }} {...getItemProps({
        item: option,
        index
      })}>
              {renderOption({
          option: option,
          isSelected: option.label === value
        })}
            </S.AutocompleteOptionWrapper>) : <MuiGrid item container>
            <S.AutocompleteNoOptionsTypography>
              Nothing found
            </S.AutocompleteNoOptionsTypography>
          </MuiGrid>}
      </S.AutocompleteOptions>
    </MuiGrid>;
};