import { FC, useCallback } from 'react';
import { useColorField } from '../../../../hooks';
import { ColorField } from '../../../../base';
import { useShadowsFields } from './useShadowsFields';
export const ShadowColorField: FC = () => {
  const {
    value: boxShadowValue,
    initialValue,
    saveChanges
  } = useShadowsFields();
  const {
    color
  } = boxShadowValue || {};
  const {
    color: initialColor
  } = initialValue || {};
  const setFieldValue = useCallback((value: string | undefined, isChangeComplete?: boolean) => {
    saveChanges({
      color: value
    }, isChangeComplete);
  }, [saveChanges]);
  const props = useColorField({
    value: color,
    initialValue: initialColor,
    fieldName: 'boxShadowColor',
    saveChanges: setFieldValue
  });
  return <ColorField icon="DDP_FIELD_COLOR" tooltipTitle="Color" {...props} data-sentry-element="ColorField" data-sentry-component="ShadowColorField" data-sentry-source-file="ShadowColorField.tsx" />;
};