import { FC } from 'react';
import { IconButton } from '@jux/ui/components';
import { Module } from '../../base';
import { ModulesKeys } from '../../DDP.interface';
import { useInstance } from './useInstance';
const MODULE_KEY = ModulesKeys.instance;
export const Instance: FC = () => {
  const {
    instanceButtonLabel,
    shouldRender,
    handleInstanceButtonClick
  } = useInstance();
  if (!shouldRender) {
    return null;
  }
  return <Module moduleKey={MODULE_KEY} title="Instance" headerSize="small" headerChildren={<IconButton variant="secondary" typographyVariant="label-s-regular" onClick={handleInstanceButtonClick}>
          {instanceButtonLabel}
        </IconButton>} data-sentry-element="Module" data-sentry-component="Instance" data-sentry-source-file="Instance.tsx" />;
};