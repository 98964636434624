import { FC } from 'react';
import { MultiDirectionalDimensionField } from '../../../../base';
import { useMarginFields } from './useMarginFields';
export const MarginField: FC = () => {
  const {
    marginField,
    rawMarginValue,
    parsedRawMarginValue,
    isComputed
  } = useMarginFields();
  return <MultiDirectionalDimensionField icon="DDP_FIELD_MARGIN_ALL" tooltipTitle="Margin" {...marginField} rawValue={rawMarginValue} parsedRawValue={parsedRawMarginValue} isComputed={isComputed} data-sentry-element="MultiDirectionalDimensionField" data-sentry-component="MarginField" data-sentry-source-file="MarginField.tsx" />;
};