/* eslint-disable @jux/must-import-FC-in-tsx-files */
import { IconBaseProps, IconType } from 'react-icons';
import { CustomIcon } from '@jux/ui/components/common/icons/custom/core/CustomIcon';
export const HeightDDPIcon: IconType = (props: IconBaseProps) => <CustomIcon tag="svg" width="16" height="16" attr={{
  viewBox: '0 0 16 16'
}} child={[{
  tag: 'path',
  attr: {
    fill: 'currentColor',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    d: 'M10.75 12C10.75 12.4142 10.4142 12.75 10 12.75H6C5.58579 12.75 5.25 12.4142 5.25 12C5.25 11.5858 5.58579 11.25 6 11.25H7.25L7.25 4.75L6 4.75C5.58579 4.75 5.25 4.41421 5.25 4C5.25 3.58579 5.58579 3.25 6 3.25L10 3.25C10.4142 3.25 10.75 3.58579 10.75 4C10.75 4.41421 10.4142 4.75 10 4.75L8.75 4.75L8.75 11.25H10C10.4142 11.25 10.75 11.5858 10.75 12Z'
  },
  child: []
}]} {...props} data-sentry-element="CustomIcon" data-sentry-component="HeightDDPIcon" data-sentry-source-file="HeightDDPIcon.tsx" />;