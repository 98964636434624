import { getComponentSlotNames } from './utils/getComponentSlotNames';
import {
  CanjuxState,
  CommonActionsParams,
  CREATE_NEW_CANVAS_NODE_DISTANCE,
  createComponentInstanceCanvasNode,
  getNodeComputedStyles,
  getRootNodeOfNode,
  isChildOfNode,
  isInstanceExistInTree,
  JuxStoreActionFn,
  setLayersData,
  SnappedPosition,
} from '@jux/canjux/core';
import {
  NodeType,
  findComponentIdsWithNodeType,
  isInsideDynamicSlotOrInstance,
} from '@jux/data-entities';
import type { Draft as WritableDraft } from 'mutative';
import { CSSProperties } from 'react';
import {
  addStorageNode,
  removeStorageNode,
  reorderStorageNode,
} from '../../store.changes.utils';
import { getLocalComponentDependencies } from '../helpers/getLocalComponentDependencies';
import { setSelectedNodes } from './setSelectedNodes';
import {
  moveNodeToRoot,
  reorderParentChildrenOnInstances,
  setRootComponentUpdateTime,
  updateInstancesOnMovedNode,
} from './utils';
import { generateName } from '@jux/ui/utils/generateName';
import { toast } from '@jux/ui/toast';

const getIndexInParentByPosition = ({
  canvasNodesDimensions,
  canvasNodesPositions,
  targetPosition,
  targetNodeComponent,
  parentComputedStyles,
}: {
  canvasNodesDimensions: CanjuxState['canvasNodesDimensions'];
  canvasNodesPositions: CanjuxState['canvasNodesIndicatorsPositions'];
  targetPosition: SnappedPosition;
  targetNodeComponent: CanjuxState['components'][string];
  parentComputedStyles: Partial<CSSProperties>;
}) => {
  let targetParentChildren = targetNodeComponent.children;
  let currentIndex = 0;

  const { display, flexDirection, flexWrap } = parentComputedStyles;

  const isVertical =
    ((display === 'flex' || display === 'inline-flex') &&
      (flexDirection === 'column' ||
        flexDirection === 'column-reverse' ||
        flexWrap === 'wrap')) ||
    display === 'block';

  const isOrderReversed =
    flexDirection === 'row-reverse' ||
    flexDirection === 'column-reverse' ||
    flexWrap === 'wrap-reverse';

  if (isOrderReversed) {
    targetParentChildren = targetParentChildren.reverse();
  }

  // Go over all the children of the target node and find the index where last child is
  // before the target position and the next child is after the target position
  for (let i = 0; i < targetParentChildren.length; i++) {
    const childId = targetParentChildren[i];
    const childDimensions = canvasNodesDimensions[childId] || {
      height: 0,
      width: 0,
    };
    const childPosition = canvasNodesPositions[childId]?.positionAbsolute || {
      x: 0,
      y: 0,
    };

    const childCenterX = childPosition.x + childDimensions.width / 2;
    const childCenterY = childPosition.y + childDimensions.height / 2;
    if (isVertical) {
      if (targetPosition.ySnapped < childCenterY) {
        break;
      }
    } else if (targetPosition.xSnapped < childCenterX) {
      break;
    }

    currentIndex++;
  }

  if (isOrderReversed) {
    currentIndex = targetParentChildren.length - currentIndex;
  }

  return currentIndex;
};

const moveNodeToTarget = ({
  sourceNodeId,
  targetNodeId,
  targetIndex,
  targetPosition,
  state,
}: {
  sourceNodeId: string;
  targetNodeId: string;
  targetIndex: number;
  targetPosition?: SnappedPosition; // The position of the new root node if no targetNodeId is provided
  state: WritableDraft<CanjuxState>;
}): string[] | undefined => {
  const nodesToSelect: string[] = [];
  const currentCanvas = state.canvases[state.currentCanvasName];
  const sourceNodeComponent = state.components[sourceNodeId];
  const targetNodeComponent = state.components[targetNodeId];

  const isLogicalSlot = sourceNodeComponent.type === NodeType.LOGICAL_SLOT;
  const isSourceNodeAComponent =
    sourceNodeComponent.type === NodeType.LIBRARY_COMPONENT ||
    sourceNodeComponent.type === NodeType.LOCAL_COMPONENT;

  const isTargetInsideDynamicSlot = isInsideDynamicSlotOrInstance({
    componentId: targetNodeId,
    components: state.components,
  });

  const movedDynamicSlotIds = findComponentIdsWithNodeType({
    componentId: sourceNodeId,
    nodeType: NodeType.DYNAMIC_SLOT,
    components: state.components,
  });

  const isSourceWithLocalComponentDependencies =
    getLocalComponentDependencies({
      componentId: sourceNodeComponent.id,
      components: state.components,
    }).length > 0;

  const targetRootComponent = getRootNodeOfNode({
    components: state.components,
    nodeId: targetNodeComponent.id,
  });

  const isMovingSlotIntoSlot =
    !isSourceNodeAComponent &&
    movedDynamicSlotIds.length &&
    isTargetInsideDynamicSlot;

  const isTargetRootComponentAComponent =
    targetRootComponent.type === NodeType.LIBRARY_COMPONENT ||
    targetRootComponent.type === NodeType.LOCAL_COMPONENT;

  const isLibraryComponentContainingLocalInstances =
    targetRootComponent.type === NodeType.LIBRARY_COMPONENT &&
    (sourceNodeComponent.type === NodeType.LOCAL_COMPONENT ||
      isSourceWithLocalComponentDependencies);

  const sourceNodeData = currentCanvas.nodes[sourceNodeComponent.id];

  const sourceNodeParentComponent = sourceNodeComponent.parentId
    ? state.components[sourceNodeComponent.parentId]
    : null;

  // ----- checks -----

  // Logical slot can only move inside of it's parent
  if (isLogicalSlot && targetNodeId !== sourceNodeComponent.parentId) {
    return;
  }

  // Do not allow moving source node into one of its children
  // (if the target node is a descendant of source node, skip)
  if (isChildOfNode(state, targetNodeComponent.id, sourceNodeComponent.id)) {
    return;
  }

  // Do not allow moving an instance of a 'Local Component' into a 'Library Component'
  if (isLibraryComponentContainingLocalInstances) {
    toast.action(
      () => 'You cannot put a local component inside a library component',
      { autoClose: 2000 }
    );
    return;
  }
  if (isMovingSlotIntoSlot) {
    toast.action(
      () => 'You cannot put dynamic slot inside another dynamic slot',
      { autoClose: 2000 }
    );
    return;
  }

  // Do not allow inserting an instance node into its own source component
  // Check if target node is in a source component, and if source node has in his tree a node
  // that is a component instance of the root node we are about to insert.
  // Note: we have to check this with the internal nodes because we don't want to stop on
  //       the first instance, we want to catch instance inside instance, etc...
  if (
    isTargetRootComponentAComponent &&
    isInstanceExistInTree({
      components: state.components,
      nodeId: sourceNodeComponent.id,
      sourceComponentNodeId: targetRootComponent.id,
    })
  ) {
    return;
  }

  // Parent of dynamic slot cannot have other dynamic slots with the same name.
  // Rename moved dynamic slot node to be a unique and valid name
  if (movedDynamicSlotIds.length) {
    const existingDynamicSlotNamesInTarget = getComponentSlotNames({
      components: state.components,
      componentId: targetRootComponent.id,
    });
    if (existingDynamicSlotNamesInTarget.length) {
      for (const movedDynamicSlotId of movedDynamicSlotIds) {
        const movedSlotComponent = state.components[movedDynamicSlotId];
        movedSlotComponent.displayName = generateName({
          baseName: movedSlotComponent.displayName ?? '',
          namesArray: existingDynamicSlotNamesInTarget as string[],
          options: {
            formatters: ['formatCase', 'removeIllegalChars'],
          },
          separator: '_',
        });
      }
    }
  }

  let newIndexInParent = targetIndex;
  if (
    targetPosition &&
    (targetPosition.xSnapped !== 0 || targetPosition.ySnapped !== 0)
  ) {
    const computedStyles = getNodeComputedStyles(targetNodeComponent.id);
    newIndexInParent = getIndexInParentByPosition({
      canvasNodesDimensions: state.canvasNodesDimensions,
      canvasNodesPositions: state.canvasNodesIndicatorsPositions,
      targetPosition,
      targetNodeComponent,
      parentComputedStyles: computedStyles,
    });
  }

  // if the target is the parent of the source just reorder
  if (targetNodeId === sourceNodeComponent.parentId) {
    const wasMoved = reorderStorageNode(
      targetNodeComponent.children,
      sourceNodeId,
      newIndexInParent
    );

    if (wasMoved) {
      // TODO: if the parent is with flex we need to lock the position
      reorderParentChildrenOnInstances({
        sourceNodeId,
        targetIndex: newIndexInParent,
        components: state.components,
      });

      // If done under a component - update it's last 'updatedAt' time
      // it's ok to do it after the move because it's under the same parent
      setRootComponentUpdateTime({
        id: sourceNodeId,
        components: state.components,
      });
    }

    return;
  }

  // Remove node from parent or from rootNodes list
  const sourceParentNodesList = sourceNodeParentComponent
    ? sourceNodeParentComponent.children
    : currentCanvas.rootNodesOrder;

  if (isSourceNodeAComponent) {
    // create an instance on the canvas and add it to the target node
    const newInstanceId = createComponentInstanceCanvasNode({
      canvasName: state.currentCanvasName,
      componentId: sourceNodeId,
      parentId: targetNodeId,
      targetIndex: newIndexInParent,
      propsOverrides: sourceNodeComponent.config.props,
      state,
    });

    if (!newInstanceId) return;

    // Add node to the new parent
    addStorageNode(
      targetNodeComponent.children,
      newInstanceId,
      newIndexInParent
    );

    // update the instances of the instances of the source component and the target component
    updateInstancesOnMovedNode({
      sourceNodeId: newInstanceId,
      state,
      targetIndex: newIndexInParent,
      targetNodeId,
    });

    const targetRootNodePosition = currentCanvas.nodes[targetRootComponent.id]
      .position || { x: 0, y: 0 };
    const targetNodeDimensions = state.canvasNodesDimensions[targetNodeId] ?? {
      width: 0,
      height: 0,
    }; // Dynamic slot node is not rendered on canvas when it has no children, so default dimensions should be 0,0
    const sourceNodeDimensions = state.canvasNodesDimensions[sourceNodeId] ?? {
      width: 0,
      height: 0,
    };

    // move the source component 80px to the right relative to the target root node
    sourceNodeData.position = {
      x:
        targetRootNodePosition.x +
        targetNodeDimensions.width +
        sourceNodeDimensions.width +
        CREATE_NEW_CANVAS_NODE_DISTANCE,
      y: targetRootNodePosition.y,
    };

    // select the new created instances
    nodesToSelect.push(newInstanceId);
  } else {
    // moving the component out of its parent or from the root nodes (removing it)
    removeStorageNode(sourceParentNodesList, sourceNodeId);

    // Add node to new parent
    addStorageNode(
      targetNodeComponent.children,
      sourceNodeId,
      newIndexInParent
    );

    // remove all the instances of the source component and add them to the right place at the target component
    updateInstancesOnMovedNode({
      sourceNodeId,
      state,
      targetIndex: newIndexInParent,
      targetNodeId,
    });

    // If done under a component - update it's last 'updatedAt' time
    // Has to be done before changing the sourceNodeParentId
    setRootComponentUpdateTime({
      id: sourceNodeId,
      components: state.components,
    });

    sourceNodeComponent.parentId = targetNodeId;

    // Set new position
    // TODO: if the parent is with flex we need to lock the position
    sourceNodeData.position = { x: 0, y: 0 };
  }

  return nodesToSelect;
};

export const moveNodesInternal = ({
  sourceNodeIds,
  targetNodeId,
  targetPosition,
  targetIndex = 0,
  state,
}: {
  sourceNodeIds: string[];
  targetIndex?: number;
  targetNodeId?: string | null | undefined;
  targetPosition?: SnappedPosition; // The position of the new root node if no targetNodeId is provided
  state: WritableDraft<CanjuxState>;
}) => {
  let finalTargetNodeId = targetNodeId;
  let nodesToSelect: string[] | undefined = undefined;

  for (const sourceNodeId of sourceNodeIds) {
    const sourceNodeComponent = state.components[sourceNodeId];
    const currentCanvas = state.canvases[state.currentCanvasName];

    if (sourceNodeId === targetNodeId) continue;

    if (!currentCanvas.nodes[sourceNodeId].properties.isDraggable) continue;

    let finalSourceNodeId = sourceNodeId;
    if (
      sourceNodeComponent.type === NodeType.VARIANT_INSTANCE &&
      sourceNodeComponent?.sourceComponentId
    ) {
      // Check if this is the root of the variant inside a matrix - in this case do not allow to move
      const parentComponent = sourceNodeComponent?.parentId
        ? state.components[sourceNodeComponent.parentId]
        : undefined;
      if (parentComponent?.type === NodeType.VARIANTS_GROUP) {
        continue;
      }

      // If it's not the root, we allow to move it by moving ths source node
      finalSourceNodeId = sourceNodeComponent.sourceComponentId;
    }

    // If not target node insert as a root node
    if (!targetNodeId) {
      moveNodeToRoot({
        nodeId: finalSourceNodeId,
        targetPosition,
        targetIndex,
        state,
      });

      continue;
    }

    const isTargetContainer =
      currentCanvas.nodes[targetNodeId].properties.isContainer;
    if (!isTargetContainer) {
      // We can't drag into non-container nodes
      continue;
    }

    const targetNodeComponent = state.components[targetNodeId];
    if (
      targetNodeComponent.type === NodeType.VARIANT_INSTANCE &&
      targetNodeComponent?.sourceComponentId
    ) {
      // if this is a variant instance node - insert the node into the source component of the target node.
      // Notice - we don't need to check if the source node is a container because checked it over the
      // variant instance node which will tell us if the source is a container
      finalTargetNodeId = targetNodeComponent.sourceComponentId;
    }

    if (!finalTargetNodeId) continue; // This is not supposed to happen, just here for type safety

    nodesToSelect = moveNodeToTarget({
      sourceNodeId: finalSourceNodeId,
      targetNodeId: finalTargetNodeId,
      targetPosition,
      targetIndex,
      state,
    });
  }

  if (finalTargetNodeId) {
    setRootComponentUpdateTime({
      id: finalTargetNodeId,
      components: state.components,
    });
  }

  if (nodesToSelect && nodesToSelect.length) {
    setSelectedNodes({
      nodeIds: nodesToSelect,
      state,
    });
  }

  setLayersData(state);
};

/**
 * Move a node to a new parent.
 */
export const moveNodes: JuxStoreActionFn<
  CommonActionsParams['moveNodes'],
  CanjuxState
> = ({
  state,
  sourceNodeIds,
  targetNodeId,
  targetPosition,
  targetIndex = 0,
}) => {
  moveNodesInternal({
    sourceNodeIds,
    targetNodeId,
    targetPosition,
    targetIndex,
    state,
  });

  return state;
};
